<template>
  <div :class="$style.grid">
    <slot />
  </div>
</template>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
  max-width: 760px;
  margin-right: auto;
  margin-left: auto;
}
</style>
