import { createApp } from 'vue';
import { vfmPlugin } from 'vue-final-modal';
import {
  applyPolyfills,
  defineCustomElements,
// eslint-disable-next-line import/extensions
} from '@aws-amplify/ui-components/loader';
import { createNanoEvents } from 'nanoevents';
import { apolloProvider } from '@/vue-apollo';
import ganttastic from '@infectoone/vue-ganttastic';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import { init } from '@/aws-api';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App);

app.config.globalProperties.$bus = createNanoEvents();

app.component('EasyDataTable', Vue3EasyDataTable);

app.use(apolloProvider);
app.use(router);
app.use(store);
app.use(ganttastic);
app.use(vfmPlugin({
  key: '$vfm',
  componentName: 'VueFinalModal',
  dynamicContainerName: 'ModalsContainer',
}));

init();

app.mount('#app');
