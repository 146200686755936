<template>
  <label
    v-if="isVisible"
    :class="[
      $style.wrapper,
      {
        [$style.isActive]: isActive,
      },
    ]"
  >
    <span :class="$style.label">
      <slot />
    </span>
    <BaseRadio
      v-if="isVisible"
      v-model="modelProxy"
      is-dark
      :class="$style.radio"
      :name="name"
      :value="value"
      :disabled="isDisabled"
      @change="onChange"
    />
  </label>
</template>

<script>
import BaseRadio from '@/components/base/Radio';

export default {
  components: {
    BaseRadio,
  },
  props: {
    name: {
      type: null,
      default: undefined,
    },
    value: {
      type: null,
      default: undefined,
    },
    modelValue: {
      type: null,
      default: undefined,
    },
    isDisabled: {
      type: null,
      default: false,
    },
    id: {
      type: null,
      default: undefined,
    },
    isVisible: {
      type: null,
      default: true,
    },
  },
  emits: {
    'update:modelValue': null,
    change: null,
  },
  computed: {
    modelProxy: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    isActive() {
      return this.modelValue === this.value;
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', e);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 7px 5px 10px;
  background-color: rgba($background-light, 0);
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;

  &.isActive {
    background-color: rgba($background-light, 1);

    .label {
      font-weight: 600;
      color: rgba($title, 1);
    }
  }
}

.label {
  font-size: 16px;
  color: rgba($title, 0.8);
  transition: color 0.2s ease-in-out;
}

.radio {
  margin-left: 10px;
}
</style>
