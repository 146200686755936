// https://github.com/vuejs/vue-next/issues/4733#issuecomment-933284261
import { Comment } from 'vue';

export default function isVNodeEmpty(vnodes) {
  return (
    !vnodes
    || (Array.isArray(vnodes)
      ? vnodes.every((vnode) => vnode.type === Comment)
      : vnodes.type === Comment)
  );
}
