<template>
  <BaseLoader
    :class="$style.cluster"
    :is-active="isLoading"
  >
    <div :class="$style.header">
      <div :class="$style.titleContainer">
        <img :src="clusterData.brand.logo">
        <p :class="$style.title">
          {{ model.global_nameplate }} -
          {{ clusterData.body.name }} -
          {{ clusterData.country.code || "?" }} -
          Gen {{ clusterData.generation || "?" }}
        </p>
        <p :class="$style.powerTrain">
          {{ clusterData.powerTrain || clusterData?.cars[0]?.power_train }}
        </p>
      </div>
      <div
        v-if="!editMode && clusterData.cars.length"
        :class="$style.clusterButtonsContainer"
      >
        <BaseButton
          type="syncSopEop"
          variant="secondary"
          :class="$style.clusterButton"
          @click="syncSopEop"
        >
          Sync SOP & EOP
        </BaseButton>
        <BaseButton
          type="setEditMode"
          variant="secondary"
          :class="$style.clusterButton"
          @click="setEditMode(true)"
        >
          Edit cluster
        </BaseButton>
      </div>
    </div>

    <template v-if="!editMode">
      <template v-if="clusterData.cars.length">
        <ClusterCarCard
          v-for="(car, index) in clusterData.cars"
          :key="car.id"
          :car="car"
          :is-first="index === 0"
          :is-last="index === clusterData.cars.length - 1"
          :cluster-sop="clusterData.sop"
          :cluster-eop="clusterData.eop"
          @car-removed="updateCluster(car)"
          @car-updated="editCar(car, index, $event)"
          @phase-added="increasePhases(index, $event)"
        />
      </template>

      <div
        v-else
        :class="$style.emptyClusterContainer"
      >
        <p :class="$style.emptyClusterLabel">
          This cluster is empty.
        </p>
        <div :class="$style.addLmiCarButtonContainer">
          <BaseButton
            type="addLmiCar"
            variant="secondary"
            :class="$style.addLmiCarButton"
            @click="addLmiCar()"
          >
            Add LMI car
          </BaseButton>
        </div>
      </div>

      <div :class="$style.sourceCarsButtonContainer">
        <BaseButton
          type="toggleShowSourceCars"
          variant="secondary"
          :class="$style.clusterButton"
          @click="toggleShowSourceCars()"
        >
          {{ showSourceCars ? 'Hide' : 'Show' }} source cars
        </BaseButton>
      </div>
      <div
        v-if="showSourceCars"
        :class="$style.sourceCarsContainer"
      >
        <p
          v-if="clusterData.sourceCars.length"
          :class="$style.title"
        >
          Source cars
        </p>
        <Draggable
          v-model="clusterData.sourceCars"
          :class="$style.sourceCarsCardsContainer"
          :ghost-class="$style.sourceCarCardGhost"
          :fallback-class="$style.sourceCarCardDragging"
          group="sourceCars"
          item-key="id"
          :force-fallback="true"
          @change="moveSourceCarBetweenClusters"
        >
          <template #item="{ element: car }">
            <div :class="$style.sourceCarCard">
              <BaseLabel
                v-if="car.source == 'supplierOne'"
                color="aqua"
              >
                {{ car.source }}
              </BaseLabel>
              <BaseLabel
                v-if="car.source == 'supplierTwo'"
                color="lime"
              >
                {{ car.source }}
              </BaseLabel>
              <p :class="$style.title">
                {{ car.global_nameplate }} -
                {{ car.body_type }} -
                {{ car.source_plant_country_territory }} -
                {{ car.body_style_program_code }} -
                {{ car.segment }}
              </p>
              <div :class="$style.infoContainer">
                <div :class="$style.side">
                  <p :class="$style.info">
                    ID : {{ car.id }}
                  </p>
                  <p :class="$style.info">
                    Generation : {{ car.generation }}
                  </p>
                  <p :class="$style.info">
                    Source country : {{ car.country?.name }}
                  </p>
                </div>
                <div :class="$style.side">
                  <p :class="$style.info">
                    SOP : {{ formatSopEop(car.sop) }}
                  </p>
                  <p :class="$style.info">
                    EOP : {{ formatSopEop(car.eop) }}
                  </p>
                  <p :class="$style.info">
                    Production plant : {{ car.production_plant }}
                  </p>
                </div>
              </div>
              <p :class="$style.ref">
                Ref : {{ car.lighting_cluster_reference }}
              </p>
              <p :class="$style.ref">
                Ref 2 : {{ car.lighting_cluster_reference_2 }}
              </p>
            </div>
          </template>
          <template
            v-if="!clusterData.sourceCars.length"
            #header
          >
            <p>This cluster has no source cars.</p>
          </template>
        </Draggable>
      </div>
    </template>

    <FormBox
      v-else
      heading="Edit cluster"
    >
      <BaseField :class="$style.field">
        <template #label>
          Generation
        </template>
        <BaseInput
          v-model="generation"
          type="number"
        />
      </BaseField>
      <BaseField
        :class="$style.field"
      >
        <template #label>
          Power train
        </template>
        <BaseInput
          v-model="powerTrain"
          type="text"
        />
      </BaseField>

      <template #footer>
        <div :class="$style.editionFormButtonsContainer">
          <BaseButton
            type="setEditMode"
            variant="secondary"
            @click="setEditMode(false)"
          >
            Cancel
          </BaseButton>
          <BaseButton
            type="editCluster"
            variant="success"
            @click="editCluster"
          >
            Submit
          </BaseButton>
        </div>
      </template>
    </FormBox>
  </BaseLoader>
</template>

<script>
import formatSopEop from '@/utils/formatSopEop';
import cars from '@/graphql/queries/cars';
import { getMaxEop, getMinSop } from '@/utils/getMinSopAndMaxEop';
import ClusterCarCard from '@/components/ClusterCarCard';
import BaseLabel from '@/components/base/Label';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseInput from '@/components/base/Input';
import BaseButton from '@/components/base/Button';
import getLightingClusterReferences from '@/utils/getLightingClusterReferences';
import { isEmpty, remove } from 'lodash';
import Draggable from 'vuedraggable';

export default ({
  components: {
    ClusterCarCard,
    BaseLoader,
    FormBox,
    BaseField,
    BaseInput,
    BaseButton,
    Draggable,
    BaseLabel,
  },
  props: {
    carCluster: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
  },
  emits: {
    emptyCluster: null,
  },
  data() {
    return {
      clusterData: null,
      showSourceCars: true,
      editMode: false,
      generation: null,
      powerTrain: null,
      isLoading: false,
    };
  },
  created() {
    this.clusterData = this.carCluster;
    this.generation = this.carCluster.generation;
    this.powerTrain = this.carCluster.powerTrain;
  },
  methods: {
    formatSopEop,
    getLightingClusterReferences,
    syncSopEop() {
      this.isLoading = true;
      const clusterCars = this.clusterData.cars;
      if (clusterCars.length === 0) {
        this.isLoading = false;
        return false;
      }
      const firstCar = clusterCars[0];
      const lastCar = clusterCars[clusterCars.length - 1];
      if (clusterCars.length === 1) {
        cars.updateCarByPkSopEop(
          firstCar.id, null, null,
        ).then(() => {
          clusterCars[0] = { ...firstCar, sop: this.clusterData.sop, eop: this.clusterData.eop };
          this.isLoading = false;
        });
      } else {
        Promise.all([
          cars.updateCarByPkSopEop(firstCar.id, null, firstCar.eop),
          cars.updateCarByPkSopEop(lastCar.id, lastCar.sop, null),
        ]).then(() => {
          clusterCars[0] = { ...firstCar, sop: this.clusterData.sop };
          clusterCars[clusterCars.length - 1] = { ...lastCar, eop: this.clusterData.eop };
          this.isLoading = false;
        });
      }

      return true;
    },
    updateCluster(car) {
      this.isLoading = true;
      const index = this.carCluster.cars.indexOf(car);
      this.decreasePhases(index, car);
    },
    decreasePhases(index, carToBeRemoved) {
      const clusterCars = this.carCluster.cars;
      if (index === 0) {
        if (clusterCars.length > 1) {
          clusterCars[1] = { ...clusterCars[1], sop: carToBeRemoved.sop };
          cars.updateCarByPkSopEop(clusterCars[1].id, clusterCars[1].sop, clusterCars[1].eop);
        }
      } else {
        clusterCars[index - 1] = { ...clusterCars[index - 1], eop: carToBeRemoved.eop };
        cars.updateCarByPkSopEop(clusterCars[index - 1].id, clusterCars[index - 1].sop, clusterCars[index - 1].eop);
      }
      const updateRequests = [];
      updateRequests.push(cars.deleteLmiCarByPk(carToBeRemoved.id));
      for (let i = index + 1; i < clusterCars.length; i += 1) {
        clusterCars[i] = { ...clusterCars[i], phase: clusterCars[i].phase - 1 };
        updateRequests.push(cars.updateCarByPkPhase(clusterCars[i].id, clusterCars[i].phase));
      }
      Promise.all(updateRequests).then(() => {
        remove(this.carCluster.cars, (clusterCar) => clusterCar.id === carToBeRemoved.id);
        if (isEmpty(this.carCluster.cars) && isEmpty(this.carCluster.sourceCars)) {
          this.$emit('emptyCluster', this.carCluster);
        }
        this.isLoading = false;
        this.syncSopEop();
      });
    },
    increasePhases(index, { newCar, setShowAddPhaseBox }) {
      this.isLoading = true;
      const clusterCars = this.carCluster.cars;
      clusterCars[index] = { ...clusterCars[index], eop: newCar.sop };
      cars.updateCarByPkSopEop(clusterCars[index].id, clusterCars[index].sop, clusterCars[index].eop);
      const updateRequests = [];
      updateRequests.push(cars.insertLmiCar(newCar));
      for (let i = index + 1; i < clusterCars.length; i += 1) {
        clusterCars[i] = { ...clusterCars[i], phase: clusterCars[i].phase + 1 };
        updateRequests.push(cars.updateCarByPkPhase(clusterCars[i].id, clusterCars[i].phase));
      }
      Promise.all(updateRequests).then((results) => {
        const [insertResult] = results;
        // eslint-disable-next-line no-param-reassign
        newCar.id = insertResult.id;
        clusterCars.splice(index + 1, 0, newCar);
        this.isLoading = false;
        setShowAddPhaseBox(false);
        this.syncSopEop();
      });
    },
    editCluster() {
      this.isLoading = true;
      const refs = getLightingClusterReferences(
        this.clusterData.brand.id,
        this.model.modelid,
        this.clusterData.body.id,
        this.clusterData.country.id,
        this.generation,
        this.clusterData.projectCode,
      );
      const updatedValues = {
        generation: this.generation,
        powerTrain: this.powerTrain,
        power_train: this.powerTrain,
        lighting_cluster_reference: refs.lightingClusterReference,
        lighting_cluster_reference_2: refs.lightingClusterReference2,
      };
      this.clusterData = { ...this.clusterData, ...updatedValues };
      delete updatedValues.powerTrain;
      const updateRequests = [];
      this.clusterData.cars.forEach((car, index) => {
        this.clusterData.cars[index] = { ...car, ...updatedValues };
        updateRequests.push(
          cars.updateCarByPkGenPowerTrain(
            car.id,
            this.generation,
            this.powerTrain,
            refs.lightingClusterReference,
            refs.lightingClusterReference2,
          ),
        );
      });
      this.clusterData.sourceCars.forEach((car, index) => {
        this.clusterData.sourceCars[index] = { ...car, ...updatedValues };
        updateRequests.push(
          cars.updateSourceCarByPkGenPowerTrain(
            car.source,
            car.id,
            this.generation,
            this.powerTrain,
            refs.lightingClusterReference,
            refs.lightingClusterReference2,
          ),
        );
      });
      Promise.all(updateRequests).then(() => {
        this.setEditMode(false);
        this.isLoading = false;
      });
    },
    addLmiCar() {
      this.isLoading = true;
      const generation = this.clusterData.generation || 1;
      const { powerTrain } = this.clusterData;
      const {
        lightingClusterReference,
        lightingClusterReference2,
      } = getLightingClusterReferences(
        this.clusterData.brand.id,
        this.model.modelid,
        this.clusterData.body.id,
        this.clusterData.country.id,
        generation,
        this.clusterData.projectCode,
      );
      const newCar = {
        brand_id: this.clusterData.brand.id,
        model_id: this.model.modelid,
        body_id: this.clusterData.body.id,
        country_id: this.clusterData.country.id,
        generation,
        phase: 1,
        project_code: this.clusterData.projectCode,
        power_train: powerTrain,
        model: this.model.global_nameplate,
        production_type: 'CBU',
        sop: null,
        eop: null,
        lighting_cluster_reference: lightingClusterReference,
        lighting_cluster_reference_2: lightingClusterReference2,
        segment: this.clusterData.segment,
      };
      cars.insertLmiCar(newCar).then((result) => {
        this.clusterData.cars.push({
          ...newCar,
          id: result.id,
        });
        const updateRequests = [];
        const updatedValues = {
          generation,
          powerTrain,
          lighting_cluster_reference: lightingClusterReference,
          lighting_cluster_reference_2: lightingClusterReference2,
        };
        this.clusterData = { ...this.clusterData, ...updatedValues };
        this.clusterData.sourceCars.forEach((sourceCar, index) => {
          this.clusterData.sourceCars[index] = { ...sourceCar, ...updatedValues };
          updateRequests.push(
            cars.updateSourceCarByPkGenPowerTrain(
              sourceCar.source,
              sourceCar.id,
              generation,
              powerTrain,
              lightingClusterReference,
              lightingClusterReference2,
            ),
          );
        });
        Promise.all(updateRequests).then(() => {
          this.isLoading = false;
        });
      });
    },
    editCar(car, index, updatedValues) {
      this.isLoading = true;
      const clusterCars = this.clusterData.cars;
      const isFirst = index === 0;
      const isLast = index === clusterCars.length - 1;
      const updateRequests = [];
      updateRequests.push(cars.updateCarByPkSopEopPhase(
        car.id,
        updatedValues.sop,
        updatedValues.eop,
        updatedValues.phase,
        updatedValues.comment,
      ));
      clusterCars[index].sop = updatedValues.sop;
      clusterCars[index].eop = updatedValues.eop;
      clusterCars[index].phase = updatedValues.phase;
      clusterCars[index].comment = updatedValues.comment;
      if (!isLast) clusterCars[index + 1].sop = updatedValues.eop;
      if (!isFirst) clusterCars[index - 1].eop = updatedValues.sop;
      for (let i = 1; index + i < clusterCars.length; i += 1) {
        clusterCars[index + i].phase = updatedValues.phase + i;
        updateRequests.push(cars.updateCarByPkPhase(clusterCars[i].id, clusterCars[i].phase));
      }
      for (let i = 1; index - i >= 0; i += 1) {
        clusterCars[index - i].phase = updatedValues.phase - i;
        updateRequests.push(cars.updateCarByPkPhase(clusterCars[i].id, clusterCars[i].phase));
      }
      Promise.all(updateRequests).then(() => { this.isLoading = false; });
    },
    moveSourceCarBetweenClusters(event) {
      if (event.moved) return;
      const lmiCars = this.clusterData.cars;
      const { sourceCars } = this.clusterData;
      if (event.added) {
        this.isLoading = true;
        const sourceCar = event.added.element;
        Promise.all([
          cars.updateSourceCarByPkGenRef2(
            sourceCar.source,
            sourceCar.id,
            this.clusterData.generation,
            this.clusterData.lighting_cluster_reference_2,
          ),
          cars.deleteSourceCarFromClusterByPk(sourceCar.id),
        ]).then(() => {
          cars.insertSourceCarInCluster(
            sourceCar.id,
            sourceCar.lighting_cluster_reference,
            sourceCar.generation,
            this.clusterData.generation,
          ).then(() => {
            const minSop = getMinSop(sourceCars);
            const maxEop = getMaxEop(sourceCars);
            this.clusterData.sop = minSop;
            this.clusterData.eop = maxEop;
            sourceCar.generation = this.clusterData.generation;
            sourceCar.lighting_cluster_reference_2 = this.clusterData.lighting_cluster_reference_2;
            this.isLoading = false;
          });
        });
      } else if (!isEmpty(sourceCars)) {
        const minSop = getMinSop(sourceCars);
        const maxEop = getMaxEop(sourceCars);
        this.clusterData.sop = minSop;
        this.clusterData.eop = maxEop;
      } else if (!isEmpty(lmiCars)) {
        this.clusterData.sop = lmiCars[0].sop;
        this.clusterData.eop = lmiCars[lmiCars.length - 1].eop;
      } else {
        this.$emit('emptyCluster', this.carCluster);
      }
    },
    toggleShowSourceCars() {
      this.showSourceCars = !this.showSourceCars;
    },
    setEditMode(value) {
      this.editMode = value;
    },
  },
});
</script>
<style lang="scss" module>
.cluster {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 375px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-radius: 10px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .titleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-weight: bold;
      color: $title;
    }

    img {
      width: 35px;
    }

    .title {
      margin: 0;
    }

    .powerTrain {
      width: 35px;
      margin: 0;
      text-align: end;
    }
  }

  .clusterButtonsContainer {
    display: flex;
    gap: 20px;
  }

  .clusterButton {
    background-color: #fff;
    transition: background-color 0.15s ease-in-out;

    &:hover,
    &:focus {
      background-color: #dadada;
    }
  }

  .emptyClusterContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 10px;
  }

  .emptyClusterLabel {
    margin: 0;
    text-align: center;
  }

  .addLmiCarButtonContainer {
    display: flex;
    justify-content: center;
  }

  .addLmiCarButton {
    color: #fff;
    background-color: #f00;
    transition: background-color 0.15s ease-in-out;

    &:hover,
    &:focus {
      background-color: #c00;
    }
  }

  .sourceCarsButtonContainer {
    display: flex;
    justify-content: center;
  }

  .sourceCarsContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 10px;

    p {
      margin: 0;
    }
  }

  .sourceCarsCardsContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #fff;
    border-radius: 10px;

    p {
      margin: 0;
    }

    .title {
      font-weight: bold;
      color: $title;
    }

    .sourceCarCard {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 5px;
      background-color: rgb(197, 197, 197);
      border-radius: 10px;

      .infoContainer {
        display: flex;
        gap: 10px;
        width: 100%;

        .side {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 50%;
        }

        .info {
          margin: 0;
        }
      }

      .ref {
        word-break: break-word;
      }
    }

    .sourceCarCardGhost {
      border: 3px solid $title;
      opacity: 0.5;
    }

    .sourceCarCardDragging {
      border: 3px solid $title;
      opacity: 1;
    }
  }

  .field:not(:first-child) {
    margin-top: 20px;
  }

  .editionFormButtonsContainer {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
}
</style>
