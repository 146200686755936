<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.ganttsLoader"
  >
    <g-gantt-chart
      v-if="!isLoading"
      chart-start="2017-01"
      chart-end="2029-12"
      precision="month"
      bar-start="sop"
      bar-end="eop"
      date-format="YYYY-MM"
      :grid="true"
    >
      <g-gantt-row
        v-for="car in refsToDisplay"
        :key="car.ref"
        :bars="car.cars"
      >
        <template #label>
          <div>{{ car.ref }}</div>
        </template>
      </g-gantt-row>
    </g-gantt-chart>
  </BaseLoader>
</template>

<script>
import cars from '@/graphql/queries/cars';
import BaseLoader from '@/components/base/Loader';
import { isEmpty } from 'lodash';
import moment from 'moment';

export default ({
  components: {
    BaseLoader,
  },
  props: {
    brandId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      refsToDisplay: [],
      isLoading: true,
    };
  },
  async created() {
    this.isLoading = true;
    const lmiCarsByBrand = await cars.getLmiCarsByBrand(this.brandId);
    let endedRequest = 0;
    const numberOfRequests = lmiCarsByBrand.length;
    lmiCarsByBrand.forEach((lmiCar) => {
      cars.getSourceCarsSopEopAggregate(lmiCar.lighting_cluster_reference_2).then((result) => {
        const clusterSop = result.aggregate.min.sop;
        const clusterEop = result.aggregate.max.eop;
        const clusterCars = result.nodes;
        const ganttCars = [];
        clusterCars.forEach((car) => {
          const sop = car.sop || clusterSop;
          const eop = car.eop || clusterEop;
          const carData = {
            ref: car.id,
            sop: moment(sop).format('YYYY-MM'),
            eop: moment(eop).format('YYYY-MM'),
            ganttBarConfig: {
              id: car.id,
              style: {
                color: 'white',
                opacity: 0.2,
                borderRadius: '10px',
                backgroundColor: 'blue',
                height: '20px',
              },
              immobile: true,
            },
          };
          ganttCars.push(carData);
        });
        if (!isEmpty(clusterCars)) {
          this.refsToDisplay.push({
            ref: `${lmiCar.model}, ${lmiCar.body}, Gen. ${lmiCar.generation}, ${lmiCar.country.code}`,
            cars: ganttCars,
          });
        }
        endedRequest += 1;
        if (endedRequest === numberOfRequests) {
          this.refsToDisplay = this.refsToDisplay.sort((gen1, gen2) => gen1.ref.localeCompare(gen2.ref));
          this.isLoading = false;
        }
      });
    });
  },
});
</script>
<style lang="scss" module>
.ganttsLoader {
  width: 100%;
  min-height: 200px;
}
</style>
