<template>
  <div :class="$style.page">
    <BaseContainer :class="$style.container">
      <form
        :class="$style.formContainer"
        @submit.prevent="getReferences"
      >
        <FormBox
          :class="$style.formBox"
        >
          <BaseField
            :class="$style.field"
          >
            <template #label>
              Brand ID
            </template>
            <BaseInput
              v-model="brandId"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Model ID
            </template>
            <BaseInput
              v-model="modelId"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Body ID
            </template>
            <BaseInput
              v-model="bodyId"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Country ID
            </template>
            <BaseInput
              v-model="countryId"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Generation
            </template>
            <BaseInput
              v-model="generation"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Project Code
            </template>
            <BaseInput
              v-model="projectCode"
            />
          </BaseField>

          <template #footer>
            <BaseButton
              variant="success"
              type="getReferences"
              is-block
            >
              SUBMIT
            </BaseButton>
          </template>
        </FormBox>
      </form>
      <div
        v-if="lightingClusterReference && lightingClusterReference2"
        :class="$style.lightingClusterReferences"
      >
        <p :class="$style.label">
          Lighting cluster reference :
        </p>
        <p :class="$style.value">
          {{ lightingClusterReference }}
        </p>
        <p :class="$style.label">
          Lighting cluster reference 2 :
        </p>
        <p :class="$style.value">
          {{ lightingClusterReference2 }}
        </p>
      </div>
    </BaseContainer>
  </div>
</template>
<script>
import BaseContainer from '@/components/base/Container';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseInput from '@/components/base/Input';
import BaseButton from '@/components/base/Button';
import getLightingClusterReferences from '@/utils/getLightingClusterReferences';

export default ({
  components: {
    FormBox,
    BaseField,
    BaseInput,
    BaseContainer,
    BaseButton,
  },
  data() {
    return {
      brandId: null,
      modelId: null,
      bodyId: null,
      countryId: null,
      generation: null,
      projectCode: null,
      lightingClusterReference: null,
      lightingClusterReference2: null,
    };
  },
  methods: {
    getReferences() {
      const refs = getLightingClusterReferences(
        this.brandId,
        this.modelId,
        this.bodyId,
        this.countryId,
        this.generation,
        this.projectCode,
      );
      this.lightingClusterReference = refs.lightingClusterReference;
      this.lightingClusterReference2 = refs.lightingClusterReference2;
    },
  },
});
</script>
<style lang="scss" module>
.page {
  padding-top: map-get($page-vertical-paddings, 'regular');
  padding-bottom: map-get($page-vertical-paddings, 'regular');
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formContainer {
  width: 100%;
  max-width: 500px;
}

.formBox {
  border: 0;
}

.field:not(:first-child) {
  margin-top: 20px;
}

.lightingClusterReferences {
  word-break: break-word;

  .label {
    margin: 0;
  }

  .value {
    margin-top: 5px;
  }
}
</style>
