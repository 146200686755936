<template>
  <div :class="$style.actionsColumn">
    <div
      :class="$style.actionsColumnHeader"
      :style="{ height: headerHeight }"
    >
      <BaseDropdown
        placement="bottom-end"
        :offset="[9, 7]"
      >
        <template #trigger="{ isActive }">
          <button
            :class="[
              $style.columnsButton,
              {
                [$style.isActive]: isActive,
              },
            ]"
          >
            <ColumnsSvg />
          </button>
        </template>

        <template #content>
          <BaseDropdownHeading>
            Select visible columns

            <template #lead>
              Description goes here for this dropdown.
            </template>
          </BaseDropdownHeading>
          <TableColumnsFilter
            v-model:visibility-toggable-columns="visibilityToggableColumnsProxy"
            :toggable-data-columns="toggableDataColumns"
          />
        </template>
      </BaseDropdown>
    </div>
    <div :class="$style.actionsColumnBody">
      <template v-if="actionsColumn">
        <TableRowActions
          v-for="(rowData, rowIndex) in data"
          :key="getDataKey(rowData, dataKey, rowIndex)"
          :style="{ height: bodyHeights[rowIndex] }"
        >
          <template #default="{ tippy }">
            <component
              :is="actionsColumn.$slots.default"
              :row-data.camel="rowData"
              :column="actionsColumn"
              :index="rowIndex"
              :tippy="tippy"
            />
          </template>
        </TableRowActions>
      </template>
    </div>
  </div>
</template>

<script>
import BaseDropdown, { DropdownHeading as BaseDropdownHeading } from '@/components/base/Dropdown';
import ColumnsSvg from '@/assets/images/icons/columns.svg?inline';
import TableRowActions from './TableRowActions';
import TableColumnsFilter from './TableColumnsFilter';
import { getDataKey } from './utils';

export default {
  components: {
    BaseDropdown,
    BaseDropdownHeading,
    TableRowActions,
    TableColumnsFilter,
    ColumnsSvg,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    actionsColumn: {
      type: Object,
      default: null,
    },
    dataKey: {
      type: String,
      default: null,
    },
    headerRowRef: {
      type: Object,
      default: null,
    },
    bodyRowRefs: {
      type: Array,
      default: null,
    },
    toggableDataColumns: {
      type: Array,
      default: () => ([]),
    },
    visibilityToggableColumns: {
      type: Array,
      default: () => ([]),
    },
  },
  emits: {
    'update:visibilityToggableColumns': null,
  },
  data() {
    return {
      headerHeight: '',
      bodyHeights: [],
    };
  },
  computed: {
    visibilityToggableColumnsProxy: {
      get() {
        return this.visibilityToggableColumns;
      },
      set(value) {
        this.$emit('update:visibilityToggableColumns', value);
      },
    },
  },
  watch: {
    headerRowRef() {
      this.updateHeaderActionStyle();
    },
    bodyRowRefs() {
      this.updateBodyActionsStyle();
    },
  },
  methods: {
    getDataKey,
    updateHeaderActionStyle() {
      this.headerHeight = `${this.headerRowRef.clientHeight}px`;
    },
    updateBodyActionsStyle() {
      this.bodyHeights = this.bodyRowRefs.map((ref) => ref && `${ref.clientHeight}px`);
    },
    updateStyle() {
      this.updateHeaderActionStyle();
      this.updateBodyActionsStyle();
    },
  },
};
</script>

<style lang="scss" module>
.actionsColumn {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $border;
}

.actionsColumnHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px 5px 15px;
}

.columnsButton {
  @include reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  overflow: hidden;
  color: $text;
  cursor: pointer;
  background: rgba($disabled, 0);
  border-radius: 50%;
  transition: background 0.2s ease-in-out;

  &:hover,
  &.isActive {
    background: rgba($disabled, 0.25);
  }
}

.actionsColumnBody {
  display: flex;
  flex-direction: column;
}
</style>
