<template>
  <div
    :class="[
      $style.field,
      {
        [$style.isBlock]: isBlock,
      },
    ]"
  >
    <div :class="$style.valueWrapper">
      <div
        :class="$style.value"
      >
        <slot />
      </div>
    </div>
    <span :class="$style.iconWrapper">
      <ChevronSvg />
    </span>
  </div>
</template>

<script>
import ChevronSvg from '@/assets/images/icons/chevron.svg?inline';

export default {
  components: {
    ChevronSvg,
  },
  props: {
    isBlock: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.field {
  position: relative;
  display: inline-flex;
  align-items: stretch;
  padding: 6px 6px 6px 8px;
  cursor: pointer;
  background-color: $white;
  border: 2px solid $border;
  border-radius: 5px;

  &.isBlock {
    display: flex;
  }

  .valueWrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    margin-right: 6px;
  }

  .value {
    flex-shrink: 1;
    min-width: 0;
    padding: 3px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
  }

  .value {
    @include truncate(100%);
    color: $title;
  }

  .iconWrapper {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    color: rgba($title, 0.7);
  }
}
</style>
