<template>
  <BaseLoader :is-active="isLoading">
    <h2 :class="$style.header">
      Update car #{{ carId }} {{ side }} image
    </h2>
    <div :class="$style.mainContainer">
      <div :class="$style.cropperContainer">
        <p
          v-if="error"
          :class="$style.errorText"
        >
          {{ error }}
        </p>
        <div :class="$style.cropperWithButtons">
          <cropper
            ref="cropper"
            :class="$style.cropper"
            :src="carImage.src"
            :auto-zoom="true"
            :stencil-props="{
              handlers: {},
              movable: false,
              resizable: false,
              aspectRatio: side === 'side' ? 16/9 : 4/3,
            }"
            :resize-image="{
              adjustStencil: false
            }"
            image-restriction="stencil"
          />
          <div :class="$style.cropperButtonsContainer">
            <BaseButton
              variant="secondary"
              @click="importImage"
            >
              Import image
            </BaseButton>
            <input
              ref="imageInput"
              type="file"
              accept="image/*"
              :class="$style.hiddenImageInput"
              @change="changeImage"
              @click="resetImageInput"
            >
            <BaseButton
              variant="secondary"
              @click="removeBackground"
            >
              Remove background
            </BaseButton>
            <BaseButton
              variant="secondary"
              @click="flipImage('horizontal')"
            >
              Flip horizontally
            </BaseButton>
            <BaseButton
              variant="secondary"
              @click="flipImage('vertical')"
            >
              Flip vertically
            </BaseButton>
            <BaseButton
              variant="danger"
              @click="deleteImage"
            >
              Delete
            </BaseButton>
          </div>
        </div>
        <div :class="$style.confirmButtonContainer">
          <BaseButton
            variant="secondary"
            @click="reset"
          >
            Reset
          </BaseButton>
          <BaseButton
            variant="success"
            @click="confirm"
          >
            Confirm
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseLoader>
</template>

<script>
import { Storage, Auth } from 'aws-amplify';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import BaseButton from '@/components/base/Button';
import BaseLoader from '@/components/base/Loader';

export default {
  components: {
    Cropper,
    BaseButton,
    BaseLoader,
  },
  props: {
    initialCarImage: {
      type: String,
      default: null,
    },
    carId: {
      type: Number,
      default: null,
    },
    side: {
      type: String,
      default: null,
    },
    brand: {
      type: String,
      default: null,
    },
  },
  emits: {
    carImageChanged: null,
  },
  data() {
    return {
      carImage: {
        src: null,
        format: null,
        type: null,
        file: null,
      },
      isLoading: false,
      error: null,
    };
  },
  watch: {
    initialCarImage() {
      this.carImage.src = this.initialCarImage;
      this.carImage.type = 'url';
    },
  },
  methods: {
    flipImage(direction) {
      this.$refs.cropper.flip(direction === 'horizontal', direction === 'vertical');
      this.error = null;
    },
    removeBackground() {
      if (!this.carImage.src) return;
      this.isLoading = true;
      const formData = new FormData();
      formData.append('size', 'auto');
      if (this.carImage.type === 'url') formData.append('image_url', this.carImage.src);
      else if (this.carImage.type === 'blob') formData.append('image_file', this.carImage.file);
      fetch('https://api.remove.bg/v1.0/removebg', {
        method: 'POST',
        body: formData,
        headers: { 'X-Api-Key': '41Ge7HiC1wfDS34ru9djaUF4' },
      }).then((response) => {
        if (response.status === 402) {
          this.error = 'Error: reached maximum number of free API calls.';
          this.isLoading = false;
        } else if (response.status !== 200) {
          this.error = `Error: ${response.statusText}`;
          this.isLoading = false;
        } else {
          response.blob().then((blob) => {
            this.error = null;
            this.carImage.src = URL.createObjectURL(blob);
            this.carImage.type = 'blob';
            this.carImage.file = new File([blob], 'new_image');
            this.isLoading = false;
          });
        }
      });
    },
    importImage() {
      this.$refs.imageInput.click();
    },
    resetImageInput(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = null;
    },
    getMimeType(file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i += 1) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg';
        default:
          return fallback;
      }
    },
    changeImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.carImage.src) {
          URL.revokeObjectURL(this.carImage.src);
        }
        const blob = URL.createObjectURL(files[0]);
        this.carImage.src = blob;
        this.carImage.type = 'blob';
        [this.carImage.file] = files;
        const formatReader = new FileReader();
        formatReader.onload = (e) => {
          this.carImage.format = this.getMimeType(e.target.result, files[0].type);
        };
        formatReader.readAsArrayBuffer(files[0]);
        this.error = null;
      }
    },
    confirm() {
      this.isLoading = true;
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob(async (blob) => {
        Storage.configure({ level: 'public' });
        const { identityId } = await Auth.currentCredentials();
        const path = `${this.brand}/${new Date().getTime()}`;
        await Storage.put(path, blob, { level: 'public', identityId });
        if (this.initialCarImage) {
          const oldImagePath = this.initialCarImage.slice(62);
          Storage.remove(oldImagePath);
        }
        const imageUrl = (await Storage.get(path)).replace(/\?.*/, '');
        this.error = null;
        this.$emit('carImageChanged', imageUrl, this.side);
        this.isLoading = false;
      }, this.carImage.format);
    },
    deleteImage() {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const ok = confirm('This image will be removed from this car. Are you sure ?');
      this.error = null;
      if (ok) this.$emit('carImageChanged', null, this.side);
    },
    reset() {
      this.$refs.cropper.reset();
      this.carImage = {
        src: this.initialCarImage,
        format: null,
        type: 'url',
        file: null,
      };
      this.error = null;
    },
  },
};
</script>

<style lang="scss" module>

.header {
  margin: 0;
  text-align: center;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 30px;
}

.cropperContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: fit-content;
}

.cropperWithButtons {
  display: flex;
  gap: 15px;
  width: fit-content;
}

.cropper {
  width: 480px;
  height: 270px;
  cursor: move;
  background-color: $background-dark;
}

.cropperButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hiddenImageInput {
  display: none;
}

.confirmButtonContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;

  button {
    flex: 1;
  }
}

.carImagesContainer {
  display: flex;
  gap: 10px;
  width: 100%;
  padding-bottom: 10px;
  overflow: auto;

  img {
    width: 300px;
    cursor: pointer;
  }
}

.imageSelectorHeadline {
  width: 100%;
}

.errorText {
  font-weight: bold;
  color: $background-danger;
}
</style>
