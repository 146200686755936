<template>
  <router-link
    :class="[
      $style.card,
      {
        [$style[`color${capitalize(color)}`]]: color,
        [$style.isDashed]: isDashed,
      },
    ]"
    :to="to"
  >
    <span :class="$style.imageWrapper">
      <template v-if="$slots.image">
        <slot name="image" />
      </template>
      <img
        v-else
        :src="image"
        alt="Card icon"
        :class="$style.image"
      >
    </span>
    <span :class="$style.content">
      <span :class="$style.title">
        <slot name="title" />
      </span>
      <span :class="$style.text">
        <slot name="lead" />
      </span>
    </span>
  </router-link>
</template>

<script>
import { capitalize } from 'lodash';

const possibleColors = ['aqua', 'barley', 'lime', 'orange', 'violet', 'purple', 'cherry'];

export default {
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    image: {
      type: String,
      default: '/images/placeholder-image.png',
    },
    color: {
      type: String,
      validator(value) {
        return possibleColors.includes(value);
      },
      default: null,
    },
    isDashed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    capitalize,
  },
};
</script>

<style lang="scss" module>
.card {
  @include reset;
  display: inline-flex;
  padding: 10px;
  color: $title;
  text-decoration: none;
  cursor: pointer;
  background-color: $white;
  border: 1px solid rgba(201, 212, 217, 0.5);
  border-radius: 5px;

  &.isDashed {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' \
    xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' \
    stroke='%23A0ACB2FF' stroke-width='1' stroke-dasharray='5%2c7' \
    stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border: 0;
  }

  &:hover {

    .imageWrapper {
      background-color: rgba($text, 0.3);
    }
  }

  &.colorAqua {

    &:hover {

      .imageWrapper {
        color: #129cbe;
        background-color: #aee4f2;
      }
    }
  }

  &.colorBarley {

    &:hover {

      .imageWrapper {
        color: #6f6726;
        background-color: #f4ebac;
      }
    }
  }

  &.colorLime {

    &:hover {

      .imageWrapper {
        color: #617627;
        background-color: #d9f78e;
      }
    }
  }

  &.colorOrange {

    &:hover {

      .imageWrapper {
        color: #b26a00;
        background: #ffd496;
      }
    }
  }

  &.colorViolet {

    &:hover {

      .imageWrapper {
        color: #425598;
        background-color: #d0daff;
      }
    }
  }

  &.colorPurple {

    &:hover {

      .imageWrapper {
        color: #7c52cb;
        background: #e3d5ff;
      }
    }
  }

  &.colorCherry {

    &:hover {

      .imageWrapper {
        color: #963959;
        background-color: #ffc4d9;
      }
    }
  }
}

.imageWrapper {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 95px;
  margin-right: 15px;
  color: $title;
  background: $background-light;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

.image {
  max-width: 80%;
  max-height: 80%;
}

.content {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  line-height: 1.375;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: $title;
}

.text {
  margin-top: 5px;
  font-size: 16px;
}
</style>
