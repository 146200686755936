import gql from 'graphql-tag';
import colorBuilder from 'color';
import {
  cloneDeep, flatten, get, isArray, merge, random,
} from 'lodash';
import { apolloClient } from '@/vue-apollo';

export function styleYearSegments(segment, ctx) {
  const currentYear = new Date().getFullYear();
  // eslint-disable-next-line no-underscore-dangle
  return currentYear <= ctx._proxy._rootScopes[0].data[segment.p0DataIndex].x ? [6, 6] : undefined;
}

export function groupByYear(records, entryKey) {
  return records.reduce((acc, record) => {
    if (!acc.has(record.year)) acc.set(record.year, { entries: new Set(), value: 0 });
    const yearData = acc.get(record.year);

    yearData.entries.add(get(record, entryKey));
    yearData.value += record.units;

    return acc;
  }, new Map());
}

export function groupByYearWithDetail(records, detail, entryKey) {
  return records.reduce((groups, record) => {
    let detailValues = get(record, detail);

    if (!isArray(detailValues)) detailValues = [detailValues];

    detailValues.forEach((detailValue) => {
      if (!groups.has(detailValue)) groups.set(detailValue, new Map());

      const group = groups.get(detailValue);
      if (!group.has(record.year)) group.set(record.year, { entries: new Set(), value: 0 });

      const yearData = group.get(record.year);
      yearData.entries.add(get(record, entryKey));
      yearData.value += record.units;
    });

    return groups;
  }, new Map());
}

export function convertGroupedByYearToChartData(grouped) {
  return [...grouped.keys()].sort().map((year) => {
    const data = grouped.get(year);

    return {
      x: year,
      y: {
        value: data.value,
        entries: data.entries.size,
      },
    };
  });
}

export class ColorGenerator {
  usedFromPredefined = 0

  predefinedColors = [
    '#FE4485',
    '#ECE088',
    '#92A5EA',
    '#47C7E6',
    '#B4E43B',
    '#74F0DD',
    '#898471',
    '#D8D9CD',
    '#7CCD39',
    '#3FBBA1',
  ]

  constructor() {
    this.colorBuilder = colorBuilder;
  }

  getNextColor() {
    if (this.usedFromPredefined < this.predefinedColors.length) {
      const color = this.predefinedColors[this.usedFromPredefined];
      this.usedFromPredefined += 1;
      return color;
    }

    return this.colorBuilder.rgb(
      random(30, 230),
      random(30, 230),
      random(30, 230),
    ).string();
  }
}

function getAllMlfsOptions(mainLightFunctions) {
  return {
    ldv_light_functionalities: [...new Set(mainLightFunctions.map((x) => x.functionality))],
    ldv_light_functions: [...new Set(mainLightFunctions.map((x) => x.function))],
    ldv_light_sources: [...new Set(mainLightFunctions.map((x) => x.source))],
    ldv_optical_systems: [...new Set(mainLightFunctions.map((x) => x.optical_system))],
  };
}

export const TYPE = {
  line: 'line',
  bar: 'bar',
  number: 'number',
};

const priceSteps = new Array(11).fill(0).map((_, i) => i * 1000);
const priceRangeOptions = [
  ...priceSteps.map((x, i) => (i === priceSteps.length - 1
    ? `${x}+`
    : `${x} – ${priceSteps[i + 1]}`)),
];
function getPriceRange(price) {
  if (price == null) return 'Null';

  let i = 0;
  while (i <= (priceSteps.length - 1) && price >= priceSteps[i]) i += 1;

  return priceRangeOptions[i - 1];
}

export const sources = [
  {
    name: 'carVolumes',
    label: 'Car Volumes',
    getSchema() {
      return {
        details: [
          {
            label: 'OEM',
            value: 'car.brand.automaker.name',
          },
          {
            label: 'OEM brand',
            value: 'car.brand.name',
          },
          {
            label: 'Segment',
            value: 'car.segment',
          },
          {
            label: 'Model',
            value: 'car.model',
          },
          {
            label: 'Power train',
            value: 'car.power_train',
          },
          {
            label: 'GenPhase',
            value: 'car.generation_phase',
          },
          {
            label: 'Body',
            value: 'car.body',
          },
          {
            label: 'OEM production region',
            value: 'car.country.region',
          },
          {
            label: 'OEM production country',
            value: 'car.country.name',
          },
        ],
        split: [
          {
            label: 'OEM',
            value: 'car.brand.automaker.name',
          },
          {
            label: 'OEM brand',
            value: 'car.brand.name',
          },
          {
            label: 'Segment',
            value: 'car.segment',
          },
          {
            label: 'Model',
            value: 'car.model',
          },
          {
            label: 'Power train',
            value: 'car.power_train',
          },
          {
            label: 'GenPhase',
            value: 'car.generation_phase',
          },
          {
            label: 'Body',
            value: 'car.body',
          },
          {
            label: 'OEM production region',
            value: 'car.country.region',
          },
          {
            label: 'OEM production country',
            value: 'car.country.name',
          },
        ],
        filter: [
          {
            name: 'productionDates',
            type: 'dateRange',
            label: 'Production Dates',
          },
          {
            name: 'oem',
            type: 'select',
            isMultiple: true,
            label: 'OEM',
          },
          {
            name: 'oemBrand',
            type: 'select',
            isMultiple: true,
            label: 'OEM brand',
          },
          {
            name: 'segment',
            type: 'select',
            isMultiple: true,
            label: 'Segment',
          },
          {
            name: 'model',
            type: 'select',
            isMultiple: true,
            label: 'Model',
          },
          {
            name: 'powerTrain',
            type: 'select',
            isMultiple: true,
            label: 'Power train',
          },
          {
            name: 'generation',
            type: 'select',
            isMultiple: true,
            label: 'Generation',
          },
          {
            name: 'phase',
            type: 'select',
            isMultiple: true,
            label: 'Phase',
          },
          {
            name: 'body',
            type: 'select',
            isMultiple: true,
            label: 'Body',
          },
          {
            name: 'oemProductionRegion',
            type: 'select',
            isMultiple: true,
            label: 'OEM production region',
          },
          {
            name: 'oemProductionCountry',
            type: 'select',
            isMultiple: true,
            label: 'OEM production country',
          },
        ],
      };
    },
    getModel() {
      return {
        details: [],
        split: null,
        filter: {
          oem: [],
          oemBrand: [],
          oemProductionRegion: [],
          oemProductionCountry: [],
          productionDates: {
            start: null,
            end: null,
          },
          segment: [],
          body: [],
          model: [],
          powerTrain: [],
          generation: [],
          phase: [],
        },
      };
    },
    async getOptions() {
      const result = await apolloClient.query({
        query: gql`query {
          oem: automakers(
            distinct_on: name,
            where: { value_chain_level: { _eq: "OEM", }, },
          ) {
            name
          }

          oemBrand: brands(
            distinct_on: name,
            where: { validated: { _eq: true }, },
          ) {
            name
          }

          oemProductionRegion: countries(
            distinct_on: region,
          ) {
            region
          }

          oemProductionCountry: countries(
            distinct_on: name,
          ) {
            name
          }

          model: cars(
            distinct_on: model,
          ) {
            model
          }

          segment: cars(
            distinct_on: segment,
          ) {
            segment
          }

          body: cars(
            distinct_on: body,
          ) {
            body
          }

          powerTrain: cars(
            distinct_on: power_train,
          ) {
            power_train
          }

          generation: cars(
            distinct_on: generation,
          ) {
            generation
          }

          phase: cars(
            distinct_on: phase,
          ) {
            phase
          }
        }`,
      });

      return {
        oem: result.data.oem.map((x) => x.name),
        oemBrand: result.data.oemBrand.map((x) => x.name),
        oemProductionRegion: result.data.oemProductionRegion.map((x) => x.region),
        oemProductionCountry: result.data.oemProductionCountry.map((x) => x.name),
        model: result.data.model.map((x) => x.model),
        segment: result.data.segment.map((x) => x.segment),
        body: result.data.body.map((x) => x.body),
        powerTrain: result.data.powerTrain.map((x) => x.power_train),
        generation: result.data.generation.map((x) => x.generation),
        phase: result.data.phase.map((x) => x.phase),
      };
    },
    async getDynamicOptions(model = {}, volumesData, changedField) {
      const filteredData = this.applyFilter(volumesData, model, true);
      const result = {};
      Object.keys(this.getModel().filter).map((key) => {
        if (!['productionDates'].includes(key)) {
          result[key] = [...new Set(filteredData[key])];
        }
        return true;
      });

      if (changedField.field && changedField.direction === 'add') {
        delete result[changedField.field];
      }
      return result;
    },
    async getData(companyUuid) {
      return (await apolloClient.query({
        query: gql`query {
          volumes: volumes_${companyUuid.replace(/-/g, '')}(where: {_and: {units: {_is_null: false, _neq: 0}}}) {
            year
            units
            car {
              id
              sop
              eop
              model
              segment
              body
              power_train
              generation
              phase
              brand {
                name
                automaker {
                  name
                }
              }
              country {
                region
                name
              }
            }
          }
        }
        `,
      })).data.volumes
        .map((item) => merge(
          {
            car: {
              generation_phase: `G${item.car.generation}P${item.car.phase}`,
            },
          },
          cloneDeep(item),
        ));
    },
    applyFilter(data, filter, columnData = false) {
      const filteredDataInColumn = this.getModel().filter;
      const filteredData = data.filter((record) => {
        const { start, end } = filter.productionDates;
        const { sop, eop } = record.car;

        const startDate = new Date(start);
        const endDate = new Date(end);
        const sopDate = new Date(sop);
        const eopDate = new Date(eop);

        if (start && end) {
          if (sopDate > endDate || eopDate < startDate) return false;
        } else if (start != null) {
          if (sopDate < startDate) return false;
        } else if (end != null) {
          if (eopDate > endDate) return false;
        }

        if (filter.oem?.length > 0) {
          if (!filter.oem.includes(record.car.brand.automaker.name)) return false;
        }

        if (filter.oemBrand?.length > 0) {
          if (!filter.oemBrand.includes(record.car.brand.name)) return false;
        }

        if (filter.oemFactoryRegion?.length > 0) {
          if (!filter.oemFactoryRegion.includes(record.car.source_factory.region)) return false;
        }

        if (filter.oemFactoryCountry?.length > 0) {
          if (!filter.oemFactoryCountry.includes(record.car.source_factory.country)) return false;
        }

        if (filter.model?.length > 0) {
          if (!filter.model.includes(record.car.model)) return false;
        }

        if (filter.segment?.length > 0) {
          if (!filter.segment.includes(record.car.segment)) return false;
        }

        if (filter.body?.length > 0) {
          if (!filter.body.includes(record.car.body)) return false;
        }

        if (filter.powerTrain?.length > 0) {
          if (!filter.powerTrain.includes(record.car.power_train)) return false;
        }

        if (filter.generation?.length > 0) {
          if (!filter.generation.includes(record.car.generation)) return false;
        }

        if (filter.phase?.length > 0) {
          if (!filter.phase.includes(record.car.phase)) return false;
        }

        filteredDataInColumn.oem.push(record.car.brand.automaker.name);
        filteredDataInColumn.oemBrand.push(record.car.brand.name);
        filteredDataInColumn.oemProductionRegion.push(record.car.country.region);
        filteredDataInColumn.oemProductionCountry.push(record.car.country.name);
        filteredDataInColumn.segment.push(record.car.segment);
        filteredDataInColumn.body.push(record.car.body);
        filteredDataInColumn.model.push(record.car.model);
        filteredDataInColumn.powerTrain.push(record.car.power_train);
        filteredDataInColumn.generation.push(record.car.generation);
        filteredDataInColumn.phase.push(record.car.generation_phase);

        return true;
      });

      if (columnData) {
        return filteredDataInColumn;
      }
      return filteredData;
    },
    getCharts(data, details = [], splitBy = null) {
      const entryKey = 'car.id';
      if (splitBy == null) {
        const colorGenerator = new ColorGenerator();

        const groupedByYears = groupByYear(data, entryKey);

        const detailsDatasets = flatten(
          details
            .map(
              (detail) => (
                [...groupByYearWithDetail(data, detail, entryKey)].map(([title, values]) => {
                  const color = colorGenerator.getNextColor();

                  return {
                    label: title,
                    borderColor: color,
                    backgroundColor: color,
                    pointBackgroundColor: '#fff',
                    segment: {
                      borderDash: styleYearSegments,
                    },
                    data: convertGroupedByYearToChartData(values),
                  };
                })
              ),
            ),
        );

        const yearsSet = new Set();

        data.forEach((item) => yearsSet.add(item.year));

        return [
          {
            label: 'All cars',
            labels: Array.from(yearsSet).sort(),
            datasets: [
              {
                label: 'Total',
                borderColor: '#FFA829',
                backgroundColor: '#FFA829',
                pointBackgroundColor: '#fff',
                segment: {
                  borderDash: styleYearSegments,
                },
                data: convertGroupedByYearToChartData(groupedByYears),
              },
              ...detailsDatasets,
            ],
          },
        ];
      }

      const splitVolumes = data.reduce((groups, record) => {
        let detailValues = get(record, splitBy);

        if (!isArray(detailValues)) detailValues = [detailValues];

        detailValues.forEach((detailValue) => {
          if (!groups.has(detailValue)) groups.set(detailValue, []);

          const group = groups.get(detailValue);
          group.push(record);
        });

        return groups;
      }, new Map());

      const colorGenerator = new ColorGenerator();
      const alreadyColoredLabels = {};
      return [...splitVolumes].sort(([a], [b]) => a.toString().localeCompare(b)).map(([label, volumes]) => {
        const groupedByYears = groupByYear(volumes, entryKey);

        const detailsDatasets = flatten(
          details
            .map(
              (detail) => (
                [...groupByYearWithDetail(volumes, detail, entryKey)].map(([title, values]) => {
                  const color = alreadyColoredLabels[title]
                    ? alreadyColoredLabels[title]
                    : alreadyColoredLabels[title] = colorGenerator.getNextColor();

                  return {
                    label: title,
                    borderColor: color,
                    backgroundColor: color,
                    pointBackgroundColor: '#fff',
                    segment: {
                      borderDash: styleYearSegments,
                    },
                    data: convertGroupedByYearToChartData(values),
                  };
                })
              ),
            ),
        );

        const yearsSet = new Set();

        volumes.forEach((item) => yearsSet.add(item.year));

        return {
          label,
          labels: Array.from(yearsSet).sort(),
          datasets: [
            {
              label: 'Total',
              borderColor: '#FFA829',
              backgroundColor: '#FFA829',
              pointBackgroundColor: '#fff',
              segment: {
                borderDash: styleYearSegments,
              },
              data: convertGroupedByYearToChartData(groupedByYears),
            },
            ...detailsDatasets,
          ],
        };
      });
    },
  },
  {
    label: 'Lighting Device Volumes',
    name: 'lightingDeviceVolumes',
    getSchema() {
      return {
        details: [
          {
            label: 'Type',
            value: 'ldv_type',
          },
          {
            label: 'Supplier',
            value: 'ldv_supplier_name',
          },
          {
            label: 'Supplier factory',
            value: 'ldv_factory_name',
          },
          {
            label: 'Supplier factory region',
            value: 'ldv_factory_region',
          },
          {
            label: 'Supplier factory country',
            value: 'ldv_factory_country',
          },
          {
            label: 'Complexity',
            value: 'ldv_complexity',
          },
          {
            label: 'Aftermarket price',
            value: 'ldv_aftermarket_price_range',
          },
          {
            label: 'Functionality',
            value: 'ldv_light_functionalities',
          },
          {
            label: 'Function',
            value: 'ldv_light_functions',
          },
          {
            label: 'Light source technology',
            value: 'ldv_light_sources',
          },
          {
            label: 'Optical concept',
            value: 'ldv_optical_systems',
          },
          {
            label: 'OEM',
            value: 'car_automaker_name',
          },
          {
            label: 'OEM brand',
            value: 'car_brand_name',
          },
          {
            label: 'Segment',
            value: 'car_segment',
          },
          {
            label: 'Model',
            value: 'car_model',
          },
          {
            label: 'Power train',
            value: 'car_power_train',
          },
          {
            label: 'GenPhase',
            value: 'car_generation_phase',
          },
          {
            label: 'Body',
            value: 'car_body',
          },
          {
            label: 'OEM production region',
            value: 'car_production_region',
          },
          {
            label: 'OEM production country',
            value: 'car_production_country',
          },
        ],
        split: [
          {
            label: 'Type',
            value: 'ldv_type',
          },
          {
            label: 'Supplier',
            value: 'ldv_supplier_name',
          },
          {
            label: 'Supplier factory',
            value: 'ldv_factory_name',
          },
          {
            label: 'Supplier factory region',
            value: 'ldv_factory_region',
          },
          {
            label: 'Supplier factory country',
            value: 'ldv_factory_country',
          },
          {
            label: 'Complexity',
            value: 'ldv_complexity',
          },
          {
            label: 'Aftermarket price',
            value: 'ldv_aftermarket_price_range',
          },
          {
            label: 'Functionality',
            value: 'ldv_light_functionalities',
          },
          {
            label: 'Function',
            value: 'ldv_light_functions',
          },
          {
            label: 'Light source technology',
            value: 'ldv_light_sources',
          },
          {
            label: 'Optical concept',
            value: 'ldv_optical_systems',
          },
          {
            label: 'OEM',
            value: 'car_automaker_name',
          },
          {
            label: 'OEM brand',
            value: 'car_brand_name',
          },
          {
            label: 'OEM production region',
            value: 'car_production_region',
          },
          {
            label: 'OEM production country',
            value: 'car_production_country',
          },
          {
            label: 'Model',
            value: 'car_model',
          },
          {
            label: 'Segment',
            value: 'car_segment',
          },
          {
            label: 'Body',
            value: 'car_body',
          },
          {
            label: 'Power train',
            value: 'car_power_train',
          },
          {
            label: 'GenPhase',
            value: 'car_generation_phase',
          },
        ],
        filter: [
          {
            name: 'carProductionDates',
            type: 'dateRange',
            label: 'Production Dates',
            columnId: 'car',
          },
          {
            name: 'carOem',
            type: 'select',
            isMultiple: true,
            label: 'OEM',
            columnId: 'car',
          },
          {
            name: 'carOemBrand',
            type: 'select',
            isMultiple: true,
            label: 'OEM Brand',
            columnId: 'car',
          },
          {
            name: 'carOemProductionRegion',
            type: 'select',
            isMultiple: true,
            label: 'OEM production region',
            columnId: 'car',
          },
          {
            name: 'carOemProductionCountry',
            type: 'select',
            isMultiple: true,
            label: 'OEM production country',
            columnId: 'car',
          },
          {
            name: 'carSegment',
            type: 'select',
            isMultiple: true,
            label: 'Segment',
            columnId: 'car',
          },
          {
            name: 'carBody',
            type: 'select',
            isMultiple: true,
            label: 'Body',
            columnId: 'car',
          },
          {
            name: 'carModel',
            type: 'select',
            isMultiple: true,
            label: 'Model',
            columnId: 'car',
          },
          {
            name: 'carPowerTrain',
            type: 'select',
            isMultiple: true,
            label: 'Power train',
            columnId: 'car',
          },
          {
            name: 'carGeneration',
            type: 'select',
            isMultiple: true,
            label: 'Generation',
            columnId: 'car',
          },
          {
            name: 'carPhase',
            type: 'select',
            isMultiple: true,
            label: 'Phase',
            columnId: 'car',
          },
          {
            name: 'type',
            type: 'select',
            isMultiple: true,
            label: 'Type',
            columnId: 'lightingDevice',
          },
          {
            name: 'supplier',
            type: 'select',
            isMultiple: true,
            label: 'Supplier',
            columnId: 'lightingDevice',
          },
          {
            name: 'factory',
            type: 'select',
            isMultiple: true,
            label: 'Supplier factory',
            columnId: 'lightingDevice',
          },
          {
            name: 'factoryRegion',
            type: 'select',
            isMultiple: true,
            label: 'Supplier factory region',
            columnId: 'lightingDevice',
          },
          {
            name: 'factoryCountry',
            type: 'select',
            isMultiple: true,
            label: 'Supplier factory country',
            columnId: 'lightingDevice',
          },
          {
            name: 'complexity',
            type: 'select',
            isMultiple: true,
            label: 'Complexity',
            columnId: 'lightingDevice',
          },
          {
            name: 'aftermarket_price',
            type: 'select',
            isMultiple: true,
            label: 'Aftermarket price',
            columnId: 'lightingDevice',
          },
          {
            name: 'functionality',
            type: 'select',
            isMultiple: true,
            label: 'Functionality',
            columnId: 'lightingDevice',
          },
          {
            name: 'function',
            type: 'select',
            isMultiple: true,
            label: 'Function',
            columnId: 'lightingDevice',
          },
          {
            name: 'lightSourceTechnology',
            type: 'select',
            isMultiple: true,
            label: 'Light source technology',
            columnId: 'lightingDevice',
          },
          {
            name: 'opticalSystem',
            type: 'select',
            isMultiple: true,
            label: 'Optical concept',
            columnId: 'lightingDevice',
          },
        ],
      };
    },
    getModel() {
      return {
        details: [],
        filter: {
          carProductionDates: {
            start: null,
            end: null,
          },
          carOem: [],
          carOemBrand: [],
          carOemProductionRegion: [],
          carOemProductionCountry: [],
          carSegment: [],
          carBody: [],
          carModel: [],
          carPowerTrain: [],
          carGeneration: [],
          carPhase: [],
          type: [],
          supplier: [],
          factory: [],
          factoryRegion: [],
          factoryCountry: [],
          complexity: [],
          aftermarket_price: [],
          functionality: [],
          function: [],
          lightSourceTechnology: [],
          opticalSystem: [],
        },
      };
    },
    async getOptions() {
      const result = await apolloClient.query({
        query: gql`query {
          carOem: automakers(
            where: { value_chain_level: { _eq: "OEM", }, },
            distinct_on: name,
          ) {
            name
          }

          carOemBrand: brands(
            where: { validated: { _eq: true }, },
            distinct_on: name,
          ) {
            name
          }

          carOemProductionRegion: countries(
            distinct_on: region,
          ) {
            region
          }

          carOemProductionCountry: countries(
            distinct_on: name,
          ) {
            name
          }

          carSegment: cars(
            distinct_on: segment,
          ) {
            segment
          }

          carBody: cars(
            distinct_on: body,
          ) {
            body
          }

          carModel: cars(
            distinct_on: model,
          ) {
            model
          }

          carPowerTrain: cars(
            distinct_on: power_train,
          ) {
            power_train
          }

          carGeneration: cars(
            distinct_on: generation,
          ) {
            generation
          }

          carPhase: cars(
            distinct_on: phase,
          ) {
            phase
          }

          supplier: brands(
            where: { value_chain_level: { _eq: "Tier1", }, },
            distinct_on: name,
          ) {
            name
          }

          factory: factories(
            distinct_on: name,
          ) {
            name
          }

          factoryRegion: factories(
            distinct_on: region,
          ) {
            region
          }

          factoryCountry: factories(
            distinct_on: country,
          ) {
            country
          }

          complexity: lighting_devices(
            distinct_on: complexity,
          ) {
            complexity
          }

          type: lighting_devices(
            distinct_on: type,
            where: {type: {_nin: ["CHMSL","AuxHL","AuxRL"]}}
          ) {
            type
          }

          functionality: light_functionalities(
            distinct_on: name,
          ) {
            name
          }

          function: light_functions(
            distinct_on: name,
          ) {
            name
          }

          lightSourceTechnology: light_sources(
            distinct_on: technology,
          ) {
            technology
          }

          opticalSystem: optical_systems(
            distinct_on: name,
          ) {
            name
          }
        }`,
      });

      return {
        carOem: result.data.carOem.map((x) => x.name),
        carOemBrand: result.data.carOemBrand.map((x) => x.name),
        carOemProductionRegion: result.data.carOemProductionRegion.map((x) => x.region),
        carOemProductionCountry: result.data.carOemProductionCountry.map((x) => x.name),
        carSegment: result.data.carSegment.map((x) => x.segment),
        carBody: result.data.carBody.map((x) => x.body),
        carModel: result.data.carModel.map((x) => x.model),
        carPowerTrain: result.data.carPowerTrain.map((x) => x.power_train),
        carGeneration: result.data.carGeneration.map((x) => x.generation),
        carPhase: result.data.carPhase.map((x) => x.phase),
        type: result.data.type.map((x) => x.type),
        supplier: result.data.supplier.map((x) => x.name),
        factory: result.data.factory.map((x) => x.name),
        factoryRegion: result.data.factoryRegion.map((x) => x.region),
        factoryCountry: result.data.factoryCountry.map((x) => x.country),
        complexity: result.data.complexity.map((x) => x.complexity),
        aftermarket_price: priceRangeOptions,
        functionality: result.data.functionality.map((x) => x.name),
        function: result.data.function.map((x) => x.name),
        lightSourceTechnology: result.data.lightSourceTechnology.map((x) => x.technology),
        opticalSystem: result.data.opticalSystem.map((x) => x.name),
      };
    },
    async getDynamicOptions(model = {}, volumesData, changedField) {
      const filteredData = this.applyFilter(volumesData, model, true);
      const result = {};
      Object.keys(this.getModel().filter).map((key) => {
        if (!['carProductionDates'].includes(key)) {
          result[key] = [...new Set(filteredData[key])];
        }
        return true;
      });

      if (changedField.field && changedField.direction === 'add') {
        delete result[changedField.field];
      }
      return result;
      // const whereObj = {};

      // if (model.type?.length) {
      //   set(whereObj, 'lighting_device_type._in', model.type);
      // }

      // const result = await apolloClient.query({
      //   query: gql`query {
      //     function: light_functions(
      //       where: ${querify(whereObj)},
      //       distinct_on: name,
      //     ) {
      //       name
      //     }
      //   }`,
      // });

      // return {
      //   function: result.data.function.map((x) => x.name),
      // };
    },
    async getData(companyUuid) {
      const result = (await apolloClient.query({
        query: gql`query {
          third_party_take_rates_aggregated(where: {
                _and: {
                  volumes_${companyUuid.replace(/-/g, '')}: {_is_null: false, _neq: 0}
                  }
          }) {
            sources_units: volumes_${companyUuid.replace(/-/g, '')}
            rate
            parts_per_car
            year
            car_automaker_name
            car_model
            car_brand_name
            car_body
            car_eop
            car_sop
            car_factory_country
            car_factory_region
            car_production_country
            car_production_region
            car_generation
            car_phase
            car_power_train
            car_segment
            ldv_id
            ldv_aftermarket_price
            ldv_complexity
            ldv_factory_name
            ldv_factory_country
            ldv_factory_region
            ldv_main_light_functions
            ldv_supplier_name
            ldv_type
          }
        }`,
      }));
      return result.data.third_party_take_rates_aggregated
        .map((item) => ({
          car_generation_phase: `\
G${item.car_generation}P${item.car_phase}`,
          ldv_aftermarket_price_range: getPriceRange(item.ldv_aftermarket_price),
          ...getAllMlfsOptions(item.ldv_main_light_functions),
          ...item,
          // eslint-disable-next-line
          units: Math.round((item.sources_units ? item.sources_units : null) * (item.rate ? item.rate : 0) * (item.parts_per_car ? item.parts_per_car : 0)),
        }));
    },
    applyFilter(data, filter, columnData = false) {
      const schema = {
        carOem: 'car_automaker_name',
        carOemBrand: 'car_brand_name',
        carOemProductionRegion: 'car_production_region',
        carOemProductionCountry: 'car_production_country',
        carSegment: 'car_segment',
        carBody: 'car_body',
        carModel: 'car_model',
        carPowerTrain: 'car_power_train',
        carGeneration: 'car_generation',
        carPhase: 'car_phase',
        type: 'ldv_type',
        supplier: 'ldv_supplier_name',
        factory: 'ldv_factory_name',
        factoryRegion: 'ldv_factory_region',
        factoryCountry: 'ldv_factory_country',
        complexity: 'ldv_complexity',
        aftermarket_price: 'ldv_aftermarket_price_range',
        functionality: 'ldv_light_functionalities',
        function: 'ldv_light_functions',
        lightSourceTechnology: 'ldv_light_sources',
        opticalSystem: 'ldv_optical_systems',
      };

      const filteredDataInColumn = this.getModel().filter;
      const filteredData = data.filter((record) => {
        const { start, end } = filter.carProductionDates;
        const sop = record.car_sop;
        const eop = record.car_eop;

        const startDate = new Date(start);
        const endDate = new Date(end);
        const sopDate = new Date(sop);
        const eopDate = new Date(eop);

        if (start && end) {
          if (sopDate > endDate || eopDate < startDate) return false;
        } else if (start != null) {
          if (sopDate < startDate) return false;
        } else if (end != null) {
          if (eopDate > endDate) return false;
        }

        if (filter.carOem?.length > 0) {
          if (!filter.carOem.includes(record.car_automaker_name)) return false;
        }

        if (filter.carOemBrand?.length > 0) {
          if (!filter.carOemBrand.includes(record.car_brand_name)) return false;
        }

        if (filter.carOemProductionRegion?.length > 0) {
          if (
            !filter.carOemProductionRegion.includes(record.car_production_region)
          ) return false;
        }

        if (filter.carOemProductionCountry?.length > 0) {
          if (
            !filter.carOemProductionCountry.includes(record.car_production_country)
          ) return false;
        }

        if (filter.carSegment?.length > 0) {
          if (!filter.carSegment.includes(record.car_segment)) return false;
        }

        if (filter.carBody?.length > 0) {
          if (!filter.carBody.includes(record.car_body)) return false;
        }

        if (filter.carModel?.length > 0) {
          if (!filter.carModel.includes(record.car_model)) return false;
        }

        if (filter.carPowerTrain?.length > 0) {
          if (!filter.carPowerTrain.includes(record.car_power_train)) return false;
        }

        if (filter.carGeneration?.length > 0) {
          if (!filter.carGeneration.includes(record.car_generation)) return false;
        }

        if (filter.carPhase?.length > 0) {
          if (!filter.carPhase.includes(record.car_phase)) return false;
        }

        if (filter.type?.length > 0) {
          if (!filter.type.includes(record.ldv_type)) return false;
        }

        if (filter.supplier?.length > 0) {
          if (!filter.supplier.includes(record.ldv_supplier_name)) return false;
        }

        if (filter.factory?.length > 0) {
          if (!filter.factory.includes(record.ldv_factory_name)) return false;
        }

        if (filter.factoryRegion?.length > 0) {
          if (
            !filter.factoryRegion.includes(record.ldv_factory_region)
          ) return false;
        }

        if (filter.factoryCountry?.length > 0) {
          if (
            !filter.factoryCountry.includes(record.ldv_factory_country)
          ) return false;
        }

        if (filter.complexity?.length > 0) {
          if (
            !filter.complexity.includes(record.ldv_complexity)
          ) return false;
        }

        if (filter.aftermarket_price?.length > 0) {
          if (
            !filter.aftermarket_price.includes(record.ldv_aftermarket_price_range)
          ) return false;
        }

        if (
          filter.functionality?.length > 0
          || filter.function?.length > 0
          || filter.lightSourceTechnology?.length > 0
          || filter.opticalSystem?.length > 0
        ) {
          const suitableMlfs = record.ldv_main_light_functions.filter((mlf) => {
            if (filter.functionality?.length > 0) {
              if (!filter.functionality.includes(mlf.functionality)) return false;
            }

            if (filter.function?.length > 0) {
              if (!filter.function.includes(mlf.function)) return false;
            }

            if (filter.lightSourceTechnology?.length > 0) {
              if (!filter.lightSourceTechnology.includes(mlf.source)) return false;
            }

            if (filter.opticalSystem?.length > 0) {
              if (!filter.opticalSystem.includes(mlf.optical_system)) return false;
            }

            return true;
          });

          if (suitableMlfs.length === 0) return false;

          Object.assign(record, getAllMlfsOptions(suitableMlfs));
        }

        Object.keys(schema).map((item) => {
          if (Array.isArray(record[schema[item]])) {
            filteredDataInColumn[item].push(...record[schema[item]]);
          } else {
            filteredDataInColumn[item].push(record[schema[item]]);
          }
          return true;
        });

        return true;
      });

      if (columnData) {
        return filteredDataInColumn;
      }
      return filteredData;
    },
    getCharts(data, details = [], splitBy = null) {
      const entryKey = 'ldv_id';
      if (splitBy == null) {
        const colorGenerator = new ColorGenerator();

        const groupedByYears = groupByYear(data, entryKey);

        const detailsDatasets = flatten(
          details
            .map(
              (detail) => (
                [...groupByYearWithDetail(data, detail, entryKey)].map(([title, values]) => {
                  const color = colorGenerator.getNextColor();

                  return {
                    label: title,
                    borderColor: color,
                    backgroundColor: color,
                    pointBackgroundColor: '#fff',
                    segment: {
                      borderDash: styleYearSegments,
                    },
                    data: convertGroupedByYearToChartData(values),
                  };
                })
              ),
            ),
        );

        const yearsSet = new Set();

        data.forEach((item) => yearsSet.add(item.year));

        return [
          {
            label: 'All Lighting Devices',
            labels: Array.from(yearsSet).sort(),
            datasets: [
              {
                label: 'Total',
                borderColor: '#FFA829',
                backgroundColor: '#FFA829',
                pointBackgroundColor: '#fff',
                segment: {
                  borderDash: styleYearSegments,
                },
                data: convertGroupedByYearToChartData(groupedByYears),
              },
              ...detailsDatasets,
            ],
          },
        ];
      }

      const splitVolumes = data.reduce((groups, record) => {
        let detailValues = get(record, splitBy);

        if (!isArray(detailValues)) detailValues = [detailValues];

        detailValues.forEach((detailValue) => {
          if (!groups.has(detailValue)) groups.set(detailValue, []);

          const group = groups.get(detailValue);
          group.push(record);
        });

        return groups;
      }, new Map());

      const colorGenerator = new ColorGenerator();
      const alreadyColoredLabels = {};
      return [...splitVolumes].sort(([a], [b]) => a.toString().localeCompare(b)).map(([label, volumes]) => {
        const groupedByYears = groupByYear(volumes, entryKey);

        const detailsDatasets = flatten(
          details
            .map(
              (detail) => (
                [...groupByYearWithDetail(volumes, detail, entryKey)].map(([title, values]) => {
                  const color = alreadyColoredLabels[title]
                    ? alreadyColoredLabels[title]
                    : alreadyColoredLabels[title] = colorGenerator.getNextColor();

                  return {
                    label: title,
                    borderColor: color,
                    backgroundColor: color,
                    pointBackgroundColor: '#fff',
                    segment: {
                      borderDash: styleYearSegments,
                    },
                    data: convertGroupedByYearToChartData(values),
                  };
                })
              ),
            ),
        );

        const yearsSet = new Set();

        volumes.forEach((item) => yearsSet.add(item.year));

        return {
          label,
          labels: Array.from(yearsSet).sort(),
          datasets: [
            {
              label: 'Total',
              borderColor: '#FFA829',
              backgroundColor: '#FFA829',
              pointBackgroundColor: '#fff',
              segment: {
                borderDash: styleYearSegments,
              },
              data: convertGroupedByYearToChartData(groupedByYears),
            },
            ...detailsDatasets,
          ],
        };
      });
    },
  },
];

export const sourcesMap = sources.reduce((acc, source) => ({
  ...acc,
  [source.name]: source,
}), {});
