<template>
  <div :class="$style.page">
    <BaseContainer :class="$style.container">
      <BaseLoader
        :class="$style.box"
      >
        <FormBox
          heading="Your company doesn't have access"
        />
      </BaseLoader>
    </BaseContainer>
  </div>
</template>

<script>
import BaseContainer from '@/components/base/Container';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';

export default {
  components: {
    BaseContainer,
    BaseLoader,
    FormBox,
  },
};
</script>

<style lang="scss" module>
.page {
  flex-grow: 1;
  padding-top: map-get($page-vertical-paddings, 'small');
  padding-bottom: map-get($page-vertical-paddings, 'small');
  background: #fafafa;
}

.container {
  display: flex;
  justify-content: center;
}

.box {
  width: 470px;
}
</style>
