<template>
  <template v-if="isRange">
    <div
      :class="[
        $style.field,
        $style.hasClearButton,
        {
          [$style.isBlock]: isBlock,
        }
      ]"
    >
      <Datepicker
        ref="pickerStartComponent"
        v-model="modelStartProxy"
        :class="$style.pickerComponentInput"
        :lower-limit="lowerLimit"
        :upper-limit="upperLimit"
        starting-view="year"
        minimum-view="year"
      />
      <span
        :class="[
          $style.value,
          {
            [$style.placeholder]: !modelStartProxy,
          },
        ]"
        @click="openPicker($refs.pickerStartComponent)"
      >
        {{ modelStartProxy ? formatValue(modelStartProxy) : 'Start year' }}
      </span>
      <span :class="$style.divider">–</span>
      <Datepicker
        ref="pickerEndComponent"
        v-model="modelEndProxy"
        :class="$style.pickerComponentInput"
        :lower-limit="lowerLimit"
        :upper-limit="upperLimit"
        starting-view="year"
        minimum-view="year"
      />
      <span
        :class="[
          $style.value,
          {
            [$style.placeholder]: !modelEndProxy,
          },
        ]"
        @click="openPicker($refs.pickerEndComponent)"
      >
        {{ modelEndProxy ? formatValue(modelEndProxy) : 'End year' }}
      </span>
      <button
        :class="[
          $style.clear,
          {
            [$style.isHidden]: modelStartProxy == null && modelEndProxy == null,
          }
        ]"
        type="button"
        @click="clear"
      >
        <CrossSvg />
      </button>
    </div>
  </template>
  <template v-else>
    <div
      :class="[
        $style.field,
        {
          [$style.isBlock]: isBlock,
        }
      ]"
    >
      <Datepicker
        ref="pickerComponent"
        v-model="modelProxy"
        :class="$style.pickerComponentInput"
        :lower-limit="lowerLimit"
        :upper-limit="upperLimit"
        starting-view="year"
        minimum-view="year"
      />
      <span
        :class="[
          $style.value,
          {
            [$style.placeholder]: !modelProxy,
          },
        ]"
        @click="openPicker($refs.pickerComponent)"
      >
        {{ modelProxy ? formatValue(modelProxy) : 'Select year' }}
      </span>
    </div>
  </template>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import { format } from 'date-fns';
import CrossSvg from '@/assets/images/icons/cross.svg?inline';

export default {
  components: {
    Datepicker,
    CrossSvg,
  },
  props: {
    modelValue: {
      type: [Object, String],
      default: null,
    },
    lowerLimit: {
      type: Date,
      default: undefined,
    },
    upperLimit: {
      type: Date,
      default: undefined,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': null,
  },
  data() {
    return {
      popoverOptions: {
        positionFixed: true,
      },
    };
  },
  computed: {
    modelProxy: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    modelStartProxy: {
      get() {
        return this.modelValue.start;
      },
      set(value) {
        this.modelProxy = {
          ...this.modelProxy,
          start: value,
        };
      },
    },
    modelEndProxy: {
      get() {
        return this.modelValue.end;
      },
      set(value) {
        this.modelProxy = {
          ...this.modelProxy,
          end: value,
        };
      },
    },
  },
  mounted() {
    this.addClassToPickers();
  },
  updated() {
    this.addClassToPickers();
  },
  methods: {
    addClassToPickers() {
      [
        this.$refs.pickerStartComponent,
        this.$refs.pickerEndComponent,
        this.$refs.pickerComponent,
      ].forEach((component) => {
        if (component == null) return;

        component.$el.classList.add(this.$style.pickerWrapper);
      });
    },
    formatValue(date) {
      return format(date, 'yyyy');
    },
    openPicker(component) {
      component.$refs.inputRef.focus();
    },
    clear() {
      this.modelProxy = {
        start: null,
        end: null,
      };
    },
  },
};
</script>

<style lang="scss" module>
.pickerWrapper {
  --vdp-hover-bg-color: #{$border};
  --vdp-selected-bg-color: #{$border};
  --vdp-hover-color: #{$title};
  --vdp-selected-color: #{$title};
  transform: translateY(30px);

  :global {

    /* stylelint-disable  selector-class-pattern */
    .v3dp__popout {
      transform: translateX(-100px);
    }
    /* stylelint-enable  selector-class-pattern */
  }
}

.field {
  position: relative;
  display: inline-flex;
  padding: 9px 6px 9px 8px;
  font-size: 16px;
  font-weight: 500;
  color: $title;
  background-color: $white;
  border: 2px solid $border;
  border-radius: 5px;

  &.isBlock {
    display: flex;
    width: 100%;
  }

  &.hasClearButton {
    padding-right: 30px;
  }

  &:hover {

    .clear:not(.isHidden) {
      opacity: 1;
    }
  }
}

.pickerComponentInput {
  @include hide-accessible;
}

.value {
  cursor: pointer;
}

.placeholder {
  color: rgba($text, 0.5);
}

.divider {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.picker {
  display: flex;
  flex-grow: 1;
}

.pickerValue {
  flex-grow: 1;
}

.clear {
  @include reset;
  position: absolute;
  top: 50%;
  right: 7px;
  display: inline-flex;
  padding: 3px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transform: translateY(-50%);

  &.isHidden {
    pointer-events: none;
    opacity: 0;
  }

  svg {
    width: 10px;
    height: auto;
  }
}
</style>
