<template>
  <BaseTableColumn
    label="Generation"
    is-toggable
  >
    <template #header>
      <TableHeaderWithIcon>
        <TableCommercialSvg /> Generation
      </TableHeaderWithIcon>
    </template>

    <template #default="{ rowData }">
      {{ rowData.generation }}
    </template>
  </BaseTableColumn>
  <BaseTableColumn
    label="Brand"
    is-toggable
  >
    <template #header>
      <TableHeaderWithIcon>
        <TableDateStartSvg /> Brand
      </TableHeaderWithIcon>
    </template>

    <template #default="{ rowData }">
      {{ rowData.production_brand }}({{ rowData.brand_id }})
    </template>
  </BaseTableColumn>

  <BaseTableColumn
    label="Model"
    is-toggable
  >
    <template #header>
      <TableHeaderWithIcon>
        <TablePhaseSvg /> Model
      </TableHeaderWithIcon>
    </template>

    <template #default="{ rowData }">
      {{ rowData.global_nameplate }}({{ rowData.model_id }})
    </template>
  </BaseTableColumn>

  <BaseTableColumn
    label="Country"
    is-toggable
  >
    <template #header>
      <TableHeaderWithIcon>
        <TablePhaseSvg /> Country
      </TableHeaderWithIcon>
    </template>

    <template #default="{ rowData }">
      {{ rowData.source_plant_country_territory }}({{ rowData.country_id }})
    </template>
  </BaseTableColumn>

  <BaseTableColumn
    label="Body"
    is-toggable
  >
    <template #header>
      <TableHeaderWithIcon>
        <TablePhaseSvg /> Body
      </TableHeaderWithIcon>
    </template>

    <template #default="{ rowData }">
      {{ rowData.body_type }}({{ rowData.body_id }})
    </template>
  </BaseTableColumn>

  <BaseTableColumn
    label="Flag"
    is-toggable
  >
    <template #header>
      <TableHeaderWithIcon>
        <TablePhaseSvg /> Flag
      </TableHeaderWithIcon>
    </template>

    <template #default="{ rowData }">
      {{ rowData.flag }}
    </template>
  </BaseTableColumn>

  <BaseTableColumn
    label="Company"
    is-toggable
  >
    <template #header>
      <TableHeaderWithIcon>
        <TablePhaseSvg /> Company
      </TableHeaderWithIcon>
    </template>

    <template #default="{ rowData }">
      {{ rowData.from }}
    </template>
  </BaseTableColumn>

  <BaseTableColumn
    label="Update reference id"
    header="Update reference id"
    #="{ rowData }"
  >
    {{ rowData.update_ref_id }}
  </BaseTableColumn>

  <BaseTableColumn
    label="Ligghting cluster reference"
    is-toggable
  >
    <template #header>
      <TableHeaderWithIcon>
        <TableJigsawSvg /> Ligghting cluster reference
      </TableHeaderWithIcon>
    </template>

    <template #default="{ rowData }">
      {{ rowData.lighting_cluster_reference }}
    </template>
  </BaseTableColumn>
</template>

<script>
import {
  TableColumn as BaseTableColumn,
} from '@/components/base/Table';
import TableHeaderWithIcon from '@/components/TableHeaderWithIcon';
import TableJigsawSvg from '@/assets/images/icons/table-jigsaw.svg?inline';
import TableCommercialSvg from '@/assets/images/icons/table-commercial.svg?inline';
import TableDateStartSvg from '@/assets/images/icons/table-date-start.svg?inline';
import TablePhaseSvg from '@/assets/images/icons/table-phase.svg?inline';

export default {
  components: {
    BaseTableColumn,
    TableHeaderWithIcon,
    TableJigsawSvg,
    TableCommercialSvg,
    TableDateStartSvg,
    TablePhaseSvg,
  },
};
</script>

<style lang="scss" module>
.pictureCell {
  width: 53px;
}

.picture {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: -7px -15px -6px 0;
  background-position: center;
  background-size: cover;
  border: 1px solid $text-dark;
  border-radius: 50%;
}

.youMark {
  margin-top: -2px;
  margin-left: 5px;
}

.roles {

  > * {

    &:not(:first-child) {
      margin-left: 5px;
    }
  }
}
</style>
