<template>
  <div :class="$style.list">
    <slot />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      dropdownActionsFieldClass: this.$style.field,
    };
  },
};
</script>

<style lang="scss" module>
.list {
  padding: 0 10px;

  > * {

    &:not(:first-child) {
      margin-top: 1px;
    }
  }

  > .field {

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
}
</style>
