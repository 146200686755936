<template>
  <div :class="$style.search">
    <input
      :class="$style.input"
      :model-value="modelValue"
      type="text"
      @input="updateModel"
    >
    <MagnifierSvg
      :class="$style.magnifier"
    />
  </div>
</template>

<script>
import MagnifierSvg from '@/assets/images/icons/magnifier.svg?inline';

export default {
  components: {
    MagnifierSvg,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: {
    'update:modelValue': null,
  },
  methods: {
    updateModel(e) {
      this.$emit('update:modelValue', e.target.value);
    },
  },
};
</script>

<style lang="scss" module>
.search {
  position: relative;
  display: flex;
}

.input {
  @include reset;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  padding: 5px 25px 5px 7px;
  font-size: 15px;
  color: $title;
  background-color: $background-light;
  border-radius: 5px;
}

.magnifier {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 10px;
  height: auto;
  color: $disabled;
  pointer-events: none;
  transform: translateY(-50%);
}
</style>
