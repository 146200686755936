<template>
  <BaseContainer>
    <BaseLoader
      :is-active="isLoading"
      :class="$style.loaderWrapper"
    >
      <div :class="$style.oemBrandsContainer">
        <div
          v-for="brand in oemBrands"
          :key="brand.id"
        >
          <button
            :class="[
              $style.brandButton,
              {
                [$style.selected]: selectedBrand && selectedBrand.id === brand.id,
              }
            ]"
            @click="selectBrand(brand)"
          >
            <img
              :src="brand.logo"
              :class="$style.image"
            >
            <p>{{ brand.name }}</p>
          </button>
        </div>
      </div>
      <div ref="brandModels">
        <EquipmentBrandModels
          v-if="sourceModels.length && lmiBrandModels.length"
          :brand="selectedBrand"
          :source-models="sourceModels"
          :lmi-brand-models="lmiBrandModels"
          :selected-model="$route.query.model ?$route.query.model : null"
        />
      </div>
    </BaseLoader>
  </BaseContainer>
</template>
<script>
import BaseContainer from '@/components/base/Container';
import EquipmentBrandModels from '@/components/EquipmentBrandModels';
import brands from '@/graphql/queries/brands';
import cars from '@/graphql/queries/cars';
import BaseLoader from '@/components/base/Loader';

export default ({
  components: {
    BaseContainer,
    EquipmentBrandModels,
    BaseLoader,
  },
  data() {
    return {
      oemBrands: [],
      selectedBrand: null,
      lmi_cars: [],
      cars_to_be_updated: [],
      sourceModels: [],
      lmiBrandModels: [],
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    const initialBrandId = this.$route.query.brand ? this.$route.query.brand : null;
    this.oemBrands = await brands.getAllOemBrands();
    if (initialBrandId) {
      this.selectedBrand = this.oemBrands.find((item) => item.id === parseInt(initialBrandId, 10));
      this.getModels();
    }
    this.isLoading = false;
  },
  methods: {
    async selectBrand(brand) {
      this.selectedBrand = brand;
      this.getModels();
    },
    getModels() {
      this.isLoading = true;
      Promise.all([
        cars.getSourceModels(this.selectedBrand.id),
        cars.getLmiBrandModels(this.selectedBrand.id),
      ]).then((results) => {
        const [sourceModels, lmiBrandModels] = results;
        this.sourceModels = sourceModels;
        this.lmiBrandModels = lmiBrandModels.map((car) => car.model);
        this.isLoading = false;
        const { brandModels } = this.$refs;
        brandModels.scrollIntoView({ behavior: 'smooth' });
      });
    },
  },
});
</script>
<style lang="scss" module>

.loaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.oemBrandsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.brandButton {
  display: inline-block;
  min-height: 52px;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: 0;

  p {
    margin: 0;
  }
}

.selected {
  background-color: $background-light;
  border-radius: 5px;
}

.image {
  width: 40px;
  object-fit: contain;
}
</style>
