<template>
  <div :class="classes">
    <BaseContainer>
      <div :class="$style.inner">
        <div :class="$style.leftSection">
          <router-link
            v-slot="{ navigate }"
            :to="{name: 'Home'}"
            :class="$style.logo"
          >
            <LogoSvg @click="navigate" />
          </router-link>
          <TheBreadcrumbs :is-light="isLight" />
        </div>
        <div :class="$style.rightSection">
          <template v-if="isLoggedIn">
            {{ updateReferenceLabel ? updateReferenceLabel : 'No reference selected' }}
          </template>
          <div
            id="headerPageContent"
            :class="$style.headerPageContent"
          />
          <HeaderBarItem>
            <UserDropdown v-if="isLoggedIn" />
          </HeaderBarItem>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BaseContainer from '@/components/base/Container';
import UserDropdown from '@/components/UserDropdown';
import TheBreadcrumbs from '@/components/TheBreadcrumbs';
import HeaderBarItem from '@/components/HeaderBarItem';
import LogoSvg from '@/assets/images/logo.svg?inline';

export default {
  components: {
    BaseContainer,
    UserDropdown,
    TheBreadcrumbs,
    HeaderBarItem,
    LogoSvg,
  },
  props: {
    isLight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapState('app', ['updateReferenceLabel']),
    classes() {
      return [
        this.$style.header,
        {
          [this.$style.isLight]: this.isLight,
        },
      ];
    },
  },
};
</script>

<style lang="scss" module>
.header {
  position: relative;
  z-index: 1;
  overflow: hidden; // Fix Safari bug with overflowing height
  color: $title;
  border-bottom: 1px solid $background-light;

  &.isLight {
    color: $white;
    border-bottom-color: rgba($white, 0.25);

    .inner {
      height: #{$header-height - 1px};
    }

    .logo {
      color: $white;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-height;
}

.logo {
  display: inline-flex;
  color: $title;

  svg {
    width: 50px;
    height: auto;
  }
}

.leftSection,
.rightSection {
  display: inline-flex;
  align-items: center;
}

.headerPageContent {
  display: flex;
  align-items: center;
}
</style>
