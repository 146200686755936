<template>
  <div
    :class="$style.inputWrapper"
  >
    <textarea
      v-if="type === 'textarea'"
      v-model="modelProxy"
      min="0"
      :placeholder="placeholder"
      :class="[
        $style.input,
        {
          [$style.hasError]: hasError,
        },
      ]"
      :type="type"
      :disabled="disabled"
    />
    <input
      v-else
      v-model="modelProxy"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :class="[
        $style.input,
        {
          [$style.hasError]: hasError,
        },
      ]"
      :type="type"
      :disabled="disabled"
    >
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: [String, Number],
      default: undefined,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: undefined,
    },
    max: {
      type: [String, Number],
      default: undefined,
    },
  },
  emits: {
    'update:modelValue': null,
  },
  computed: {
    modelProxy: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" module>
.inputWrapper {
  display: flex;
}

.input {
  @include reset;
  flex-grow: 1;
  align-items: stretch;
  padding: 10px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: $title;
  background-color: $white;
  border: 2px solid $border;
  border-radius: 5px;
  transition: border-color 0.2s ease-in-out;

  &::placeholder {
    color: rgba($text, 0.5);
  }

  &.hasError {
    border-color: #f39d9d;
  }

  &:disabled {
    color: $text;
    background-color: #dadada;
  }
}
</style>
