<template>
  <BaseDropdown>
    <template #trigger="{ isActive }">
      <HeaderIconButton
        :is-active="isActive"
        :badge="modelValue ? ' ' : null"
      >
        <template #icon>
          <SplitCircleSvg />
        </template>
        <template
          v-if="modelValue"
          #badge
        >
          <TickSvg />
        </template>
      </HeaderIconButton>
    </template>

    <template #content>
      <BaseDropdownHeading>
        Select which update to show
      </BaseDropdownHeading>
      <BaseDropdownActionsList>
        <BaseDropdownActionRadio
          v-for="option in schema"
          :key="option.value"
          v-model="model"
          :value="option.value"
          :is-visible="option.label"
        >
          {{ option.label }}
        </BaseDropdownActionRadio>
      </BaseDropdownActionsList>
    </template>
  </BaseDropdown>
</template>

<script>
import BaseDropdown, {
  DropdownActionRadio as BaseDropdownActionRadio, DropdownHeading as BaseDropdownHeading,
  DropdownActionsList as BaseDropdownActionsList,
} from '@/components/base/Dropdown';
import HeaderIconButton from '@/components/HeaderIconButton';
import SplitCircleSvg from '@/assets/images/icons/split-circle.svg?inline';
import TickSvg from '@/assets/images/icons/tick.svg?inline';

export default {
  components: {
    BaseDropdown,
    BaseDropdownHeading,
    BaseDropdownActionRadio,
    BaseDropdownActionsList,
    HeaderIconButton,
    SplitCircleSvg,
    TickSvg,
  },
  props: {
    schema: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: null,
      default: null,
    },
    name: {
      type: String,
      default: 'Update reference',
    },
  },
  emits: {
    'update:modelValue': null,
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    reset() {
      this.$emit('update:modelValue', null);
    },
  },
};
</script>

<style lang="scss" module>

.resetButton {
  width: calc(100% - 16px);
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 8px;
}

</style>
