export default [
  {
    name: 'HL',
    value: 0,
    init: [
      {
        id: 'version',
        name: 'Version',
        type: 'multiselect',
        value: null,
      },
      {
        id: 'order_in_row',
        name: 'Order',
        type: 'number',
        value: null,
      },
      {
        id: 'position',
        name: 'Position',
        type: 'multiselect',
        value: 'Middle',
      },
      {
        id: 'parts_per_car',
        name: 'Parts per car',
        type: 'number',
        value: 2,
      },
      {
        id: 'rate',
        name: 'Rate',
        type: 'number',
        value: 1,
      },
      {
        id: 'country_id',
        name: 'Countries',
        type: 'multiselect',
        value: 1,
      },
      {
        id: 'supplier_id',
        name: 'Suppliers',
        type: 'multiselect',
        value: 55,
      },
    ],
    model: [],
  },
  {
    name: 'RL',
    value: 0,
    init: [
      {
        id: 'version',
        name: 'Version',
        type: 'multiselect',
        value: null,
      },
      {
        id: 'order_in_row',
        name: 'Order',
        type: 'number',
        value: null,
      },
      {
        id: 'position',
        name: 'Position',
        type: 'multiselect',
        value: 'Outer',
      },
      {
        id: 'parts_per_car',
        name: 'Parts per car',
        type: 'number',
        value: 2,
      },
      {
        id: 'rate',
        name: 'Rate',
        type: 'number',
        value: 1,
      },
      {
        id: 'country_id',
        name: 'Countries',
        type: 'multiselect',
        value: 1,
      },
      {
        id: 'supplier_id',
        name: 'Suppliers',
        type: 'multiselect',
        value: 55,
      },
    ],
    model: [],
  },
  {
    name: 'AuxHL',
    value: 0,
    init: [
      {
        id: 'version',
        name: 'Version',
        type: 'multiselect',
        value: null,
      },
      {
        id: 'order_in_row',
        name: 'Order',
        type: 'number',
        value: null,
      },
      {
        id: 'position',
        name: 'Position',
        type: 'multiselect',
        value: 'Lower',
      },
      {
        id: 'parts_per_car',
        name: 'Parts per car',
        type: 'number',
        value: 2,
      },
      {
        id: 'rate',
        name: 'Rate',
        type: 'number',
        value: 1,
      },
      {
        id: 'country_id',
        name: 'Countries',
        type: 'multiselect',
        value: 1,
      },
      {
        id: 'supplier_id',
        name: 'Suppliers',
        type: 'multiselect',
        value: 55,
      },
    ],
    model: [],
  },
  {
    name: 'AuxRL',
    value: 0,
    init: [
      {
        id: 'version',
        name: 'Version',
        type: 'multiselect',
        value: null,
      },
      {
        id: 'order_in_row',
        name: 'Order',
        type: 'number',
        value: null,
      },
      {
        id: 'position',
        name: 'Position',
        type: 'multiselect',
        value: 'Lower',
      },
      {
        id: 'parts_per_car',
        name: 'Parts per car',
        type: 'number',
        value: 2,
      },
      {
        id: 'rate',
        name: 'Rate',
        type: 'number',
        value: 1,
      },
      {
        id: 'country_id',
        name: 'Countries',
        type: 'multiselect',
        value: 1,
      },
      {
        id: 'supplier_id',
        name: 'Suppliers',
        type: 'multiselect',
        value: 55,
      },
    ],
    model: [],
  },
  {
    name: 'Illuminated Logo',
    value: 0,
    init: [
      {
        id: 'version',
        name: 'Version',
        type: 'multiselect',
        value: null,
      },
      {
        id: 'order_in_row',
        name: 'Order',
        type: 'number',
        value: null,
      },
      {
        id: 'position',
        name: 'Position',
        type: 'multiselect',
        value: 'Center',
      },
      {
        id: 'parts_per_car',
        name: 'Parts per car',
        type: 'number',
        value: 1,
      },
      {
        id: 'rate',
        name: 'Rate',
        type: 'number',
        value: 1,
      },
      {
        id: 'country_id',
        name: 'Countries',
        type: 'multiselect',
        value: 1,
      },
      {
        id: 'supplier_id',
        name: 'Suppliers',
        type: 'multiselect',
        value: 55,
      },
    ],
    model: [],
  },
  {
    name: 'Illuminated Grille',
    value: 0,
    init: [
      {
        id: 'version',
        name: 'Version',
        type: 'multiselect',
        value: null,
      },
      {
        id: 'order_in_row',
        name: 'Order',
        type: 'number',
        value: null,
      },
      {
        id: 'position',
        name: 'Position',
        type: 'multiselect',
        value: 'Center',
      },
      {
        id: 'parts_per_car',
        name: 'Parts per car',
        type: 'number',
        value: 1,
      },
      {
        id: 'rate',
        name: 'Rate',
        type: 'number',
        value: 1,
      },
      {
        id: 'country_id',
        name: 'Countries',
        type: 'multiselect',
        value: 1,
      },
      {
        id: 'supplier_id',
        name: 'Suppliers',
        type: 'multiselect',
        value: 55,
      },
    ],
    model: [],
  },
  {
    name: 'CHMSL',
    value: 0,
    init: [
      {
        id: 'version',
        name: 'Version',
        type: 'multiselect',
        value: null,
      },
      {
        id: 'order_in_row',
        name: 'Order',
        type: 'number',
        value: null,
      },
      {
        id: 'position',
        name: 'Position',
        type: 'multiselect',
        value: 'Center',
      },
      {
        id: 'parts_per_car',
        name: 'Parts per car',
        type: 'number',
        value: 1,
      },
      {
        id: 'rate',
        name: 'Rate',
        type: 'number',
        value: 1,
      },
      {
        id: 'country_id',
        name: 'Countries',
        type: 'multiselect',
        value: 1,
      },
      {
        id: 'supplier_id',
        name: 'Suppliers',
        type: 'multiselect',
        value: 55,
      },
    ],
    model: [],
  },
  {
    name: 'Body Lighting',
    value: 0,
    init: [
      {
        id: 'version',
        name: 'Version',
        type: 'multiselect',
        value: null,
      },
      {
        id: 'order_in_row',
        name: 'Order',
        type: 'number',
        value: null,
      },
      {
        id: 'position',
        name: 'Position',
        type: 'multiselect',
        value: 'Side',
      },
      {
        id: 'parts_per_car',
        name: 'Parts per car',
        type: 'number',
        value: 1,
      },
      {
        id: 'rate',
        name: 'Rate',
        type: 'number',
        value: 1,
      },
      {
        id: 'country_id',
        name: 'Countries',
        type: 'multiselect',
        value: 1,
      },
      {
        id: 'supplier_id',
        name: 'Suppliers',
        type: 'multiselect',
        value: 55,
      },
    ],
    model: [],
  },
];
