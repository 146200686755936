import { render } from "./Multiselect.vue?vue&type=template&id=4ff9c849"
import script from "./Multiselect.vue?vue&type=script&lang=js"
export * from "./Multiselect.vue?vue&type=script&lang=js"

import "./Multiselect.vue?vue&type=style&index=0&id=4ff9c849&lang=scss"
const cssModules = script.__cssModules = {}
import style1 from "./Multiselect.vue?vue&type=style&index=1&id=4ff9c849&lang=scss&module=true"
cssModules["$style"] = style1
script.render = render

export default script