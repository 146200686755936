<template>
  <div
    :class="[
      $style.container,
      {
        [$style.isPaddingless]: isPaddingless,
      },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isPaddingless: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;

  &:not(.isPaddingless) {
    padding-right: 40px;
    padding-left: 40px;
  }
}
</style>
