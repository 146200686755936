<template>
  <div :class="$style.background">
    <div :class="$style.constellation">
      <span
        v-for="(star, i) in stars"
        :key="i"
        :class="[$style.star, star.size, star.opacity]"
        :style="{ animationDelay: `${star.animationDelay}s`, left: `${star.left}px`, top: `${star.top}px` }"
      />
    </div>
    <div
      v-if="meteor.isVisible"
      :class="[$style.meteor, meteor.style]"
    />
  </div>
</template>

<script>
import { random } from 'lodash';

export default {
  STARS_QUANTITY: 50,
  data() {
    return {
      stars: [],
      meteor: {
        style: null,
        isVisible: false,
        castTimeout: null,
        visibleTimeout: null,
      },
      meteorTimeout: null,
    };
  },
  mounted() {
    this.generateStars();
    this.initMeteor();
  },
  beforeUnmount() {
    clearTimeout(this.meteor.castTimeout);
    clearTimeout(this.meteor.visibleTimeout);
  },
  methods: {
    generateStars() {
      const width = this.$el.clientWidth;
      const height = this.$el.clientHeight;

      const sizes = [
        this.$style.size1,
        this.$style.size1,
        this.$style.size1,
        this.$style.size2,
        this.$style.size3,
      ];
      const opacities = [
        this.$style.opacity1,
        this.$style.opacity1,
        this.$style.opacity1,
        this.$style.opacity2,
        this.$style.opacity2,
        this.$style.opacity3,
      ];

      for (let i = 0; i < this.$options.STARS_QUANTITY; i += 1) {
        this.stars.push({
          size: sizes[random(0, sizes.length)],
          opacity: opacities[random(0, opacities.length)],
          animationDelay: random(0, 9),
          left: random(0, width),
          top: random(0, height),
        });
      }
    },
    initMeteor() {
      const styles = [
        this.$style.style1,
        this.$style.style2,
        this.$style.style3,
        this.$style.style4,
      ];

      this.meteor.castTimeout = setTimeout(() => {
        this.meteor.style = styles[random(0, styles.length)];
        this.meteor.isVisible = true;
        this.meteor.visibleTimeout = setTimeout(() => {
          this.meteor.isVisible = false;
          this.initMeteor();
        }, 1000);
      }, random(5000, 10000));
    },
  },
};
</script>

<style lang="scss" module>
.background {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, rgb(0, 0, 0) 50%, rgb(25, 19, 39) 80%, rgb(43, 32, 72));
}

.constellation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //animation: rotate 600s infinite linear;
}

.star {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
}

.star.size1 { width: 1px;
  height: 1px; }

.star.size2 { width: 2px;
  height: 2px; }

.star.size3 { width: 3px;
  height: 3px; }

.star.opacity1 { opacity: 1; }

.star.opacity2 { opacity: 0.5; }

.star.opacity3 { opacity: 0.1; }

.meteor {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #fff;
  border-radius: 50%;
  transform: rotate(-35deg);
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.meteor::before {
  position: absolute;
  top: 0;
  left: 2px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 10px;
  vertical-align: middle;
  content: '';
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 85px solid #fff;
}

.meteor.style1 { animation-name: meteorStyle1; }

.meteor.style2 { animation-name: meteorStyle2; }

.meteor.style3 { animation-name: meteorStyle3; }

.meteor.style4 { animation-name: meteorStyle4; }

@keyframes meteorStyle1 {

  0% { top: 100px;
    right: 300px;
    opacity: 0; }

  30% { opacity: 0.3; }

  60% { opacity: 0.3; }

  100% { top: 600px;
    right: 1000px;
    opacity: 0; }
}

@keyframes meteorStyle2 {

  0% { top: 100px;
    right: 700px;
    opacity: 0; }

  30% { opacity: 1; }

  60% { opacity: 1; }

  100% { top: 600px;
    right: 1400px;
    opacity: 0; }
}

@keyframes meteorStyle3 {

  0% { top: 300px;
    right: 300px;
    opacity: 0; }

  30% { opacity: 1; }

  60% { opacity: 1; }

  100% { top: 800px;
    right: 1000px;
    opacity: 0; }
}

@keyframes meteorStyle4 {

  0% { top: 300px;
    right: 700px;
    opacity: 0; }

  30% { opacity: 1; }

  60% { opacity: 1; }

  100% { top: 800px;
    right: 1400px;
    opacity: 0; }
}

@keyframes rotate {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
