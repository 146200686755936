<template>
  <div
    ref="card"
    :class="$style.clusterCarCard"
    @click="navigateToCar"
  >
    <div :class="$style.infoContainer">
      <div :class="$style.side">
        <p :class="$style.info">
          ID : {{ car.id }}
        </p>
        <p :class="$style.info">
          Generation : {{ car.generation }}
        </p>
        <p :class="$style.info">
          Phase : {{ car.phase }}
        </p>
      </div>
      <div :class="$style.side">
        <p :class="$style.info">
          SOP : {{ formatSopEop(isFirst ? clusterSop : car.sop) }}
        </p>
        <p :class="$style.info">
          EOP : {{ formatSopEop(isLast ? clusterEop : car.eop) }}
        </p>
        <p :class="$style.info">
          Project code : {{ car.project_code }}
        </p>
      </div>
    </div>
    <div :class="$style.updaterefContainer">
      <template v-if="volumeInfos.length > 0">
        <BaseLabel
          v-for="item in volumeInfos"
          :key="item.update_ref_id"
          color="cherry"
        >
          {{ item.update_ref_id }}
        </BaseLabel>
      </template>
      <template v-else>
        <BaseLabel
          color="cherry"
        >
          No volume available
        </BaseLabel>
      </template>
    </div>
    <div :class="$style.picturesContainer">
      <img
        :src="
          car.front_image
            || 'https://images104500-lmi.s3.eu-central-1.amazonaws.com/public/defaultCarFront.jpg'
        "
      >
      <img
        :src="
          car.back_image
            || 'https://images104500-lmi.s3.eu-central-1.amazonaws.com/public/defaultCarBack.jpg'
        "
      >
    </div>
  </div>
</template>

<script>
import formatSopEop from '@/utils/formatSopEop';
import BaseLabel from '@/components/base/Label';

export default ({
  components: {
    BaseLabel,
  },
  props: {
    car: {
      type: Object,
      default: null,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    clusterSop: {
      type: String,
      default: null,
    },
    clusterEop: {
      type: String,
      default: null,
    },
    hideActionsButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sop: null,
      eop: null,
      volumeInfos: [],
    };
  },
  created() {
    this.volumeInfos = this.car.volumes_infos.filter((item) => item.update_ref_id);
  },
  updated() {
    this.sop = this.car.sop;
    this.eop = this.car.eop;
  },
  methods: {
    formatSopEop,
    navigateToCar() {
      const selection = window.getSelection();
      const isTextSelected = selection.toString() !== '';

      if (
        isTextSelected
        && (
          this.$refs.card.contains(selection.anchorNode)
          || this.$refs.card.contains(selection.focusNode)
        )
      ) {
        return;
      }

      this.$router.push({
        name: 'Car',
        path: '/car?test=1',
        params: {
          carId: this.car.id,
        },
        query: {
          sop: formatSopEop(this.isFirst ? this.clusterSop : this.car.sop),
          eop: formatSopEop(this.isLast ? this.clusterEop : this.car.eop),
        },
      });
    },
  },
});
</script>
<style lang="scss" module>
.clusterCarCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border-radius: 10px;
}

.infoContainer {
  display: flex;
  gap: 10px;
  width: 100%;
  cursor: pointer;

  .side {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
  }

  .info {
    margin: 0;
  }
}

.picturesContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    width: calc(50% - 5px);
    border-radius: 10px;
  }
}

.buttonsContainer {
  display: flex;
  gap: 15px;
}

.field:not(:first-child) {
  margin-top: 10px;
}

.updaterefContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
