<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.tableContainer"
  >
    <div :class="$style.searchContainer">
      <BaseInput
        v-model="searchValue"
        placeholder="Search"
        type="text"
      />
      <BaseButton
        variant="success"
        type="insertRow"
        @click="openForm({}, 'create')"
      >
        Insert new MLFS
      </BaseButton>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="mlfs"
      :rows-per-page="10"
      :search-value="searchValue"
      sort-by="id"
      sort-type="asc"
      :table-class-name="$style.dataTable"
      @click-row="openForm($event, 'update')"
    />
    <FormBox
      v-if="isFormOpen"
      :class="$style.formBox"
      :heading="`${formMode === 'create' ? 'Create' : 'Update'} row`"
    >
      <div :class="$style.fieldsContainer">
        <BaseField
          :class="$style.field"
        >
          <template #label>
            Device ID
          </template>
          <BaseInput
            v-model="lightingDeviceId"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Light function
          </template>
          <BaseMultiselect
            :model-value="lightFunctionId"
            :options="filterLightFunctions()"
            :class="$style.multiselect"
            is-block
            @update:modelValue="setMultiselectValue($event, 'lightFunctionId')"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Light functionality
          </template>
          <BaseMultiselect
            :model-value="lightFunctionalityId"
            :options="filterLightFunctionalities()"
            :class="$style.multiselect"
            is-block
            @update:modelValue="setMultiselectValue($event, 'lightFunctionalityId')"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Light source
          </template>
          <BaseMultiselect
            :model-value="lightSourceId"
            :options="filterLightSources()"
            :class="$style.multiselect"
            is-block
            @update:modelValue="setMultiselectValue($event, 'lightSourceId')"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Optical system
          </template>
          <BaseMultiselect
            :model-value="opticalSystemId"
            :options="filterOpticalSystems()"
            :class="$style.multiselect"
            is-block
            @update:modelValue="setMultiselectValue($event, 'opticalSystemId')"
          />
        </BaseField>
      </div>

      <template #footer>
        <div :class="$style.formButtonsContainer">
          <BaseButton
            variant="success"
            type="updateOrCreateRow"
            @click="formMode === 'create' ? createRow() : updateRow()"
          >
            {{ formMode === 'create' ? 'Create' : 'Update' }}
          </BaseButton>
          <BaseButton
            variant="secondary"
            type="closeForm"
            @click="closeForm"
          >
            Close
          </BaseButton>
        </div>
      </template>
    </FormBox>
  </BaseLoader>
</template>
<script>
import ldvs from '@/graphql/queries/ldvs';
import mainLightFunctions from '@/graphql/queries/mainLightFunctions';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseButton from '@/components/base/Button';
import BaseInput from '@/components/base/Input';
import BaseMultiselect from '@/components/base/Multiselect';
import { isEmpty, isArray } from 'lodash';

export default ({
  components: {
    BaseLoader,
    FormBox,
    BaseField,
    BaseButton,
    BaseInput,
    BaseMultiselect,
  },
  data() {
    return {
      headers: [
        {
          text: 'ID', value: 'id', sortable: true,
        },
        {
          text: 'Light function', value: 'light_function.name', sortable: true,
        },
        {
          text: 'Light functionality', value: 'light_functionality.name', sortable: true,
        },
        {
          text: 'Light source', value: 'light_source.technology', sortable: true,
        },
        {
          text: 'Optical system', value: 'optical_system.name', sortable: true,
        },
      ],
      mlfs: [],
      ldvConfigOptions: [],
      lightFunctions: [],
      lightFunctionalities: [],
      lightSources: [],
      opticalSystems: [],
      searchValue: '',
      isFormOpen: false,
      formMode: null,
      isLoading: false,
      rowId: null,
      lightingDeviceType: null,
      lightingDeviceId: null,
      lightFunctionId: null,
      lightFunctionalityId: null,
      lightSourceId: null,
      opticalSystemId: null,
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([
      mainLightFunctions.getAllMlfs(),
      ldvs.getAllLdvConfigOptions(),
    ]).then((results) => {
      this.mlfs = [...results[0]];
      this.ldvConfigOptions = [...results[1]];
      this.ldvConfigOptions.forEach((option) => {
        if (!option.light_function) return;
        if (!this.lightFunctions.find((lightFunction) => lightFunction.id === option.light_function.id)) {
          this.lightFunctions.push({
            ...option.light_function,
            label: option.light_function.name,
            value: option.light_function.id,
          });
        }
        if (!this.lightFunctionalities.find(
          (lightFunctionality) => lightFunctionality.id === option.light_functionality.id,
        )) {
          this.lightFunctionalities.push({
            ...option.light_functionality,
            label: option.light_functionality.name,
            value: option.light_functionality.id,
          });
        }
        if (!this.lightSources.find((lightSource) => lightSource.id === option.light_source.id)) {
          this.lightSources.push({
            ...option.light_source,
            label: option.light_source.technology,
            value: option.light_source.id,
          });
        }
        if (!this.opticalSystems.find((opticalSystem) => opticalSystem.id === option.optical_system.id)) {
          this.opticalSystems.push({
            ...option.optical_system,
            label: option.optical_system.name,
            value: option.optical_system.id,
          });
        }
      });
      this.isLoading = false;
    });
  },
  methods: {
    createRow() {
      this.closeForm();
      this.isLoading = true;
      const mlfsToCreate = {
        lighting_device_id: this.lightingDeviceId,
        light_function_id: this.lightFunctionId,
        light_functionality_id: this.lightFunctionalityId,
        light_source_id: this.lightSourceId,
        optical_system_id: this.opticalSystemId,
      };
      mainLightFunctions.insertMlf(mlfsToCreate).then((insertResult) => {
        this.mlfs.push(insertResult);
        this.isLoading = false;
      });
    },
    openForm(row, formMode) {
      this.formMode = formMode;
      this.rowId = row.id;
      this.lightingDeviceType = row.lighting_device?.type;
      this.lightingDeviceId = row.lighting_device_id;
      this.lightFunctionId = row.light_function?.id;
      this.lightFunctionalityId = row.light_functionality?.id;
      this.lightSourceId = row.light_source?.id;
      this.opticalSystemId = row.optical_system?.id;
      this.isFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
    },
    updateRow() {
      this.closeForm();
      this.isLoading = true;
      const mlfsToUpdate = {
        id: this.rowId,
        lighting_device_id: this.lightingDeviceId,
        light_function_id: this.lightFunctionId,
        light_functionality_id: this.lightFunctionalityId,
        light_source_id: this.lightSourceId,
        optical_system_id: this.opticalSystemId,
      };
      mainLightFunctions.updateMlfByPk(mlfsToUpdate).then(() => {
        const localMlfsToUpdate = this.mlfs.find((mlf) => mlf.id === this.rowId);
        const index = this.mlfs.indexOf(localMlfsToUpdate);
        this.mlfs[index] = { ...this.mlfs[index], ...mlfsToUpdate };
        this.isLoading = false;
      });
    },
    filterLightFunctions() {
      if (!this.lightingDeviceType) {
        return this.lightFunctions.map((lightFunction) => ({
          ...lightFunction,
          label: `${lightFunction.lighting_device_type} - ${lightFunction.name}`,
        })).sort((a, b) => (a.label < b.label ? -1 : 1));
      }
      return this.lightFunctions.filter(
        (lightFunction) => lightFunction.lighting_device_type === this.lightingDeviceType,
      );
    },
    filterLightFunctionalities() {
      if (!this.lightFunctionId) return this.lightFunctionalities;
      const result = this.lightFunctionalities.filter((lightFunctionality) => this.ldvConfigOptions.find(
        (option) => option.light_functionality.id === lightFunctionality.id
          && option.light_function.id === this.lightFunctionId,
      ));
      if (!result.find((lightFunctionality) => lightFunctionality.id === this.lightFunctionalityId)) {
        this.lightFunctionalityId = null;
      }
      return result;
    },
    filterLightSources() {
      if (!this.lightFunctionId && !this.lightFunctionalityId) return this.lightSources;
      let result;
      if (!this.lightFunctionId) {
        result = this.lightSources.filter(
          (lightSource) => this.ldvConfigOptions.find(
            (option) => option.light_source.id === lightSource.id
              && option.light_functionality.id === this.lightFunctionalityId,
          ),
        );
      } else if (!this.lightFunctionalityId) {
        result = this.lightSources.filter(
          (lightSource) => this.ldvConfigOptions.find(
            (option) => option.light_source.id === lightSource.id
              && option.light_function.id === this.lightFunctionId,
          ),
        );
      } else {
        result = this.lightSources.filter(
          (lightSource) => this.ldvConfigOptions.find(
            (option) => option.light_source.id === lightSource.id
            && option.light_function.id === this.lightFunctionId
            && option.light_functionality.id === this.lightFunctionalityId,
          ),
        );
      }
      if (!result.find((lightSource) => lightSource.id === this.lightSourceId)) {
        this.lightSourceId = null;
      }
      return result;
    },
    filterOpticalSystems() {
      if (!this.lightFunctionId && !this.lightFunctionalityId) return this.opticalSystems;
      let result;
      if (!this.lightFunctionId) {
        result = this.opticalSystems.filter(
          (opticalSystem) => this.ldvConfigOptions.find(
            (option) => option.optical_system.id === opticalSystem.id
              && option.light_functionality.id === this.lightFunctionalityId,
          ),
        );
      } else if (!this.lightFunctionalityId) {
        result = this.opticalSystems.filter(
          (opticalSystem) => this.ldvConfigOptions.find(
            (option) => option.optical_system.id === opticalSystem.id
              && option.light_function.id === this.lightFunctionId,
          ),
        );
      } else {
        result = this.opticalSystems.filter(
          (opticalSystem) => this.ldvConfigOptions.find(
            (option) => option.optical_system.id === opticalSystem.id
            && option.light_function.id === this.lightFunctionId
            && option.light_functionality.id === this.lightFunctionalityId,
          ),
        );
      }
      if (!result.find((opticalSystem) => opticalSystem.id === this.opticalSystemId)) {
        this.opticalSystemId = null;
      }
      return result;
    },
    setMultiselectValue(value, inputName) {
      if (isArray(value) && isEmpty(value)) { this[inputName] = null; } else { this[inputName] = value; }
    },
  },
});
</script>
<style lang="scss" module>

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.searchContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.dataTable {
  --easy-table-body-row-height: 32px;
}

.oemLogo {
  width: 25px;
  height: 25px;
}

.deleteIconContainer {
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 2px;
    cursor: pointer;
  }
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.field {
  width: 250px;
}

.multiselect {
  height: 100%;
}

.formButtonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
</style>
