<template>
  <div>
    <div :class="[$style.tableWrapper, { [$style.isBlock]: isBlock }]">
      <div :class="$style.tableScrollable">
        <table :class="$style.table">
          <slot />
          <TableHeader
            ref="header"
            :data-columns="dataColumns"
            :is-selectable="isSelectable"
            :is-all-data-selected="isAllDataSelected"
            :is-data-partially-selected="isDataPartiallySelected"
            :has-actions-column="hasActionsColumn"
            :visibility-toggable-columns="visibilityToggableColumns"
            @toggleSelectAll="toggleSelectAll"
            @setHeaderRowRef="setHeaderRowRef"
          />
          <TableBody
            ref="body"
            v-model:selection="selectionProxy"
            :data="data"
            :data-columns="dataColumns"
            :data-key="dataKey"
            :is-selectable="isSelectable"
            :visibility-toggable-columns="visibilityToggableColumns"
            @setRowRefs="setBodyRowRefs"
          />
        </table>
      </div>
      <TableActionsColumnWrapper
        v-if="actionsColumn || toggableDataColumns.length"
        v-model:visibility-toggable-columns="visibilityToggableColumns"
        :data="data"
        :actions-column="actionsColumn"
        :data-key="dataKey"
        :header-row-ref="headerRowRef"
        :body-row-refs="bodyRowRefs"
        :toggable-data-columns="toggableDataColumns"
      />
    </div>
    <div
      v-if="data.length === 0"
      :class="$style.noData"
    >
      <span>No data</span>
    </div>
  </div>
</template>

<script>
import TableActionsColumn from './TableActionsColumn';
import TableActionsColumnWrapper from './TableActionsColumnWrapper';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { getColumnId, getIsDataColumnToggable } from './utils';

export default {
  components: {
    TableHeader,
    TableBody,
    TableActionsColumnWrapper,
  },
  provide() {
    return {
      registerDataColumn: this.registerDataColumn,
      registerActionsColumn: this.registerActionsColumn,
      forceUpdateTable: this.forceUpdateTable,
    };
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    dataKey: {
      type: String,
      default: null,
    },
    selection: {
      type: Array,
      default: () => [],
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:selection': null,
  },
  data() {
    return {
      headerRowRef: null,
      bodyRowRefs: null,
      dataColumns: [],
      actionsColumn: null,
      visibilityToggableColumns: [],
    };
  },
  computed: {
    toggableDataColumns() {
      return this.dataColumns.filter(getIsDataColumnToggable);
    },
    hasActionsColumn() {
      return this.actionsColumn != null;
    },
    selectionProxy: {
      get() {
        return this.selection;
      },
      set(value) {
        this.$emit('update:selection', value);
      },
    },
    isEmpty() {
      return this.data.length === 0;
    },
    isAllDataSelected() {
      return !this.isEmpty && this.data.every((data) => this.selection.includes(data));
    },
    isDataPartiallySelected() {
      return !this.isAllDataSelected && this.data.some((data) => this.selection.includes(data));
    },
  },
  mounted() {
    this.visibilityToggableColumns = this.toggableDataColumns.map(getColumnId);
  },
  methods: {
    forceUpdateTable() {
      this.$refs.header.$forceUpdate();
      this.$refs.body.$forceUpdate();
    },
    registerDataColumn(instance) {
      this.dataColumns.push(instance);
    },
    registerActionsColumn(instance) {
      this.actionsColumn = instance;
    },
    checkIfActionsColumn(item) {
      return item.type === TableActionsColumn;
    },
    setHeaderRowRef(ref) {
      this.headerRowRef = ref;
    },
    setBodyRowRefs(refs) {
      this.bodyRowRefs = refs;
    },
    toggleSelectAll() {
      if (this.isAllDataSelected) {
        this.selectionProxy = [];
      } else {
        this.selectionProxy = [...this.data];
      }
    },
  },
};
</script>

<style lang="scss" module>
.tableWrapper {
  display: inline-flex;
  max-width: 100%;
  padding-top: 10px;

  &.isBlock {
    display: flex;
  }
}

.tableScrollable {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  max-width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.noData {
  padding: 25px;
  text-align: center;

  span {
    display: inline-block;
    padding: 8px 12px;
    font-weight: 500;
    color: $title;
    background: $background-light;
    border-radius: 5px;
  }
}
</style>
