import gql from 'graphql-tag';
import { apolloClient } from '@/vue-apollo';

export default {
  async getFactories() {
    const result = await apolloClient.query({
      query: gql`query {
        factories (order_by: {id: asc}) {
          id
          name
          region
          country
          city
          longitude
          latitude
          created_by
          brand {
            id
            name
            value_chain_level
          }
        }
      }`,
    });

    return result.data.factories;
  },

  async getFactoriesNamesAndIds() {
    const result = await apolloClient.query({
      query: gql`query {
        factories (order_by: {name: asc}) {
          id
          name
        }
      }`,
    });

    return result.data.factories;
  },

  async insertFactory(factory) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $factory: factories_insert_input!,
      ) {
        insert_factories_one(
          object: $factory
        ) {
          id
          name
          region
          country
          city
          longitude
          latitude
          created_by
          brand {
            id
            name
            value_chain_level
          }
        }
      }`,
      variables: {
        factory,
      },
    });

    return result.data.insert_factories_one;
  },

  async updateFactoriesByPk(factory) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $updatedValues: factories_set_input,
      ) {
        update_factories_by_pk(
          pk_columns: {id: $id},
          _set: $updatedValues
        ) {
          id
          name
          region
          country
          city
          longitude
          latitude
          created_by
          brand {
            id
            name
            value_chain_level
          }
        }
      }`,
      variables: {
        id: factory.id,
        updatedValues: factory,
      },
    });

    return result;
  },

  async deleteFactoryByPk(id) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!) {
        delete_factories_by_pk(id: $id) {
          id
        }
      }`,
      variables: {
        id,
      },
    });

    return result;
  },
};
