<template>
  <div :class="$style.header">
    <h1 :class="$style.heading">
      <slot />
    </h1>
    <div>
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .heading {
    padding: 0;
    margin: 0;
    margin-left: 15px;
    font-size: 26px;
    line-height: 1.238;
    color: rgba($title, 0.9);
    letter-spacing: -0.22px;
  }
}
</style>
