<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.loader"
  >
    <FormBox
      :heading="mode === 'edit' ? `Edit device #${device.id}` : 'Create new device'"
      :class="$style.formBox"
    >
      <div :class="$style.fieldsContainer">
        <div>
          <BaseField>
            <template #label>
              Type
            </template>
            <BaseMultiselect
              :model-value="type"
              :options="types"
              is-searchable
              is-block
              @update:modelValue="setMultiselectValue($event, 'type')"
            />
          </BaseField>
          <BaseField>
            <template #label>
              Supplier
            </template>
            <BaseMultiselect
              :model-value="supplierId"
              :options="suppliers"
              is-searchable
              is-block
              @update:modelValue="setMultiselectValue($event, 'supplierId')"
            />
          </BaseField>
          <BaseField>
            <template #label>
              Country
            </template>
            <BaseMultiselect
              :model-value="countryId"
              :options="countries"
              is-searchable
              is-block
              @update:modelValue="setMultiselectValue($event, 'countryId')"
            />
          </BaseField>
          <BaseField>
            <template #label>
              Parts per car
            </template>
            <BaseInput
              v-model="partsPerCar"
              type="number"
            />
          </BaseField>
          <BaseField>
            <template #label>
              Part number
            </template>
            <BaseInput
              v-model="partNumber"
              type="text"
            />
          </BaseField>
          <BaseField>
            <template #label>
              Aftermarket price
            </template>
            <BaseInput
              v-model="aftermarketPrice"
              type="number"
            />
          </BaseField>
        </div>
        <div>
          <BaseField>
            <template #label>
              Position
            </template>
            <div :class="$style.radioContainer">
              <BaseLabeledRadio
                v-for="option in positions"
                :key="option"
                v-model="position"
                :value="option"
                is-dark
                :class="$style.radioPositionOption"
              >
                {{ option }}
              </BaseLabeledRadio>
            </div>
          </BaseField>
          <BaseField>
            <template #label>
              Order in row
            </template>
            <div :class="$style.radioContainer">
              <BaseLabeledRadio
                v-for="option in ordersInRow"
                :key="option"
                v-model="orderInRow"
                :value="option"
                is-dark
                :class="$style.radioOrderOption"
              >
                {{ option }}
              </BaseLabeledRadio>
            </div>
          </BaseField>
          <BaseField :class="$style.commentField">
            <template #label>
              Comment on lighting config #{{ lightingConfigId }}
            </template>
            <BaseInput
              v-model="comment"
              type="textarea"
              :class="$style.commentTextArea"
            />
          </BaseField>
        </div>
      </div>
    </FormBox>
    <div :class="$style.buttonsContainer">
      <BaseButton
        variant="secondary"
        @click="cancel"
      >
        Cancel
      </BaseButton>
      <BaseButton
        variant="success"
        @click="updateOrCreateDevice"
      >
        {{ mode === 'edit' ? 'Update' : 'Create' }}
      </BaseButton>
    </div>
  </BaseLoader>
</template>
<script>
import ldvs from '@/graphql/queries/ldvs';
import brands from '@/graphql/queries/brands';
import utils from '@/graphql/queries/utils';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseInput from '@/components/base/Input';
import BaseField from '@/components/base/Field';
import BaseMultiselect from '@/components/base/Multiselect';
import BaseLabeledRadio from '@/components/base/LabeledRadio';
import BaseButton from '@/components/base/Button';
import { isEmpty, isArray } from 'lodash';

export default {
  components: {
    BaseLoader,
    FormBox,
    BaseInput,
    BaseField,
    BaseMultiselect,
    BaseLabeledRadio,
    BaseButton,
  },
  props: {
    device: {
      type: Object,
      default: null,
    },
    carId: {
      type: Number,
      default: null,
    },
    initialComment: {
      type: String,
      default: null,
    },
    lightingConfigId: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  emits: {
    cancelUpdate: null,
    updateDevice: null,
    createDevice: null,
  },
  data() {
    return {
      type: null,
      types: ['HL', 'RL', 'AuxHL', 'AuxRL', 'CHMSL', 'Illuminated Grille', 'Illuminated Logo', 'Body Lighting'],
      suppliers: [],
      supplierId: null,
      countries: [],
      countriesObjects: [],
      countryId: null,
      partsPerCar: null,
      partNumber: null,
      aftermarketPrice: null,
      positions: ['Outer', 'Inner', 'Center', 'Middle', 'Lower', 'Upper', 'Side', 'Front', 'Back'],
      position: null,
      ordersInRow: [1, 2, 3, 4],
      orderInRow: null,
      comment: null,
      isLoading: false,
    };
  },
  watch: {
    device() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.isLoading = true;
      Promise.all([
        brands.getAllSuppliers(),
        utils.getCountries(),
      ])
        .then(([suppliers, countries]) => {
          this.suppliers = suppliers.map((supplier) => ({
            label: supplier.name,
            value: supplier.id,
          }));
          this.suppliersObjects = suppliers;
          this.countries = countries.map((country) => ({
            label: country.name,
            value: country.id,
          }));
          this.countriesObjects = countries;

          this.type = this.device?.type;
          this.supplierId = this.device?.supplier_id;
          this.countryId = this.device?.country_id;
          this.partsPerCar = this.device?.parts_per_car;
          this.partNumber = this.device?.part_number;
          this.aftermarketPrice = this.device?.aftermarket_price;
          this.position = this.device?.position;
          this.orderInRow = this.device?.order_in_row;
          this.comment = this.initialComment;
          this.isLoading = false;
        });
    },
    setMultiselectValue(value, inputName) {
      if (isArray(value) && isEmpty(value)) { this[inputName] = null; } else { this[inputName] = value; }
    },
    updateOrCreateDevice() {
      this.isLoading = true;
      const ldvToUpdateOrCreate = {
        type: this.type,
        supplier_id: this.supplierId,
        country_id: this.countryId,
        parts_per_car: this.partsPerCar,
        part_number: this.partNumber,
        aftermarket_price: this.aftermarketPrice,
        position: this.position,
        order_in_row: this.orderInRow,
      };
      const lightingConfigurationToUpdate = {
        id: this.lightingConfigId,
        comment: this.comment,
      };

      if (this.mode === 'edit') {
        ldvToUpdateOrCreate.id = this.device.id;
        Promise.all([
          ldvs.updateLdvsByPk(ldvToUpdateOrCreate),
          ldvs.updateLightingConfigurationByPk(lightingConfigurationToUpdate),
        ])
          .then(() => {
            ldvToUpdateOrCreate.country = this.countriesObjects.find((country) => country.id === this.countryId);
            ldvToUpdateOrCreate.supplier = this.suppliersObjects.find((supplier) => supplier.id === this.supplierId);
            this.$emit('updateDevice', ldvToUpdateOrCreate, this.comment);
            this.isLoading = false;
          });
      } else if (this.mode === 'create') {
        ldvs.insertLdv(ldvToUpdateOrCreate).then((ldv) => {
          lightingConfigurationToUpdate.lighting_device_id = ldv.id;
          ldvToUpdateOrCreate.id = ldv.id;
          ldvs.updateLightingConfigurationByPk(lightingConfigurationToUpdate).then(() => {
            this.$emit('createDevice', ldvToUpdateOrCreate, this.comment);
            this.isLoading = false;
          });
        });
      }
    },
    cancel() {
      this.$emit('cancelUpdate');
    },
  },
};
</script>
<style lang="scss" module>

.loader {
  min-height: unset;
}

.formBox {
  padding-top: 0;
  padding-bottom: 0;
}

.fieldsContainer {
  display: flex;
  gap: 50px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 300px;
  }
}

.radioContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 30px;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
}

.radioPositionOption {
  width: 30%;
}

.radioOrderOption {
  width: 40px;
}

.commentField {
  flex: 1;
}

.commentTextArea {
  height: 100%;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
