import gql from 'graphql-tag';
import { apolloClient } from '@/vue-apollo';

export default {

  async getCountries() {
    const result = await apolloClient.query({
      query: gql`query {
        countries (order_by: {name: asc}) {
          id
          name
          code
          region
        }
      }`,
    });

    return result.data.countries;
  },

  async getCarBodiesAndCountries() {
    const result = await apolloClient.query({
      query: gql`query {
        bodies {
          id
          name
        }
        countries (order_by: {name: asc}) {
          id
          name
        }
      }`,
    });

    return result.data;
  },

  async insertGlobalTakeRate(globalTakeRate) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $globalTakeRate: global_take_rates_insert_input!,
      ) {
        insert_global_take_rates_one(
          object: $globalTakeRate
        ) {
          id
          rate
          created_at
          lighting_configuration_id
        }
      }`,
      variables: {
        globalTakeRate,
      },
    });

    return result.data.insert_global_take_rates_one;
  },
};
