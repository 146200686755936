<template>
  <div
    ref="switch"
    :class="$style.switch"
  >
    <div :class="$style.availableOptions">
      <template
        v-for="(option, index) in options"
        :key="option.value"
      >
        <button
          :ref="addOptionsRef"
          :class="$style.optionButton"
          @click="set(option.value)"
        >
          {{ option.label }}
        </button>
        <span
          v-if="index !== options.length - 1"
          :class="$style.divider"
        />
      </template>
    </div>
    <div
      ref="active"
      :class="$style.activeOptions"
    >
      <template
        v-for="(option, index) in options"
        :key="option.value"
      >
        <span
          :class="$style.optionButton"
        >
          {{ option.label }}
        </span>
        <span
          v-if="index !== options.length - 1"
          :class="$style.divider"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
  },
  emits: {
    'update:modelValue': null,
  },
  data() {
    return {
      optionsRefs: [],
    };
  },
  mounted() {
    this.updateSelectedStyle();
  },
  updated() {
    this.updateSelectedStyle();
  },
  methods: {
    addOptionsRef(ref) {
      this.optionsRefs.push(ref);
    },
    updateSelectedStyle() {
      const selectedIndex = this.options.findIndex((option) => option.value === this.modelValue);
      const optionEl = this.optionsRefs[selectedIndex];
      const left = Math.max(optionEl.offsetLeft - 2, 2);
      const right = Math.max(this.$refs.switch.clientWidth - optionEl.offsetLeft - optionEl.clientWidth - 2, 2);
      this.$refs.active.style.clipPath = `inset(2px ${right}px 2px ${left}px round 4px)`;
    },
    set(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style lang="scss" module>
.switch {
  position: relative;
  display: inline-flex;
}

.availableOptions,
.activeOptions {
  display: inline-flex;
  align-items: center;
  background: $background-light;
  border-radius: 5px;
}

.availableOptions {
  display: inline-flex;
  align-items: center;
  background: $background-light;
  border-radius: 5px;

  .optionButton {
    cursor: pointer;
  }
}

.optionButton {
  @include reset;
  padding: 6px 10px 4px;
  font-size: 13px;
  color: $text;
}

.divider {
  display: inline-block;
  width: 1px;
  height: 15px;
  background: $disabled;
}

.activeOptions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  clip-path: inset(1px round 5px);
  background: $white;
  transition: clip-path 0.2s ease-in-out;

  .optionButton {
    color: $title;
  }

  .divider {
    background: transparent;
  }
}
</style>
