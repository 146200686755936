<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.tableContainer"
  >
    <div :class="$style.searchContainer">
      <BaseInput
        v-model="searchValue"
        placeholder="Search"
        type="text"
      />
      <BaseButton
        variant="success"
        type="insertRow"
        @click="openForm({}, 'create')"
      >
        Insert new factory
      </BaseButton>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="factories"
      :rows-per-page="10"
      :search-value="searchValue"
      sort-by="name"
      sort-type="asc"
      :table-class-name="$style.dataTable"
      @click-row="openForm($event, 'update')"
    />
    <FormBox
      v-if="isFormOpen"
      :class="$style.formBox"
      :heading="`${formMode === 'create' ? 'Create' : 'Update'} row`"
    >
      <div :class="$style.fieldsContainer">
        <BaseField
          :class="$style.field"
        >
          <template #label>
            Name
          </template>
          <BaseInput
            v-model="name"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Brand
          </template>
          <BaseMultiselect
            :model-value="brandId"
            :options="brands"
            :class="$style.brandMultiselect"
            is-searchable
            is-block
            @update:modelValue="setBrandId"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Region
          </template>
          <BaseInput
            v-model="region"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Country
          </template>
          <BaseInput
            v-model="country"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            City
          </template>
          <BaseInput
            v-model="city"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Latitude
          </template>
          <BaseInput
            v-model="latitude"
            type="number"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Longitude
          </template>
          <BaseInput
            v-model="longitude"
            type="number"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Owner
          </template>
          <BaseInput
            v-model="owner"
            type="text"
          />
        </BaseField>
      </div>

      <template #footer>
        <div :class="$style.formButtonsContainer">
          <BaseButton
            variant="success"
            type="updateOrCreateRow"
            @click="formMode === 'create' ? createRow() : updateRow()"
          >
            {{ formMode === 'create' ? 'Create' : 'Update' }}
          </BaseButton>
          <BaseButton
            variant="secondary"
            type="closeForm"
            @click="closeForm"
          >
            Close
          </BaseButton>
        </div>
      </template>
    </FormBox>
  </BaseLoader>
</template>
<script>
import factories from '@/graphql/queries/factories';
import brands from '@/graphql/queries/brands';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseButton from '@/components/base/Button';
import BaseInput from '@/components/base/Input';
import BaseMultiselect from '@/components/base/Multiselect';
import { isEmpty, isArray } from 'lodash';

export default ({
  components: {
    BaseLoader,
    FormBox,
    BaseField,
    BaseButton,
    BaseInput,
    BaseMultiselect,
  },
  data() {
    return {
      headers: [
        {
          text: 'ID', value: 'id', sortable: true, width: 75,
        },
        {
          text: 'Name', value: 'name', sortable: true, width: 150,
        },
        {
          text: 'Brand', value: 'brand.name', sortable: true, width: 150,
        },
        {
          text: 'Brand VCL', value: 'brand.value_chain_level', sortable: true, width: 100,
        },
        {
          text: 'Region', value: 'region', sortable: true, width: 100,
        },
        {
          text: 'Country', value: 'country', sortable: true, width: 100,
        },
        {
          text: 'City', value: 'city', sortable: true, width: 100,
        },
      ],
      factories: [],
      brands: [],
      searchValue: '',
      isFormOpen: false,
      formMode: null,
      isLoading: false,
      rowId: null,
      name: null,
      brandId: null,
      region: null,
      country: null,
      city: null,
      latitude: null,
      longitude: null,
      owner: null,
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([factories.getFactories(), brands.getBrandsOemNamesAndIds()])
      .then((results) => {
        this.factories = [...results[0]];
        this.brands = results[1].map((brand) => ({ label: brand.name, value: brand.id }));
        this.isLoading = false;
      });
  },
  methods: {
    setBrandId(brandId) {
      if (isArray(brandId) && isEmpty(brandId)) {
        this.brandId = null;
      } else {
        this.brandId = brandId;
      }
    },
    createRow() {
      this.closeForm();
      this.isLoading = true;
      const factoryToCreate = {
        name: this.name,
        brand_id: this.brandId,
        region: this.region,
        country: this.country,
        city: this.city,
        latitude: this.latitude || null,
        longitude: this.longitude || null,
        created_by: this.owner,
      };
      factories.insertFactory(factoryToCreate).then((insertResult) => {
        this.factories.push(insertResult);
        this.isLoading = false;
      });
    },
    openForm(row, formMode) {
      this.formMode = formMode;
      this.rowId = row.id;
      this.name = row.name;
      this.brandId = formMode === 'create'
        ? null
        : this.brands.find((brand) => brand.value === row.brand.id)?.value;
      this.region = row.region;
      this.country = row.country;
      this.city = row.city;
      this.latitude = row.latitude;
      this.longitude = row.longitude;
      this.owner = row.created_by;
      this.isFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
    },
    updateRow() {
      this.closeForm();
      this.isLoading = true;
      const factoryToUpdate = {
        id: this.rowId,
        name: this.name,
        brand_id: this.brandId,
        region: this.region,
        country: this.country,
        city: this.city,
        latitude: this.latitude || null,
        longitude: this.longitude || null,
        created_by: this.owner,
      };
      factories.updateFactoriesByPk(factoryToUpdate).then(() => {
        const localFactoryToUpdate = this.factories.find((factory) => factory.id === this.rowId);
        const index = this.factories.indexOf(localFactoryToUpdate);
        this.factories[index] = { ...this.factories[index], ...factoryToUpdate };
        this.isLoading = false;
      });
    },
  },
});
</script>
<style lang="scss" module>

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.searchContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.dataTable {
  --easy-table-body-row-height: 32px;
}

.deleteIconContainer {
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 2px;
    cursor: pointer;
  }
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.field {
  width: 250px;
}

.brandMultiselect {
  height: 100%;
}

.formButtonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
</style>
