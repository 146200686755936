<template>
  <div :class="$style.cluster">
    <div :class="$style.header">
      <div :class="$style.titleContainer">
        <img :src="carCluster.brand.logo">
        <p :class="$style.title">
          {{ model.global_nameplate }} -
          {{ carCluster.body.name }} -
          {{ carCluster.country.code || "?" }} -
          Gen {{ carCluster.generation || "?" }}
        </p>
        <p :class="$style.powerTrain">
          {{ carCluster.powerTrain }}
        </p>
      </div>
    </div>

    <EquipCarCard
      v-for="(car, index) in carCluster.cars"
      :key="car.id"
      :car="car"
      :is-first="index === 0"
      :is-last="index === carCluster.cars.length - 1"
      :cluster-sop="carCluster.sop"
      :cluster-eop="carCluster.eop"
      @car-removed="updateCluster(car)"
      @car-updated="editCar(car, index, $event)"
      @phase-added="increasePhases(index, $event)"
    />
  </div>
</template>

<script>
import EquipCarCard from '@/components/EquipCarCard';

export default ({
  components: {
    EquipCarCard,
  },
  props: {
    carCluster: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
  },
});
</script>

<style lang="scss" module>
.cluster {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 375px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-radius: 10px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .titleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-weight: bold;
      color: $title;
    }

    img {
      width: 35px;
    }

    .title {
      margin: 0;
    }

    .powerTrain {
      width: 35px;
      margin: 0;
      text-align: end;
    }
  }
}
</style>
