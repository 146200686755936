import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '@/layouts/Main';
import CompanyDisabled from '@/views/CompanyDisabled';
import Home from '@/views/Home';
import Login from '@/views/Login';
import BasedataAdmin from '@/views/BasedataAdmin';
import LightingClusters from '@/views/LightingClusters';
import Update from '@/views/Update';
import TableManagement from '@/views/TableManagement';
import Equipment from '@/views/Equipment';
import Car from '@/views/Car';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        component: Home,
        name: 'Home',
        meta: {
          hero: {
            title: 'Administration',
            lead: 'Administration of the LMI Data Base Tables',
          },
        },
      },
      {
        path: 'login',
        component: Login,
        name: 'Login',
      },
      {
        path: '/logout',
        name: 'Logout',
        async beforeEnter() {
          await store.dispatch('user/logOut');
          window.location.href = '/';
        },
      },
      {
        path: '/no-access',
        name: 'NoAccess',
        component: CompanyDisabled,
      },
      {
        path: 'basedata',
        component: BasedataAdmin,
        name: 'BasedataAdmin',
      },
      {
        path: 'lighting_clusters',
        component: LightingClusters,
        name: 'LightingClusters',
      },
      {
        path: 'update',
        component: Update,
        name: 'Update',
      },
      {
        path: 'table-management',
        component: TableManagement,
        name: 'TableManagement',
      },
      {
        path: 'equipment',
        component: Equipment,
        name: 'Equipment',
      },
      {
        path: 'car/:carId',
        component: Car,
        name: 'Car',
      },

      /*
      {
        path: 'sourcecars',
        component: SourceCars,
        name: 'SourceCars',
        meta: {
          hero: {
            title: 'Source Cars',
            lead: 'Administration of the Source Cars',
          },
        },
      },
      {
        path: 'equipcars',
        component: EquipeCarsWithLdv,
        name: 'EquipeCarsWithLdv'
      }, */
    ],
  },
];

const publicRoutes = ['Login', 'Logout'];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const isPublicRoute = publicRoutes.includes(to.name);
  await store.dispatch('user/restore');

  if ((!store.getters['user/isLoggedIn'] && !isPublicRoute)) {
    await store.dispatch('user/logOut');
    return true;
  }

  if (!store.getters['user/isLoggedIn'] && !isPublicRoute) {
    return { name: 'NoAccess' };
  }

  return true;
});

export default router;
