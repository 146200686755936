import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import app from './app';
// eslint-disable-next-line import/no-cycle
import user from './user';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    app: {
      updateReferenceId: state.app.updateReferenceId,
      updateReferenceLabel: state.app.updateReferenceLabel,
    },
  }),
});

const store = createStore({
  modules: {
    app,
    user,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
