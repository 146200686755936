export default function getDeviceTypeLabelColor(type) {
  switch (type) {
    case 'HL':
      return 'barley';
    case 'RL':
      return 'cherry';
    case 'AuxHL':
      return 'lime';
    case 'AuxRL':
      return 'aqua';
    case 'CHMSL':
      return 'orange';
    default:
      return '';
  }
}
