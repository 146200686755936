<template>
  <div :class="$style.wrapper">
    <button
      :class="[$style.navButton, $style.previousPage]"
      :disabled="currentPage === 1"
      @click="prevPage"
    >
      <ChevronSvg />
    </button>
    <div :class="$style.pagesRow">
      <template
        v-for="element in elementsList"
        :key="element.id"
      >
        <button
          v-if="element.type === $options.ELEMENT_TYPES.PAGE"
          :class="[
            $style.navButton,
            {
              [$style.isCurrent]: currentPage === element.page,
            },
          ]"
          :disabled="currentPage === element.page"
          @click="setPage(element.page)"
        >
          <template v-if="currentPage === element.page">
            Page
          </template>
          {{ element.page }}
        </button>
        <BaseDropdown
          v-else-if="element.type === $options.ELEMENT_TYPES.COLLAPSED"
          is-paddingless
        >
          <template #trigger="{ isActive }">
            <button
              :class="[
                $style.collapsed,
                {
                  [$style.isActive]: isActive,
                },
              ]"
            >
              <DotsMoreSvg />
            </button>
          </template>
          <template #content="{ tippy }">
            <table :class="$style.collapsedTable">
              <tr
                v-for="(row, rowIndex) in chunk(element.pages, 5)"
                :key="rowIndex"
              >
                <td
                  v-for="page in row"
                  :key="page"
                >
                  <button
                    :class="$style.collapsedPageButton"
                    @click="setPageWithAction(page, tippy.hide)"
                  >
                    {{ page }}
                  </button>
                </td>
              </tr>
            </table>
          </template>
        </BaseDropdown>
      </template>
    </div>
    <button
      :class="[$style.navButton, $style.nextPage]"
      :disabled="currentPage === totalPages"
      @click="nextPage"
    >
      <ChevronSvg />
    </button>
  </div>
</template>

<script>
import { range, chunk } from 'lodash';
import BaseDropdown from '@/components/base/Dropdown';
import ChevronSvg from '@/assets/images/icons/chevron.svg?inline';
import DotsMoreSvg from '@/assets/images/icons/dots-more.svg?inline';

export default {
  components: {
    BaseDropdown,
    ChevronSvg,
    DotsMoreSvg,
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  emits: {
    setPage: null,
  },
  ELEMENT_TYPES: {
    PAGE: 'PAGE',
    COLLAPSED: 'COLLAPSED',
  },
  MIN_NEAREST: 1,
  MAX_NEAREST: 3,
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    elementsList() {
      const nearestPages = range(
        this.currentPage - this.$options.MIN_NEAREST,
        this.currentPage + this.$options.MIN_NEAREST + 1,
      ).filter((item) => item > 0 && item <= this.totalPages);
      const centralElements = nearestPages.map(this.numberToPageElement);

      let startElements = [];
      const distanceToFirst = this.currentPage - 1;
      if (distanceToFirst <= this.$options.MAX_NEAREST) {
        if (distanceToFirst > this.$options.MIN_NEAREST) {
          startElements = range(1, this.currentPage - 1)
            .map(this.numberToPageElement);
        }
      } else {
        startElements = [
          this.numberToPageElement(1),
          this.generateCollapsed('start', range(2, this.currentPage - this.$options.MIN_NEAREST)),
        ];
      }

      let endElements = [];
      const distanceToLast = this.totalPages - this.currentPage;
      if (distanceToLast <= this.$options.MAX_NEAREST) {
        if (distanceToLast > this.$options.MIN_NEAREST) {
          endElements = range(this.currentPage + this.$options.MIN_NEAREST + 1, this.totalPages + 1)
            .map(this.numberToPageElement);
        }
      } else {
        endElements = [
          this.generateCollapsed(
            'end',
            range(this.currentPage + this.$options.MIN_NEAREST + 1, this.totalPages),
          ),
          this.numberToPageElement(this.totalPages),
        ];
      }

      return [
        ...startElements,
        ...centralElements,
        ...endElements,
      ];
    },
  },
  methods: {
    chunk,
    numberToPageElement(page) {
      return { id: page, type: this.$options.ELEMENT_TYPES.PAGE, page };
    },
    generateCollapsed(id, pages) {
      return {
        id,
        pages,
        type: this.$options.ELEMENT_TYPES.COLLAPSED,
      };
    },
    setPage(page) {
      this.$emit('setPage', page);
    },
    setPageWithAction(page, action) {
      this.setPage(page);
      action();
    },
    prevPage() {
      if (this.currentPage === 1) return;

      this.setPage(this.currentPage - 1);
    },
    nextPage() {
      if (this.currentPage === this.totalPages) return;

      this.setPage(this.currentPage + 1);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: inline-flex;
  align-items: center;
}

.navButton {
  @include reset;
  display: inline-flex;
  align-items: center;
  padding: 4px 5px 3px;
  font-size: 15px;
  font-weight: 500;
  color: $text;
  transition: color 0.2s ease-in-out;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      color: $title;
    }
  }

  &.isCurrent {
    background: $white;
    border: 2px solid $background-light;
    border-radius: 5px;
    opacity: 1;
  }
}

.pagesRow {
  display: inline-flex;
  align-items: center;

  > .navButton {
    margin-right: 3px;
    margin-left: 3px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.collapsed {
  @include reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  color: $text;
  cursor: pointer;
  background: rgba($disabled, 0);
  border-radius: 50%;
  transition: background 0.2s ease-in-out;

  &:hover,
  &.isActive {
    background: rgba($disabled, 0.25);
  }
}

.collapsedTable {
  padding: 7px;

  td {
    text-align: center;
  }
}

.collapsedPageButton {
  @include reset;
  padding: 3px;
  font-size: 16px;
  color: $text;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $title;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}

.previousPage,
.nextPage {

  &:disabled {
    opacity: 0.3;
  }
}

.previousPage {

  svg {
    transform: rotate(90deg);
  }
}

.nextPage {

  svg {
    transform: rotate(-90deg);
  }
}
</style>
