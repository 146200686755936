<template>
  <BaseContainer>
    <BaseLoader
      :is-active="isLoading"
      :class="$style.loaderWrapper"
    >
      <div :class="$style.oemBrandsContainer">
        <div
          v-for="brand in oemBrands"
          :key="brand.id"
        >
          <button
            :class="[
              $style.brandButton,
              {
                [$style.selected]: selectedBrand && selectedBrand.id === brand.id,
              }
            ]"
            @click="selectBrand(brand)"
          >
            <p>{{ brand.name }}</p>
            <p>{{ brand.id }}</p>
          </button>
        </div>
      </div>
      <div ref="brandModels">
        <BrandModels
          v-if="sourceModels.length"
          :brand="selectedBrand"
          :source-models="sourceModels"
        />
      </div>
    </BaseLoader>
  </BaseContainer>
</template>
<script>
import BaseContainer from '@/components/base/Container';
import BrandModels from '@/components/BrandModels';
import brands from '@/graphql/queries/brands';
import cars from '@/graphql/queries/cars';
import BaseLoader from '@/components/base/Loader';

export default ({
  components: {
    BaseContainer,
    BrandModels,
    BaseLoader,
  },
  data() {
    return {
      oemBrands: [],
      selectedBrand: null,
      lmi_cars: [],
      cars_to_be_updated: [],
      sourceModels: [],
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    this.oemBrands = await brands.getAllOemBrands();
    this.isLoading = false;
  },
  methods: {
    async selectBrand(brand) {
      this.selectedBrand = brand;
      this.getModels();
    },
    getModels() {
      this.isLoading = true;
      cars.getSourceModels(this.selectedBrand.id).then((results) => {
        cars.getLmiBrandModels(this.selectedBrand.id)
          .then((data) => {
            this.sourceModels = results.map((el) => {
              const numberOfLmiCars = data
                .filter((item) => item.model_id === el.modelid).length;
              return {
                ...el,
                numberOfLmiCars,
              };
            });
            this.isLoading = false;
            const { brandModels } = this.$refs;
            brandModels.scrollIntoView({ behavior: 'smooth' });
          });
      });
    },
  },
});
</script>
<style lang="scss" module>

.loaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.oemBrandsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.brandButton {
  display: inline-block;
  width: 200px;
  min-height: 52px;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: $background-light;
  border-radius: 5px;

  p {
    margin: 0;
  }
}

.selected {
  background-color: #7fe2e2;
}
</style>
