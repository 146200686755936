<template>
  <div :class="$style.wrapper">
    <Modal
      :model-value="isVisible"
      @update:modelValue="setShowModal"
    >
      <BaseLoader :is-active="isLoadingModal">
        <FormBox
          :class="$style.formBox"
          heading="Add cluster"
        >
          <BaseField
            :class="$style.field"
          >
            <template #label>
              Brand
            </template>
            <BaseInput
              v-model="brandName"
              type="text"
              disabled
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Model
            </template>
            <BaseInput
              v-model="modelName"
              type="text"
              disabled
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Body
            </template>
            <BaseMultiselect
              :model-value="bodyId"
              :options="carBodies"
              is-searchable
              is-block
              @update:modelValue="setAddClusterBody"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Country
            </template>
            <BaseMultiselect
              :model-value="countryId"
              :options="countries"
              is-searchable
              is-block
              @update:modelValue="setAddClusterCountry"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Generation
            </template>
            <BaseInput
              v-model="generation"
              type="number"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Project Code
            </template>
            <BaseInput
              v-model="projectCode"
              type="text"
            />
          </BaseField>

          <BaseField
            :class="$style.field"
          >
            <template #label>
              Segment
            </template>
            <BaseMultiselect
              :model-value="segment"
              :options="segments"
              is-searchable
              is-block
              @update:modelValue="setAddClusterSegment"
            />
          </BaseField>

          <template #footer>
            <BaseButton
              variant="success"
              type="addCluster"
              is-block
              @click="addCluster"
            >
              SUBMIT
            </BaseButton>
          </template>
        </FormBox>
      </BaseLoader>
    </Modal>
    <div>
      <BaseButton
        type="setShowModal"
        variant="secondary"
        :class="$style.addClusterButton"
        @click="setShowModal(true)"
      >
        <PlusCircleSvg />
        <p>Add cluster</p>
      </BaseButton>
    </div>
    <div :class="$style.clustersContainer">
      <ClusterCard
        v-for="carCluster in clustersToDisplay"
        :key="carCluster.lighting_cluster_reference_2"
        :car-cluster="carCluster"
        :model="model"
        @empty-cluster="removeCluster"
      />
    </div>
  </div>
</template>

<script>
import cars from '@/graphql/queries/cars';
import utils from '@/graphql/queries/utils';
import getLightingClusterReferences from '@/utils/getLightingClusterReferences';
import { getMaxEop, getMinSop } from '@/utils/getMinSopAndMaxEop';
import ClusterCard from '@/components/ClusterCard';
import Modal from '@/components/base/Modal';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseMultiselect from '@/components/base/Multiselect';
import BaseField from '@/components/base/Field';
import BaseInput from '@/components/base/Input';
import BaseButton from '@/components/base/Button';
import PlusCircleSvg from '@/assets/images/icons/plus-circle.svg?inline';
import {
  cloneDeep, remove, isEmpty, isArray,
} from 'lodash';

export default {
  components: {
    ClusterCard,
    Modal,
    BaseLoader,
    FormBox,
    BaseMultiselect,
    BaseField,
    BaseInput,
    BaseButton,
    PlusCircleSvg,
  },
  props: {
    brand: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
  },
  emits: {
    'finished-loading': null,
  },
  data() {
    return {
      clustersToDisplay: [],
      carBodies: [],
      countries: [],
      segments: ['A', 'B', 'C', 'D', 'E', 'F'],
      brandName: null,
      modelName: null,
      isVisible: false,
      isLoadingModal: false,
      showAddPhaseBox: false,
      brandId: null,
      modelId: null,
      bodyId: null,
      countryId: null,
      generation: null,
      projectCode: null,
      segment: null,
    };
  },
  watch: {
    model() {
      this.getCarsToDisplay();
      this.modelId = this.model.modelid;
      this.modelName = this.model.global_nameplate;
    },
  },
  created() {
    this.getCarsToDisplay();
    this.isLoadingModal = true;
    utils.getCarBodiesAndCountries().then((result) => {
      this.carBodies = result.bodies.map((body) => ({ label: body.name, value: body.id }));
      this.countries = result.countries.map((country) => ({ label: country.name, value: country.id }));
      this.isLoadingModal = false;
    });
    this.brandId = this.brand.id;
    this.brandName = this.brand.name;
    this.modelId = this.model.modelid;
    this.modelName = this.model.global_nameplate;
  },
  methods: {
    getCarsToDisplay() {
      this.clustersToDisplay = [];
      const sourceCarsPromise = cars.getSourceCarsByBrandAndModel(this.model.brandid, this.model.modelid);
      const lmiCarsPromise = cars.getLmiCarsByBrandAndModel(this.model.brandid, this.model.modelid);
      Promise.all([sourceCarsPromise, lmiCarsPromise]).then((results) => {
        const [sourceCars, lmiCars] = cloneDeep(results);
        const refs2List = new Set(lmiCars.map((car) => car.lighting_cluster_reference_2));
        refs2List.forEach((ref2) => {
          const clusterCars = lmiCars.filter((car) => car.lighting_cluster_reference_2 === ref2);
          const updateRefIds = clusterCars.map((car) => car.volumes_infos.map((info) => info.update_ref_id)).flat();
          const linkedSourceCars = sourceCars.filter((sourceCar) => sourceCar.lighting_cluster_reference_2 === ref2
              || (
                !sourceCar.lighting_cluster_reference_2
                && sourceCar.lighting_cluster_reference === clusterCars[0].lighting_cluster_reference
              ));
          const sameRefLinkedSourceCars = linkedSourceCars.filter(
            (sourceCar) => updateRefIds.includes(sourceCar.update_ref_id),
          );
          const hasNoSourceCar = isEmpty(linkedSourceCars);
          const data = {
            lighting_cluster_reference_2: ref2,
            lighting_cluster_reference: (
              hasNoSourceCar ? clusterCars[0] : linkedSourceCars[0]
            ).lighting_cluster_reference,
            brand: clusterCars[0].brand,
            body: clusterCars[0].car_body,
            country: clusterCars[0].country,
            powerTrain: (hasNoSourceCar ? clusterCars : linkedSourceCars)[0].power_train,
            projectCode: clusterCars[0].project_code,
            generation: (hasNoSourceCar ? clusterCars : linkedSourceCars)[0].generation || clusterCars[0].generation,
            sop: sameRefLinkedSourceCars.length === 0 ? null : getMinSop(sameRefLinkedSourceCars),
            eop: sameRefLinkedSourceCars.length === 0 ? null : getMaxEop(sameRefLinkedSourceCars),
            cars: clusterCars,
            sourceCars: linkedSourceCars,
            segment: (hasNoSourceCar ? clusterCars : linkedSourceCars)[0].segment,
          };
          this.clustersToDisplay.push(data);
          linkedSourceCars.forEach((linkedSourceCar) => remove(
            sourceCars, (sourceCar) => sourceCar.id === linkedSourceCar.id,
          ));
        });
        const remainingSourceRefs = new Set(sourceCars.map((sourceCar) => sourceCar.lighting_cluster_reference_2));
        remainingSourceRefs.forEach((ref2) => {
          const linkedSourceCars = sourceCars.filter(
            (sourceCar) => sourceCar.lighting_cluster_reference_2 === ref2,
          );
          if (!ref2) {
            const remainingSourceRefs1 = new Set(
              linkedSourceCars.map((sourceCar) => sourceCar.lighting_cluster_reference),
            );
            remainingSourceRefs1.forEach((ref1) => {
              const linkedSourceCarsByRef1 = linkedSourceCars.filter(
                (sourceCar) => sourceCar.lighting_cluster_reference === ref1,
              );
              const emptyClusterData = {
                lighting_cluster_reference_2: ref2,
                lighting_cluster_reference: linkedSourceCarsByRef1[0].lighting_cluster_reference,
                brand: {
                  id: this.model.brandid,
                },
                body: {
                  name: linkedSourceCarsByRef1[0].body_type,
                  id: linkedSourceCarsByRef1[0].bodyid,
                },
                country: {
                  id: linkedSourceCarsByRef1[0].countryid,
                },
                powerTrain: linkedSourceCarsByRef1[0].power_train,
                projectCode: linkedSourceCarsByRef1[0].body_style_program_code,
                generation: linkedSourceCarsByRef1[0].generation,
                sop: getMinSop(linkedSourceCarsByRef1),
                eop: getMaxEop(linkedSourceCarsByRef1),
                cars: [],
                sourceCars: linkedSourceCarsByRef1,
                segment: linkedSourceCarsByRef1[0].segment,
              };
              this.clustersToDisplay.push(emptyClusterData);
            });
          } else {
            const emptyClusterData = {
              lighting_cluster_reference_2: ref2,
              lighting_cluster_reference: linkedSourceCars[0].lighting_cluster_reference,
              brand: {
                id: this.model.brandid,
              },
              body: {
                name: linkedSourceCars[0].body_type,
                id: linkedSourceCars[0].bodyid,
              },
              country: {
                id: linkedSourceCars[0].countryid,
              },
              powerTrain: linkedSourceCars[0].power_train,
              projectCode: linkedSourceCars[0].body_style_program_code,
              generation: linkedSourceCars[0].generation,
              sop: getMinSop(linkedSourceCars),
              eop: getMaxEop(linkedSourceCars),
              cars: [],
              sourceCars: linkedSourceCars,
              segment: linkedSourceCars[0].segment,
            };
            this.clustersToDisplay.push(emptyClusterData);
          }
        });
        this.clustersToDisplay.sort((a, b) => a.generation - b.generation);
        this.$emit('finished-loading');
      });
    },
    addCluster() {
      this.isLoadingModal = true;
      const {
        lightingClusterReference,
        lightingClusterReference2,
      } = getLightingClusterReferences(
        this.brandId,
        this.modelId,
        this.bodyId,
        this.countryId,
        this.generation,
        this.projectCode,
      );
      const car = {
        brand_id: this.brandId,
        model_id: this.modelId,
        body_id: this.bodyId,
        country_id: this.countryId,
        generation: this.generation,
        project_code: this.projectCode,
        lighting_cluster_reference: lightingClusterReference,
        lighting_cluster_reference_2: lightingClusterReference2,
        segment: this.segment,
        model: this.model.global_nameplate,
        phase: 1,
      };
      cars.insertLmiCar(car).then((result) => {
        const existingCluster = this.clustersToDisplay.find(
          (cluster) => cluster.lighting_cluster_reference_2 === result.lighting_cluster_reference_2,
        );
        if (existingCluster) {
          existingCluster.cars.push(result);
        } else {
          this.clustersToDisplay.push({
            lighting_cluster_reference_2: result.lighting_cluster_reference_2,
            lighting_cluster_reference: result.lighting_cluster_reference,
            brand: result.brand,
            body: result.car_body,
            country: result.country,
            powerTrain: result.power_train,
            projectCode: result.project_code,
            generation: result.generation,
            sop: null,
            eop: null,
            cars: [result],
            sourceCars: [],
          });
        }
        this.isLoadingModal = false;
        this.setShowModal(false);
        this.resetAddClusterValues();
      });
    },
    resetAddClusterValues() {
      this.bodyId = null;
      this.countryId = null;
      this.generation = null;
      this.projectCode = null;
      this.segment = null;
    },
    setAddClusterBody(bodyId) {
      if (isArray(bodyId) && isEmpty(bodyId)) { this.bodyId = null; } else { this.bodyId = bodyId; }
    },
    setAddClusterCountry(countryId) {
      if (isArray(countryId) && isEmpty(countryId)) { this.countryId = null; } else { this.countryId = countryId; }
    },
    setAddClusterSegment(segment) {
      if (isArray(segment) && isEmpty(segment)) { this.segment = null; } else { this.segment = segment; }
    },
    removeCluster(clusterToRemove) {
      remove(this.clustersToDisplay, (cluster) => cluster === clusterToRemove);
    },
    setShowModal(value) {
      this.isVisible = value;
    },
  },
};
</script>
<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.clustersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

.addClusterButton {
  display: flex;
  gap: 7px;

  p {
    margin: 0;
  }
}

.formBox {
  width: 400px;
  border: 0;

  .field:not(:first-child) {
    margin-top: 20px;
  }
}
</style>
