<template>
  <BaseDropdown>
    <template #trigger="{ isActive }">
      <HeaderIconButton :is-active="isActive">
        <template #icon>
          <ProfileCircleSvg />
        </template>
      </HeaderIconButton>
    </template>

    <template #content>
      <BaseDropdownHeading>
        User settings

        <template #lead>
          Hello, {{ user.givenName }}
        </template>
      </BaseDropdownHeading>

      <BaseDropdownActionsList>
        <router-link
          #="{ navigate }"
          :to="{ name: 'Logout' }"
          custom
        >
          <BaseDropdownAction @click="navigate">
            Log Out
          </BaseDropdownAction>
        </router-link>
      </BaseDropdownActionsList>
    </template>
  </BaseDropdown>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BaseDropdown, {
  DropdownHeading as BaseDropdownHeading,
  DropdownActionsList as BaseDropdownActionsList,
  DropdownAction as BaseDropdownAction,
} from '@/components/base/Dropdown';
import HeaderIconButton from '@/components/HeaderIconButton';
import ProfileCircleSvg from '@/assets/images/icons/profile-circle.svg?inline';

export default {
  components: {
    BaseDropdown,
    BaseDropdownHeading,
    BaseDropdownActionsList,
    BaseDropdownAction,
    HeaderIconButton,
    ProfileCircleSvg,
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAdmin', 'isCompanyAdmin']),
  },
  methods: {
    navigateAndClose(navigate, tippy) {
      navigate();
      tippy.hide();
    },
  },
};
</script>

<style scoped>

</style>
