<template>
  <component
    :is="tag"
    :class="buttonClasses"
    :type="buttonType"
    :disabled="isDisabled"
  >
    <span
      v-if="$slots.iconLeft"
      :class="[$style.iconWrapper, $style.iconWrapperLeft]"
    >
      <slot name="iconLeft" />
    </span>
    <slot />
    <span
      v-if="$slots.iconRight"
      :class="[$style.iconWrapper, $style.iconWrapperRight]"
    >
      <slot name="iconRight" />
    </span>
  </component>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'secondary',
      validator(value) {
        return ['secondary', 'success', 'danger'].includes(value);
      },
    },
    tag: {
      type: String,
      default: 'button',
    },
    type: {
      type: String,
      default: 'button',
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      return [
        this.$style.button,
        this.$style[this.variant],
        {
          [this.$style.isDisabled]: this.isDisabled,
          [this.$style.isBlock]: this.isBlock,
        },
      ];
    },
    buttonType() {
      return this.tag === 'button' ? this.type : null;
    },
  },
};
</script>

<style lang="scss" module>
.button {
  @include reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 15px 11px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
  letter-spacing: -0.1px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.15s ease-in-out;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    border-color: #00f;
    outline: none;
  }

  &.isBlock {
    display: flex;
    width: 100%;
  }

  &.secondary {
    color: $text;
    background-color: $background-light;

    &:hover,
    &:focus {
      background-color: #dadada;
    }

    .iconWrapper {
      color: #000;
    }
  }

  &.success {
    color: $white;
    background: #24cb71;

    &:hover,
    &:focus {
      background-color: #1caf60;
    }
  }

  &.danger {
    color: $white;
    background-color: #fe4485;

    &:hover,
    &:focus {
      background-color: #e81762;
    }

    .iconWrapper {
      color: $white;
    }
  }

  &.isDisabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.iconWrapper {
  position: relative;
  top: -1px;
  display: inline-flex;
  width: 16px;
  height: 16px;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.iconWrapperLeft {
  left: -2px;
  margin-right: 3px;
}

.iconWrapperRight {
  right: -2px;
  margin-left: 3px;
}
</style>
