<template>
  <router-view />
</template>

<style lang="scss">
@import '~modern-normalize/modern-normalize.css';
@import '~@/assets/scss/fonts';

body {
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  font-family: $font;
  color: $text;
  background: $white;

  input,
  button,
  select {
    font-family: $font;
  }
}

/* stylelint-disable selector-max-id */
#app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}
/* stylelint-enable */

.link {
  color: #129cbe;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #05647a;
  }
}
</style>

<style lang="scss" module>
.loader {
  flex-grow: 1;
}
</style>
