import Table from './Table';
import TableColumn from './TableColumn';
import TableActionsColumn from './TableActionsColumn';

export default Table;

export {
  TableColumn,
  TableActionsColumn,
};
