<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.tableContainer"
  >
    <div :class="$style.searchContainer">
      <BaseInput
        v-model="searchValue"
        placeholder="Search"
        type="text"
      />
      <BaseButton
        variant="success"
        type="insertRow"
        @click="openForm({}, 'create')"
      >
        Insert new OEM
      </BaseButton>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="oems"
      :rows-per-page="10"
      :search-value="searchValue"
      sort-by="name"
      sort-type="asc"
      :table-class-name="$style.dataTable"
      @click-row="openForm($event, 'update')"
    >
      <template #item-logo="{ logo }">
        <img
          :src="logo || 'https://lightingmi.s3.eu-central-1.amazonaws.com/logos/default.png'"
          :class="$style.oemLogo"
        >
      </template>
    </EasyDataTable>
    <FormBox
      v-if="isFormOpen"
      :class="$style.formBox"
      :heading="`${formMode === 'create' ? 'Create' : 'Update'} row`"
    >
      <div :class="$style.fieldsContainer">
        <BaseField
          :class="$style.field"
        >
          <template #label>
            Name
          </template>
          <BaseInput
            v-model="name"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            VCL
          </template>
          <BaseInput
            v-model="vcl"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Region
          </template>
          <BaseInput
            v-model="region"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Country
          </template>
          <BaseInput
            v-model="country"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            City
          </template>
          <BaseInput
            v-model="city"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Latitude
          </template>
          <BaseInput
            v-model="latitude"
            type="number"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Longitude
          </template>
          <BaseInput
            v-model="longitude"
            type="number"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Owner
          </template>
          <BaseInput
            v-model="owner"
            type="text"
          />
        </BaseField>
      </div>

      <template #footer>
        <div :class="$style.formButtonsContainer">
          <BaseButton
            variant="success"
            type="updateOrCreateRow"
            @click="formMode === 'create' ? createRow() : updateRow()"
          >
            {{ formMode === 'create' ? 'Create' : 'Update' }}
          </BaseButton>
          <BaseButton
            variant="secondary"
            type="closeForm"
            @click="closeForm"
          >
            Close
          </BaseButton>
        </div>
      </template>
    </FormBox>
  </BaseLoader>
</template>
<script>
import automakers from '@/graphql/queries/automakers';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseButton from '@/components/base/Button';
import BaseInput from '@/components/base/Input';

export default ({
  components: {
    BaseLoader,
    FormBox,
    BaseField,
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      headers: [
        {
          text: 'ID', value: 'id', sortable: true, width: 75,
        },
        {
          text: 'Logo', value: 'logo', sortable: true, width: 75,
        },
        {
          text: 'Name', value: 'name', sortable: true, width: 300,
        },
        {
          text: 'VCL', value: 'value_chain_level', sortable: true, width: 100,
        },
        {
          text: 'Region', value: 'region', sortable: true, width: 100,
        },
        {
          text: 'Country', value: 'country', sortable: true, width: 100,
        },
        {
          text: 'City', value: 'city', sortable: true, width: 100,
        },
      ],
      oems: [],
      searchValue: '',
      isFormOpen: false,
      formMode: null,
      isLoading: false,
      rowId: null,
      name: null,
      vcl: null,
      region: null,
      country: null,
      city: null,
      latitude: null,
      longitude: null,
      owner: null,
    };
  },
  created() {
    this.isLoading = true;
    automakers.getAutomakers().then((results) => {
      this.oems = [...results];
      this.isLoading = false;
    });
  },
  methods: {
    createRow() {
      this.closeForm();
      this.isLoading = true;
      const automakerToCreate = {
        name: this.name,
        value_chain_level: this.vcl,
        region: this.region,
        country: this.country,
        city: this.city,
        latitude: this.latitude || null,
        longitude: this.longitude || null,
        created_by: this.owner,
      };
      automakers.insertAutomaker(automakerToCreate).then((insertResult) => {
        this.oems.push(insertResult);
        this.isLoading = false;
      });
    },
    openForm(row, formMode) {
      this.formMode = formMode;
      this.rowId = row.id;
      this.name = row.name;
      this.vcl = row.value_chain_level;
      this.region = row.region;
      this.country = row.country;
      this.city = row.city;
      this.latitude = row.latitude;
      this.longitude = row.longitude;
      this.owner = row.created_by;
      this.isFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
    },
    updateRow() {
      this.closeForm();
      this.isLoading = true;
      const automakerToUpdate = {
        id: this.rowId,
        name: this.name,
        value_chain_level: this.vcl,
        region: this.region,
        country: this.country,
        city: this.city,
        latitude: this.latitude || null,
        longitude: this.longitude || null,
        created_by: this.owner,
      };
      automakers.updateAutomakersByPk(automakerToUpdate).then(() => {
        const oemToUpdate = this.oems.find((oem) => oem.id === this.rowId);
        const index = this.oems.indexOf(oemToUpdate);
        this.oems[index] = { ...this.oems[index], ...automakerToUpdate };
        this.isLoading = false;
      });
    },
  },
});
</script>
<style lang="scss" module>

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.searchContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.dataTable {
  --easy-table-body-row-height: 32px;
}

.oemLogo {
  width: 25px;
  height: 25px;
}

.deleteIconContainer {
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 2px;
    cursor: pointer;
  }
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.field {
  width: 250px;
}

.formButtonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
</style>
