<template>
  <div
    :class="[
      $style.select,
      {
        [$style.isSmall]: isSmall,
        [$style.hasError]: hasError,
      },
    ]"
  >
    <select
      v-model="modelValueProxy"
      @change="onChange"
    >
      <slot />
    </select>
    <span :class="$style.iconWrapper">
      <ChevronSvg />
    </span>
  </div>
</template>

<script>
import ChevronSvg from '@/assets/images/icons/chevron.svg?inline';

export default {
  components: {
    ChevronSvg,
  },
  props: {
    modelValue: {
      type: null,
      default: null,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': null,
    change: null,
  },
  computed: {
    modelValueProxy: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.modelValue);
    },
  },
};
</script>

<style lang="scss" module>
.select {
  position: relative;
  display: inline-flex;
  background-color: $white;
  border-radius: 5px;
  box-shadow: inset 0 0 0 2px $border;
  transition: box-shadow 0.2s ease-in-out;

  select {
    @include reset;
    flex-grow: 1;
    align-self: stretch;
    padding: 8px 27px 7px 11px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    color: $title;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  &.isSmall {
    box-shadow: inset 0 0 0 2px $background-light;

    select {
      padding: 7px 27px 6px 11px;
      font-size: 15px;
      color: $text;
    }
  }

  &.hasError {
    box-shadow: inset 0 0 0 2px #f39d9d;
  }
}

.iconWrapper {
  position: absolute;
  top: 50%;
  right: 7px;
  display: inline-flex;
  color: rgba($title, 0.7);
  pointer-events: none;
  transform: translateY(calc(-50% + 1px));
}
</style>
