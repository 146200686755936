import { get } from 'lodash';

export function getColumnKey(column, index) {
  return column?.key || column?.field || index;
}

export function getDataKey(data, dataKey, index) {
  return dataKey ? get(data, dataKey) : index;
}

export function getColumnId(column) {
  return column?.id || column.label;
}

export function getIsDataColumnToggable(column) {
  return column.isToggable === true;
}

export function getColumnLabel(column) {
  return column.label;
}
