<template>
  <button
    type="button"
    :class="[
      $style.button,
      {
        [$style.isActive]: isActive,
        [$style.isDanger]: isDanger,
        [$style.themeLight]: isLight,
      },
    ]"
  >
    <span :class="$style.icon">
      <slot name="icon" />
      <span
        v-if="badge || $slots.badge"
        :class="[
          $style.badge,
          {
            [$style.isWide]: badge?.toString().length > 1,
          },
        ]"
      >
        <slot name="badge">{{ badge }}</slot>
      </span>
    </span>
    <span
      v-if="$slots.label"
      :class="[
        $style.label,
        {
          [$style.isDanger]: isDanger,
        }
      ]"
    >
      <slot name="label" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    badge: {
      type: [String, Number],
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLight() {
      return this.$route.meta.hero;
    },
  },
};
</script>

<style lang="scss" module>
.button {
  @include reset;
  display: inline-flex;
  align-items: center;
  padding: 5px 8px;
  background-color: rgba($background-light, 0);
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &.isActive {
      background-color: rgba($background-light, 1);
    }

    &.isDanger {
      background-color: rgba($background-danger, 1);

      &:hover,
      &:focus {
        background-color: #e81762;
      }
    }
  }
}

.icon {
  position: relative;
  display: inline-flex;
  color: $title;
}

.badge {
  position: absolute;
  top: -6px;
  left: calc(-100% + 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  min-width: 14px;
  height: 14px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: $white;
  letter-spacing: -0.1px;
  background-color: #ffa829;
  border-radius: 10px;

  svg {
    width: 8px;
    height: auto;
  }

  &.isWide {
    width: initial;
    padding-right: 4px;
    padding-left: 3px;
  }
}

.label {
  margin-top: 2px;
  margin-left: 5px;
  font-weight: 500;
  color: $title;

  &.isDanger {
    color: rgba($white, 1);
  }
}

.themeLight {

  &.button {
    background-color: rgba($background-light, 0);

    &:not(:disabled) {

      &:hover,
      &.isActive {
        background-color: rgba($background-light, 0.2);
      }
    }
  }

  .icon {
    display: inline-flex;
    color: $white;
  }

  .label {
    margin-top: 2px;
    margin-left: 5px;
    font-weight: 500;
    color: $white;
  }
}
</style>
