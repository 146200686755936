import { minBy, maxBy } from 'lodash';
import moment from 'moment/moment';

export function getMinSop(carsArray) {
  if (carsArray.every((car) => !car.sop)) return null;
  const minSop = minBy(carsArray, 'sop').sop;
  return moment(minSop).format('YYYY-MM-DD');
}

export function getMaxEop(carsArray) {
  if (carsArray.every((car) => !car.eop)) return null;
  const maxEop = maxBy(carsArray, 'eop').eop;
  return moment(maxEop).format('YYYY-MM-DD');
}
