<template>
  <div
    v-if="!editMode"
    :class="$style.clusterCarCard"
  >
    <div :class="$style.infoContainer">
      <div :class="$style.side">
        <p :class="$style.info">
          ID : {{ car.id }}
        </p>
        <p :class="$style.info">
          Generation : {{ car.generation }}
        </p>
        <p :class="$style.info">
          Phase : {{ car.phase }}
        </p>
      </div>
      <div :class="$style.side">
        <p :class="$style.info">
          SOP : {{ formatSopEop(isFirst ? clusterSop : car.sop) }}
        </p>
        <p :class="$style.info">
          EOP : {{ formatSopEop(isLast ? clusterEop : car.eop) }}
        </p>
        <p :class="$style.info">
          Project code : {{ car.project_code }}
        </p>
      </div>
    </div>
    <div :class="$style.updaterefContainer">
      <BaseLabel
        v-for="item in car.volumes_infos"
        :key="item.update_ref_id"
        color="cherry"
      >
        {{ item.update_ref_id }}
      </BaseLabel>
    </div>
    <TippyWrapper
      ref="tippyWrapper"
      :key="1"
      :theme="['dropdown']"
      interactive
      tag="div"
      content-tag="div"
    >
      <template #default>
        <div
          v-if="car.comment"
          name="externalTrigger"
          :class="$style.commentIndicatorContainer"
        >
          Comment available <CommentSvg />
        </div>
      </template>

      <template #content>
        <div :class="$style.commentContainer">
          {{ car.comment }}
        </div>
      </template>
    </TippyWrapper>
    <div :class="$style.picturesContainer">
      <img
        :src="
          car.front_image
            || 'https://images104500-lmi.s3.eu-central-1.amazonaws.com/public/defaultCarFront.jpg'
        "
      >
      <img
        :src="
          car.back_image
            || 'https://images104500-lmi.s3.eu-central-1.amazonaws.com/public/defaultCarBack.jpg'
        "
      >
    </div>
    <AddPhase
      v-if="showAddPhaseBox"
      :car="car"
      :is-first="isFirst"
      :is-last="isLast"
      :cluster-sop="clusterSop"
      :cluster-eop="clusterEop"
      @close-add-phase-box="setShowAddPhaseBox(false)"
      @phase-added="updatePhases($event)"
    />
    <div
      v-else
      :class="$style.buttonsContainer"
    >
      <template v-if="!hideActionsButton">
        <BaseButton
          type="setShowAddPhaseBox"
          variant="secondary"
          @click="setShowAddPhaseBox(true)"
        >
          Add phase
        </BaseButton>
        <BaseButton
          type="setEditMode"
          variant="secondary"
          @click="setEditMode(true)"
        >
          Edit car
        </BaseButton>
        <BaseButton
          type="removeLmiCar"
          variant="secondary"
          @click="removeLmiCar"
        >
          Remove car
        </BaseButton>
      </template>
    </div>
  </div>
  <FormBox
    v-else
    heading="Edit car"
  >
    <BaseField
      :class="$style.field"
    >
      <template #label>
        SOP
      </template>
      <BaseInput
        v-model="sop"
        type="date"
        :disabled="isFirst"
      />
    </BaseField>

    <BaseField
      :class="$style.field"
    >
      <template #label>
        EOP
      </template>
      <BaseInput
        v-model="eop"
        type="date"
        :disabled="isLast"
      />
    </BaseField>

    <BaseField
      :class="$style.field"
    >
      <template #label>
        Phase
      </template>
      <BaseInput
        v-model="phase"
        type="number"
      />
    </BaseField>

    <BaseField
      :class="$style.field"
    >
      <template #label>
        Comment
      </template>
      <BaseInput
        v-model="comment"
        type="textarea"
      />
    </BaseField>

    <template #footer>
      <div :class="$style.buttonsContainer">
        <BaseButton
          variant="success"
          type="setEditMode"
          is-block
          @click="setEditMode(false)"
        >
          Cancel
        </BaseButton>
        <BaseButton
          variant="success"
          type="editCar"
          is-block
          @click="editCar"
        >
          Submit
        </BaseButton>
      </div>
    </template>
  </FormBox>
</template>

<script>
import { Tippy as TippyWrapper } from 'vue-tippy';
import formatSopEop from '@/utils/formatSopEop';
import AddPhase from '@/components/AddPhase';
import BaseButton from '@/components/base/Button';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseInput from '@/components/base/Input';
import BaseLabel from '@/components/base/Label';
import CommentSvg from '@/assets/images/icons/comment.svg?inline';

export default ({
  components: {
    TippyWrapper,
    AddPhase,
    BaseButton,
    FormBox,
    BaseField,
    BaseInput,
    BaseLabel,
    CommentSvg,
  },
  props: {
    car: {
      type: Object,
      default: null,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    clusterSop: {
      type: String,
      default: null,
    },
    clusterEop: {
      type: String,
      default: null,
    },
    hideActionsButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    carRemoved: null,
    carUpdated: null,
    phaseAdded: null,
  },
  data() {
    return {
      showAddPhaseBox: false,
      editMode: false,
      sop: null,
      eop: null,
      phase: null,
      comment: null,
    };
  },
  updated() {
    this.sop = this.isFirst ? this.clusterSop : this.car.sop;
    this.eop = this.isLast ? this.clusterEop : this.car.eop;
    this.phase = this.car.phase;
    this.comment = this.car.comment;
  },
  methods: {
    formatSopEop,
    removeLmiCar() {
      const carId = this.car.id;
      // eslint-disable-next-line no-alert, no-restricted-globals
      const ok = confirm(`Car ${carId} will be deleted. Are you sure ?`);
      if (ok) {
        this.$emit('carRemoved');
      }
    },
    editCar() {
      this.editMode = false;
      const updatedValues = {
        sop: this.sop,
        eop: this.eop,
        phase: this.phase,
        comment: this.comment,
      };
      this.$emit('carUpdated', updatedValues);
    },
    updatePhases(newCar) {
      this.$emit('phaseAdded', { newCar, setShowAddPhaseBox: this.setShowAddPhaseBox });
    },
    setShowAddPhaseBox(value) {
      this.showAddPhaseBox = value;
    },
    setEditMode(value) {
      this.editMode = value;
    },
  },
});
</script>
<style lang="scss" module>
.clusterCarCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border-radius: 10px;
}

.infoContainer {
  display: flex;
  gap: 10px;
  width: 100%;

  .side {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
  }

  .info {
    margin: 0;
  }
}

.picturesContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    width: calc(50% - 5px);
    border-radius: 10px;
  }
}

.buttonsContainer {
  display: flex;
  gap: 15px;
}

.field:not(:first-child) {
  margin-top: 10px;
}

.updaterefContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.commentIndicatorContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;

  svg {
    color: #24cb71;
  }
}

.commentContainer {
  padding: 10px;
}
</style>
