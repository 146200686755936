<template>
  <thead>
    <tr ref="row">
      <th
        v-if="isSelectable"
        :class="[$style.th, $style.checkboxColumn]"
      >
        <BaseCheckbox
          :model-value="isAllDataSelected"
          :is-indeterminate="isDataPartiallySelected"
          @update:modelValue="toggleSelectAll"
        />
      </th>
      <template
        v-for="(col, index) in dataColumns"
        :key="getColumnKey(col, index)"
      >
        <th
          v-if="!getIsDataColumnToggable(col) || visibilityToggableColumns.includes(getColumnId(col))"
          :class="[$style.th, col.headerClass]"
        >
          <component
            :is="col.$slots.header"
            v-if="col.$slots?.header"
            :column="col"
            :index="index"
          />
          <span v-else-if="col.header">{{ col.header }}</span>
        </th>
      </template>
      <!-- Empty column to shrink other ones -->
      <th />
    </tr>
  </thead>
</template>

<script>
import BaseCheckbox from '@/components/base/Checkbox';
import { getColumnKey, getColumnId, getIsDataColumnToggable } from './utils';

export default {
  components: {
    BaseCheckbox,
  },
  props: {
    dataColumns: {
      type: Array,
      default: () => [],
    },
    visibilityToggableColumns: {
      type: Array,
      default: () => ([]),
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    isAllDataSelected: {
      type: Boolean,
      default: false,
    },
    isDataPartiallySelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    toggleSelectAll: null,
    setHeaderRowRef: null,
  },
  mounted() {
    this.$emit('setHeaderRowRef', this.$refs.row);
  },
  updated() {
    this.$emit('setHeaderRowRef', this.$refs.row);
  },
  methods: {
    getColumnKey,
    getColumnId,
    getIsDataColumnToggable,
    toggleSelectAll() {
      this.$emit('toggleSelectAll');
    },
  },
};
</script>

<style lang="scss" module>
.th {
  width: 1px;
  padding: 5px 20px 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: 0.24px;
  white-space: nowrap;

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-right: 15px;
  }

  > * {
    vertical-align: middle;
  }
}

.checkboxColumn {
  width: 38px;
}
</style>
