import Amplify, { Auth } from 'aws-amplify';
import awsExports from '@/lmi-aws-exports';
import * as adminQueries from './adminQueries';
import * as companyAdminQueries from './companyAdminQueries';

export { adminQueries, companyAdminQueries };

export async function checkSavedLogInData() {
  try {
    return await Auth.currentAuthenticatedUser({ bypassCache: true });
  } catch (e) {
    return null;
  }
}

export function logIn(username, password) {
  return Auth.signIn({
    username,
    password,
  });
}

export function logOut() {
  return Auth.signOut();
}

export function init() {
  Amplify.configure(awsExports);
}

async function updateUserAttributes(attributes) {
  return Auth.currentAuthenticatedUser().then((user) => Auth.updateUserAttributes(user, attributes));
}

export default {
  updateUserAttributes,
};
