<template>
  <div
    :class="[
      $style.breadcrumbs,
      {
        [$style.themeLight]: isLight,
      },
    ]"
  >
    <template
      v-for="(item, index) in breadcrumbs"
      :key="index"
    >
      <ChevronSvg :class="$style.divider" />
      <router-link
        v-if="index < breadcrumbs.length - 1"
        :to="item.to"
        :class="$style.item"
      >
        {{ item.label }}
      </router-link>
      <span
        v-else
        :class="[$style.item, $style.isCurrent]"
      >
        {{ item.label }}
      </span>
    </template>
  </div>
</template>

<script>
import { flatten, isFunction } from 'lodash';
import ChevronSvg from '@/assets/images/icons/chevron.svg?inline';

export default {
  components: {
    ChevronSvg,
  },
  props: {
    isLight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      breadcrumbs: [],
      updateListener: null,
    };
  },
  watch: {
    $route() {
      this.generate();
    },
  },
  created() {
    this.generate();
    this.updateListener = this.$bus.on('breadcrumbs:update', this.generate);
  },
  beforeUnmount() {
    this.updateListener();
  },
  methods: {
    async generate() {
      const sectionsPromises = this.$route.matched.map((route) => {
        const current = route.meta.breadcrumbs;
        if (current == null) return [];

        if (isFunction(current)) {
          return current(this.$route);
        }

        return current;
      });

      const sections = await Promise.all(sectionsPromises);

      const breadcrumbsPromises = flatten(sections).map((item) => {
        if (isFunction(item)) {
          return item(this.$route);
        }

        return item;
      });

      this.breadcrumbs = await Promise.all(breadcrumbsPromises);
    },
  },
};
</script>

<style lang="scss" module>
.breadcrumbs {
  display: inline-flex;
  align-items: center;
}

.divider {
  margin: 0 2px;
  color: rgba($title, 0.3);
  transform: rotate(-90deg);
}

.item {
  display: inline-block;
  padding: 5px 8px;
  font-size: 18px;
  font-weight: 500;
  color: rgba($title, 0.6);
  text-decoration: none;
  background-color: rgba($background-light, 0);
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:not(:disabled):not(.isCurrent) {
    cursor: pointer;

    &:hover {
      color: rgba($title, 1);
      background-color: rgba($background-light, 1);
    }
  }

  &.isCurrent {
    color: rgba($title, 1);
  }
}

.themeLight {

  .divider {
    color: rgba($white, 0.3);
  }

  .item {
    color: rgba($white, 0.5);
    background-color: rgba($background-light, 0);

    &:not(:disabled):not(.isCurrent) {

      &:hover {
        color: rgba($white, 1);
        background-color: rgba($background-light, 0.2);
      }
    }

    &.isCurrent {
      color: rgba($white, 1);
    }
  }
}
</style>
