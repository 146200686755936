/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:3f2ee05d-9678-4adb-a64d-0f59b6378df6",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_WIqNKBy9X",
    "aws_user_pools_web_client_id": "5u8l5lim11f9e0idht6n1gm5l2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "images104500-lmi",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
