<template>
  <div :class="$style.chartContainer">
    <div :class="$style.header">
      <h3
        v-if="hasHeading"
        :class="[
          $style.heading,
          {
            [$style.isBig]: hasBigHeading,
          },
        ]"
      >
        <slot name="heading" />
      </h3>
      <div :class="$style.controls">
        <BaseDropdown
          @hide="checkCagrCoverVisibility"
        >
          <template #trigger="{ isActive }">
            <button
              v-if="hasCagr"
              :class="[
                $style.cagr,
                {
                  [$style.isActive]: isActive,
                },
              ]"
            >
              <span
                v-if="cagrConfig.years.start != null || cagrConfig.years.end != null"
                :class="$style.calendarIcon"
              >
                <CalendarSmallSvg />
              </span>
              <span
                v-if="cagrConfig.isVisible"
                :class="$style.indicator"
              >
                <TickSvg />
              </span>
              <span :class="$style.label">CAGR</span>
              <span
                v-if="cagrConfig.years.start != null || cagrConfig.years.end != null"
                :class="$style.label"
              >
                {{ cagrConfig.years.start?cagrConfig.years.start.getFullYear():'' }}
                - {{ cagrConfig.years.end?cagrConfig.years.end.getFullYear():'' }}
              </span>
            </button>
          </template>

          <template #content>
            <div :class="$style.cagrDropdownContent">
              <BaseDropdownHeading>
                Modify CAGR
              </BaseDropdownHeading>
              <BaseDropdownActionsList>
                <BaseDropdownActionField>
                  <template #label>
                    Display
                  </template>

                  <template #field>
                    <BaseMultiselect
                      v-model="cagrConfig.isVisible"
                      :options="$options.CAGR_IS_VISIBLE_OPTIONS"
                      is-block
                    />
                  </template>
                </BaseDropdownActionField>
                <BaseDropdownActionField>
                  <template #label>
                    Date range
                  </template>

                  <template #field>
                    <BaseInputCover
                      v-if="isCagrCoverVisible"
                      is-block
                      @click="isCagrCoverVisible = false"
                    >
                      Same as filtered range
                    </BaseInputCover>
                    <BaseYearInput
                      v-else
                      v-model="cagrConfig.years"
                      :lower-limit="new Date(minLabel.toString())"
                      :upper-limit="new Date(maxLabel.toString())"
                      is-range
                      is-block
                    />
                  </template>
                </BaseDropdownActionField>
              </BaseDropdownActionsList>
            </div>
          </template>
        </BaseDropdown>
        <BaseSwitch
          v-if="hasUnitsSelector"
          v-model="displayValues"
          :class="$style.switch"
          :options="$options.VALUE_OPTIONS"
        />
        <BaseDropdown
          v-if="$slots.actions"
          ref="actionsDropdown"
          placement="bottom-end"
          :offset="[9, 7]"
          @show="onShowActions"
          @hide="onHideActions"
        >
          <template #trigger="{ isActive }">
            <button
              ref="actionsButton"
              :class="[
                $style.actionsButton,
                {
                  [$style.isActive]: isActive,
                },
              ]"
            >
              <DotsMoreSvg />
            </button>
          </template>

          <template #content="{ tippy }">
            <slot
              name="actions"
              :tippy="tippy"
            />
          </template>
        </BaseDropdown>
        <button
          v-if="hasCloseButton"
          :class="$style.closeButton"
          type="button"
          @click="close"
        >
          <CrossSvg />
        </button>
      </div>
    </div>
    <div :class="$style.chartWrapper">
      <div
        :class="[
          $style.legendWrapper,
          {
            [$style.isExpanded]: isFullLegendVisible || cagrConfig.isVisible,
            [$style.hasCagr]: cagrConfig.isVisible,
          },
        ]"
      >
        <div :class="$style.legendPlacer">
          <div
            ref="legendPreview"
            :class="[
              $style.legend,
              {
                [$style.isPreview]: !isFullLegendVisible && hasMorePreviewItems,
              },
            ]"
          >
            <button
              v-for="item in legendItems"
              :key="item.datasetIndex"
              :class="$style.legendItem"
              type="button"
              @click="toggleDatasetVisibility(item.datasetIndex)"
            >
              <span :class="$style.legendItemBase">
                <span
                  :class="$style.legendItemIndicator"
                  :style="{
                    backgroundColor: item.hidden ? null : item.strokeStyle,
                    borderColor: item.strokeStyle,
                  }"
                />
                {{ item.text }}
              </span>
              <span
                v-if="cagrConfig.isVisible && item.cagr"
                :class="$style.legendItemCagr"
              >
                {{ `${(item.cagr * 100).toFixed(2)}%` }}
              </span>
            </button>
            <button
              v-if="isFullLegendVisible"
              type="button"
              :class="$style.legendToggle"
              @click="toggleFullLegend"
            >
              <span :class="$style.cross">
                <CrossSvg />
              </span>
              less
            </button>
          </div>
          <transition
            :enter-from-class="$style.fadeEnter"
            :enter-active-class="$style.fadeEnterActive"
            :leave-to-class="$style.fadeLeaveTo"
            :leave-active-class="$style.fadeLeaveActive"
          >
            <div
              v-if="isFullLegendPreviewVisible && !isFullLegendVisible"
              :class="[$style.legend, $style.isFullPreview]"
            >
              <button
                v-for="item in legendItems"
                :key="item.datasetIndex"
                :class="$style.legendItem"
                type="button"
                @click="toggleDatasetVisibility(item.datasetIndex)"
              >
                <span :class="$style.legendItemBase">
                  <span
                    :class="$style.legendItemIndicator"
                    :style="{
                      backgroundColor: item.hidden ? null : item.strokeStyle,
                      borderColor: item.strokeStyle,
                    }"
                  />
                  {{ item.text }}
                </span>
                <span
                  v-if="cagrConfig.isVisible && item.cagr"
                  :class="$style.legendItemCagr"
                >
                  {{ `${(item.cagr * 100).toFixed(2)}%` }}
                </span>
              </button>
            </div>
          </transition>
        </div>
        <button
          v-if="hasMorePreviewItems && !isFullLegendVisible"
          type="button"
          :class="$style.legendToggle"
          @mouseenter="showFullLegendPreview"
          @mouseleave="hideFullLegendPreview"
          @click="toggleFullLegend"
        >
          more
        </button>
      </div>
      <ChartJs
        ref="chart"
        :key="type"
        :type="type"
        :options="chartOptions"
        :plugins="plugins"
        :data="data"
        @afterUpdate="calculateCagr"
      />
      <div
        v-if="yLabel"
        :class="[$style.axisLabel, $style.isY]"
      >
        {{ selectedValueYLabel }}
      </div>
      <div
        v-if="xLabel"
        :class="[$style.axisLabel, $style.isX]"
      >
        {{ xLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import colorBuilder from 'color';
import { merge } from 'lodash';
import ChartJs from '@j-t-mcc/vue3-chartjs';
import isVNodeEmpty from '@/utils/isVNodeEmpty';
import BaseDropdown, {
  DropdownHeading as BaseDropdownHeading, DropdownActionsList as BaseDropdownActionsList,
  DropdownActionField as BaseDropdownActionField,
} from '@/components/base/Dropdown';
import BaseMultiselect from '@/components/base/Multiselect';
import BaseYearInput from '@/components/base/YearInput';
import BaseSwitch from '@/components/base/Switch';
import BaseInputCover from '@/components/base/InputCover';
import CrossSvg from '@/assets/images/icons/cross.svg?inline';
import TickSvg from '@/assets/images/icons/tick.svg?inline';
import DotsMoreSvg from '@/assets/images/icons/dots-more.svg?inline';
import CalendarSmallSvg from '@/assets/images/icons/calendar-small.svg?inline';

const gradientPlugin = {
  afterLayout(c) {
    const { datasets } = c.data;
    const yScale = c.scales.y;

    datasets.forEach((dataset) => {
      const color = colorBuilder(dataset.borderColor);

      const max = dataset.data.reduce((maxValue, value) => {
        if (maxValue == null || value.y > maxValue) return value.y;
        return maxValue;
      }, null) || 0;

      const maxPixel = yScale.getPixelForValue(max);

      const gradientFill = c.ctx.createLinearGradient(0, maxPixel, 0, maxPixel + 400);

      const firstColorStop = Math.min(0.1, 1);
      const secondColorStop = Math.min(0.4, 1);

      gradientFill.addColorStop(0, color.alpha(0.5).rgb().string());
      gradientFill.addColorStop(firstColorStop, color.alpha(0.3).rgb().string());
      gradientFill.addColorStop(secondColorStop, color.alpha(0).rgb().string());

      // eslint-disable-next-line no-param-reassign
      dataset.fill = {
        target: true,
        above: gradientFill,
        below: 'rgba(23, 23, 23, 0.1)',
      };
    });
  },
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    options.setLegendItems(items);
  },
};

export default {
  components: {
    ChartJs,
    BaseDropdown,
    BaseDropdownHeading,
    BaseDropdownActionsList,
    BaseDropdownActionField,
    BaseMultiselect,
    BaseYearInput,
    BaseSwitch,
    BaseInputCover,
    CrossSvg,
    TickSvg,
    DotsMoreSvg,
    CalendarSmallSvg,
  },
  props: {
    labels: {
      type: Array,
      default: undefined,
    },
    datasets: {
      type: Array,
      default: () => ([]),
    },
    aspectRatio: {
      type: Number,
      default: 2,
    },
    type: {
      type: String,
      default: 'line',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    xLabel: {
      type: String,
      default: null,
    },
    yLabel: {
      type: String,
      default: null,
    },
    hasBigHeading: {
      type: Boolean,
      default: false,
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
    hasUnitsSelector: {
      type: Boolean,
      default: false,
    },
    hasCagr: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    close: null,
    showActions: null,
    hideActions: null,
  },
  VALUE_OPTIONS: [
    {
      label: 'Values',
      value: 'values',
    },
    {
      label: '%',
      value: 'percentageChange',
    },
    {
      label: 'nbr',
      value: 'entriesNumber',
    },
  ],
  CAGR_IS_VISIBLE_OPTIONS: [
    {
      label: 'Visible',
      value: true,
    },
    {
      label: 'Hidden',
      value: false,
    },
  ],
  data(vm) {
    const baseOptions = {
      animation: false,
      aspectRatio: vm.aspectRatio,
      tension: 0.2,
      scales: {
        x: {
          grid: {
            display: false,
            borderColor: '#F0F1F2',
          },
          ticks: {
            font: {
              size: 13,
              family: 'Urania, sans-serif',
            },
            color: '#6A7073',
          },
        },
        y: {
          grid: {
            drawBorder: false,
            tickLength: 10,
            color: '#F0F1F2',
          },
          ticks: {
            align: 'end',
            font: {
              size: 13,
              family: 'Urania, sans-serif',
            },
            color: '#6A7073',
          },
        },
      },
      elements: {
        line: {
          borderWidth: 2,
        },
        point: {
          radius: 4,
          backgroundColor: 'red',
          borderWidth: 2,
          hoverRadius: 5,
          hoverBorderWidth: 6,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        htmlLegend: {
          setLegendItems: this.setLegendItems,
        },
      },
    };

    return {
      legendItems: [],
      plugins: [
        gradientPlugin,
        htmlLegendPlugin,
      ],
      data: {
        labels: vm.labels,
        datasets: [],
      },
      baseOptions,
      chartOptions: {},
      isFullLegendPreviewVisible: false,
      isFullLegendVisible: false,
      hasMorePreviewItems: false,
      cagrConfig: {
        isVisible: vm.hasCagr,
        years: {
          start: null,
          end: null,
        },
      },
      isCagrCoverVisible: true,
      displayValues: vm.$options.VALUE_OPTIONS[0].value,
    };
  },
  computed: {
    minLabel() {
      return Math.min(...this.data.labels);
    },
    maxLabel() {
      return Math.max(...this.data.labels);
    },
    hasHeading() {
      return !isVNodeEmpty(this.$slots.heading?.());
    },
    selectedDatasetsValues() {
      if (this.displayValues === 'percentageChange') return this.datasetsPercentageChange;
      if (this.displayValues === 'entriesNumber') return this.datasetsEntriesNumber;

      return this.datasetsValues;
    },
    datasetsValues() {
      return this.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.map((data) => ({
          ...data,
          y: data.y.value,
        })),
      }));
    },
    datasetsEntriesNumber() {
      return this.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.map((data) => ({
          ...data,
          y: data.y.entries,
        })),
      }));
    },
    datasetsPercentageChange() {
      return this.datasets.map((dataset) => {
        let lastData = null;

        return {
          ...dataset,
          data: dataset.data.map((data) => {
            const newY = lastData == null
              ? 0
              : ((data.y.value - lastData) / (lastData / 100));
            lastData = data.y.value;

            return {
              ...data,
              y: newY,
            };
          }),
        };
      });
    },
    selectedValueYLabel() {
      switch (this.displayValues) {
        case this.$options.VALUE_OPTIONS[0].value:
          return this.yLabel;
        case this.$options.VALUE_OPTIONS[1].value:
          return 'percent';
        case this.$options.VALUE_OPTIONS[2].value:
          return 'number';
        default:
          return null;
      }
    },
  },
  watch: {
    labels() {
      this.data.labels = this.labels;
      this.$refs.chart.update();
    },
    displayValues() {
      this.data.datasets = this.selectedDatasetsValues;
      this.$refs.chart.update();
    },
    datasets() {
      this.data.datasets = this.selectedDatasetsValues;
      this.$refs.chart.update();
    },
    'cagrConfig.isVisible': function onIsVisibleChange() {
      this.checkPreviewHasMoreItems();
    },
    'cagrConfig.years.start': function onYearsStartChange() {
      this.calculateCagr();
    },
    'cagrConfig.years.end': function onYearsEndChange() {
      this.calculateCagr();
    },
    aspectRatio(val) {
      this.chartOptions.aspectRatio = val;
      this.$refs.chart.update();
    },
    type() {
      setTimeout(() => {
        this.$refs.chart.destroy();
        this.chartOptions = this.compileOptions();
        this.$refs.chart.chartJSState.props.options = this.chartOptions;
        this.$refs.chart.render();
      }, 200);
    },
    options() {
      this.chartOptions = this.compileOptions();
      this.$refs.chart.chartJSState.props.options = this.chartOptions;
      this.$refs.chart.update();
    },
  },
  created() {
    this.chartOptions = this.compileOptions();
  },
  mounted() {
    this.data.datasets = this.selectedDatasetsValues;
    this.$refs.chart.update();

    this.$nextTick(() => {
      this.checkPreviewHasMoreItems();
    });

    window.addEventListener('resize', this.checkPreviewHasMoreItems);
  },
  updated() {
    this.checkPreviewHasMoreItems();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkPreviewHasMoreItems);
  },
  methods: {
    compileOptions() {
      return merge({}, this.baseOptions, this.options || {});
    },
    checkPreviewHasMoreItems() {
      this.$refs.legendPreview.style.height = '21px';
      this.hasMorePreviewItems = this.$refs.legendPreview.scrollHeight > (this.$refs.legendPreview.clientHeight * 2);
      this.$refs.legendPreview.style.height = '';
    },
    setLegendItems(items) {
      this.legendItems = items;
    },
    toggleDatasetVisibility(datasetIndex) {
      const chartJs = this.$refs.chart.chartJSState.chart;
      chartJs.setDatasetVisibility(datasetIndex, !chartJs.isDatasetVisible(datasetIndex));
      chartJs.update();
    },
    showFullLegendPreview() {
      this.isFullLegendPreviewVisible = true;
    },
    hideFullLegendPreview() {
      this.isFullLegendPreviewVisible = false;
    },
    showFullLegend() {
      this.hideFullLegendPreview();
      this.isFullLegendVisible = true;
    },
    hideFullLegend() {
      this.hideFullLegendPreview();
      this.isFullLegendVisible = false;
    },
    toggleFullLegend() {
      if (this.isFullLegendVisible) {
        this.hideFullLegend();
      } else {
        this.showFullLegend();
      }
    },
    close() {
      this.$emit('close');
    },
    checkCagrCoverVisibility() {
      if (this.cagrConfig.years.end == null && this.cagrConfig.years.start == null) {
        this.isCagrCoverVisible = true;
      }
    },
    calculateCagr() {
      this.$nextTick(() => {
        this.legendItems.forEach((item) => {
          const { data } = this.data.datasets[item.datasetIndex];

          if (data.length === 0) {
            // eslint-disable-next-line no-param-reassign
            item.cagr = null;
            return;
          }

          let first;
          let last;

          if (this.cagrConfig.years.start) {
            const year = this.cagrConfig.years.start.getFullYear();
            first = data.find(({ x }) => x === year);
          } else {
            // eslint-disable-next-line prefer-destructuring
            first = data[0];
          }

          if (this.cagrConfig.years.end) {
            const year = this.cagrConfig.years.end.getFullYear();
            last = data.find(({ x }) => x === year);
          } else {
            last = data[data.length - 1];
          }

          let result = null;

          if (first.y !== 0) {
            result = ((last.y / first.y) ** (1 / (last.x - first.x)) - 1).toFixed(4);
          }

          // eslint-disable-next-line no-param-reassign, no-restricted-globals
          item.cagr = isNaN(result) ? null : result;
        });
      });
    },
    onShowActions() {
      this.$emit('showActions');
    },
    onHideActions() {
      this.$emit('hideActions');
    },
    showActions() {
      this.$refs.actionsDropdown.show();
    },
    hideActions() {
      this.$refs.actionsDropdown.hide();
    },
  },
};
</script>

<style lang="scss" module>
.chartContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  font-size: 18px;
  font-weight: 500;
  color: $title;

  &.isBig {
    font-size: 26px;
  }
}

.chartWrapper {
  position: relative;

  &:hover {

    .axisLabel {
      opacity: 1;
    }
  }
}

.controls {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

.cagr {
  @include reset;
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;

  &:hover,
  &.isActive {
    background-color: $border;
  }

  .calendarIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    color: $text;
  }

  .indicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    margin-right: 4px;
    color: $white;
    background: $text;
    border: 1px solid $text;
    border-radius: 50%;

    svg {
      width: 6px;
      height: auto;
    }
  }

  .label {
    display: inline-block;
    padding-top: 2px;
    padding-right: 5px;
    font-size: 13px;
    color: $text;
  }
}

.cagrDropdownContent {
  min-width: 300px;
}

.switch {
  margin-left: 5px;
}

.actionsButton {
  @include reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-left: 10px;
  overflow: hidden;
  color: $text;
  cursor: pointer;
  background: rgba($disabled, 0);
  border-radius: 50%;
  transition: background 0.2s ease-in-out;

  &:hover,
  &.isActive {
    background: rgba($disabled, 0.25);
  }
}

.closeButton {
  @include reset;
  display: inline-flex;
  padding: 5px;
  margin-left: 10px;
  color: $disabled;
  cursor: pointer;

  svg {
    width: 10px;
    height: auto;
  }
}

.legendWrapper {
  position: absolute;
  top: -4px;
  right: 0;
  left: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.hasCagr {
    top: -8px;
  }

  &.isExpanded {
    position: initial;
    padding: 10px;

    .legendToggle {
      padding-top: 5px;
    }
  }
}

.legendPlacer {
  position: relative;
  margin-right: 7px;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -7px;

  &.isPreview {
    height: 21px;
    overflow: hidden;
  }

  &.isFullPreview {
    position: absolute;
    top: -6px;
    right: -6px;
    left: -6px;
    padding: 5px;
    background: $white;
    border: 1px solid $border;
    border-radius: 5px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
}

.legendItem {
  @include reset;
  display: inline-flex;
  flex-direction: column;
  padding: 4px 0;
  margin: 0 7px;
  font-size: 13px;
  color: $text;
  cursor: pointer;
}

.legendItemBase {
  display: inline-flex;
  align-items: center;
}

.legendItemCagr {
  font-size: 11px;
}

.legendToggle {
  @include reset;
  display: inline-flex;
  align-items: center;
  padding-top: 2px;
  margin-left: 7px;
  font-size: 13px;
  color: $disabled;
  cursor: pointer;

  .cross {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    margin-right: 3px;
    background-color: $disabled;
    border-radius: 50%;

    svg {
      width: 4px;
      height: auto;
      color: $white;
    }
  }
}

.legendItemIndicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  border: 1px solid #333;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}

.axisLabel {
  position: absolute;
  z-index: 1;
  padding: 3px 8px 1px;
  font-size: 12px;
  color: $disabled;
  text-transform: uppercase;
  pointer-events: none;
  background-color: $white;
  border: 1px solid $disabled;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transform-origin: center;

  &.isX {
    bottom: -15px;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  &.isY {
    top: 50%;
    left: -20px;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}

.fadeEnterActive,
.fadeLeaveActive {
  transition: opacity 0.5s;
}

.fadeEnter,
.fadeLeaveTo {
  opacity: 0;
}
</style>
