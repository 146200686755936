<template>
  <div
    :class="[$style.wrapper]"
  >
    <BaseDropdown
      placement="bottom-end"
      :offset="[9, 7]"
    >
      <template #trigger="{ isActive }">
        <button
          :class="[
            $style.button,
            {
              [$style.isActive]: isActive,
            },
          ]"
        >
          <DotsMoreSvg />
        </button>
      </template>

      <template #content="{ tippy }">
        <slot :tippy="tippy" />
      </template>
    </BaseDropdown>
  </div>
</template>

<script>
import BaseDropdown from '@/components/base/Dropdown';
import DotsMoreSvg from '@/assets/images/icons/dots-more.svg?inline';

export default {
  components: {
    BaseDropdown,
    DotsMoreSvg,
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  padding-left: 15px;
}

.button {
  @include reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  overflow: hidden;
  color: $text;
  cursor: pointer;
  background: rgba($disabled, 0);
  border-radius: 50%;
  transition: background 0.2s ease-in-out;

  &:hover,
  &.isActive {
    background: rgba($disabled, 0.25);
  }
}
</style>
