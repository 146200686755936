<template>
  <tbody>
    <tr
      v-for="(rowData, rowIndex) in data"
      :ref="pushRowRef"
      :key="getDataKey(rowData, dataKey, rowIndex)"
      :class="[$style.tr]"
    >
      <td
        v-if="isSelectable"
        :class="[$style.td, $style.checkboxColumn]"
      >
        <BaseCheckbox
          v-model="selectionProxy"
          :value="rowData"
        />
      </td>
      <template
        v-for="(col, colIndex) in dataColumns"
        :key="getColumnKey(col, colIndex)"
      >
        <td
          v-if="!getIsDataColumnToggable(col) || visibilityToggableColumns.includes(getColumnId(col))"
          :class="[$style.td, col.contentClass]"
        >
          <component
            :is="col.$slots.default"
            v-if="col.$slots?.default"
            :row-data.camel="rowData"
            :column="col"
            :index="rowIndex"
          />
          <span v-else-if="col?.field">{{ getCellData(rowData, col.field) }}</span>
        </td>
      </template>
      <!-- Empty column to shrink other ones -->
      <td />
    </tr>
  </tbody>
</template>

<script>
import { get } from 'lodash';
import BaseCheckbox from '@/components/base/Checkbox';
import TableRowActions from './TableRowActions';
import {
  getColumnKey, getDataKey, getColumnId, getIsDataColumnToggable,
} from './utils';

export default {
  components: {
    BaseCheckbox,
    TableRowActions,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    dataColumns: {
      type: Array,
      default: () => [],
    },
    dataKey: {
      type: String,
      default: null,
    },
    selection: {
      type: Array,
      default: () => [],
    },
    visibilityToggableColumns: {
      type: Array,
      default: () => ([]),
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    setRowRefs: null,
    'update:selection': null,
  },
  data() {
    return {
      rowRefs: [],
    };
  },
  computed: {
    selectionProxy: {
      get() {
        return this.selection;
      },
      set(value) {
        this.$emit('update:selection', value);
      },
    },
  },
  mounted() {
    this.$emit('setRowRefs', this.rowRefs);
  },
  beforeUpdate() {
    this.rowRefs = [];
  },
  updated() {
    this.$emit('setRowRefs', this.rowRefs);
  },
  methods: {
    getIsDataColumnToggable,
    getColumnKey,
    getColumnId,
    getDataKey,
    getCellData(row, field) {
      return get(row, field);
    },
    pushRowRef(ref) {
      this.rowRefs.push(ref);
    },
  },
};
</script>

<style lang="scss" module>
.tr {
  background-color: rgba(174, 228, 242, 0);

  &:hover {
    background-color: rgba(174, 228, 242, 0.35);
  }
}

.td {
  width: 1px;
  padding: 10px 20px;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: $title;
  text-align: left;
  letter-spacing: -0.23px;
  white-space: nowrap;

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-right: 15px;
  }

  > * {
    vertical-align: middle;
  }
}

.checkboxColumn {
  width: 38px;
}
</style>
