import gql from 'graphql-tag';
import { apolloClient } from '@/vue-apollo';

export default {
  async getCarById(id) {
    const result = await apolloClient.query({
      query: gql`query ($id: Int!) {
        cars_by_pk(id: $id) {
          id
          model
          model_id
          commercial_name
          project_code
          generation
          phase
          car_body {
            name
          }
          power_train
          sop
          eop
          front_image
          side_image
          back_image
          segment
          brand {
            id
            logo
            name
          }
          country {
            id
            region
            code
          }
        }
      }`,
      variables: {
        id,
      },
    });

    return result.data.cars_by_pk;
  },

  async getSameModelCars(brandId, model) {
    const result = await apolloClient.query({
      query: gql`query ($brandId: Int!, $model: String!) {
        cars(where: { brand_id: { _eq: $brandId }, model: { _eq: $model }}) {
          commercial_name
          country {
            region
          }
        }
      }`,
      variables: {
        brandId,
        model,
      },
    });

    return result.data.cars;
  },

  async getCars() {
    const result = await apolloClient.query({
      query: gql`query {
        cars(where: {to_be_deleted: {_eq: false}}) {
          id
          body
          model
          project_code
          lighting_cluster_reference_2
          brand_id
          country_id
          body_id
          model_id
          generation
          brand {
            name
          }
          production_factory {
            id
            name
          }
        }
      }`,
    });

    return result.data.cars;
  },

  async getToBeDeletedCars() {
    const result = await apolloClient.query({
      query: gql`query {
        cars(where: {to_be_deleted: {_eq: true}}) {
          id
          sop
          eop
          body
          model
          model_id
          brand_id
          body_id
          country_id
          generation
          power_train
          phase
          car_body {
            id
            name
          }
          project_code
          front_image
          back_image
          lighting_cluster_reference_2
          lighting_cluster_reference
          brand {
            id
            name
            logo
          }
          country {
            id
            code
          }
          segment
          volumes_infos {
             update_ref_id: update_ref_id_3e83f030e97749c4b572eafd7b7a90e4   
          }
        }
      }`,
    });

    return result.data.cars;
  },

  async getSourceModels(brandId) {
    const result = await apolloClient.query({
      query: gql`query {
        models (
          where: {brand_id: {_eq: ${brandId}}},
          distinct_on: name
        ) {
          global_nameplate: name
          modelid: id
          brandid: brand_id
        }
      }`,
    });

    return result.data.models;
  },

  async getSourceCarsByBrandAndModel(brandId, modelId) {
    const result = await apolloClient.query({
      query: gql`query {
        source_cars_suppliers (
          where: {
            brandid: {_eq: ${brandId}},
            modelid: {_eq: ${modelId}}
          },
        ) {
          id
          generation
          sop
          eop
          global_nameplate
          body_type
          body_style
          body_style_program_code
          bodyid
          countryid
          source_plant
          source_plant_country_territory
          production_plant
          power_train
          lighting_cluster_reference
          lighting_cluster_reference_2
          segment: global_sales_segment
          source
          country {
            name
          }
          update_ref_id
        }
      }`,
    });

    return result.data.source_cars_suppliers;
  },

  async getLmiBrandModels(brandId) {
    const result = await apolloClient.query({
      query: gql`query {
        cars(where: {brand_id: {_eq: ${brandId}}, to_be_deleted: {_eq: false}}) {
          model_id
          model
        }
      }`,
    });

    return result.data.cars;
  },

  async getLmiCarsByBrand(brandId) {
    const result = await apolloClient.query({
      query: gql`query {
        cars(
          where: {brand_id: {_eq: ${brandId}}, to_be_deleted: {_eq: false}},
          distinct_on: lighting_cluster_reference_2
        ) {
          body
          generation
          model
          lighting_cluster_reference_2
          country {
            id
            code
          }
        }
      }`,
    });

    return result.data.cars;
  },

  async getLmiCarsByBrandAndModel(brandId, modelId) {
    const result = await apolloClient.query({
      query: gql`query {
        cars(
          where: {
            brand_id: {_eq: ${brandId}},
            model_id: {_eq: ${modelId}},
            to_be_deleted: {_eq: false}
          },
          order_by: {phase: asc}
        ) {
          id
          sop
          eop
          body
          model
          comment
          model_id
          brand_id
          body_id
          country_id
          generation
          power_train
          phase
          car_body {
            id
            name
          }
          project_code
          front_image
          back_image
          lighting_cluster_reference_2
          lighting_cluster_reference
          brand {
            id
            name
            logo
          }
          country {
            id
            code
            region
            name
          }
          segment
          volumes_infos {
             update_ref_id: update_ref_id_3e83f030e97749c4b572eafd7b7a90e4   
          }
          lighting_configurations {
            lighting_device_id
          }
        }
      }`,
    });

    return result.data.cars;
  },

  async getSourceCarsSopEopAggregate(lightingClusterReference2) {
    const result = await apolloClient.query({
      query: gql`query ($lightingClusterReference2: String!) {
        source_cars_suppliers_aggregate(
          where: {lighting_cluster_reference_2: {_eq: $lightingClusterReference2}}
        ) {
          nodes {
            id
            sop
            eop
            generation
            lighting_cluster_reference
          }
          aggregate {
            min {
              sop
            }
            max {
              eop
            }
          }
        }
      }`,
      variables: {
        lightingClusterReference2,
      },
    });

    return result.data.source_cars_suppliers_aggregate;
  },

  async insertLmiCar(car) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($car: cars_insert_input = {}) {
        insert_cars_one(object: $car) {
          id
          sop
          eop
          body
          generation
          power_train
          phase
          car_body {
            id
            name
          }
          project_code
          front_image
          back_image
          lighting_cluster_reference_2
          lighting_cluster_reference
          brand {
            id
            name
            logo
          }
          country {
            id
            code
          }
        }
      }`,
      variables: {
        car,
      },
    });

    return result.data.insert_cars_one;
  },

  async deleteLmiCarByPk(id) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!) {
        update_cars_by_pk(pk_columns: {id: $id}, _set: {to_be_deleted: true}) {
          id
        }
      }`,
      variables: {
        id,
      },
    });

    return result;
  },

  async updateCarByPkSopEop(id, sop, eop) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!, $sop: date!, $eop: date!) {
        update_cars_by_pk(pk_columns: {id: $id}, _set: {sop: $sop, eop: $eop}) {
          id
          sop
          eop
        }
      }`,
      variables: {
        id,
        sop,
        eop,
      },
    });

    return result;
  },

  async updateCarByPkPhase(id, phase) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!, $phase: smallint!) {
        update_cars_by_pk(pk_columns: {id: $id}, _set: {phase: $phase}) {
          id
          phase
        }
      }`,
      variables: {
        id,
        phase,
      },
    });

    return result;
  },

  async updateCarByPkSopEopPhase(id, sop, eop, phase, comment) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!, $sop: date!, $eop: date!, $phase: smallint!, $comment: String!) {
        update_cars_by_pk(
          pk_columns: {id: $id},
          _set: {
            sop: $sop,
            eop: $eop,
            phase: $phase
            comment: $comment
          }) {
          id
          sop
          eop
          phase
          comment
        }
      }`,
      variables: {
        id,
        sop,
        eop,
        phase,
        comment,
      },
    });

    return result;
  },

  async updateCarByPkGenPowerTrain(
    id,
    generation,
    powerTrain,
    lightingClusterReference,
    lightingClusterReference2,
  ) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $generation: smallint!,
        $powerTrain: String!,
        $lightingClusterReference: String!
        $lightingClusterReference2: String!
      ) {
        update_cars_by_pk(
          pk_columns: {id: $id},
          _set: {
            generation: $generation,
            power_train: $powerTrain,
            lighting_cluster_reference: $lightingClusterReference
            lighting_cluster_reference_2: $lightingClusterReference2
          }
        ) {
          id
          generation
          power_train
          lighting_cluster_reference
          lighting_cluster_reference_2
        }
      }`,
      variables: {
        id,
        generation,
        powerTrain,
        lightingClusterReference,
        lightingClusterReference2,
      },
    });

    return result;
  },

  async updateSourceCarByPkGenPowerTrain(
    source,
    id,
    generation,
    powerTrain,
    lightingClusterReference,
    lightingClusterReference2,
  ) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $generation: Int!,
        $powerTrain: String!,
        $lightingClusterReference: String!
        $lightingClusterReference2: String!
      ) {
        update_source_cars_supplier_${source === 'supplierOne' ? '1' : '2'}_by_pk(
          pk_columns: {id: $id},
          _set: {
            generation: $generation,
            power_train: $powerTrain,
            lighting_cluster_reference: $lightingClusterReference
            lighting_cluster_reference_2: $lightingClusterReference2
          }
        ) {
          id
          generation
          power_train
          lighting_cluster_reference
          lighting_cluster_reference_2
        }
      }`,
      variables: {
        id,
        generation,
        powerTrain,
        lightingClusterReference,
        lightingClusterReference2,
      },
    });

    return result;
  },

  async updateSourceCarByPkGenRef2(
    source,
    id,
    generation,
    lightingClusterReference2,
  ) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $generation: Int!,
        $lightingClusterReference2: String!
      ) {
        update_source_cars_supplier_${source === 'supplierOne' ? '1' : '2'}_by_pk(
          pk_columns: {id: $id},
          _set: {
            generation: $generation,
            lighting_cluster_reference_2: $lightingClusterReference2
          }
        ) {
          id
          generation
          lighting_cluster_reference_2
        }
      }`,
      variables: {
        id,
        generation,
        lightingClusterReference2,
      },
    });

    return result;
  },

  async insertSourceCarInCluster(
    id,
    lightingClusterReference,
    oldGeneration,
    currentGeneration,
  ) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $lightingClusterReference: String!
        $oldGeneration: Int!,
        $currentGeneration: Int!,
      ) {
        insert_forced_clusters_one(
          object: {
            id: $id
            lighting_cluster_reference: $lightingClusterReference,
            old_generation: $oldGeneration,
            current_generation: $currentGeneration,
          }
        ) {
          id
          lighting_cluster_reference
          old_generation
          current_generation
        }
      }`,
      variables: {
        id,
        lightingClusterReference,
        oldGeneration,
        currentGeneration,
      },
    });

    return result;
  },

  async deleteSourceCarFromClusterByPk(id) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!) {
        delete_forced_clusters_by_pk(id: $id) {
          id
        }
      }`,
      variables: {
        id,
      },
    });

    return result;
  },

  async getLmiCarsTable() {
    const result = await apolloClient.query({
      query: gql`query {
        cars {
          id
          status
          parent_id
          brand {
            id
            name
          }
          country {
            id
            name
          }
          carline {
            name
          }
          model
          commercial_name
          project_code
          generation
          phase
          segment
          power_train
          body
          lmi_body
          source_factory {
            id
            name
            brand {
              name
            }
          }
          sop
          eop
          to_be_deleted
        }
      }`,
    });

    return result.data.cars;
  },

  async insertCar(car) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($car: cars_insert_input = {}) {
        insert_cars_one(object: $car) {
          id
          status
          parent_id
          brand {
            id
            name
          }
          country {
            id
            name
          }
          carline {
            name
          }
          model
          commercial_name
          project_code
          generation
          phase
          segment
          power_train
          body
          lmi_body
          source_factory {
            id
            name
            brand {
              name
            }
          }
          sop
          eop
        }
      }`,
      variables: {
        car,
      },
    });

    return result.data.insert_cars_one;
  },

  async updateCarsByPk(car) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $updatedValues: cars_set_input,
      ) {
        update_cars_by_pk(
          pk_columns: {id: $id},
          _set: $updatedValues
        ) {
          id
          status
          parent_id
          brand {
            id
            name
          }
          country {
            id
            name
          }
          carline {
            name
          }
          model
          commercial_name
          project_code
          generation
          phase
          segment
          power_train
          body
          lmi_body
          source_factory {
            id
            name
            brand {
              name
            }
          }
          sop
          eop
        }
      }`,
      variables: {
        id: car.id,
        updatedValues: car,
      },
    });

    return result;
  },

  async updateCarImageByPk(carId, imageUrl, side) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $carId: Int!,
        $imageUrl: String,
      ) {
        update_cars_by_pk(
          pk_columns: {id: $carId},
          _set: {${side}_image: $imageUrl}
        ) {
          id
        }
      }`,
      variables: {
        carId,
        imageUrl,
      },
    });

    return result;
  },
};
