<template>
  <div :class="$style.formBox">
    <div>
      <h3
        :class="$style.heading"
        v-text="heading"
      />
      <p
        :class="$style.lead"
        v-text="lead"
      />
    </div>
    <div :class="$style.body">
      <slot />
    </div>
    <div :class="$style.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: null,
    },
    lead: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
.formBox {
  padding: 30px;
  background: $white;
  border: 1px solid #d9dee0;
  border-radius: 5px;
}

.heading {
  margin: 15px 0 0;
  font-size: 26px;
  color: $title;
  text-align: center;
  letter-spacing: -0.22px;
}

.lead {
  margin: 6px 0 0;
  font-size: 16px;
  line-height: 1.375;
  color: $title;
  text-align: center;
}

.body {
  margin-top: 30px;
}

.footer {
  margin-top: 20px;
}
</style>
