<template>
  <div :class="$style.item">
    <slot />
  </div>
</template>

<style lang="scss" module>
.item {
  display: inline-flex;
  align-items: center;
  margin-left: 9px;
}
</style>
