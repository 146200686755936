<template>
  <div :class="classes">
    <slot />
    <transition
      name="fade"
      :enter-from-class="$style.fadeEnterFrom"
      :enter-active-class="$style.fadeEnterActive"
      :leave-to-class="$style.fadeLeaveTo"
      :leave-active-class="$style.fadeLeaveActive"
    >
      <div
        v-if="isActive"
        :class="$style.backdrop"
      >
        Loading
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        this.$style.loader,
        {
          [this.$style.isBlock]: this.isBlock,
          [this.$style.hasChildren]: this.$slots.default,
        },
      ];
    },
  },
};
</script>

<style lang="scss" module>
.loader {
  position: relative;
  display: inline-block;
  min-width: 60px;
  min-height: 30px;
}

.isBlock {
  display: block;
}

.hasChildren {

  .backdrop {
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fadeEnterActive,
.fadeLeaveActive {
  transition: opacity 0.3s ease;
}

.fadeEnterFrom,
.fadeLeaveTo {
  opacity: 0;
}
</style>
