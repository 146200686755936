import gql from 'graphql-tag';
import { apolloClient } from '@/vue-apollo';

export default {
  async getAllLdvs() {
    const result = await apolloClient.query({
      query: gql`query {
        lighting_devices(order_by: {id: asc}) {
          id
          supplier {
            name
          }
          factory {
            name
            region
          }
          lighting_configurations {
            id
          }
          type
          part_number
          aftermarket_price
          parts_per_car
          front_image
          created_by
        }
      }`,
    });

    return result.data.lighting_devices;
  },

  async getAllLdvsByCarBrandAndModel(brandId, modelId) {
    const result = await apolloClient.query({
      query: gql`query ($brandId: Int!, $modelId: Int!) {
        lighting_devices(
          order_by: {id: asc},
          where: {lighting_configurations: {car: {brand_id: {_eq: $brandId}, model_id: {_eq: $modelId}}}}
        ) {
          aftermarket_price
          back_image
          bottom_image
          complexity
          country_id
          created_at
          created_by
          dim_x
          dim_y
          dim_z
          factory_id
          front_image
          left_image
          id
          object_360
          order_in_row
          part_number
          parts_per_car
          position
          right_image
          supplier_id
          top_image
          type
          updated_at
          weight
          supplier {
            name
            logo
            region
            country
          }
          lighting_configurations {
            id
          }
          main_light_functions {
            id
            light_function {
              id
              name
            }
            light_functionality {
              id
              name
            }
            light_source {
              id
              name: technology
            }
            optical_system {
              id
              name
            }
          }
        }
      }`,
      variables: {
        brandId,
        modelId,
      },
    });

    return result.data.lighting_devices;
  },

  async getAllLdvsByCarBrand(brandId) {
    const result = await apolloClient.query({
      query: gql`query ($brandId: Int!) {
        lighting_devices(
          order_by: {id: asc},
          where: {lighting_configurations: {car: {brand_id: {_eq: $brandId}}}}
        ) {
          aftermarket_price
          back_image
          bottom_image
          complexity
          country_id
          country {
            code
            region
          }
          created_at
          created_by
          dim_x
          dim_y
          dim_z
          factory_id
          front_image
          left_image
          id
          object_360
          order_in_row
          part_number
          parts_per_car
          position
          right_image
          supplier_id
          top_image
          type
          updated_at
          weight
          supplier_id
          supplier {
            name
            logo
            region
            country
          }
          parts_per_car
          lighting_configurations {
            id
            car {
              id
              segment
              model
              project_code
              generation
              phase
            }
            comment
          }
          main_light_functions {
            id
            light_function {
              id
              name
            }
            light_functionality {
              id
              name
            }
            light_source {
              id
              name: technology
            }
            optical_system {
              id
              name
            }
          }
        }
      }`,
      variables: {
        brandId,
      },
    });

    return result.data.lighting_devices;
  },

  async insertLdv(ldv) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $ldv: lighting_devices_insert_input!,
      ) {
        insert_lighting_devices_one(
          object: $ldv
        ) {
          aftermarket_price
          back_image
          bottom_image
          complexity
          country_id
          created_at
          created_by
          dim_x
          dim_y
          dim_z
          factory_id
          front_image
          left_image
          id
          object_360
          order_in_row
          part_number
          parts_per_car
          position
          right_image
          supplier_id
          top_image
          type
          updated_at
          weight
          supplier {
            name
            logo
            region
            country
          }
          lighting_configurations {
            id
          }
          main_light_functions {
            id
            light_function {
              id
              name
            }
            light_functionality {
              id
              name
            }
            light_source {
              id
              name: technology
            }
            optical_system {
              id
              name
            }
          }
          factory {
            name
            region
          }
        }
      }`,
      variables: {
        ldv,
      },
    });

    return result.data.insert_lighting_devices_one;
  },

  async updateLdvsByPk(ldv) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $updatedValues: lighting_devices_set_input,
      ) {
        update_lighting_devices_by_pk(
          pk_columns: {id: $id},
          _set: $updatedValues
        ) {
          id
        }
      }`,
      variables: {
        id: ldv.id,
        updatedValues: ldv,
      },
    });

    return result;
  },

  async updateLdvImageByPk(ldvId, imageUrl) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $ldvId: Int!,
        $imageUrl: String,
      ) {
        update_lighting_devices_by_pk(
          pk_columns: {id: $ldvId},
          _set: {front_image: $imageUrl}
        ) {
          id
        }
      }`,
      variables: {
        ldvId,
        imageUrl,
      },
    });

    return result;
  },

  async deleteLdvByPk(id) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!) {
        delete_lighting_devices_by_pk(id: $id) {
          id
        }
      }`,
      variables: {
        id,
      },
    });

    return result;
  },

  async getLdvFunction(type) {
    const result = await apolloClient.query({
      query: gql`query($type: String!) {
        light_functions(
          where: {lighting_device_type: {_eq: $type}}
        ) {
          id
          name
        }
      }`,
      variables: {
        type,
      },
    });

    return result.data.light_functions;
  },

  async getLightingConfigurationsOfCar(carId) {
    const result = await apolloClient.query({
      query: gql`
        query ($carId: Int!) {
          lighting_configurations(
            where: {
              car_id: {_eq: $carId}
            },
            order_by: {id: asc}
          ) {
            id
            version
            comment
            car {
              id
              brand {
                name
                logo
              }
              back_image
              front_image
            }
            take_rates_aggregate {
              aggregate {
                sum {
                  units
                }
              }
            }
            take_rates: global_take_rates {
              id
              rate
              created_at
              lighting_configuration_id
            }
            lighting_device {
              id
              type
              front_image
              part_number
              order_in_row
              position
              aftermarket_price
              parts_per_car
              complexity
              country_id
              country {
                code
                region
              }
              supplier_id
              supplier {
                name
                logo
                region
                country
              }
              main_light_functions {
                id
                light_function {
                  id
                  name
                }
                light_functionality {
                  id
                  name
                }
                light_source {
                  id
                  name: technology
                }
                optical_system {
                  id
                  name
                }
              }
              lighting_configurations {
                id
              }
            }
          }
        }
      `,
      variables: {
        carId,
      },
    });

    return result.data.lighting_configurations;
  },

  async insertLdvConfig(ldvConfig) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $ldvConfig: lighting_configurations_insert_input!,
      ) {
        insert_lighting_configurations_one(
          object: $ldvConfig
        ) {
          id
          version
          comment
          car {
            id
            brand {
              name
              logo
            }
            back_image
            front_image
          }
          take_rates_aggregate {
            aggregate {
              sum {
                units
              }
            }
          }
          take_rates: global_take_rates {
            id
            rate
            created_at
            lighting_configuration_id
          }
          lighting_device {
            id
            type
            front_image
            part_number
            order_in_row
            position
            aftermarket_price
            parts_per_car
            complexity
            country_id
            supplier_id
            supplier {
              name
              logo
              region
              country
            }
            main_light_functions {
              id
              light_function {
                id
                name
              }
              light_functionality {
                id
                name
              }
              light_source {
                id
                name: technology
              }
              optical_system {
                id
                name
              }
            }
            lighting_configurations {
              id
            }
          }
        }
      }`,
      variables: {
        ldvConfig,
      },
    });

    return result.data.insert_lighting_configurations_one;
  },

  async updateGlobalTakeRatesByPk(id, rate) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!, $rate: numeric) {
        update_global_take_rates_by_pk(pk_columns: {id: $id}, _set: {rate: $rate}) {
          id
          rate
          created_at
          lighting_configuration_id
        }
      }`,
      variables: {
        id,
        rate,
      },
    });

    return result.data.update_global_take_rates_by_pk;
  },

  async updateLightingConfigurationLdv(lcId, ldvId) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($lcId: Int!, $ldvId: Int!) {
        update_lighting_configurations_by_pk(pk_columns: {id: $lcId}, _set: {lighting_device_id: $ldvId}) {
          id
          lighting_device_id
        }
      }`,
      variables: {
        lcId,
        ldvId,
      },
    });

    return result;
  },

  async updateLightingConfigurationByPk(lightingConfiguration) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $updatedValues: lighting_configurations_set_input,
      ) {
        update_lighting_configurations_by_pk(
          pk_columns: {id: $id},
          _set: $updatedValues
        ) {
          id
        }
      }`,
      variables: {
        id: lightingConfiguration.id,
        updatedValues: lightingConfiguration,
      },
    });

    return result;
  },

  async deleteLightingConfigurationByPk(lightingConfigurationId) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!) {
        delete_lighting_configurations_by_pk(id: $id) {
          id
        }
      }`,
      variables: {
        id: lightingConfigurationId,
      },
    });

    return result;
  },

  async getAllLdvConfigOptions() {
    const result = await apolloClient.query({
      query: gql`query {
        lighting_device_configuration_options(order_by: {id: asc}) {
          id
          light_device_type
          light_function {
            id
            lighting_device_type
            name
          }
          light_functionality {
            id
            name
          }
          light_source {
            id
            technology
          }
          optical_system {
            id
            name
          }
        }
      }`,
    });

    return result.data.lighting_device_configuration_options;
  },

  async getAllLightFunctions() {
    const result = await apolloClient.query({
      query: gql`query {
        light_functions(order_by: {name: asc}) {
          id
          name
          lighting_device_type
        }
      }`,
    });

    return result.data.light_functions;
  },

  async getAllLightFunctionalities() {
    const result = await apolloClient.query({
      query: gql`query {
        light_functionalities(order_by: {name: asc}) {
          id
          name
        }
      }`,
    });

    return result.data.light_functionalities;
  },

  async getAllLightSources() {
    const result = await apolloClient.query({
      query: gql`query {
        light_sources(order_by: {technology: asc}) {
          id
          technology
        }
      }`,
    });

    return result.data.light_sources;
  },

  async getAllOpticalSystems() {
    const result = await apolloClient.query({
      query: gql`query {
        optical_systems(order_by: {name: asc}) {
          id
          name
        }
      }`,
    });

    return result.data.optical_systems;
  },

  async insertLdvConfigOption(ldvConfigOption) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $ldvConfigOption: lighting_device_configuration_options_insert_input!,
      ) {
        insert_lighting_device_configuration_options_one(
          object: $ldvConfigOption
        ) {
          id
          light_device_type
          light_function {
            id
            lighting_device_type
            name
          }
          light_functionality {
            id
            name
          }
          light_source {
            id
            technology
          }
          optical_system {
            id
            name
          }
        }
      }`,
      variables: {
        ldvConfigOption,
      },
    });

    return result.data.insert_lighting_device_configuration_options_one;
  },

  async updateLdvConfigOptionByPk(ldvConfigOption) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $updatedValues: lighting_device_configuration_options_set_input,
      ) {
        update_lighting_device_configuration_options_by_pk(
          pk_columns: {id: $id},
          _set: $updatedValues
        ) {
          id
          light_device_type
          light_function {
            id
            lighting_device_type
            name
          }
          light_functionality {
            id
            name
          }
          light_source {
            id
            technology
          }
          optical_system {
            id
            name
          }
        }
      }`,
      variables: {
        id: ldvConfigOption.id,
        updatedValues: ldvConfigOption,
      },
    });

    return result.data.update_lighting_device_configuration_options_by_pk;
  },

  async updateLightingConfigurationVersion(lcId, version) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($lcId: Int!, $version: String!) {
        update_lighting_configurations_by_pk(pk_columns: {id: $lcId}, _set: {version: $version}) {
          id
          lighting_device_id
        }
      }`,
      variables: {
        lcId,
        version,
      },
    });

    return result;
  },

  async insertMlfsMany(mlfsArray) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $mlfsArray: [main_light_functions_insert_input!]!,
      ) {
        insert_main_light_functions(
          objects: $mlfsArray
        ) {
          returning {
            id
            light_function {
              id
              name
            }
            light_functionality {
              id
              name
            }
            light_source {
              id
              name: technology
            }
            optical_system {
              id
              name
            }
          }
        }
      }`,
      variables: {
        mlfsArray,
      },
    });

    return result.data.insert_main_light_functions.returning;
  },

  async getAssociatedCarsImages(deviceId) {
    const result = await apolloClient.query({
      query: gql`query (
        $deviceId: Int!,
      ) {
        lighting_devices_by_pk(id: $deviceId) {
          lighting_configurations {
            car {
              back_image
              front_image
            }
          }
        }
      }`,
      variables: {
        deviceId,
      },
    });

    return result.data.lighting_devices_by_pk;
  },
};
