<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.wrapper"
  >
    <div :class="$style.tablesContainer">
      <div
        v-for="deviceType in deviceTypes"
        :key="deviceType"
        :class="[$style.deviceTypeSection, {
          [$style.uniqueSection]: usedDeviceTypes === 1
        }]"
      >
        <h3 :class="$style.heading">
          {{ deviceType }}
        </h3>
        <EasyDataTable
          :headers="headers"
          :items="takeRates[deviceType]"
          :table-min-height="0"
          :hide-footer="true"
        >
          <template #item-rate="rowData">
            <input
              v-model="rateInputs[rowData.index]"
              type="number"
              step="0.1"
              :class="$style.rateInput"
              @change="handleChange(rowData.index, deviceType)"
            >
          </template>
          <template #item-created_at="{created_at}">
            {{ created_at ? format(new Date(created_at), 'yyyy-MM-dd') : 'No data' }}
          </template>
          <template #item-id="{id}">
            {{ id || '-' }}
          </template>
          <template #item-version="{version}">
            {{ version || '-' }}
          </template>
        </EasyDataTable>
      </div>
    </div>
    <div
      v-if="!isEmpty(sortedLightingConfigurations)"
      :class="$style.buttonsContainer"
    >
      <BaseButton
        variant="secondary"
        @click="reset"
      >
        Reset
      </BaseButton>
      <BaseButton
        variant="success"
        @click="update"
      >
        Update
      </BaseButton>
    </div>
  </BaseLoader>
</template>
<script>
import { cloneDeep, isEmpty } from 'lodash';
import BaseButton from '@/components/base/Button';
import BaseLoader from '@/components/base/Loader';
import ldvs from '@/graphql/queries/ldvs';
import utils from '@/graphql/queries/utils';
import { format } from 'date-fns';
import { mlfsInitConfig } from '@/config';

export default {
  components: {
    BaseButton,
    BaseLoader,
  },
  props: {
    sortedLightingConfigurations: {
      type: Object,
      default: null,
    },
  },
  emits: {
    updateTakeRates: null,
  },
  data() {
    return {
      headers: [
        {
          text: 'Id', value: 'id', sortable: false,
        },
        {
          text: 'Version', value: 'version', sortable: true,
        },
        {
          text: 'Rate', value: 'rate', sortable: false, width: 75,
        },
        {
          text: 'CLDV Id', value: 'lighting_configuration_id', sortable: false,
        },
        {
          text: 'Created at', value: 'created_at', sortable: false,
        },
      ],
      rateInputs: [],
      updatedRows: [],
      isLoading: false,
      deviceTypes: [],
      takeRates: {},
    };
  },
  computed: {
    usedDeviceTypes() {
      return Object.values(this.sortedLightingConfigurations)
        .map((value) => {
          if (isEmpty(value)) return 0;
          return 1;
        })
        .reduce((acc, value) => acc + value, 0);
    },
  },
  watch: {
    sortedLightingConfigurations() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    isEmpty,
    format,
    initData() {
      const sortList = mlfsInitConfig.map((item) => item.name);
      this.deviceTypes = Object.keys(this.sortedLightingConfigurations)
        .filter((deviceType) => !isEmpty(this.sortedLightingConfigurations[deviceType]))
        .sort((a, b) => (
          sortList.indexOf(a) - sortList.indexOf(b)
        ));
      let index = 0;
      this.rateInputs = [];
      this.deviceTypes.forEach((deviceType) => {
        this.takeRates[deviceType] = [];
        cloneDeep(this.sortedLightingConfigurations[deviceType])
          .sort((a, b) => {
            const versionA = a.version?.toUpperCase();
            const versionB = b.version?.toUpperCase();
            if (versionA > versionB) {
              return 1;
            }
            if (versionA < versionB) {
              return -1;
            }
            return 0;
          })
          .forEach((lightingConfiguration) => {
            const takeRate = lightingConfiguration.take_rates[0];
            if (!isEmpty(takeRate)) {
              this.takeRates[deviceType].push({
                ...takeRate,
                index,
                version: lightingConfiguration.version,
              });
            } else {
              this.takeRates[deviceType].push({
                id: null,
                version: lightingConfiguration.version,
                rate: 0,
                lighting_configuration_id: lightingConfiguration.id,
                created_at: null,
                index,
              });
            }
            this.rateInputs.push(takeRate?.rate || 0);
            index += 1;
          });
      });
      this.isLoading = isEmpty(this.deviceTypes);
    },
    handleChange(index, deviceType) {
      const initialValue = this.takeRates[deviceType].find((takeRate) => takeRate.index === index).rate;
      const indexToRemove = this.updatedRows.indexOf(index);
      if (initialValue === this.rateInputs[index]) {
        if (indexToRemove !== -1) {
          this.updatedRows.splice(indexToRemove, 1);
        }
      } else if (indexToRemove === -1) {
        this.updatedRows.push({ index, deviceType });
      }
    },
    reset() {
      this.deviceTypes.forEach((deviceType) => {
        this.takeRates[deviceType].forEach((takeRate) => {
          const { index } = takeRate;
          this.rateInputs[index] = takeRate.rate;
        });
      });
      this.updatedRows = [];
    },
    update() {
      this.isLoading = true;
      const updateRequests = [];
      const takeRatesToUpdate = [];
      this.updatedRows.forEach(({ index, deviceType }) => {
        const newRate = this.rateInputs[index];
        const takeRate = this.takeRates[deviceType].find((takeRateItem) => takeRateItem.index === index);
        takeRate.rate = newRate;
        // eslint-disable-next-line camelcase
        const { id, lighting_configuration_id } = takeRate;
        if (id) updateRequests.push(ldvs.updateGlobalTakeRatesByPk(id, newRate));
        else {
          updateRequests.push(utils.insertGlobalTakeRate({
            rate: newRate,
            lighting_configuration_id,
            update_ref: 'LMI_22_Q2',
          }));
        }
      });
      Promise.all(updateRequests).then((results) => {
        results.forEach((createdOrUpdatedTakeRate) => {
          takeRatesToUpdate.push(createdOrUpdatedTakeRate);
        });
        this.$emit('updateTakeRates', takeRatesToUpdate);
        this.updatedRows = [];
        this.isLoading = false;
      });
    },
  },
};
</script>
<style lang="scss" module>

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
}

.rateInput {
  width: 100%;
  padding: 4px 2px;
  border: 0;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.tablesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1000px;
}

.deviceTypeSection {
  width: calc(50% - 15px);
  @media screen and (max-width: 850px) {
    width: 100%;
  }
}

.uniqueSection {
  width: 500px;
  max-width: 100%;
}

.heading {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: 700;
  color: $title;
}
</style>
