<template>
  <div :class="$style.wrapper">
    <BaseModal
      :model-value="showEditCarImageModal"
      @update:modelValue="setShowEditCarImageModal"
    >
      <EditCarImage
        :initial-car-image="initialCarImage"
        :car-id="carId"
        :side="imageSide"
        :brand="brand"
        @carImageChanged="setNewCarImage"
      />
    </BaseModal>
    <div
      :class="[$style.image, $style.isSide]"
      :style="{ backgroundImage: `url('${carImages.side || '/images/default-car-side.jpg'}')` }"
    >
      <button
        type="button"
        :class="$style.editImageButton"
        @click="editCarImage('side')"
      >
        <PictureSvg />
      </button>
    </div>
    <div
      :class="[$style.image, $style.isFront]"
      :style="{ backgroundImage: `url('${carImages.front || '/images/default-car-front.jpg'}')` }"
    >
      <button
        type="button"
        :class="$style.editImageButton"
        @click="editCarImage('front')"
      >
        <PictureSvg />
      </button>
    </div>
    <div
      :class="[$style.image, $style.isBack]"
      :style="{ backgroundImage: `url('${carImages.back || '/images/default-car-back.jpg'}')` }"
    >
      <button
        type="button"
        :class="$style.editImageButton"
        @click="editCarImage('back')"
      >
        <PictureSvg />
      </button>
    </div>
  </div>
</template>

<script>
import cars from '@/graphql/queries/cars';
import BaseModal from '@/components/base/Modal';
import EditCarImage from '@/components/EditCarImage';
import PictureSvg from '@/assets/images/icons/picture.svg?inline';

export default {
  components: {
    BaseModal,
    EditCarImage,
    PictureSvg,
  },
  props: {
    frontImage: {
      type: String,
      default: null,
    },
    sideImage: {
      type: String,
      default: null,
    },
    backImage: {
      type: String,
      default: null,
    },
    carId: {
      type: Number,
      default: null,
    },
    brand: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      carImages: {
        side: null,
        front: null,
        back: null,
      },
      imageSide: null,
      initialCarImage: null,
      showEditCarImageModal: false,
    };
  },
  created() {
    this.carImages = {
      side: this.sideImage,
      front: this.frontImage,
      back: this.backImage,
    };
  },
  methods: {
    editCarImage(side) {
      this.imageSide = side;
      this.initialCarImage = this.carImages[side];
      this.setShowEditCarImageModal(true);
    },
    setNewCarImage(newImage, side) {
      this.isLoading = true;
      this.setShowEditCarImageModal(false);
      cars.updateCarImageByPk(this.carId, newImage, side).then(() => {
        this.carImages[side] = newImage;
        this.isLoading = false;
      });
    },
    setShowEditCarImageModal(value) {
      this.showEditCarImageModal = value;
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  $chart-height: 380px;
  $row-gar: 5px;
  $right-column-width: ($chart-height - $row-gar) / 2;
  display: grid;
  grid-template-rows: $right-column-width $right-column-width;
  grid-template-columns: 1fr $right-column-width;
  row-gap: 5px;
  column-gap: 18px;
  overflow: hidden;
  border-radius: 5px;

  .image {
    position: relative;
    width: 100%;
    background-color: $disabled;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.isSide {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &.isFront {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
      padding-top: 100%;
    }

    &.isBack {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 2;
      grid-column-end: 3;
      padding-top: 100%;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: inline-block;
      content: '';
      background: linear-gradient(
        180deg, rgba(230, 230, 230, 0.5) 0%, rgba(146, 147, 148, 0.25) 70%, rgba(15, 18, 20, 0.25) 100%
      );
    }
  }

  .editImageButton {
    @include reset;
    position: absolute;
    right: 8px;
    bottom: 4px;
    z-index: 2;
    width: 25px;
    height: 25px;
    padding: 5px;
    color: $text;
    cursor: pointer;
    background-color: $background-light;
    border-radius: 50%;

    > svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
