<template>
  <div :class="$style.wrapper">
    <slot />
  </div>
</template>

<style lang="scss" module>
.wrapper {
  display: inline-flex;

  > svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
}
</style>
