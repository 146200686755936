<template>
  <BaseLoader :is-active="isLoading">
    <h2 :class="$style.header">
      Update device #{{ deviceId }} image
    </h2>
    <div :class="$style.mainContainer">
      <div :class="$style.cropperContainer">
        <div :class="$style.cropperWithButtons">
          <cropper
            ref="cropper"
            :class="$style.cropper"
            :src="deviceImage.src"
            :stencil-props="{
              handlers: {},
              movable: false,
              resizable: false,
              aspectRatio: 16/9,
            }"
            :resize-image="{
              adjustStencil: false
            }"
            image-restriction="stencil"
          />
          <div :class="$style.cropperButtonsContainer">
            <BaseButton
              variant="secondary"
              @click="importImage"
            >
              Import image
            </BaseButton>
            <input
              ref="imageInput"
              type="file"
              accept="image/*"
              :class="$style.hiddenImageInput"
              @change="changeImage"
            >
            <BaseButton
              variant="secondary"
              @click="removeBackground"
            >
              Remove background
            </BaseButton>
            <BaseButton
              variant="secondary"
              @click="flipImage('horizontal')"
            >
              Flip horizontally
            </BaseButton>
            <BaseButton
              variant="secondary"
              @click="flipImage('vertical')"
            >
              Flip vertically
            </BaseButton>
            <BaseButton
              variant="danger"
              @click="deleteImage"
            >
              Delete
            </BaseButton>
          </div>
        </div>
        <div :class="$style.confirmButtonContainer">
          <BaseButton
            variant="secondary"
            @click="reset"
          >
            Reset
          </BaseButton>
          <BaseButton
            variant="success"
            @click="confirm"
          >
            Confirm
          </BaseButton>
        </div>
      </div>
      <template v-if="carImages.length">
        <h3 :class="$style.imageSelectorHeadline">
          Select an image to crop from :
        </h3>
        <div :class="$style.carImagesContainer">
          <img
            v-for="(carImage, index) in carImages"
            :key="index"
            :src="carImage"
            @click="selectImage(carImage)"
          >
        </div>
      </template>
    </div>
  </BaseLoader>
</template>

<script>
import ldvs from '@/graphql/queries/ldvs';
import { Storage, Auth } from 'aws-amplify';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import BaseButton from '@/components/base/Button';
import BaseLoader from '@/components/base/Loader';

export default {
  components: {
    Cropper,
    BaseButton,
    BaseLoader,
  },
  props: {
    initialDeviceImage: {
      type: String,
      default: null,
    },
    deviceId: {
      type: Number,
      default: null,
    },
    brand: {
      type: String,
      default: null,
    },
  },
  emits: {
    deviceImageChanged: null,
  },
  data() {
    return {
      deviceImage: {
        src: null,
        format: null,
        type: null,
        base64: null,
      },
      carImages: [],
      isLoading: false,
    };
  },
  watch: {
    initialDeviceImage() {
      this.deviceImage.src = this.initialDeviceImage;
      this.deviceImage.type = 'url';
    },
    deviceId() {
      this.getCarImagesFromLcs(this.deviceId);
    },
  },
  created() {
    this.getCarImagesFromLcs(this.deviceId);
  },
  methods: {
    getCarImagesFromLcs(deviceId) {
      if (deviceId) {
        this.isLoading = true;
        ldvs.getAssociatedCarsImages(deviceId).then((result) => {
          const images = [];
          result.lighting_configurations.forEach((lc) => {
            const { car } = lc;
            if (car) {
              if (car.front_image && !images.includes(car.front_image)) {
                images.push(car.front_image);
              }
              if (car.back_image && !images.includes(car.back_image)) {
                images.push(car.back_image);
              }
            }
          });
          this.carImages = images;
          this.isLoading = false;
        });
      }
    },
    flipImage(direction) {
      this.$refs.cropper.flip(direction === 'horizontal', direction === 'vertical');
    },
    importImage() {
      this.$refs.imageInput.click();
    },
    removeBackground() {
      if (!this.deviceImage.src) return;
      this.isLoading = true;
      const formData = new FormData();
      formData.append('size', 'auto');
      if (this.deviceImage.type === 'url') formData.append('image_url', this.deviceImage.src);
      else if (this.deviceImage.type === 'blob') formData.append('image_file_b64', this.deviceImage.base64);
      fetch('https://api.remove.bg/v1.0/removebg', {
        method: 'POST',
        body: formData,
        headers: { 'X-Api-Key': '41Ge7HiC1wfDS34ru9djaUF4' },
      }).then((response) => {
        if (response.status !== 200) {
          console.error('Error:', response.status, response.statusText);
          this.isLoading = false;
        } else {
          response.blob().then((blob) => {
            this.deviceImage.src = URL.createObjectURL(blob);
            this.deviceImage.type = 'blob';
            this.getBase64OfImage(blob);
            this.isLoading = false;
          });
        }
      });
    },
    getMimeType(file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i += 1) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg';
        default:
          return fallback;
      }
    },
    getBase64OfImage(blob) {
      const base64reader = new FileReader();
      base64reader.onloadend = () => {
        this.deviceImage.base64 = base64reader.result;
      };
      base64reader.readAsDataURL(blob);
    },
    changeImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.deviceImage.src) {
          URL.revokeObjectURL(this.deviceImage.src);
        }
        const blob = URL.createObjectURL(files[0]);
        this.deviceImage.src = blob;
        this.deviceImage.type = 'blob';
        const reader = new FileReader();
        reader.onload = (e) => {
          this.deviceImage.format = this.getMimeType(e.target.result, files[0].type);
        };
        reader.readAsArrayBuffer(files[0]);
        this.getBase64OfImage(files[0]);
      }
    },
    selectImage(imageUrl) {
      this.deviceImage = {
        src: imageUrl,
        type: null,
      };
    },
    confirm() {
      this.isLoading = true;
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob(async (blob) => {
        Storage.configure({ level: 'public' });
        const { identityId } = await Auth.currentCredentials();
        const path = `${this.brand}/${new Date().getTime()}`;
        await Storage.put(path, blob, { level: 'public', identityId });
        if (this.initialDeviceImage) {
          const oldImagePath = this.initialDeviceImage.slice(62);
          Storage.remove(oldImagePath);
        }
        const imageUrl = (await Storage.get(path)).replace(/\?.*/, '');
        this.$emit('deviceImageChanged', imageUrl);
        this.isLoading = false;
      }, this.deviceImage.format);
    },
    deleteImage() {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const ok = confirm('This image will be removed from this device. Are you sure ?');
      if (ok) this.$emit('deviceImageChanged', null);
    },
    reset() {
      this.$refs.cropper.reset();
      this.deviceImage = {
        src: this.initialDeviceImage,
        format: null,
        type: 'url',
        base64: null,
      };
    },
  },
};
</script>

<style lang="scss" module>

.header {
  margin: 0;
  text-align: center;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 30px;
}

.cropperContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: fit-content;
}

.cropperWithButtons {
  display: flex;
  gap: 15px;
  width: fit-content;
}

.cropper {
  width: 480px;
  height: 270px;
  cursor: move;
  background-color: $background-dark;
}

.cropperButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hiddenImageInput {
  display: none;
}

.confirmButtonContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;

  button {
    flex: 1;
  }
}

.carImagesContainer {
  display: flex;
  gap: 10px;
  width: 100%;
  padding-bottom: 10px;
  overflow: auto;

  img {
    width: 300px;
    cursor: pointer;
  }
}

.imageSelectorHeadline {
  width: 100%;
}
</style>
