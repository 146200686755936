import gql from 'graphql-tag';
import { apolloClient } from '@/vue-apollo';

export default {
  async getAllMlfs() {
    const result = await apolloClient.query({
      query: gql`query {
        main_light_functions(order_by: {id: asc}) {
          id
          lighting_device_id
          lighting_device {
            type
          }
          light_function {
            id
            name
          }
          light_source {
            id
            technology
          }
          light_functionality  {
            id
            name
          }
          optical_system {
            id
            name
          }
        }
      }`,
    });

    return result.data.main_light_functions;
  },

  async insertMlf(mlf) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $mlf: main_light_functions_insert_input!,
      ) {
        insert_main_light_functions_one(
          object: $mlf
        ) {
          id
          lighting_device_id
          light_function {
            id
            name
          }
          light_source {
            id
            name: technology
          }
          light_functionality  {
            id
            name
          }
          optical_system {
            id
            name
          }
        }
      }`,
      variables: {
        mlf,
      },
    });

    return result.data.insert_main_light_functions_one;
  },

  async updateMlfByPk(mlf) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
          $id: Int!,
          $updatedValues: main_light_functions_set_input,
        ) {
          update_main_light_functions_by_pk(
            pk_columns: {id: $id},
            _set: $updatedValues
          ) {
            id
            lighting_device_id
            light_function {
              id
              name
            }
            light_source {
              id
              name: technology
            }
            light_functionality  {
              id
              name
            }
            optical_system {
              id
              name
            }
        }
        }`,
      variables: {
        id: mlf.id,
        updatedValues: mlf,
      },
    });

    return result.data.update_main_light_functions_by_pk;
  },

  async deleteMlfByPk(id) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!) {
          delete_main_light_functions_by_pk(id: $id) {
            id
          }
        }`,
      variables: {
        id,
      },
    });

    return result;
  },
};
