<template>
  <VueFinalModal
    v-model="modelProxy"
    :classes="$style.modalContainer"
    :content-class="$style.modalContent"
  >
    <button
      :class="$style.close"
      @click="modelProxy = false"
    >
      <CrossSvg />
    </button>
    <slot />
  </VueFinalModal>
</template>

<script>
import CrossSvg from '@/assets/images/icons/cross.svg?inline';

export default {
  components: {
    CrossSvg,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': null,
  },
  computed: {
    modelProxy: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" module>
.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  max-height: 90%;
  padding: 1rem;
  margin: 0 1rem;
  overflow: auto;
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
}

.close {
  @include reset;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: $white;
  cursor: pointer;
  background: rgba($disabled, 0.8);
  border-radius: 50%;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: rgba($text, 0.7);
  }

  svg {
    width: 10px;
    height: auto;
  }
}
</style>
