<script>
export default {
  inject: ['registerDataColumn', 'forceUpdateTable'],
  props: {
    field: {
      type: String,
      default: null,
    },
    header: {
      type: null,
      default: null,
    },
    key: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    isToggable: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    headerClass: {
      type: String,
      default: null,
    },
    contentClass: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.registerDataColumn(this);
  },
  updated() {
    this.forceUpdateTable();
  },
  render() {
    return null;
  },
};
</script>
