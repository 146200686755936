import {
  ApolloClient,
  createHttpLink,
  split,
  InMemoryCache,
} from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { offsetLimitPagination, getMainDefinition } from '@apollo/client/utilities';
import { createApolloProvider } from '@vue/apollo-option';
import { logErrorMessages } from '@vue/apollo-util';

function offsetLimitRead(existing, {
  args: {
    offset = 0,
    limit = existing?.length,
  } = {},
}) {
  return existing && existing.slice(offset, offset + limit);
}

async function getHeaders() {
  const headers = {};
  headers['X-Hasura-Admin-Secret'] = 'Xw6KdWqF7MvPFl0nvtoaz6yLLg6wpUd5RvjSNO3dWtrnDqjdxYz13OUKzjYk0zyG';
  return headers;
}
const httpLink = createHttpLink({
  uri: 'https://welcomed-walrus-17.hasura.app/v1/graphql',
  fetch: async (uri, options) => {
    // eslint-disable-next-line
    options.headers = await getHeaders();
    return fetch(uri, options);
  },
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: 'wss://welcomed-walrus-17.hasura.app/v1/graphql',
  options: {
    reconnect: true,
    lazy: true,
    timeout: 30000,
    inactivityTimeout: 30000,
    connectionParams: async () => ({ headers: await getHeaders() }),
  },
});

const errorLink = onError((error) => {
  if (process.env.NODE_ENV !== 'production') {
    logErrorMessages(error);
  }
});

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        lighting_configurations: {
          ...offsetLimitPagination(),
          read: offsetLimitRead,
          keyArgs: ['where'],
        },
      },
    },
  },
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: errorLink.concat(
    split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition'
          && definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    ),
  ),
  cache,
});

// eslint-disable-next-line new-cap
const apolloProvider = new createApolloProvider({
  defaultClient: apolloClient,
});

export {
  apolloClient,
  apolloProvider,
};
