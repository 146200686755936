<template>
  <div :class="$style.row">
    <slot />
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" module>
.row {
  display: flex;
  align-items: flex-start;
}
</style>
