<template>
  <label
    :class="[
      $style.wrapper,
      {
        [$style.isActive]: isActive,
        [$style.isDark]: isDark,
        [$style.hasBackgroundHighlight]: hasBackgroundHighlight,
        [$style.rightLabel]: labelPosition === 'right',
      },
    ]"
  >
    <span
      v-if="labelPosition === 'left'"
      :class="$style.label"
    >
      <slot />
    </span>
    <BaseCheckbox
      v-model="modelProxy"
      v-model:isIndeterminate="isIndeterminateProxy"
      :class="$style.checkbox"
      :name="name"
      :value="value"
      :disabled="isDisabled"
      :is-dark="isDark"
      @change="onChange"
    />
    <span
      v-if="labelPosition === 'right'"
      :class="[
        $style.label,
        $style.rightLabel
      ]"
    >
      <slot />
    </span>
  </label>
</template>

<script>
import BaseCheckbox from '@/components/base/Checkbox';

export default {
  components: {
    BaseCheckbox,
  },
  props: {
    name: {
      type: null,
      default: undefined,
    },
    value: {
      type: null,
      default: undefined,
    },
    modelValue: {
      type: null,
      default: undefined,
    },
    isIndeterminate: {
      type: null,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: null,
      default: undefined,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    hasBackgroundHighlight: {
      type: Boolean,
      default: false,
    },
    labelPosition: {
      type: String,
      default: 'left',
    },
  },
  emits: {
    'update:modelValue': null,
    'update:isIndeterminate': null,
    change: null,
  },
  computed: {
    modelProxy: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    isIndeterminateProxy: {
      get() {
        return this.isIndeterminate;
      },
      set(value) {
        this.$emit('update:isIndeterminate', value);
      },
    },
    isActive() {
      return this.modelValue === true || this.modelValue.includes(this.value);
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', e);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.hasBackgroundHighlight {
    padding: 5px 7px 5px 10px;
    background-color: rgba($background-light, 0);
    border-radius: 5px;

    &.isActive {
      background-color: $background-light;
    }
  }

  &.isActive {

    .label {
      font-weight: 600;
    }
  }

  &.rightLabel {
    justify-content: unset;
  }
}

.label {
  font-size: 16px;
  color: $title;
  opacity: 0.8;

  &.rightLabel {
    margin-left: 10px;
  }
}

.checkbox {
  margin-left: 10px;
}
</style>
