<template>
  <TippyWrapper
    ref="tippyWrapper"
    :class="[
      $style.tippyWrapper,
      {
        [$style.isBlock]: $style.isBlock,
      },
    ]"
    :popper-options="popperOptions"
    :append-to="getAppendTo"
    :offset="[0, 0]"
    :arrow="false"
    theme="multiselect-dropdown"
    placement="bottom"
    trigger="click"
    tag="div"
    content-tag="div"
    interactive
    @show="onShow"
    @shown="onShown"
    @hide="onHide"
  >
    <template #default>
      <div
        :class="[
          $style.multiselect,
          {
            [$style.hasError]: hasError,
            [$style.isBlock]: isBlock,
          },
        ]"
        v-bind="$attrs"
        :data-placement="isActive ? placement : null"
        tabindex="0"
      >
        <div :class="$style.valueWrapper">
          <template v-if="hasValue">
            <div
              v-if="isSingle"
              :class="[$style.value, $style.isSingle]"
            >
              {{ selectedSingle?.label }}
            </div>
            <div
              v-else
              :class="[$style.value, $style.isMultiple]"
            >
              <BaseLabel
                v-for="option in selectedOptions"
                :key="option.value"
                color="lime"
              >
                {{ option.label }}
              </BaseLabel>
            </div>
          </template>
          <div
            v-else
            :class="$style.placeholder"
          >
            {{ placeholder }}
          </div>
        </div>
        <span :class="$style.iconWrapper">
          <ChevronSvg />
        </span>
      </div>
    </template>

    <template #content>
      <MultiselectSearch
        v-if="isSearchable"
        v-model.trim="searchTerm"
        :class="$style.search"
      />
      <BaseButton
        v-if="!noReset"
        variant="secondary"
        :class="$style.resetButton"
        @click="reset"
      >
        Reset
      </BaseButton>
      <div :class="$style.options">
        <div :class="$style.optionsInner">
          <template v-if="isSingle">
            <BaseLabeledRadio
              v-for="option in normalizedOptionsWithSearch"
              :key="option.value"
              v-model="modelProxy"
              :value="option.value"
              is-dark
              @change="hideDropdown"
            >
              {{ option.label }}
            </BaseLabeledRadio>
          </template>
          <template v-else>
            <BaseLabeledCheckbox
              v-for="option in selectedOptionsWithSearch"
              :key="option.value"
              v-model="modelProxy"
              :value="option.value"
              is-dark
            >
              {{ option.label }}
            </BaseLabeledCheckbox>
            <BaseLabeledCheckbox
              v-for="option in unselectedOptionsWithSearch"
              :key="option.value"
              v-model="modelProxy"
              :value="option.value"
              is-dark
            >
              {{ option.label }}
            </BaseLabeledCheckbox>
          </template>
        </div>
      </div>
    </template>
  </TippyWrapper>
</template>

<script>
import { Tippy as TippyWrapper } from 'vue-tippy';
import { isObject, difference, isArray } from 'lodash';
import BaseLabeledCheckbox from '@/components/base/LabeledCheckbox';
import BaseLabeledRadio from '@/components/base/LabeledRadio';
import BaseLabel from '@/components/base/Label';
import BaseButton from '@/components/base/Button';
import ChevronSvg from '@/assets/images/icons/chevron.svg?inline';
import MultiselectSearch from './MultiselectSearch';

const sameWidthModifier = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${
      state.elements.reference.offsetWidth
    }px`;
  },
};

export default {
  components: {
    TippyWrapper,
    MultiselectSearch,
    BaseLabel,
    BaseLabeledCheckbox,
    BaseLabeledRadio,
    ChevronSvg,
    BaseButton,
  },
  props: {
    modelValue: {
      type: null,
      default: null,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    placeholder: {
      type: String,
      default: 'Select...',
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    noReset: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': null,
  },
  data() {
    return {
      isActive: false,
      placement: '',
      popperOptions: {
        modifiers: [sameWidthModifier],
      },
      selectedCache: new Map(),
      tippyInstance: null,
      searchTerm: '',
    };
  },
  computed: {
    modelProxy: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    isSingle() {
      return !isArray(this.modelValue);
    },
    hasValue() {
      return this.isSingle ? this.modelValue != null : this.modelValue.length !== 0;
    },
    selectedSingle() {
      return this.selectedCache.get(this.modelValue);
    },
    normalizedOptions() {
      return this.options.map((option) => (
        isObject(option)
          ? option
          : (
            {
              value: option,
              label: option,
            }
          )
      ));
    },
    selectedOptions() {
      if (this.isSingle) {
        return this.modelValue == null ? [] : [this.modelValue];
      }
      return this.modelValue.map((item) => this.selectedCache.get(item));
    },
    unselectedOptions() {
      return this.normalizedOptions.filter((option) => !this.selectedCache.has(option.value));
    },
    hasSearchTerm() {
      return this.searchTerm && this.searchTerm !== '';
    },
    searchRegExp() {
      return new RegExp(this.searchTerm, 'i');
    },
    normalizedOptionsWithSearch() {
      if (this.hasSearchTerm) {
        return this.searchInOptions(this.normalizedOptions, this.searchRegExp);
      }

      return this.normalizedOptions;
    },
    selectedOptionsWithSearch() {
      if (this.hasSearchTerm) {
        return this.searchInOptions(this.selectedOptions, this.searchRegExp);
      }

      return this.selectedOptions;
    },
    unselectedOptionsWithSearch() {
      if (this.hasSearchTerm) {
        return this.searchInOptions(this.unselectedOptions, this.searchRegExp);
      }

      return this.unselectedOptions;
    },
  },
  watch: {
    modelValue(newValue, prevValue) {
      this.updateSelectedCache(prevValue, newValue);
    },
    options() {
      this.updateSelectedCache(this.modelValue, this.modelValue);
    },
  },
  created() {
    this.updateSelectedCache([], this.modelValue);
  },
  updated() {
    // Fix bug with scrolling container when it renders being scrolled to bottom
    if (this.tippyInstance) {
      this.tippyInstance.popper.children[0].scrollTop = 0;
    }
  },
  methods: {
    getAppendTo() {
      return this.$root.$el.parentElement;
    },
    onShow() {
      this.isActive = true;
    },
    onShown(tippy) {
      this.placement = tippy.popperInstance.state.placement;
      this.tippyInstance = tippy;
    },
    onHide() {
      this.isActive = false;
      this.tippyInstance = null;
    },
    updateSelectedCache(prevValue, newValue) {
      if (isArray(newValue)) {
        const removed = difference(prevValue, newValue);

        removed.forEach((item) => {
          if (this.selectedCache.has(item)) this.selectedCache.delete(item);
        });

        const added = difference(newValue, prevValue);

        added.forEach((item) => {
          this.selectedCache.set(item, this.normalizedOptions.find((option) => option.value === item));
        });
      } else {
        this.selectedCache.clear();
        this.selectedCache.set(newValue, this.normalizedOptions.find((option) => option.value === newValue));
      }
    },
    searchInOptions(options, searchRegExp) {
      return options.filter((option) => option.label.match(searchRegExp));
    },
    hideDropdown() {
      this.$refs.tippyWrapper.hide();
    },
    reset() {
      this.$emit('update:modelValue', []);
      return true;
    },
  },
};
</script>

<style lang="scss">
/* stylelint-disable selector-class-pattern */
.tippy-box[data-theme~='multiselect-dropdown'] {
  position: relative;
  min-width: 40px;
  max-width: none !important;
  overflow: hidden;
  line-height: normal;
  background-color: #fff;
  border: 2px solid $border;
  border-radius: 5px;
  outline: 0;
  transition-property: transform, visibility, opacity;

  &[data-animation=fade][data-state=hidden] {
    opacity: 0;
  }

  &[data-placement^='bottom'] {
    top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  }

  &[data-placement^='top'] {
    bottom: -2px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.15);
  }
}
/* stylelint-enable */
</style>

<style lang="scss" module>
.tippyWrapper {

  &.isBlock {
    display: flex;
  }
}

.resetButton {
  width: calc(100% - 16px);
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 8px;
}

.multiselect {
  position: relative;
  display: inline-flex;
  align-items: stretch;
  padding: 6px 6px 6px 8px;
  cursor: pointer;
  background-color: $white;
  border: 2px solid $border;
  border-radius: 5px;
  transition: border-color 0.2s ease-in-out;

  &.hasError {
    border-color: #f39d9d;
  }

  &.isBlock {
    flex-grow: 1;
  }

  &[data-placement^='bottom'] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &[data-placement^='top'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .valueWrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    margin-right: 6px;
  }

  .placeholder,
  .value {
    flex-shrink: 1;
    min-width: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
  }

  .placeholder {
    padding-top: 3px;
    padding-bottom: 3px;
    color: rgba($text, 0.5);
  }

  .value {
    @include truncate(100%);
    color: $title;

    &.isSingle {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    &.isMultiple {
      padding-top: 2px;
      padding-bottom: 2px;

      > * {

        &:not(:first-child) {
          margin-left: 6px;
        }
      }
    }
  }

  .iconWrapper {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    color: rgba($title, 0.7);
  }
}

.search {
  margin: 8px 8px 0;
}

.options {
  max-height: 200px;
  overflow: auto;

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:first-child {

    .optionsInner {
      padding-top: 8px;
    }
  }
}

.optionsInner {
  padding-right: 8px;
  padding-bottom: 8px;
  padding-left: 8px;

  > * {

    &:not(:first-child) {
      margin-top: 5px;
    }
  }
}
</style>
