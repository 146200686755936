<template>
  <BaseChart
    ref="chart"
    :labels="labels"
    :datasets="datasets"
    :aspect-ratio="aspectRatio"
    :type="type"
    :has-big-heading="hasBigHeading"
    :has-close-button="hasCloseButton"
    :has-units-selector="hasUnitsSelector"
    :has-cagr="hasCagr"
    :options="options"
    x-label="year"
    y-label="volumes"
  >
    <template
      v-if="hasHeading"
      #heading
    >
      <slot name="heading" />
    </template>
    <template
      v-if="hasActions"
      #actions
    >
      <slot name="actions" />
    </template>
  </BaseChart>
</template>

<script>
import BaseChart from '@/components/base/Chart';
import isVNodeEmpty from '@/utils/isVNodeEmpty';

export default {
  components: {
    BaseChart,
  },
  props: {
    labels: {
      type: Array,
      default: undefined,
    },
    datasets: {
      type: Array,
      default: () => ([]),
    },
    aspectRatio: {
      type: Number,
      default: 2,
    },
    type: {
      type: String,
      default: 'line',
    },
    hasBigHeading: {
      type: Boolean,
      default: false,
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
    hasUnitsSelector: {
      type: Boolean,
      default: false,
    },
    suggestedYMax: {
      type: Number,
      default: undefined,
    },
    hasCagr: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasHeading() {
      return !isVNodeEmpty(this.$slots.heading?.());
    },
    hasActions() {
      return !isVNodeEmpty(this.$slots.actions?.());
    },
    options() {
      return {
        scales: {
          y: {
            beginAtZero: true,
            suggestedMax: this.suggestedYMax,
            ticks: {
              callback(value) {
                const absValue = Math.abs(value);

                if (absValue >= 1000000000) return `${value / 1e9}B`;

                if (absValue >= 1000000) return `${value / 1e6}M`;

                if (absValue >= 1000) return `${value / 1e3}K`;

                return value;
              },
            },
          },
        },
      };
    },
  },
};
</script>
