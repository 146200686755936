<template>
  <BaseDropdownActionsList>
    <BaseDropdownActionCheckbox
      v-for="(col, colIndex) in toggableDataColumns"
      :key="getColumnKey(col, colIndex)"
      v-model="visibilityToggableColumnsProxy"
      :value="getColumnId(col)"
    >
      {{ getColumnLabel(col) }}
    </BaseDropdownActionCheckbox>
  </BaseDropdownActionsList>
</template>

<script>
import {
  DropdownActionsList as BaseDropdownActionsList,
  DropdownActionCheckbox as BaseDropdownActionCheckbox,
} from '@/components/base/Dropdown';
import { getColumnKey, getColumnId, getColumnLabel } from './utils';

export default {
  components: {
    BaseDropdownActionsList,
    BaseDropdownActionCheckbox,
  },
  props: {
    toggableDataColumns: {
      type: Array,
      default: () => ([]),
    },
    visibilityToggableColumns: {
      type: Array,
      default: () => ([]),
    },
  },
  emits: {
    'update:visibilityToggableColumns': null,
  },
  computed: {
    visibilityToggableColumnsProxy: {
      get() {
        return this.visibilityToggableColumns;
      },
      set(value) {
        this.$emit('update:visibilityToggableColumns', value);
      },
    },
  },
  methods: {
    getColumnKey,
    getColumnId,
    getColumnLabel,
  },
};
</script>

<style lang="scss" module>
.list {
  padding: 12px;
  margin: 0;
  list-style: none;

  li {

    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}
</style>
