import gql from 'graphql-tag';
import { apolloClient } from '@/vue-apollo';

export default {
  async getAutomakers() {
    const result = await apolloClient.query({
      query: gql`query {
        automakers(order_by: {name: asc}) {
          id
          name
          value_chain_level
          logo
          region
          country
          city
          longitude
          latitude
          created_at
          created_by
          updated_at
          brands {
            id
            name
          }
        }
      }`,
    });

    return result.data.automakers;
  },

  async getAutomakersNamesAndIds() {
    const result = await apolloClient.query({
      query: gql`query {
        automakers(order_by: {name: asc}) {
          id
          name
        }
      }`,
    });

    return result.data.automakers;
  },

  async insertAutomaker(automaker) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $automaker: automakers_insert_input!,
      ) {
        insert_automakers_one(
          object: $automaker
        ) {
          id
          name
          value_chain_level
          region
          country
          city
          latitude
          longitude
          created_by
        }
      }`,
      variables: {
        automaker,
      },
    });

    return result.data.insert_automakers_one;
  },

  async updateAutomakersByPk(automaker) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $updatedValues: automakers_set_input,
      ) {
        update_automakers_by_pk(
          pk_columns: {id: $id},
          _set: $updatedValues
        ) {
          id
          name
          value_chain_level
          region
          country
          city
          latitude
          longitude
          created_by
        }
      }`,
      variables: {
        id: automaker.id,
        updatedValues: automaker,
      },
    });

    return result;
  },

  async deleteAutomakerByPk(id) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!) {
        delete_automakers_by_pk(id: $id) {
          id
        }
      }`,
      variables: {
        id,
      },
    });

    return result;
  },
};
