import { toLower } from 'lodash';
import CryptoJS from 'crypto-js';

const code = (
  brandId,
  modelId,
  bodyId,
  countryId,
  lastParam,
) => {
  let ref = `${brandId}${modelId}${bodyId}${countryId}${lastParam}`;
  ref = toLower(ref.replace(/\s/g, ''));
  return CryptoJS.SHA256(`4Rdf092bbe0X4P06${ref}`);
};

export default function getLightingClusterReferences(
  brandId,
  modelId,
  bodyId,
  countryId,
  generation,
  projectCode,
) {
  return {
    lightingClusterReference: code(brandId, modelId, bodyId, countryId, projectCode).toString(),
    lightingClusterReference2: code(brandId, modelId, bodyId, countryId, generation).toString(),
  };
}
