<template>
  <div :class="$style.header">
    <div :class="$style.headRow">
      <div :class="$style.logo">
        <img
          :src="carData.brand.logo"
          :alt="`${carData.brand.name} logo`"
        >
      </div>
      <div>
        <h1>
          {{ carData.brand.name }} {{ carData.commercial_name }}
        </h1>
        <span
          v-if="otherNamesString"
          :class="$style.otherNames"
        >
          {{ otherNamesString }}
        </span>
      </div>
    </div>
    <div :class="$style.info">
      <div :class="$style.infoBlock">
        <span :class="$style.infoBlockValue">
          <BaseLabel
            :class="$style.infoBlockLabel"
            color="lime"
          >
            Gen {{ carData.generation }}
          </BaseLabel>
          <BaseLabel
            :class="$style.infoBlockLabel"
            color="orange"
          >
            Phase {{ carData.phase }}
          </BaseLabel>
          <BaseLabel
            :class="$style.infoBlockLabel"
            color="violet"
          >
            {{ carData.car_body?.name }}
          </BaseLabel>
          <BaseLabel
            :class="$style.infoBlockLabel"
            color="cherry"
          >
            Segment {{ carData.segment }}
          </BaseLabel>
          <BaseLabel
            :class="$style.infoBlockLabel"
            color="aqua"
          >
            {{ carData.country.region }}
            <img
              :src="`/images/icons/flags/${carData.country.code.toLowerCase()}.png`"
              :alt="carData.country.code.toUpperCase()"
              :class="$style.flag"
            >
          </BaseLabel>
        </span>
      </div>

      <div :class="$style.infoBlock">
        <span :class="$style.infoBlockHeading">
          <TableJigsawSvg />
        </span>
        <span :class="$style.infoBlockValue">
          {{ carData.project_code }}
        </span>
      </div>

      <div :class="$style.infoBlock">
        <span :class="$style.infoBlockHeading">
          <TablePowertrainSvg />
        </span>
        <span :class="$style.infoBlockValue">
          {{ carData.power_train }}
        </span>
      </div>

      <div :class="$style.infoBlock">
        <span :class="$style.infoBlockHeading">
          <TableDateStartSvg />
        </span>
        <span :class="$style.infoBlockValue">
          SOP {{ carSop }} – EOP {{ carEop }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import BaseLabel from '@/components/base/Label';
import TableJigsawSvg from '@/assets/images/icons/table-jigsaw.svg?inline';
import TablePowertrainSvg from '@/assets/images/icons/table-powertrain.svg?inline';
import TableDateStartSvg from '@/assets/images/icons/table-date-start.svg?inline';

export default {
  components: {
    BaseLabel,
    TableJigsawSvg,
    TablePowertrainSvg,
    TableDateStartSvg,
  },
  props: {
    carData: {
      type: Object,
      default: null,
    },
    volumesData: {
      type: Object,
      default: null,
    },
    carNames: {
      type: Object,
      default: null,
    },
    carSop: {
      type: String,
      default: '',
    },
    carEop: {
      type: String,
      default: '',
    },
  },
  computed: {
    otherNamesString() {
      if (this.carNames == null) return null;

      const names = Object.keys(this.carNames);
      if (names.length < 2) return null;

      const otherNames = names.filter((name) => name !== this.carData.commercial_name);
      const strings = otherNames.map((name) => `${name} in ${this.carNames[name].join('/')}`);
      return `(also known as: ${strings.join(', ')})`;
    },
  },
  methods: {
    format,
  },
};
</script>

<style lang="scss" module>
.header {
  padding-left: 15px;
}

.headRow {
  display: flex;
  align-items: center;

  .logo {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    margin-right: 15px;
    overflow: hidden;
    background: $background-light;
    border-radius: 5px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  h1 {
    display: inline-block;
    margin: 0;
    margin-right: 10px;
    font-size: 45px;
    color: $title;
  }

  .otherNames {
    font-size: 18px;
    color: $text;
    vertical-align: baseline;
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-left: -18px;
}

.infoBlock {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 18px;
}

.infoBlockHeading {
  display: inline-flex;
  align-items: center;
  margin-right: 5px;

  svg {
    margin-top: -2px;

    + .infoBlockTitle {
      margin-left: 5px;
    }
  }
}

.infoBlockTitle {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
}

.infoBlockValue {
  display: inline-flex;
  font-size: 13px;
  color: $text;
  letter-spacing: -0.01px;
}

.infoBlockLabel {

  &:not(:first-child) {
    margin-left: 5px;
  }

  .flag {
    margin-bottom: -1px;
    margin-left: 3px;
  }
}
</style>
