<template>
  <FormBox
    :class="$style.formBox"
    heading="Add phase"
  >
    <BaseField
      :class="$style.field"
    >
      <template #label>
        SOP
      </template>
      <BaseInput
        v-model="sop"
        type="date"
        :min="clusterSop"
        :max="clusterEop"
      />
    </BaseField>

    <BaseField
      :class="$style.field"
    >
      <template #label>
        EOP
      </template>
      <BaseInput
        v-model="eop"
        type="date"
        :min="clusterSop"
        :max="clusterEop"
        :disabled="isLast"
      />
    </BaseField>

    <template #footer>
      <div :class="$style.buttonsContainer">
        <BaseButton
          variant="success"
          type="addPhase"
          is-block
          @click="cancel"
        >
          Cancel
        </BaseButton>
        <BaseButton
          variant="success"
          type="addPhase"
          is-block
          @click="addPhase"
        >
          Submit
        </BaseButton>
      </div>
    </template>
  </FormBox>
</template>
<script>
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseInput from '@/components/base/Input';
import BaseButton from '@/components/base/Button';
import moment from 'moment';
import { cloneDeep } from 'lodash';

export default ({
  components: {
    FormBox,
    BaseField,
    BaseInput,
    BaseButton,
  },
  props: {
    car: {
      type: Object,
      default: null,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    clusterSop: {
      type: String,
      default: null,
    },
    clusterEop: {
      type: String,
      default: null,
    },
  },
  emits: {
    closeAddPhaseBox: null,
    phaseAdded: null,
  },
  data() {
    return {
      sop: null,
      eop: null,
    };
  },
  async created() {
    const sopDate = moment(this.isFirst ? this.clusterSop : this.car.sop, 'YYYY-MM-DD');
    const eopDate = moment(this.isLast ? this.clusterEop : this.car.eop, 'YYYY-MM-DD');
    const duration = moment.duration(eopDate.diff(sopDate)).asDays();
    const halfDuration = Math.floor(duration * 0.55);
    this.sop = moment(sopDate).add(halfDuration, 'days').format('YYYY-MM-DD');
    this.eop = this.isLast ? this.clusterEop : this.car.eop;
  },
  methods: {
    async addPhase() {
      const newCar = cloneDeep(this.car);
      delete newCar.volumes_infos;
      newCar.phase += 1;
      newCar.front_image = null;
      newCar.back_image = null;
      newCar.side_image = null;
      newCar.sop = this.sop;
      newCar.eop = this.isLast ? null : this.eop;

      delete newCar.id;
      // eslint-disable-next-line no-underscore-dangle
      delete newCar.__typename;
      delete newCar.country;
      delete newCar.brand;
      delete newCar.car_body;
      delete newCar.lighting_configurations;

      this.$emit('phaseAdded', newCar);
    },
    cancel() {
      this.$emit('closeAddPhaseBox');
    },
  },
});
</script>
<style lang="scss" module>
.formBox {
  width: 100%;
}

.field:not(:first-child) {
  margin-top: 10px;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
}
</style>
