<template>
  <span
    :class="[
      $style.label,
      {
        [$style[`color${capitalize(color)}`]]: color,
      },
    ]"
  >
    <slot />
  </span>
</template>

<script>
import { capitalize } from 'lodash';

const possibleColors = ['aqua', 'barley', 'lime', 'orange', 'violet', 'purple', 'cherry'];

export default {
  props: {
    color: {
      type: String,
      validator(value) {
        return possibleColors.includes(value);
      },
      default: null,
    },
  },
  methods: {
    capitalize,
  },
};
</script>

<style lang="scss" module>
.label {
  display: inline-block;
  padding: 0 7px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.7;
  color: $title;
  background: $background-light;
  border-radius: 5px;

  &.colorAqua {
    color: #129cbe;
    background: #aee4f2;
  }

  &.colorBarley {
    color: #6f6726;
    background: #f4ebac;
  }

  &.colorLime {
    color: #5b7712;
    background: #d9f78e;
  }

  &.colorOrange {
    color: #b26a00;
    background: #ffd496;
  }

  &.colorViolet {
    color: #425598;
    background: #d0daff;
  }

  &.colorPurple {
    color: #7c52cb;
    background: #e3d5ff;
  }

  &.colorCherry {
    color: #963959;
    background: #ffc4d9;
  }
}
</style>
