<template>
  <div :class="$style.mainContainer">
    <h2 :class="$style.heading">
      Copy lighting functions to device #{{ deviceId }}
    </h2>
    <div :class="$style.filterContainer">
      <h3>Segment :</h3>
      <BaseButton
        v-for="segment in segments"
        :key="segment"
        :variant="selectedSegment === segment ? 'success' : 'secondary'"
        @click="filterModel(segment)"
      >
        {{ segment }}
      </BaseButton>
    </div>
    <div :class="$style.filterContainer">
      <h3>Models :</h3>
      <BaseButton
        v-for="model in models"
        :key="model"
        :variant="selectedModel === model ? 'success' : 'secondary'"
        @click="filterDevices(model)"
      >
        {{ model }}
      </BaseButton>
    </div>
    <template
      v-if="!isEmpty(filteredDevices)"
    >
      <h3>Select device to copy from ({{ deviceType }}):</h3>
      <div
        :class="$style.devicesContainer"
      >
        <div
          v-for="device in filteredDevices"
          :key="device.id"
          :class="$style.deviceContainer"
        >
          <div :class="$style.deviceContainerTitle">
            <BaseLabel
              color="barley"
            >
              {{ deviceLeftReference(device) }}
            </BaseLabel>
            <BaseLabel
              color="purple"
            >
              {{ device.part_number }}
            </BaseLabel>
          </div>
          <div :class="$style.deviceImageContainer">
            <img
              :src="device.front_image
                || getDeviceDefaultImage()"
              :class="$style.deviceImage"
            >
            <BaseLabel
              color="lime"
              :class="$style.deviceId"
            >
              {{ device.id }}
            </BaseLabel>
          </div>
          <h3>Device #{{ device.id }} lighting functions :</h3>
          <SimpleMlfsTable
            :mlfs="device.main_light_functions"
          />
          <div :class="$style.buttonsContainer">
            <BaseButton
              variant="secondary"
              @click="cancel"
            >
              Cancel
            </BaseButton>
            <BaseButton
              variant="success"
              @click="copyMlfs(device.id)"
            >
              Copy
            </BaseButton>
          </div>
        </div>
      </div>
    </template>
    <p
      v-else-if="isEmpty(filteredDevices) && selectedModel"
      :class="$style.emptyDataText"
    >
      This model has no {{ deviceType }}.
    </p>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash';
import ldvs from '@/graphql/queries/ldvs';
import BaseLabel from '@/components/base/Label';
import SimpleMlfsTable from '@/components/tables/SimpleMlfsTable';
import BaseButton from '@/components/base/Button';

export default {
  components: {
    BaseLabel,
    SimpleMlfsTable,
    BaseButton,
  },
  props: {
    deviceId: {
      type: Number,
      default: null,
    },
    deviceType: {
      type: String,
      default: null,
    },
    brandId: {
      type: Number,
      default: null,
    },
  },
  emits: {
    cancelMlfsCopy: null,
    copyMlfs: null,
  },
  data() {
    return {
      segments: [],
      allDevices: [],
      filteredDevices: [],
      selectedDeviceId: null,
      models: [],
      selectedModel: null,
      selectedSegment: null,
    };
  },
  created() {
    ldvs.getAllLdvsByCarBrand(this.brandId).then((result) => {
      this.allDevices = result;
      const lightingConfigurations = result
        .map((item) => item.lighting_configurations)
        .flat(1);
      let segments = lightingConfigurations
        .map((item) => item.car.segment)
        .filter((item) => item);
      segments = [...new Set(segments)]
        .sort();
      segments.push('All');
      this.segments = segments;

      let models = lightingConfigurations
        .map((item) => item.car.model)
        .filter((item) => item);
      models = [...new Set(models)]
        .sort();
      this.models = models;
    });
  },
  methods: {
    isEmpty,
    filterModel(segment) {
      this.selectedSegment = segment;
      let models = cloneDeep(this.allDevices)
        .map((item) => item.lighting_configurations)
        .flat(1);

      if (segment === 'All') {
        models = models
          .filter((item) => item);
      } else {
        models = models
          .filter((item) => item.car.segment === segment);
      }
      models = models
        .map((item) => item.car.model);
      models = [...new Set(models)]
        .sort();
      this.models = models;
    },
    filterDevices(model) {
      this.selectedModel = model;
      this.filteredDevices = this.allDevices.filter(
        (device) => device.lighting_configurations
          .find((lc) => lc.car.model === model) && device.type === this.deviceType,
      );
      this.selectedDeviceId = null;
    },
    copyMlfs(deviceId) {
      const mainLightFunctionsToDuplicate = this.filteredDevices.find(
        (device) => device.id === deviceId,
      ).main_light_functions
        .map((mlf) => ({
          lighting_device_id: this.deviceId,
          light_function_id: mlf.light_function.id,
          light_source_id: mlf.light_source.id,
          light_functionality_id: mlf.light_functionality.id,
          optical_system_id: mlf.optical_system.id,
        }));
      this.$emit('copyMlfs', mainLightFunctionsToDuplicate);
    },
    cancel() {
      this.$emit('cancelMlfsCopy');
    },
    deviceLeftReference(device) {
      const [car] = device.lighting_configurations;
      return `${car.car.project_code}-G${car.car.generation}\
        P${car.car.phase}/${device.type}-${device.order_in_row}-${device.position}`;
    },
    getDeviceDefaultImage() {
      switch (this.deviceType) {
        case 'HL':
          return '/images/default-hl-front.jpg';
        case 'RL':
          return '/images/default-rl-front.jpg';
        case 'CHMSL':
          return '/images/default-chmsl-front.jpg';
        case 'AuxHL':
          return '/images/default-auxhl-front.jpg';
        case 'AuxRL':
          return '/images/default-auxrl-front.jpg';
        default:
          return '/images/placeholder-image.png';
      }
    },
  },
};
</script>

<style lang="scss" module>

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 20px;
}

.heading {
  margin: 0;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;

  h3 {
    margin: 0;
  }
}

.emptyDataText {
  text-align: center;
}

.devicesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
  justify-content: space-between;
  min-width: 1000px;
}

.deviceContainer {
  width: calc(50% - 10px);
  padding: 20px;
  border: 1px solid #e0e0e0;
}

.deviceContainerTitle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.deviceImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.deviceImage {
  width: 300px;
}

.deviceId {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #fff;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;

  button {
    width: 30%;
  }
}
</style>
