<script>
export default {
  inject: ['registerActionsColumn'],
  mounted() {
    this.registerActionsColumn(this);
  },
  render() {
    return null;
  },
};
</script>
