<template>
  <div :class="$style.hero">
    <ConstellationBackground :class="$style.background" />
    <div :class="$style.inner">
      <h1 :class="$style.title">
        {{ title }}
      </h1>
      <p :class="$style.lead">
        {{ lead }}
      </p>
    </div>
  </div>
</template>

<script>
import { isFunction } from 'lodash';
import ConstellationBackground from '@/components/ConstellationBackground';

export default {
  components: {
    ConstellationBackground,
  },
  computed: {
    title() {
      const data = this.$route.meta?.hero?.title;
      return isFunction(data) ? data() : data;
    },
    lead() {
      const data = this.$route.meta?.hero?.lead;
      return isFunction(data) ? data() : data;
    },
  },
};
</script>

<style lang="scss" module>
.hero {
  position: relative;
  padding-top: $header-height;
  margin-top: -$header-height;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.inner {
  padding: 90px 0;
  text-align: center;
}

.title {
  margin: 0;
  font-size: 45px;
  font-weight: 700;
  color: $white;
}

.lead {
  max-width: 500px;
  margin: 10px auto 0;
  font-size: 18px;
  color: $title-dark;
}
</style>
