<template>
  <BaseModal
    v-if="selectedLightingDeviceObject"
    :model-value="showEditDeviceImageModal"
    @update:modelValue="setShowEditDeviceImageModal"
  >
    <EditDeviceImage
      :initial-device-image="deviceImage"
      :device-id="selectedLightingDeviceId"
      :brand="brandName"
      @deviceImageChanged="setNewDeviceImage"
    />
  </BaseModal>
  <BaseModal
    v-if="selectedLightingDeviceObject"
    :model-value="showCopyMlfsModal"
    @update:modelValue="setShowCopyMlfsModal"
  >
    <CopyDeviceMlfs
      :device-id="selectedLightingDeviceId"
      :device-type="selectedLightingDeviceObject.type"
      :brand-id="brandId"
      @cancelMlfsCopy="setShowCopyMlfsModal(false)"
      @copyMlfs="copyMlfs($event)"
    />
  </BaseModal>
  <BaseModal
    :model-value="showEditDeviceModal"
    @update:modelValue="setShowEditDeviceModal"
  >
    <EditDevice
      :device="selectedLightingDeviceObject"
      :car-id="carId"
      :initial-comment="latestComment"
      :lighting-config-id="lightingConfiguration.id"
      :mode="editDeviceModalMode"
      @updateDevice="setNewDeviceProperties"
      @createDevice="attachNewDevice"
      @cancelUpdate="setShowEditDeviceModal(false)"
    />
  </BaseModal>
  <div
    ref="card"
    :class="$style.card"
  >
    <BaseLoader
      :is-active="isLoading"
      :class="$style.mainLoader"
    >
      <template v-if="finishedInitialSetup">
        <div :class="$style.header">
          <div :class="$style.brandLogo">
            <img
              :src="brandLogo"
              :alt="brandName"
            >
          </div>
          <div
            v-if="!['CHMSL','AuxHL','AuxRL'].includes(selectedLightingDeviceObject?.type)"
            :class="$style.supplierLogo"
          >
            <img
              :src="selectedLightingDeviceObject?.supplier?.logo
                || 'https://lightingmi.s3.eu-central-1.amazonaws.com/logos/default.png'"
              :alt="selectedLightingDeviceObject?.supplier?.name"
            >
          </div>

          <div :class="[$style.infoRow, $style.isTop]">
            <div :class="$style.infoRowSection">
              <BaseLabel
                v-if="lightingConfiguration.take_rates[0]"
                :class="$style.label"
                color="orange"
              >
                {{ Math.round(lightingConfiguration.take_rates[0].rate*100) }} %
              </BaseLabel>
              <BaseLabel
                :class="$style.label"
                color="cherry"
              >
                X {{ selectedLightingDeviceObject?.parts_per_car }}
              </BaseLabel>
              <BaseLabel
                v-if="!['CHMSL','AuxHL','AuxRL'].includes(selectedLightingDeviceObject?.type)"
                :class="$style.label"
                color="aqua"
              >
                {{ selectedLightingDeviceObject?.country?.region || '?' }}
                <img
                  v-if="selectedLightingDeviceObject?.country"
                  :src="`/images/icons/flags/${selectedLightingDeviceObject?.country?.code?.toLowerCase()}.png`"
                  :alt="selectedLightingDeviceObject?.country?.code"
                  :class="$style.flag"
                >
              </BaseLabel>
            </div>
          </div>
        </div>
        <div
          v-if="selectedLightingDeviceObject"
          :class="$style.images"
        >
          <img :src="selectedLightingDeviceObject?.front_image || defaultDeviceImage">
          <div :class="$style.greyFilter" />
          <button
            v-if="selectedLightingDeviceObject"
            type="button"
            :class="$style.editImageButton"
            @click="editDeviceImage"
          >
            <PictureSvg />
          </button>
          <BaseLabel
            v-if="selectedLightingDeviceObject"
            :class="$style.typeLabelWrapper"
            color="lime"
          >
            {{ selectedLightingDeviceId }}
          </BaseLabel>
        </div>
        <div :class="[$style.infoRow, $style.isBottom]">
          <BaseLoader
            :is-active="ldvsListIsLoading"
            :class="$style.deviceSelectContainer"
          >
            <div :class="$style.infoRowSection">
              <BaseMultiselect
                v-if="allLightingDeviceIds.length"
                :model-value="selectedLightingDeviceId"
                :options="allLightingDeviceIds"
                is-searchable
                is-block
                no-reset
                placeholder="Attach a lighting device"
                :class="$style.ldvMultiselect"
                @update:modelValue="setSelectedLightingDevice"
              />
              <div
                v-else
                :class="$style.ldvMultiselect"
              >
                There are no ldvs for selected brand.
              </div>
            </div>
            <div :class="[$style.infoRowSection, $style.brandsMultiselectRow]">
              <BaseMultiselect
                :model-value="ldvsBrand"
                :options="allBrands"
                is-searchable
                is-block
                no-reset
                placeholder="Change brand"
                :class="$style.brandsMultiselect"
                @update:modelValue="setSelectedLdvsBrand"
              />
              <BaseButton
                variant="secondary"
                @click="resetLdvsList"
              >
                Reset
              </BaseButton>
            </div>
          </BaseLoader>
        </div>
        <div
          v-if="selectedLightingDeviceObject"
          :class="$style.deviceButtonsContainer"
        >
          <BaseButton
            variant="success"
            is-block
            @click="cloneAssociatedDevice"
          >
            Clone device
          </BaseButton>
          <BaseButton
            variant="secondary"
            is-block
            @click="setShowCopyMlfsModal(true)"
          >
            Copy MLFS
          </BaseButton>
          <BaseButton
            variant="secondary"
            is-block
            @click="isEquipCarInvisible = !isEquipCarInvisible"
          >
            Equipe
          </BaseButton>
          <BaseButton
            variant="secondary"
            is-block
            :class="$style.editDeviceButton"
            @click="setShowEditDeviceModal(true, 'edit')"
          >
            Edit device
          </BaseButton>
        </div>
        <BaseButton
          v-else
          variant="success"
          is-block
          :class="$style.createDeviceButton"
          @click="setShowEditDeviceModal(true, 'create')"
        >
          Create device
        </BaseButton>
        <div :class="$style.configuationIdAndCarsNumber">
          <h3>
            Lighting configuration #{{ lightingConfiguration.id }}
          </h3>
          <div :class="$style.iconsContainer">
            <TippyWrapper
              ref="tippyWrapper"
              :key="1"
              :theme="['dropdown']"
              interactive
            >
              <template #default>
                <CommentSvg :class="latestComment ? $style.greenIcon : null" />
              </template>

              <template #content>
                <div :class="$style.commentContainer">
                  {{ latestComment || "No comment available" }}
                </div>
              </template>
            </TippyWrapper>
            <span :class="$style.spec">
              <BoxSvg />
              <span>
                {{ (selectedLightingDeviceObject?.lighting_configurations.length || 0).toLocaleString() }} cars
              </span>
            </span>
          </div>
        </div>
        <p :class="$style.lightingConfigurationSection">
          Version
        </p>
        <BaseLoader
          :is-active="isLoadingVersion"
          :class="$style.versionSection"
        >
          <div
            v-for="option in $options.CONFIGURATION_VERSION"
            :key="option.value"
            :class="$style.versionOption"
            @click.exact="updateLcVersion(option.value)"
          >
            <BaseLabeledRadio
              v-model="selectedVersion"
              :value="option.value"
              is-dark
            >
              {{ option.label }}
            </BaseLabeledRadio>
          </div>
        </BaseLoader>
        <p
          v-if="!isEquipCarInvisible"
          :class="$style.lightingConfigurationSection"
        >
          Equip car
        </p>
        <div
          v-if="!isEquipCarInvisible"
          :class="$style.carToEquip"
        >
          <EquipCar
            v-for="(car, index) in cars"
            :key="car.id"
            :car="car"
            :is-equiped="car.relyDevice.includes(selectedLightingDeviceId)"
            :device-id="selectedLightingDeviceId"
            @clone-device-for-car="cloneDeviceForCar(car, index, $event)"
            @equipe-car="associateDeviceToCar(car, $event)"
          />
        </div>
        <template v-if="selectedLightingDeviceObject">
          <p
            v-if="!isEquipmentInvisible"
            :class="$style.lightingConfigurationSection"
          >
            Lighting device details
          </p>
          <LightingDeviceFunctionsTable
            v-if="!isEquipmentInvisible"
            :main-light-functions-data="selectedLightingDeviceObject.main_light_functions"
            :light-device-type="selectedLightingDeviceObject.type"
            :lighting-device-configuration-options="lightingDeviceConfigurationOptions"
            :light-device-id="selectedLightingDeviceId"
            @mlfsUpdated="updateMlfs"
            @mlfRemoved="removeMlf"
          />
        </template>
        <div :class="$style.bottomButtonsContainer">
          <BaseButton
            v-if="selectedLightingDeviceObject"
            is-block
            variant="secondary"
            @click="removeDevice"
          >
            Reset configuration
          </BaseButton>
          <BaseButton
            is-block
            :class="$style.deleteConfigurationButton"
            @click="deleteConfiguration"
          >
            Delete configuration
          </BaseButton>
        </div>
      </template>
    </BaseLoader>
  </div>
</template>

<script>
import { Tippy as TippyWrapper } from 'vue-tippy';
import { format } from 'date-fns';
import { mapState } from 'vuex';
import BaseLabel from '@/components/base/Label';
import BoxSvg from '@/assets/images/icons/table-box.svg?inline';
import getDeviceTypeLabelColor from '@/utils/getDeviceTypeLabelColor';
import BaseLabeledRadio from '@/components/base/LabeledRadio';
import BaseLoader from '@/components/base/Loader';
import LightingDeviceFunctionsTable from '@/components/tables/LightingDeviceFunctionsTable';
import EquipCar from '@/components/EquipCar';
import BaseMultiselect from '@/components/base/Multiselect';
import { cloneDeep, remove, sortBy } from 'lodash';
import ldvs from '@/graphql/queries/ldvs';
import brands from '@/graphql/queries/brands';
import utils from '@/graphql/queries/utils';
import cars from '@/graphql/queries/cars';
import BaseButton from '@/components/base/Button';
import BaseModal from '@/components/base/Modal';
import EditDeviceImage from '@/components/EditDeviceImage';
import CopyDeviceMlfs from '@/components/CopyDeviceMlfs';
import EditDevice from '@/components/EditDevice';
import CommentSvg from '@/assets/images/icons/comment.svg?inline';
import PictureSvg from '@/assets/images/icons/picture.svg?inline';

export default {
  components: {
    TippyWrapper,
    BaseLabel,
    BoxSvg,
    BaseLabeledRadio,
    BaseLoader,
    LightingDeviceFunctionsTable,
    BaseMultiselect,
    BaseButton,
    BaseModal,
    EditDeviceImage,
    CopyDeviceMlfs,
    EditDevice,
    CommentSvg,
    PictureSvg,
    EquipCar,
  },
  CONFIGURATION_VERSION: [
    { value: 'Base', label: 'Base' },
    { value: 'Option1', label: 'Option1' },
    { value: 'Option2', label: 'Option2' },
    { value: 'Option3', label: 'Option3' },
    { value: 'Option4', label: 'Option4' },
  ],
  props: {
    brandName: {
      type: String,
      default: null,
    },
    brandLogo: {
      type: String,
      default: null,
    },
    brandId: {
      type: Number,
      default: null,
    },
    modelId: {
      type: Number,
      default: null,
    },
    carId: {
      type: Number,
      default: null,
    },
    lightingConfiguration: {
      type: Object,
      default: null,
    },
    isEquipmentInvisible: {
      type: Boolean,
      default: false,
    },
    lightingDeviceConfigurationOptions: {
      type: Array,
      default: null,
    },
    version: {
      type: null,
      default: null,
    },
  },
  emits: {
    lightingConfigurationUpdated: null,
    lightingConfigurationRemoved: null,
  },
  data() {
    return {
      selectedVersion: null,
      selectedLightingDeviceId: null,
      selectedLightingDeviceObject: null,
      allLightingDeviceIds: [],
      allLightingDeviceObjects: [],
      ldvsBrand: null,
      allBrands: [],
      ldvsListIsLoading: false,
      initialLdvsObjectList: [],
      isLoading: true,
      isEquipCarInvisible: true,
      cars: [],
      showEditDeviceImageModal: false,
      deviceImage: null,
      showCopyMlfsModal: false,
      showEditDeviceModal: false,
      editDeviceModalMode: null,
      latestComment: null,
      finishedInitialSetup: false,
      isLoadingVersion: false,
    };
  },
  computed: {
    ...mapState('app', ['updatedDevices']),
    defaultDeviceImage() {
      if (!this.selectedLightingDeviceObject) return '/images/placeholder-image.png';
      switch (this.selectedLightingDeviceObject.type) {
        case 'HL':
          return '/images/default-hl-front.jpg';
        case 'RL':
          return '/images/default-rl-front.jpg';
        case 'CHMSL':
          return '/images/default-chmsl-front.jpg';
        case 'AuxHL':
          return '/images/default-auxhl-front.jpg';
        case 'AuxRL':
          return '/images/default-auxrl-front.jpg';
        default:
          return '/images/placeholder-image.png';
      }
    },
  },
  watch: {
    updatedDevices: {
      handler() {
        this.setAllLightingDevicesList(this.allLightingDeviceObjects);
      },
      deep: true,
    },
  },
  created() {
    this.selectedVersion = this.version;
    this.latestComment = this.lightingConfiguration.comment;
    Promise.all([
      ldvs.getAllLdvsByCarBrand(this.brandId),
      brands.getBrandsOemNamesAndIds(),
      cars.getLmiCarsByBrandAndModel(this.brandId, this.modelId),
    ]).then((results) => {
      const [ldvsResult, brandsResult, carsResult] = results;
      this.cars = carsResult
        .filter((car) => car.id !== this.lightingConfiguration.car.id)
        .map((car) => {
          const relyDevice = car.lighting_configurations.map((lc) => lc.lighting_device_id);
          return {
            ...car,
            relyDevice,
          };
        });
      const allLdvs = cloneDeep(ldvsResult);
      if (this.lightingConfiguration.lighting_device) {
        const hasCurrentLdv = ldvsResult
          .map((item) => item.id)
          .includes(this.lightingConfiguration.lighting_device.id);
        if (!hasCurrentLdv) {
          allLdvs.push(this.lightingConfiguration.lighting_device);
        }
      }
      this.initialLdvsObjectList = allLdvs;
      this.selectedLightingDeviceId = this.lightingConfiguration.lighting_device?.id;
      this.selectedLightingDeviceObject = this.lightingConfiguration.lighting_device;
      this.setAllLightingDevicesList(allLdvs);
      this.allBrands = brandsResult.map((brand) => ({
        label: brand.name, value: brand.id,
      }));
      this.finishedInitialSetup = true;
      this.isLoading = false;
    });
  },
  methods: {
    format,
    getDeviceTypeLabelColor,
    cloneAssociatedDevice() {
      this.isLoading = true;

      const deviceToDuplicate = cloneDeep(this.selectedLightingDeviceObject);
      delete deviceToDuplicate.id;
      delete deviceToDuplicate.supplier;
      delete deviceToDuplicate.country;
      delete deviceToDuplicate.lighting_configurations;
      delete deviceToDuplicate.main_light_functions;
      delete deviceToDuplicate.factory;
      // eslint-disable-next-line no-underscore-dangle
      delete deviceToDuplicate.__typename;

      ldvs.insertLdv(deviceToDuplicate).then((deviceClone) => {
        const mainLightFunctionsToDuplicate = this.selectedLightingDeviceObject.main_light_functions
          .map((mlf) => ({
            lighting_device_id: deviceClone.id,
            light_function_id: mlf.light_function.id,
            light_source_id: mlf.light_source.id,
            light_functionality_id: mlf.light_functionality.id,
            optical_system_id: mlf.optical_system.id,
          }));

        Promise.all([
          ldvs.updateLightingConfigurationLdv(
            this.lightingConfiguration.id,
            deviceClone.id,
          ),
          ldvs.insertMlfsMany(mainLightFunctionsToDuplicate),
        ]).then((results) => {
          this.setAllLightingDevicesList([
            ...this.allLightingDeviceObjects,
            { ...deviceClone, main_light_functions: results[1] },
          ]);
          this.selectedLightingDeviceId = deviceClone.id;
          this.selectedLightingDeviceObject = this.allLightingDeviceObjects.find(
            (ldv) => ldv.id === deviceClone.id,
          );
          this.isLoading = false;
        });
      });
    },
    setAllLightingDevicesList(ldvsList) {
      const updatedList = cloneDeep(ldvsList);
      this.updatedDevices.forEach((updatedDevice) => {
        const foundDeviceIndex = updatedList.findIndex((deviceToFind) => deviceToFind.id === updatedDevice.id);
        if (foundDeviceIndex !== -1) {
          updatedList[foundDeviceIndex] = {
            ...updatedList[foundDeviceIndex],
            ...updatedDevice,
          };
          if (this.selectedLightingDeviceId === updatedDevice.id) {
            this.selectedLightingDeviceObject = updatedList[foundDeviceIndex];
          }
        }
      });
      this.allLightingDeviceIds = updatedList.map((ldv) => {
        const label = `${ldv.type}${ldv.order_in_row}
          - ${ldv.position}
          - ${ldv.aftermarket_price}€
          - ${ldv.part_number}`;
        return { label, value: ldv.id };
      });
      this.allLightingDeviceIds = sortBy(this.allLightingDeviceIds, (item) => item.label);
      this.allLightingDeviceObjects = updatedList;
      this.closeAllMenus();
    },
    setSelectedLightingDevice(selectedLightingDeviceId) {
      this.closeAllMenus();
      this.isLoading = true;
      this.selectedLightingDeviceId = selectedLightingDeviceId;
      this.selectedLightingDeviceObject = this.allLightingDeviceObjects.find(
        (ldv) => ldv.id === selectedLightingDeviceId,
      ) || null;
      ldvs.updateLightingConfigurationLdv(
        this.lightingConfiguration.id,
        selectedLightingDeviceId || null,
      ).then(() => {
        this.isLoading = false;
      });
    },
    setSelectedLdvsBrand(brandId) {
      this.ldvsListIsLoading = true;
      this.closeAllMenus();
      this.ldvsBrand = brandId;
      ldvs.getAllLdvsByCarBrand(brandId).then((allLdvsOfBrand) => {
        this.setAllLightingDevicesList(allLdvsOfBrand);
        this.ldvsListIsLoading = false;
      });
    },
    resetLdvsList() {
      this.closeAllMenus();
      this.setAllLightingDevicesList(this.initialLdvsObjectList);
      this.setSelectedLightingDevice(this.lightingConfiguration.lighting_device?.id);
      this.ldvsBrand = null;
    },
    removeDevice() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const ok = confirm('This device will be removed from this lighting configuration. Are you sure ?');
      if (ok) {
        this.closeAllMenus();
        this.isLoading = true;
        ldvs.updateLightingConfigurationLdv(
          this.lightingConfiguration.id,
          null,
        ).then(() => {
          this.setSelectedLightingDevice(null);
          this.isLoading = false;
        });
      }
    },
    updateMlfs(mlfs) {
      this.selectedLightingDeviceObject.main_light_functions = mlfs;
      this.setNewDeviceProperties({
        main_light_functions: this.selectedLightingDeviceObject.main_light_functions,
      });
      this.$store.dispatch(
        'app/updateLightingConfigurationMlfs',
        {
          lightingConfigurationId: this.lightingConfiguration.id,
          mlfs: this.selectedLightingDeviceObject.main_light_functions,
        },
      );
    },
    removeMlf(mlfId) {
      remove(this.selectedLightingDeviceObject.main_light_functions, ((mlf) => mlf.id === mlfId));
      this.setNewDeviceProperties({
        main_light_functions: this.selectedLightingDeviceObject.main_light_functions,
      });
      this.$store.dispatch(
        'app/updateLightingConfigurationMlfs',
        {
          lightingConfigurationId: this.lightingConfiguration.id,
          mlfs: this.selectedLightingDeviceObject.main_light_functions,
        },
      );
    },
    updateLcVersion(newValue) {
      this.isLoadingVersion = true;
      this.selectedVersion = newValue;
      ldvs.updateLightingConfigurationVersion(
        this.lightingConfiguration.id,
        newValue,
      ).then(() => {
        this.$emit('lightingConfigurationUpdated', { version: newValue });
        this.isLoadingVersion = false;
      });
    },
    editDeviceImage() {
      this.deviceImage = this.selectedLightingDeviceObject.front_image;
      this.setShowEditDeviceImageModal(true);
    },
    setShowEditDeviceModal(value, mode) {
      this.showEditDeviceModal = value;
      this.editDeviceModalMode = mode;
    },
    setShowEditDeviceImageModal(value) {
      this.showEditDeviceImageModal = value;
    },
    setShowCopyMlfsModal(value) {
      this.showCopyMlfsModal = value;
    },
    setNewDeviceImage(newImage) {
      this.isLoading = true;
      this.setShowEditDeviceImageModal(false);
      ldvs.updateLdvImageByPk(this.selectedLightingDeviceId, newImage).then(() => {
        this.selectedLightingDeviceObject.front_image = newImage;
        this.isLoading = false;
      });
    },
    cloneDeviceForCar(car, index, updatedValues) {
      this.isLoading = true;

      const deviceToDuplicate = cloneDeep(this.selectedLightingDeviceObject);
      delete deviceToDuplicate.id;
      delete deviceToDuplicate.supplier;
      delete deviceToDuplicate.country;
      delete deviceToDuplicate.lighting_configurations;
      delete deviceToDuplicate.main_light_functions;
      delete deviceToDuplicate.factory;
      // eslint-disable-next-line no-underscore-dangle
      delete deviceToDuplicate.__typename;

      ldvs.insertLdv(deviceToDuplicate).then((deviceClone) => {
        const mainLightFunctionsToDuplicate = this.selectedLightingDeviceObject.main_light_functions
          .map((mlf) => ({
            lighting_device_id: deviceClone.id,
            light_function_id: mlf.light_function.id,
            light_source_id: mlf.light_source.id,
            light_functionality_id: mlf.light_functionality.id,
            optical_system_id: mlf.optical_system.id,
          }));

        const lightingConfigurationToBeCreated = {
          car_id: updatedValues.carId,
          lighting_device_id: deviceClone.id,
          version: 'Base',
        };

        Promise.all([
          ldvs.insertLdvConfig(lightingConfigurationToBeCreated),
          ldvs.insertMlfsMany(mainLightFunctionsToDuplicate),
        ]).then(async (results) => {
          const [newLightingConfiguration] = results;
          const globalTakeRateToBeCreated = {
            lighting_configuration_id: newLightingConfiguration.id,
            rate: 1,
            update_ref: 'LMI_22_Q2',
          };
          const newGlobalTakeRates = await utils.insertGlobalTakeRate(globalTakeRateToBeCreated);
          newLightingConfiguration.take_rates = [newGlobalTakeRates];
          newLightingConfiguration.lighting_device = { ...deviceClone, main_light_functions: results[1] };
          this.$store.dispatch('app/saveLightingConfiguration', newLightingConfiguration);
          this.cars = this.cars.map((carItem) => {
            if (carItem.id === updatedValues.carId) {
              const { relyDevice } = carItem;
              relyDevice.push(updatedValues.deviceId);
              return {
                ...carItem,
                relyDevice,
              };
            }
            return carItem;
          });
          this.isLoading = false;
        });
      });
    },
    associateDeviceToCar(car, updatedValues) {
      this.isLoading = true;

      const lightingConfigurationToBeCreated = {
        car_id: updatedValues.carId,
        lighting_device_id: updatedValues.deviceId,
        version: 'Base',
      };
      ldvs.insertLdvConfig(
        lightingConfigurationToBeCreated,
      ).then(async (newLightingConfiguration) => {
        const newLightingConfigurationObject = cloneDeep(newLightingConfiguration);
        const globalTakeRateToBeCreated = {
          lighting_configuration_id: newLightingConfiguration.id,
          rate: 1,
          update_ref: 'LMI_22_Q2',
        };
        const newGlobalTakeRates = await utils.insertGlobalTakeRate(globalTakeRateToBeCreated);
        newLightingConfigurationObject.take_rates = [newGlobalTakeRates];
        this.$store.dispatch('app/saveLightingConfiguration', newLightingConfigurationObject);
        this.cars = this.cars.map((carItem) => {
          if (carItem.id === updatedValues.carId) {
            const { relyDevice } = carItem;
            relyDevice.push(updatedValues.deviceId);
            return {
              ...carItem,
              relyDevice,
            };
          }
          return carItem;
        });
        this.isLoading = false;
      });
    },
    copyMlfs(mlfs) {
      this.setShowCopyMlfsModal(false);
      this.isLoading = true;
      ldvs.insertMlfsMany(mlfs)
        .then((result) => {
          let mainLightFunctions = cloneDeep(this.selectedLightingDeviceObject.main_light_functions);
          mainLightFunctions = mainLightFunctions.concat(result);
          this.setNewDeviceProperties({
            main_light_functions: mainLightFunctions,
          });
          this.isLoading = false;
        });
    },
    setNewDeviceProperties(newProperties, comment) {
      this.selectedLightingDeviceObject = {
        ...this.selectedLightingDeviceObject,
        ...newProperties,
      };
      const indexToUpdate = this.allLightingDeviceObjects.indexOf(
        this.allLightingDeviceObjects.find((deviceToFind) => deviceToFind.id === this.selectedLightingDeviceId),
      );
      this.allLightingDeviceObjects[indexToUpdate] = this.selectedLightingDeviceObject;
      if (comment !== undefined) this.latestComment = comment;
      this.$store.dispatch('app/addUpdatedLightingDevice', this.selectedLightingDeviceObject);
      this.setShowEditDeviceModal(false);
    },
    attachNewDevice(newDevice, comment) {
      const deviceToAdd = cloneDeep(newDevice);
      deviceToAdd.lighting_configurations = [this.lightingConfiguration];
      deviceToAdd.main_light_functions = [];
      this.setAllLightingDevicesList([
        ...this.allLightingDeviceObjects,
        deviceToAdd,
      ]);
      this.selectedLightingDeviceId = deviceToAdd.id;
      this.selectedLightingDeviceObject = this.allLightingDeviceObjects.find(
        (ldv) => ldv.id === deviceToAdd.id,
      );
      if (comment !== undefined) this.latestComment = comment;
    },
    deleteConfiguration() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const ok = confirm(`Lighting configuration #${this.lightingConfiguration.id} will be deleted. Are you sure ?`);
      if (ok) {
        this.isLoading = true;
        ldvs.deleteLightingConfigurationByPk(this.lightingConfiguration.id).then(() => {
          this.$emit('lightingConfigurationRemoved');
          this.isLoading = false;
        });
      }
    },
    closeAllMenus() {
      this.isEquipCarInvisible = true;
      this.showCopyMlfsModal = false;
      this.showEditDeviceImageModal = false;
      this.showEditDeviceModal = false;
    },
  },
};
</script>

<style lang="scss" module>
.card {
  width: calc(25% - 10px);
  max-width: 540px;
  height: fit-content;
  padding: 25px 20px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);

  @media screen and (max-width: 1650px) {
    width: calc(33% - 10px);
  }

  @media screen and (max-width: 1300px) {
    width: calc(50% - 10px);
  }
}

.mainLoader {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
}

.brandLogo,
.supplierLogo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.brandLogo {
  margin-right: 3px;
}

.flag {
  margin-bottom: -1px;
  margin-left: 3px;
}

.infoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.isTop {
    margin-left: auto;
  }

  &.isBottom {
    margin-top: 10px;
  }
}

.versionSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 20px;
}

.infoRowSection {
  display: flex;
  align-items: center;

  .label {

    &:not(:first-child) {
      margin-left: 5px;
    }
  }
}

.spec {
  display: inline-flex;
  align-items: center;
  font-size: 13px;

  &:not(:first-child) {
    margin-left: 15px;
  }

  svg {
    margin-top: -3px;
    margin-right: 5px;
  }
}

.images {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  img {
    max-width: 100%;
    max-height: 150px;
    border-radius: 5px;
    object-fit: contain;
  }
}

.greyFilter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg, rgba(230, 230, 230, 0.5) 0%, rgba(146, 147, 148, 0.25) 43%, rgba(15, 18, 20, 0.25) 100%
  );
  border-radius: 5px;
  opacity: 0.5;
}

.typeLabelWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #fff;
}

.lightingConfigurationSection {
  font-style: italic;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.table {
  display: inline-block;
  width: 100%;
}

.versionOption {
  padding: 8px;
  background: $white;

  &:not(:first-child) {
    margin-left: 5px;
  }
}

.deviceSelectContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.ldvMultiselect {
  width: 100%;
}

.brandsMultiselectRow {
  gap: 10px;
}

.brandsMultiselect {
  flex: 1;
}

.createDeviceButton {
  margin-top: 10px;
}

.configuationIdAndCarsNumber {
  display: flex;
  justify-content: space-between;
}

.bottomButtonsContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;

  .deleteConfigurationButton {
    color: #e00;
    background-color: $white;
    border: 2px solid #e00;

    &:hover {
      background-color: $background-light;
    }
  }
}

.deviceButtonsContainer {
  display: flex;
  gap: 10px;
  margin-top: 15px;

  .editDeviceButton {
    background-color: #aee4f2;

    &:hover,
    &:focus {
      background-color: #9ed4e2;
    }
  }

  button {
    text-align: center;
  }
}

.editImageButton {
  @include reset;
  position: absolute;
  right: 4px;
  bottom: 4px;
  z-index: 2;
  width: 25px;
  height: 25px;
  padding: 5px;
  color: $title-dark;
  cursor: pointer;
  background-color: $text;
  border-radius: 50%;
  box-shadow: 0 0 0 1px $title-dark;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.carToEquip {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.iconsContainer {
  display: flex;
  align-items: center;
}

.commentContainer {
  padding: 0 10px;
}

.greenIcon {
  color: #24cb71;
}
</style>
