<template>
  <TheHeader :is-light="hasHero" />
  <TheHero v-if="hasHero" />
  <div :class="$style.pageContent">
    <router-view />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader';
import TheHero from '@/components/TheHero';

export default {
  components: {
    TheHeader,
    TheHero,
  },
  computed: {
    hasHero() {
      return Boolean(this.$route.meta.hero);
    },
  },
};
</script>

<style lang="scss" module>
.pageContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
