<template>
  <div
    :class="$style.clusterCarCard"
  >
    <div :class="$style.header">
      <p>G{{ car.generation }}P{{ car.phase }}</p>
      <p>{{ car.id }}-{{ car.project_code }}</p>
    </div>
    <div :class="$style.updaterefContainer">
      <BaseLabel
        v-for="item in car.volumes_infos"
        :key="item.update_ref_id"
        color="cherry"
      >
        {{ item.update_ref_id }}
      </BaseLabel>
    </div>
    <img
      :src="car.front_image
        || '/images/default-car-front.jpg'"
      :class="$style.carImage"
    >
    <div :class="$style.countryBodyContainer">
      <p>{{ car.country.region }}</p>
      <p>{{ car.country.name }}</p>
      <p>{{ car.body }}</p>
    </div>
    <div
      v-if="!isEquiped"
      :class="$style.buttonsContainer"
    >
      <BaseButton
        type="clone()"
        variant="success"
        @click="clone()"
      >
        CBU
      </BaseButton>
      <BaseButton
        type="equipe"
        variant="secondary"
        @click="equipe(true)"
      >
        CKD
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/Button';
import BaseLabel from '@/components/base/Label';

export default ({
  components: {
    BaseButton,
    BaseLabel,
  },
  props: {
    car: {
      type: Object,
      default: null,
    },
    isEquiped: {
      type: Boolean,
      default: false,
    },
    deviceId: {
      type: Number,
      default: null,
    },
  },
  emits: {
    equipeCar: null,
    cloneDeviceForCar: null,
  },
  methods: {
    equipe() {
      const carId = this.car.id;
      const { deviceId } = this;
      const updatedValues = {
        carId,
        deviceId,
      };
      this.$emit('equipeCar', updatedValues);
    },
    clone() {
      const carId = this.car.id;
      const { deviceId } = this;
      const updatedValues = {
        carId,
        deviceId,
      };
      this.$emit('cloneDeviceForCar', updatedValues);
    },
  },
});
</script>
<style lang="scss" module>
.clusterCarCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 140px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    margin: 0;
    color: $title;
    text-align: center;
  }
}

.carImage {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 10px;
}

.countryBodyContainer {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  p:last-child {
    margin-top: 5px;
  }
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;

  button {
    padding: 5px;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 13px;
  }
}

.updaterefContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
</style>
