<template>
  <div :class="$style.divider" />
</template>

<script>
export default {
};
</script>

<style lang="scss" module>
.divider {
  width: calc(100% + 60px);
  height: 1px;
  margin: 25px -30px;
  background: #e6e6e6;
}
</style>
