import { checkSavedLogInData, logOut } from '@/aws-api';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

function transformCognitoResult(cognitoUser) {
  return {
    username: cognitoUser.username,
    givenName: cognitoUser.attributes.given_name,
    familyName: cognitoUser.attributes.family_name,
    email: cognitoUser.attributes.email,
    isEmailVerified: cognitoUser.attributes.email_verified,
    groups: cognitoUser.signInUserSession.accessToken.payload['cognito:groups'] || [],
    jwtExp: cognitoUser.signInUserSession.idToken.payload.exp,
  };
}

export default {
  namespaced: true,
  state() {
    return {
      user: null,
    };
  },
  getters: {
    isLoggedIn(state) {
      return state.user != null;
    },
    jwtTokenExp(state) {
      const jwtexp = state?.user?.jwtExp;
      return jwtexp;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async logIn({ commit }, cognitoUser) {
      const userData = {
        ...transformCognitoResult(cognitoUser),
      };
      commit('setUser', userData);
    },
    async restore({ dispatch }) {
      const cognitoUser = await checkSavedLogInData();

      if (cognitoUser == null) return;

      await dispatch('logIn', cognitoUser);
    },
    async reload({ dispatch }) {
      await dispatch('restore');
    },
    async logOut({ commit }) {
      await logOut();
      commit('setUser', null);
      await router.push('/login');
    },
  },
};
