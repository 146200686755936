<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.tableContainer"
  >
    <div :class="$style.searchContainer">
      <BaseInput
        v-model="searchValue"
        placeholder="Search"
        type="text"
      />
      <BaseButton
        variant="success"
        type="insertRow"
        @click="openForm({}, 'create')"
      >
        Insert new LDVC OPTION
      </BaseButton>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="ldvConfigOptions"
      :rows-per-page="10"
      :search-value="searchValue"
      sort-by="name"
      sort-type="asc"
      :table-class-name="$style.dataTable"
      @click-row="openForm($event, 'update')"
    />
    <FormBox
      v-if="isFormOpen"
      :class="$style.formBox"
      :heading="`${formMode === 'create' ? 'Create' : 'Update'} row`"
    >
      <div :class="$style.fieldsContainer">
        <BaseField
          :class="$style.field"
        >
          <template #label>
            Device type
          </template>
          <BaseInput
            v-model="deviceType"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Light function
          </template>
          <BaseMultiselect
            :model-value="lightFunctionId"
            :options="lightFunctions"
            is-searchable
            is-block
            @update:modelValue="setLightFunction"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Light functionality
          </template>
          <BaseMultiselect
            :model-value="lightFunctionalityId"
            :options="lightFunctionalities"
            is-searchable
            is-block
            @update:modelValue="setLightFunctionality"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Source
          </template>
          <BaseMultiselect
            :model-value="lightSourceId"
            :options="lightSources"
            is-searchable
            is-block
            @update:modelValue="setLightSource"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Optical system
          </template>
          <BaseMultiselect
            :model-value="opticalSystemId"
            :options="opticalSystems"
            is-searchable
            is-block
            @update:modelValue="setOpticalSystem"
          />
        </BaseField>
      </div>

      <template #footer>
        <div :class="$style.formButtonsContainer">
          <BaseButton
            variant="success"
            type="updateOrCreateRow"
            @click="formMode === 'create' ? createRow() : updateRow()"
          >
            {{ formMode === 'create' ? 'Create' : 'Update' }}
          </BaseButton>
          <BaseButton
            variant="secondary"
            type="closeForm"
            @click="closeForm"
          >
            Close
          </BaseButton>
        </div>
      </template>
    </FormBox>
  </BaseLoader>
</template>
<script>
import ldvs from '@/graphql/queries/ldvs';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseButton from '@/components/base/Button';
import BaseInput from '@/components/base/Input';
import BaseMultiselect from '@/components/base/Multiselect';
import { isEmpty, isArray } from 'lodash';

export default ({
  components: {
    BaseLoader,
    FormBox,
    BaseField,
    BaseButton,
    BaseInput,
    BaseMultiselect,
  },
  data() {
    return {
      headers: [
        {
          text: 'ID', value: 'id', sortable: true, width: 25,
        },
        {
          text: 'Device type', value: 'light_device_type', sortable: true, width: 50,
        },
        {
          text: 'Function', value: 'light_function.name', sortable: true, width: 50,
        },
        {
          text: 'Functionality', value: 'light_functionality.name', sortable: true, width: 50,
        },
        {
          text: 'Source', value: 'light_source.technology', sortable: true, width: 50,
        },
        {
          text: 'Optical system', value: 'optical_system.name', width: 50,
        },
      ],
      ldvConfigOptions: [],
      lightFunctions: [],
      lightFunctionalities: [],
      opticalSystems: [],
      searchValue: '',
      isFormOpen: false,
      formMode: null,
      isLoading: false,
      rowId: null,
      deviceType: null,
      lightFunctionId: null,
      lightFunctionalityId: null,
      lightSourceId: null,
      opticalSystemId: null,
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([
      ldvs.getAllLdvConfigOptions(),
      ldvs.getAllLightFunctions(),
      ldvs.getAllLightFunctionalities(),
      ldvs.getAllLightSources(),
      ldvs.getAllOpticalSystems(),
    ]).then((results) => {
      this.ldvConfigOptions = [...results[0]];
      this.lightFunctions = results[1].map((lightFunction) => ({
        label: `${lightFunction.name} - ${lightFunction.lighting_device_type}`,
        value: lightFunction.id,
      }));
      this.lightFunctionalities = results[2].map((lightFunctionality) => ({
        label: lightFunctionality.name,
        value: lightFunctionality.id,
      }));
      this.lightSources = results[3].map((lightSource) => ({
        label: lightSource.technology,
        value: lightSource.id,
      }));
      this.opticalSystems = results[4].map((opticalSystem) => ({
        label: opticalSystem.name,
        value: opticalSystem.id,
      }));
      this.isLoading = false;
    });
  },
  methods: {
    createRow() {
      this.closeForm();
      this.isLoading = true;
      const ldvConfigOptionToCreate = {
        light_device_type: this.deviceType,
        light_function_id: this.lightFunctionId,
        light_functionality_id: this.lightFunctionalityId,
        light_source_id: this.lightSourceId,
        optical_system_id: this.opticalSystemId,
      };
      ldvs.insertLdvConfigOption(ldvConfigOptionToCreate).then((insertResult) => {
        this.ldvConfigOptions.push(insertResult);
        this.isLoading = false;
      });
    },
    openForm(row, formMode) {
      this.formMode = formMode;
      this.rowId = row.id;
      this.deviceType = row.light_device_type;
      this.lightFunctionId = row.light_function?.id;
      this.lightFunctionalityId = row.light_functionality?.id;
      this.lightSourceId = row.light_source?.id;
      this.opticalSystemId = row.optical_system?.id;
      this.isFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
    },
    updateRow() {
      this.closeForm();
      this.isLoading = true;
      const ldvConfigOptionToUpdate = {
        id: this.rowId,
        light_device_type: this.deviceType,
        light_function_id: this.lightFunctionId,
        light_functionality_id: this.lightFunctionalityId,
        light_source_id: this.lightSourceId,
        optical_system_id: this.opticalSystemId,
      };
      ldvs.updateLdvConfigOptionByPk(ldvConfigOptionToUpdate).then((result) => {
        const localLdvConfigOptionToUpdate = this.ldvConfigOptions.find(
          (ldvConfigOption) => ldvConfigOption.id === this.rowId,
        );
        const index = this.ldvConfigOptions.indexOf(localLdvConfigOptionToUpdate);
        this.ldvConfigOptions[index] = result;
        this.isLoading = false;
      });
    },
    setLightFunction(lightFunctionId) {
      if (isArray(lightFunctionId) && isEmpty(lightFunctionId)) {
        this.lightFunctionId = null;
      } else {
        this.lightFunctionId = lightFunctionId;
      }
    },
    setLightFunctionality(lightFunctionalityId) {
      if (isArray(lightFunctionalityId) && isEmpty(lightFunctionalityId)) {
        this.lightFunctionalityId = null;
      } else {
        this.lightFunctionalityId = lightFunctionalityId;
      }
    },
    setLightSource(lightSourceId) {
      if (isArray(lightSourceId) && isEmpty(lightSourceId)) {
        this.lightSourceId = null;
      } else {
        this.lightSourceId = lightSourceId;
      }
    },
    setOpticalSystem(opticalSystemId) {
      if (isArray(opticalSystemId) && isEmpty(opticalSystemId)) {
        this.opticalSystemId = null;
      } else {
        this.opticalSystemId = opticalSystemId;
      }
    },
  },
});
</script>
<style lang="scss" module>

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.searchContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.dataTable {
  --easy-table-body-row-height: 32px;
}

.oemLogo {
  width: 25px;
  height: 25px;
}

.deleteIconContainer {
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 2px;
    cursor: pointer;
  }
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.field {
  width: 250px;
}

.formButtonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
</style>
