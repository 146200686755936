<template>
  <span :class="$style.wrapper">
    <span :class="$style.heading">
      <slot />
    </span>
    <span :class="$style.lead">
      <slot name="lead" />
    </span>
    <span :class="$style.lead">
      <slot name="actions" />
    </span>
  </span>
</template>

<script>
export default {
};
</script>

<style lang="scss" module>
.wrapper {
  display: block;
  padding: 3px 20px;
  margin-bottom: 15px;
}

.heading {
  display: block;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.428;
  color: #424a4e;
}

.lead {
  display: block;
  margin-top: 2px;
  font-size: 16px;
  color: $title;
}
</style>
