<template>
  <div :class="$style.field">
    <div :class="$style.top">
      <div :class="$style.label">
        <slot name="label" />
      </div>
      <BaseLabel
        v-if="isRequired"
        color="barley"
      >
        Required
      </BaseLabel>
    </div>
    <div :class="$style.body">
      <slot />
    </div>
    <div
      v-if="error"
      :class="$style.error"
      v-text="error"
    />
    <div
      v-if="$slots.help"
      :class="$style.help"
    >
      <slot name="help" />
    </div>
  </div>
</template>

<script>
import BaseLabel from '@/components/base/Label';

export default {
  components: {
    BaseLabel,
  },
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" module>
.field {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.label {
  font-size: 12px;
  color: $text;
  text-transform: uppercase;
  letter-spacing: 0.48px;
}

.body {
  height: 100%;
}

.error {
  margin-top: 4px;
  font-size: 12px;
  color: #f38888;
  letter-spacing: 0.48px;
}
</style>
