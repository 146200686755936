<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.tableContainer"
  >
    <div :class="$style.searchContainer">
      <BaseInput
        v-model="searchValue"
        placeholder="Search"
        type="text"
      />
      <BaseButton
        variant="success"
        type="insertRow"
        @click="openForm({}, 'create')"
      >
        Insert new LDV
      </BaseButton>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="ldvs"
      :rows-per-page="10"
      :search-value="searchValue"
      sort-by="id"
      sort-type="asc"
      :table-class-name="$style.dataTable"
      @click-row="openForm($event, 'update')"
    >
      <template #item-configNumber="{ lighting_configurations }">
        <p>{{ lighting_configurations.length }}</p>
      </template>
      <template #item-picture="{ front_image }">
        <img
          :src="front_image"
          :class="$style.picture"
        >
      </template>
    </EasyDataTable>
    <FormBox
      v-if="isFormOpen"
      :class="$style.formBox"
      :heading="`${formMode === 'create' ? 'Create' : 'Update'} row`"
    >
      <div :class="$style.fieldsContainer">
        <BaseField
          :class="$style.field"
        >
          <template #label>
            Type
          </template>
          <BaseInput
            v-model="stdBody"
            type="text"
          />
        </BaseField>
      </div>

      <template #footer>
        <div :class="$style.formButtonsContainer">
          <BaseButton
            variant="success"
            type="updateOrCreateRow"
            @click="formMode === 'create' ? createRow() : updateRow()"
          >
            {{ formMode === 'create' ? 'Create' : 'Update' }}
          </BaseButton>
          <BaseButton
            variant="secondary"
            type="closeForm"
            @click="closeForm"
          >
            Close
          </BaseButton>
        </div>
      </template>
    </FormBox>
  </BaseLoader>
</template>
<script>
import ldvs from '@/graphql/queries/ldvs';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseButton from '@/components/base/Button';
import BaseInput from '@/components/base/Input';

export default ({
  components: {
    BaseLoader,
    FormBox,
    BaseField,
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      headers: [
        {
          text: 'ID', value: 'id', sortable: true, width: 50,
        },
        {
          text: 'Config Nbr.', value: 'configNumber', sortable: true, width: 50,
        },
        {
          text: 'Supplier', value: 'supplier.name', sortable: true, width: 150,
        },
        {
          text: 'Factory', value: 'factory.name', sortable: true, width: 150,
        },
        {
          text: 'Factory region', value: 'factory.region', sortable: true, width: 100,
        },
        {
          text: 'Type', value: 'type', sortable: true, width: 50,
        },
        {
          text: 'Part number', value: 'part_number', sortable: true, width: 100,
        },
        {
          text: 'AM Price', value: 'aftermarket_price', sortable: true, width: 100,
        },
        {
          text: 'Parts per car', value: 'parts_per_car', sortable: true, width: 50,
        },
        {
          text: 'Picture', value: 'picture', sortable: true, width: 50,
        },
        {
          text: 'Created by', value: 'created_by', sortable: true, width: 150,
        },
      ],
      searchValue: '',
      isFormOpen: false,
      formMode: null,
      isLoading: false,
      rowId: null,
      ldvs: [],
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([ldvs.getAllLdvs()])
      .then((results) => {
        this.ldvs = [...results[0]];
        this.isLoading = false;
      });
  },
  methods: {
    createRow() {
      this.closeForm();
      this.isLoading = true;
      const ldvToCreate = {

      };
      ldvs.insertLdv(ldvToCreate).then((insertResult) => {
        this.ldvs.push(insertResult);
        this.isLoading = false;
      });
    },
    openForm(row, formMode) {
      this.formMode = formMode;
      this.rowId = row.id;
      this.isFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
    },
    updateRow() {
      this.closeForm();
      this.isLoading = true;
      const ldvToUpdate = {
        id: this.rowId,
      };
      ldvs.updateLdvsByPk(ldvToUpdate).then(() => {
        const localLdvToUpdate = this.ldvs.find((ldv) => ldv.id === this.rowId);
        const index = this.ldvs.indexOf(localLdvToUpdate);
        this.ldvs[index] = { ...this.ldvs[index], ...ldvToUpdate };
        this.isLoading = false;
      });
    },
  },
});
</script>
<style lang="scss" module>

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.searchContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.dataTable {
  --easy-table-body-row-height: 32px;
}

.picture {
  height: 25px;
}

.deleteIconContainer {
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 2px;
    cursor: pointer;
  }
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.field {
  width: 250px;
}

.formMultiselect {
  height: 100%;
}

.formButtonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
</style>
