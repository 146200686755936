<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.wrapper"
  >
    <div :class="$style.modelsContainer">
      <div :class="$style.header">
        <h1 :class="$style.modelsHeader">
          {{ brand.name }} models
        </h1>
      </div>
      <div :class="$style.modelsButtons">
        <button
          v-for="sourceModel in sourceModels"
          :key="sourceModel.modelid"
          :class="[
            $style.sourceModelButton,
            {
              [$style.selected]: selectedSourceModel && selectedSourceModel.modelid === sourceModel.modelid,
            }
          ]"
          @click="selectSourceModel(sourceModel)"
        >
          {{ sourceModel.global_nameplate }}
        </button>
      </div>
    </div>
    <EquipmentModelLightingClusters
      v-if="selectedSourceModel"
      :brand="brand"
      :model="selectedSourceModel"
      @finished-loading="setIsLoading(false)"
    />
  </BaseLoader>
</template>
<script>
import EquipmentModelLightingClusters from '@/components/EquipmentModelLightingClusters';
import BaseLoader from '@/components/base/Loader';

export default ({
  components: {
    EquipmentModelLightingClusters,
    BaseLoader,
  },
  props: {
    brand: {
      type: Object,
      default: null,
    },
    sourceModels: {
      type: Object,
      default: null,
    },
    selectedModel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      lmiCars: [],
      selectedSourceModel: null,
      showGantts: false,
      isLoading: false,
    };
  },
  watch: {
    brand() {
      this.selectedSourceModel = null;
      this.showGantts = false;
      this.isLoading = false;
    },
  },
  created() {
    if (this.selectedModel) {
      const sourceModel = this.sourceModels.find((item) => item.modelid === parseInt(this.selectedModel, 10));
      if (this.selectedSourceModel !== sourceModel) {
        this.setIsLoading(true);
      }
      this.selectedSourceModel = sourceModel;
    }
  },
  methods: {
    selectSourceModel(sourceModel) {
      if (this.selectedSourceModel !== sourceModel) {
        this.setIsLoading(true);
      }
      this.selectedSourceModel = sourceModel;
    },
    toggleGantts() {
      this.showGantts = !this.showGantts;
    },
    setIsLoading(value) {
      this.isLoading = value;
    },
  },
});
</script>
<style lang="scss" module>

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modelsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
}

.header {
  display: flex;
  gap: 10px;
}

.modelsButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.sourceModelButton {
  display: inline-block;
  width: 200px;
  min-height: 52px;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #f4ebac;
  border-radius: 5px;
}

.notIncluded {
  background-color: #b20009;
}

.selected {
  background-color: #7fe2e2;
}

.modelsHeader {
  display: block;
  width: 100%;
  text-align: center;
}

.ganttButton {
  width: 25%;
  margin: 20px auto;
}
</style>
