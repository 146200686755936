import gql from 'graphql-tag';
import { apolloClient } from '@/vue-apollo';

export default {
  async getCarsBrands() {
    const result = await apolloClient.query({
      query: gql`query {
        brands(
          distinct_on: name,
          where: { validated: { _eq: true, }, },
        ) {
          name
          logo
        }
      }`,
    });

    return result.data.brands;
  },

  async getAllOemBrands() {
    const result = await apolloClient.query({
      query: gql`query {
        brands(
          where: { value_chain_level: { _eq: "OEM" } },
          order_by: { name: asc }
        ) {
          id
          name
          logo
        }
      }`,
    });

    return result.data.brands;
  },

  async getAllbrands() {
    const result = await apolloClient.query({
      query: gql`query {
        brands(order_by: {name: asc}) {
          id
          name
          value_chain_level
          logo
          region
          country
          city
          longitude
          latitude
          automaker {
            id
            name
          }
          created_by
          validated
        }
      }`,
    });

    return result.data.brands;
  },

  async getBrandsTiersOneNamesAndIds() {
    const result = await apolloClient.query({
      query: gql`query {
        brands(where: { value_chain_level: { _eq: "Tier1" } }, order_by: {name: asc}) {
          id
          name
        }
      }`,
    });
    return result.data.brands;
  },

  async getBrandsOemNamesAndIds() {
    const result = await apolloClient.query({
      query: gql`query {
        brands(where: { value_chain_level: { _eq: "OEM" } }, order_by: {name: asc}) {
          id
          name
        }
      }`,
    });

    return result.data.brands;
  },

  async getAllBrandsNamesAndIds() {
    const result = await apolloClient.query({
      query: gql`query {
        brands(order_by: {name: asc}) {
          id
          name
        }
      }`,
    });

    return result.data.brands;
  },

  async getAllSuppliers() {
    const result = await apolloClient.query({
      query: gql`query {
        brands(order_by: {name: asc}, where: {value_chain_level: {_eq: "Tier1"}}) {
          id
          name
          logo
          country
          region
        }
      }`,
    });

    return result.data.brands;
  },

  async insertBrand(brand) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $brand: brands_insert_input!,
      ) {
        insert_brands_one(
          object: $brand
        ) {
          id
          name
          value_chain_level
          logo
          region
          country
          city
          longitude
          latitude
          automaker {
            id
            name
          }
          created_by
        }
      }`,
      variables: {
        brand,
      },
    });

    return result.data.insert_brands_one;
  },

  async updateBrandsByPk(brand) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation (
        $id: Int!,
        $updatedValues: brands_set_input,
      ) {
        update_brands_by_pk(
          pk_columns: {id: $id},
          _set: $updatedValues
        ) {
          id
          name
          value_chain_level
          logo
          region
          country
          city
          longitude
          latitude
          automaker {
            id
            name
          }
          created_by
        }
      }`,
      variables: {
        id: brand.id,
        updatedValues: brand,
      },
    });

    return result;
  },

  async deleteBrandByPk(id) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($id: Int!) {
        delete_brands_by_pk(id: $id) {
          id
        }
      }`,
      variables: {
        id,
      },
    });

    return result;
  },
};
