<template>
  <teleport
    to="#headerPageContent"
  >
    <HeaderBarItem>
      <UpdateReferenceDropdown
        v-model="updateReference.model"
        :schema="updateReference.schema"
        :name="updateReferenceLabel"
      />
    </HeaderBarItem>
  </teleport>

  <div :class="$style.page">
    <BaseContainer>
      <NavigationCardsGrid>
        <NavigationCard
          color="aqua"
          :to="{ name: 'BasedataAdmin' }"
        >
          <template #image>
            <EditSvg />
          </template>
          <template #title>
            Cluster admin
          </template>
          <template #lead>
            OEMs, Brands, Factories ...
          </template>
        </NavigationCard>

        <NavigationCard
          color="orange"
          :to="{ name: 'LightingClusters' }"
        >
          <template #image>
            <ClusterSvg />
          </template>
          <template #title>
            LMI Lighting Clusters
          </template>
          <template #lead>
            CRUD LMI Clusters
          </template>
        </NavigationCard>

        <NavigationCard
          color="lime"
          :to="{ name: 'Update' }"
        >
          <template #image>
            <UpdateSvg />
          </template>
          <template #title>
            Update
          </template>
          <template #lead>
            Update client database
          </template>
        </NavigationCard>

        <NavigationCard
          color="cherry"
          :to="{ name: 'TableManagement' }"
        >
          <template #image>
            <DatabaseSvg />
          </template>
          <template #title>
            Table Management
          </template>
          <template #lead>
            View and update tables
          </template>
        </NavigationCard>

        <NavigationCard
          color="barley"
          :to="{ name: 'Equipment' }"
        >
          <template #image>
            <EquipmentSvg />
          </template>
          <template #title>
            Equipment
          </template>
          <template #lead>
            <div>
              Add lighting devices to car
            </div>
            <BaseLabel
              color="cherry"
            >
              Work in progress
            </BaseLabel>
          </template>
        </NavigationCard>
      </NavigationCardsGrid>
    </BaseContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import gql from 'graphql-tag';
import BaseContainer from '@/components/base/Container';
import NavigationCardsGrid from '@/components/NavigationCardsGrid';
import NavigationCard from '@/components/NavigationCard';
import UpdateReferenceDropdown from '@/components/UpdateReferenceDropdown';
import HeaderBarItem from '@/components/HeaderBarItem';
import ClusterSvg from '@/assets/images/icons/cluster.svg?inline';
import EditSvg from '@/assets/images/icons/edit.svg?inline';
import UpdateSvg from '@/assets/images/icons/update.svg?inline';
import DatabaseSvg from '@/assets/images/icons/database.svg?inline';
import EquipmentSvg from '@/assets/images/icons/equipment.svg?inline';
import BaseLabel from '@/components/base/Label';

export default {
  components: {
    BaseContainer,
    NavigationCardsGrid,
    NavigationCard,
    UpdateReferenceDropdown,
    HeaderBarItem,
    ClusterSvg,
    EditSvg,
    UpdateSvg,
    DatabaseSvg,
    EquipmentSvg,
    BaseLabel,
  },
  data() {
    return {
      updateReference: [],
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('app', ['updateReferenceId']),
    ...mapState('app', ['updateReferenceLabel']),
  },
  watch: {
    'updateReference.model': {
      deep: false,
      handler: async function updateReferenceChanged(newValue) {
        this.$store.dispatch('app/referenceId', { updateReferenceId: newValue });
        this.$apollo.mutate({
          mutation: gql`mutation ($userId: String!, $update_ref_id: String!) {
            update_users_by_pk(pk_columns: {id: $userId}, _set: {update_ref_id: $update_ref_id}) {
              id
            }
          }`,
          variables: {
            userId: '624d5aaf-7eda-47b2-8a03-01c7a370abf5',
            update_ref_id: newValue,
          },
        });
      },
    },
  },
  async created() {
    this.updateReference = await this.loadUpdateReference();
    if (this.updateReferenceId) {
      this.updateReference.model = this.updateReferenceId;
    }
  },
  methods: {
    async loadUpdateReference() {
      const { data } = await this.$apollo.query({
        query: gql`query {
          importHistory: import_history(
            distinct_on: uuid
          ) {
            update_ref
            uuid
          }
        }`,
      });

      return {
        schema: data.importHistory.map((x) => ({ label: x.update_ref, value: x.uuid })),
        model: data.importHistory.map((x) => x.uuid)[data.importHistory.length - 1],
      };
    },
  },
};
</script>

<style lang="scss" module>
.page {
  padding-top: map-get($page-vertical-paddings, 'regular');
  padding-bottom: map-get($page-vertical-paddings, 'regular');
}

.hide {
  display: none;
}
</style>
