<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <div :class="$style.page">
    <BaseContainer>
      <amplify-authenticator :auth-config="authConfig">
        <amplify-sign-in
          slot="sign-in"
          hide-sign-up
        />
      </amplify-authenticator>
    </BaseContainer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import BaseContainer from '@/components/base/Container';

export default {
  components: {
    BaseContainer,
  },
  data() {
    return {
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false,
        },
      },
      unsubscribeAuth: null,
    };
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange(async (authState, authData) => {
      if (authState === 'signedin') {
        await this.logIn(authData);
        await this.$router.push({ name: 'Home' });
      }
    });
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
  methods: {
    ...mapActions('user', ['logIn']),
  },
};
</script>

<style lang="scss" module>
.page {
  padding-top: map-get($page-vertical-paddings, 'regular');
  padding-bottom: map-get($page-vertical-paddings, 'regular');
}
</style>

<style lang="scss">
amplify-authenticator {
  --container-height: initial;
}
</style>
