<template>
  <span
    :class="[
      $style.wrapper,
      {
        [$style.isDark]: isDark,
      },
    ]"
    @click="clickInput"
  >
    <input
      :id="id"
      ref="input"
      v-model="modelProxy"
      type="radio"
      :name="name"
      :value="value"
      :class="$style.input"
      :disabled="isDisabled"
      @change="onChange"
    >
    <span :class="$style.box">
      <DashSvg :class="$style.iconDash" />
      <TickSvg :class="$style.iconTick" />
    </span>
  </span>
</template>

<script>
import DashSvg from '@/assets/images/icons/dash.svg?inline';
import TickSvg from '@/assets/images/icons/tick.svg?inline';

export default {
  components: {
    TickSvg,
    DashSvg,
  },
  props: {
    name: {
      type: String,
      default: undefined,
    },
    value: {
      type: [Boolean, String, Number, Object, Function, Array],
      default: undefined,
    },
    modelValue: {
      type: [Boolean, String, Number, Object, Function, Array],
      default: undefined,
    },
    id: {
      type: String,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': null,
    change: null,
  },
  computed: {
    modelProxy: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    clickInput() {
      const parentLabel = this.$refs.input.closest('label');

      if (parentLabel) {
        const forAttrValue = parentLabel.getAttribute('for');
        if (forAttrValue == null || forAttrValue === this.id) {
          return;
        }
      }

      this.$refs.input.click();
      this.$refs.input.focus();
    },
    onChange(e) {
      this.$emit('change', e);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: inline-flex;
  border-radius: 50%;

  &.isDark {

    .box {
      background-color: $background-light;
    }
  }
}

.input {
  @include hide-accessible;

  &:checked {

    + .box {
      background-color: #55cfe5;
      border-color: transparent;
    }
  }

  &:checked + .box {

    .iconTick {
      opacity: 1;
    }
  }

  &:disabled + .box {
    opacity: 0.5;
  }
}

.box {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  color: $white;
  background-color: $white;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}

.iconDash,
.iconTick {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.iconTick {
  width: 14px;
  height: auto;
}
</style>
