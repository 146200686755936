<template>
  <BaseContainer :class="$style.page">
    <BaseLoader
      is-block
      :is-active="isPostProcessingCarsToCheck"
    >
      <TableSection
        :page="page"
        :data="carsToCheck"
        :total-items="totalCarsToCheck"
        :per-page="perPage"
        :per-page-options="$options.PER_PAGE_OPTIONS"
        :class="$style.table"
        @update:page="setPage"
        @update:per-page="setPerPage"
      >
        <template #heading>
          All cars to check
        </template>

        <CarsToCheckTableColumns />
      </TableSection>
      <FormBox
        :class="$style.form"
      >
        <BaseLabel
          v-for="company in companies"
          :id="company.uuid"
          :key="company.uuid"
          :class="$style.label"
          :color="company.color"
        >
          {{ company.name }}
        </BaseLabel>
        <template #footer>
          <BaseButton
            variant="success"
            is-block
            @click="updateClientDatabase"
          >
            Update customer LMI GMBH
          </BaseButton>
          <BaseButton
            :class="$style.button"
            variant="success"
            type="submit"
            is-block
            @click="updateAllClientDatabase"
          >
            Update all customers
          </BaseButton>
        </template>
      </FormBox>
      <div :class="$style.listCarsToBeDeleted">
        <template v-if="carsToDisplay.length">
          <ClusterCarCard
            v-for="(car, index) in carsToDisplay"
            :key="car.id"
            :car="car"
            :is-first="index === 0"
            :is-last="index === carsToDisplay.length - 1"
            :cluster-sop="carsToDisplay.sop"
            :cluster-eop="carsToDisplay.eop"
            :class="$style.carsToBeDeleted"
            :hide-actions-button="true"
          />
        </template>
      </div>
    </BaseLoader>
  </BaseContainer>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import gql from 'graphql-tag';
import cars from '@/graphql/queries/cars';
import BaseContainer from '@/components/base/Container';
import ClusterCarCard from '@/components/ClusterCarCard';
import BaseLoader from '@/components/base/Loader';
import BaseButton from '@/components/base/Button';
import BaseLabel from '@/components/base/Label';
import TableSection from '@/components/TableSection';
import FormBox from '@/components/FormBox';
import CarsToCheckTableColumns from '@/components/CarsToCheckTableColumns';

export default {
  components: {
    BaseContainer,
    BaseLoader,
    BaseButton,
    BaseLabel,
    TableSection,
    ClusterCarCard,
    FormBox,
    CarsToCheckTableColumns,
  },
  PER_PAGE_OPTIONS: [10, 20, 30],
  apollo: {
    carsToCheck: {
      query() {
        return gql`
          query ($limit: Int!, $offset: Int!) {

            consolidate_cars_to_check_aggregate {
              aggregate {
                count
              }
            }

            consolidate_cars_to_check(
              limit: $limit,
              offset: $offset,
            ) {
              update_ref_id
              lighting_cluster_reference
              generation
              production_brand
              brand_id
              global_nameplate
              model_id
              source_plant_country_territory
              country_id
              body_type
              body_id
              flag
              from
            }

            companies {
              name
              uuid
            }
          }
        `;
      },
      debounce: 250,
      variables() {
        return {
          limit: this.perPage,
          offset: this.perPage * (this.page - 1),
        };
      },
      update(data) {
        this.totalCarsToCheck = data.consolidate_cars_to_check_aggregate.aggregate.count;

        return data.carsToCheck;
      },
      manual: true,
      async result({ data }) {
        this.isPostProcessingCarsToCheck = true;
        const r = data.consolidate_cars_to_check.map((car) => ({
          ...car,
        }));

        this.carsToCheck = r;
        this.totalCarsToCheck = data.consolidate_cars_to_check_aggregate.aggregate.count;
        this.companies = data.companies.map((company) => ({
          ...company,
          color: 'lime',
        }));
        this.isPostProcessingCarsToCheck = false;

        this.companies.map((company) => company.uuid.replace(/-/g, '')).map(async (company) => {
          if (['a3e086e9facd4a59a45b3a8f8f37d1b3'].includes(company)) {
            return false;
          }
          await this.$apollo.subscribe({
            query: gql`
              subscription {
                update_status_${company}(where: {update_ref_id: {_eq: "${this.updateReferenceId}"}}) {
                  update_ref_id
                  status
                  update_date
                }
              }
            `,
          }).subscribe((subscribeData) => {
            if (subscribeData.data) {
              this.companies = this.companies.map((item) => {
                let res = null;
                if (item.uuid.replace(/-/g, '') === company) {
                  const key = `update_status_${company}`;
                  res = {
                    ...item,
                    color: subscribeData.data[key][0]?.status === 'START' ? 'cherry' : 'lime',
                  };
                } else {
                  res = item;
                }

                return res;
              });
            }
          });
          return true;
        });
      },
    },
  },
  data() {
    return {
      carsToCheck: [],
      totalCarsToCheck: 0,
      perPage: this.$options.PER_PAGE_OPTIONS[0],
      page: 1,
      isPostProcessingCarsToCheck: false,
      companies: [],
      carsToDisplay: [],
    };
  },
  computed: {
    ...mapState('app', ['updateReferenceId']),
  },
  created() {
    this.getCarsToDisplay();
  },
  methods: {
    getCarsToDisplay() {
      const lmiCarsPromise = cars.getToBeDeletedCars();
      Promise.all([lmiCarsPromise]).then((results) => {
        const [lmiCars] = cloneDeep(results);
        this.carsToDisplay = lmiCars;
      });
    },
    setPage(page) {
      const query = this.$apollo.queries.carsToCheck;

      const more = query.fetchMore({
        variables: {
          offset: (page - 1) * this.perPage,
          limit: this.perPage,
        },
      });

      if (more) {
        more.then(() => {
          this.page = page;
        });
      }
    },
    setPerPage(value) {
      this.perPage = value;
      this.setPage(1);
    },
    async updateClientDatabase() {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($objects: [update_all_clients_history_insert_input!] = {}) {
            insert_update_all_clients_history(objects: $objects) {
              affected_rows
            }
          }
        `,
        variables: {
          objects: [
            {
              client_update_ref_id: this.updateReferenceId,
              update_date: new Date(),
              status: 'TEST',
            },
          ],
        },
      });
    },
    async updateAllClientDatabase() {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($objects: [update_all_clients_history_insert_input!] = {}) {
            insert_update_all_clients_history(objects: $objects) {
              affected_rows
            }
          }
        `,
        variables: {
          objects: [
            {
              client_update_ref_id: this.updateReferenceId,
              update_date: new Date(),
              status: 'PROD',
            },
          ],
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.page {
  flex-grow: 1;
  padding-top: map-get($page-vertical-paddings, 'small');
  padding-bottom: map-get($page-vertical-paddings, 'small');
}

.table {
  display: inline-block;
  width: 75%;
}

.form {
  display: inline-block;
  float: right;
  width: 20%;
}

.button {
  margin-top: 20px;
}

.label {
  width: 100%;
  margin-bottom: 10px;
}

.listCarsToBeDeleted {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.carsToBeDeleted {
  flex: 1 0 auto;
  width: 25%;
  padding: 10px;
  border: solid 1px;
}
</style>
