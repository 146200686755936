<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.tableContainer"
  >
    <div :class="$style.searchContainer">
      <BaseInput
        v-model="searchValue"
        placeholder="Search"
        type="text"
      />
      <BaseButton
        variant="success"
        type="insertRow"
        @click="openForm({}, 'create')"
      >
        Insert new car
      </BaseButton>
    </div>
    <EasyDataTable
      :headers="headers"
      :items="cars"
      :rows-per-page="10"
      :search-value="searchValue"
      sort-by="id"
      sort-type="asc"
      :table-class-name="$style.dataTable"
      @click-row="openForm($event, 'update')"
    >
      <template #item-factoryBrand="{ source_factory }">
        <p>{{ source_factory?.brand?.name }}</p>
      </template>
      <template #item-factoryName="{ source_factory }">
        <p>{{ source_factory?.name }}</p>
      </template>
      <template #item-to_be_deleted="row">
        {{ row.to_be_deleted ? "Yes" : "No" }}
      </template>
    </EasyDataTable>
    <FormBox
      v-if="isFormOpen"
      :class="$style.formBox"
      :heading="`${formMode === 'create' ? 'Create' : 'Update'} row`"
    >
      <div :class="$style.fieldsContainer">
        <BaseField
          :class="$style.field"
        >
          <template #label>
            Brand
          </template>
          <BaseMultiselect
            :model-value="brandId"
            :options="brands"
            :class="$style.multiselect"
            is-searchable
            is-block
            @update:modelValue="setBrandId"
          />
        </BaseField>

        <BaseField
          v-if="formMode === 'create'"
          :class="$style.field"
        >
          <template #label>
            Carline
          </template>
          <BaseInput
            v-model="carline"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Commercial name
          </template>
          <BaseInput
            v-model="commercialName"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Project code
          </template>
          <BaseInput
            v-model="projectCode"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Generation
          </template>
          <BaseInput
            v-model="generation"
            type="number"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Phase
          </template>
          <BaseInput
            v-model="phase"
            type="number"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Segment
          </template>
          <BaseInput
            v-model="segment"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Power train
          </template>
          <BaseInput
            v-model="powerTrain"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Body
          </template>
          <BaseMultiselect
            :model-value="bodyId"
            :options="bodies"
            :class="$style.multiselect"
            is-searchable
            is-block
            @update:modelValue="setBodyId"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Std. body
          </template>
          <BaseInput
            v-model="stdBody"
            type="text"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Factory
          </template>
          <BaseMultiselect
            :model-value="factoryId"
            :options="factories"
            :class="$style.multiselect"
            is-searchable
            is-block
            @update:modelValue="setFactoryId"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            Country
          </template>
          <BaseMultiselect
            :model-value="countryId"
            :options="countries"
            :class="$style.multiselect"
            is-searchable
            is-block
            @update:modelValue="setCountryId"
          />
        </BaseField>

        <BaseField
          :class="$style.field"
        >
          <template #label>
            To be deleted
          </template>
          <BaseMultiselect
            :model-value="toBeDeleted"
            :options="toBeDeletedOptions"
            :class="$style.multiselect"
            is-block
            no-reset
            @update:modelValue="setToBeDeleted"
          />
        </BaseField>
      </div>

      <template #footer>
        <div :class="$style.formButtonsContainer">
          <BaseButton
            variant="success"
            type="updateOrCreateRow"
            @click="formMode === 'create' ? createRow() : updateRow()"
          >
            {{ formMode === 'create' ? 'Create' : 'Update' }}
          </BaseButton>
          <BaseButton
            variant="secondary"
            type="closeForm"
            @click="closeForm"
          >
            Close
          </BaseButton>
        </div>
      </template>
    </FormBox>
  </BaseLoader>
</template>
<script>
import cars from '@/graphql/queries/cars';
import brands from '@/graphql/queries/brands';
import factories from '@/graphql/queries/factories';
import utils from '@/graphql/queries/utils';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseButton from '@/components/base/Button';
import BaseInput from '@/components/base/Input';
import BaseMultiselect from '@/components/base/Multiselect';
import { isEmpty, isArray } from 'lodash';

export default ({
  components: {
    BaseLoader,
    FormBox,
    BaseField,
    BaseButton,
    BaseInput,
    BaseMultiselect,
  },
  data() {
    return {
      headers: [
        {
          text: 'ID', value: 'id', sortable: true, width: 50,
        },
        {
          text: 'Status', value: 'status', sortable: true, width: 100,
        },
        {
          text: 'Parent ID', value: 'parent_id', sortable: true, width: 100,
        },
        {
          text: 'Brand', value: 'brand.name', sortable: true, width: 200,
        },
        {
          text: 'Model', value: 'model', sortable: true, width: 150,
        },
        {
          text: 'Com. name', value: 'commercial_name', sortable: true, width: 150,
        },
        {
          text: 'Project code', value: 'project_code', sortable: true, width: 150,
        },
        {
          text: 'Generation', value: 'generation', sortable: true, width: 50,
        },
        {
          text: 'Phase', value: 'phase', sortable: true, width: 50,
        },
        {
          text: 'Segment', value: 'segment', sortable: true, width: 50,
        },
        {
          text: 'PWT', value: 'power_train', sortable: true, width: 100,
        },
        {
          text: 'CBT', value: 'body', sortable: true, width: 100,
        },
        {
          text: 'Std. CBT', value: 'lmi_body', sortable: true, width: 100,
        },
        {
          text: 'Factory brand', value: 'factoryBrand', sortable: true, width: 200,
        },
        {
          text: 'Factory', value: 'factoryName', sortable: true, width: 200,
        },
        {
          text: 'SOP', value: 'sop', sortable: true, width: 100,
        },
        {
          text: 'EOP', value: 'eop', sortable: true, width: 100,
        },
        {
          text: 'To be deleted', value: 'to_be_deleted', sortable: true, width: 150,
        },
      ],
      searchValue: '',
      isFormOpen: false,
      formMode: null,
      isLoading: false,
      rowId: null,
      cars: [],
      brands: [],
      factories: [],
      bodies: [],
      countries: [],
      brandId: null,
      carline: null,
      commercialName: null,
      projectCode: null,
      generation: null,
      phase: null,
      segment: null,
      powerTrain: null,
      bodyId: null,
      stdBody: null,
      factoryId: null,
      countryId: null,
      toBeDeleted: false,
      toBeDeletedOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([
      cars.getLmiCarsTable(),
      brands.getBrandsOemNamesAndIds(),
      factories.getFactoriesNamesAndIds(),
      utils.getCarBodiesAndCountries(),
    ])
      .then((results) => {
        this.cars = [...results[0]];
        this.brands = results[1].map((brand) => ({ label: brand.name, value: brand.id }));
        this.factories = results[2].map((factory) => ({ label: factory.name, value: factory.id }));
        this.bodies = results[3].bodies.map((body) => ({ label: body.name, value: body.id }));
        this.countries = results[3].countries.map((country) => ({ label: country.name, value: country.id }));
        this.isLoading = false;
      });
  },
  methods: {
    setBrandId(brandId) {
      if (isArray(brandId) && isEmpty(brandId)) {
        this.brandId = null;
      } else {
        this.brandId = brandId;
      }
    },
    setFactoryId(factoryId) {
      if (isArray(factoryId) && isEmpty(factoryId)) {
        this.factoryId = null;
      } else {
        this.factoryId = factoryId;
      }
    },
    setBodyId(bodyId) {
      if (isArray(bodyId) && isEmpty(bodyId)) {
        this.bodyId = null;
      } else {
        this.bodyId = bodyId;
      }
    },
    setCountryId(countryId) {
      if (isArray(countryId) && isEmpty(countryId)) {
        this.countryId = null;
      } else {
        this.countryId = countryId;
      }
    },
    setToBeDeleted(toBeDeleted) {
      this.toBeDeleted = toBeDeleted;
    },
    createRow() {
      this.closeForm();
      this.isLoading = true;
      const carToCreate = {
        brand_id: this.brandId,
        carline: { data: { name: this.carline } },
        commercial_name: this.commercialName,
        project_code: this.projectCode,
        generation: this.generation,
        phase: this.phase,
        segment: this.segment,
        power_train: this.powerTrain,
        body_id: this.bodyId,
        lmi_body: this.stdBody,
        source_factory_id: this.factoryId,
        country_id: this.countryId,
        to_be_deleted: this.toBeDeleted,
      };
      cars.insertCar(carToCreate).then((insertResult) => {
        this.cars.push(insertResult);
        this.isLoading = false;
      });
    },
    openForm(row, formMode) {
      this.formMode = formMode;
      this.rowId = row.id;
      this.brandId = formMode === 'create'
        ? null
        : this.brands.find((brand) => brand.value === row.brand.id)?.value;
      this.carline = formMode === 'create' ? null : row.carline.name;
      this.commercialName = row.commercial_name;
      this.projectCode = row.project_code;
      this.generation = row.generation;
      this.phase = row.phase;
      this.segment = row.segment;
      this.powerTrain = row.power_train;
      this.bodyId = formMode === 'create'
        ? null
        : this.bodies.find((body) => body.label === row.body)?.value;
      this.stdBody = row.lmi_body;
      this.factoryId = formMode === 'create'
        ? null
        : this.factories.find((factory) => factory.value === row.source_factory?.id)?.value;
      this.countryId = formMode === 'create'
        ? null
        : this.countries.find((country) => country.value === row.country.id)?.value;
      this.toBeDeleted = row.to_be_deleted;
      this.isFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
    },
    updateRow() {
      this.closeForm();
      this.isLoading = true;
      const carToUpdate = {
        id: this.rowId,
        brand_id: this.brandId,
        commercial_name: this.commercialName,
        project_code: this.projectCode,
        generation: this.generation,
        phase: this.phase,
        segment: this.segment,
        power_train: this.powerTrain,
        body_id: this.bodyId,
        lmi_body: this.stdBody,
        source_factory_id: this.factoryId,
        country_id: this.countryId,
        to_be_deleted: this.toBeDeleted,
      };
      cars.updateCarsByPk(carToUpdate).then(() => {
        const localCarToUpdate = this.cars.find((car) => car.id === this.rowId);
        const index = this.cars.indexOf(localCarToUpdate);
        this.cars[index] = { ...this.cars[index], ...carToUpdate };
        this.isLoading = false;
      });
    },
  },
});
</script>
<style lang="scss" module>

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.searchContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.dataTable {
  --easy-table-body-row-height: 32px;
}

.deleteIconContainer {
  display: flex;
  justify-content: flex-end;

  svg {
    padding: 2px;
    cursor: pointer;
  }
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.field {
  width: 250px;
}

.multiselect {
  height: 100%;
}

.formButtonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
</style>
