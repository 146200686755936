<template>
  <div :class="[$style.actionField, dropdownActionsFieldClass]">
    <span :class="$style.label">
      <slot name="label" />
    </span>
    <div :class="$style.field">
      <slot name="field" />
    </div>
  </div>
</template>

<script>
export default {
  inject: ['dropdownActionsFieldClass'],
};
</script>

<style lang="scss" module>
.actionField {
  padding-right: 10px;
  padding-left: 10px;
}

.label {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: $text;
  text-transform: uppercase;
}

.field {
  margin-top: 3px;
}
</style>
