<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.tableContainer"
  >
    <EasyDataTable
      :headers="headers"
      :items="data"
      :rows-per-page="10"
      :table-class-name="$style.dataTable"
      @click-row="openForm()"
    >
      <template #item-delete="{ id }">
        <div :class="$style.deleteIconContainer">
          <BaseLabel
            color="cherry"
            @click.stop="deleteRow(id)"
          >
            Remove
          </BaseLabel>
        </div>
      </template>
      <template #header-light_source>
        <span :class="$style.th">
          <TableTypeSvg
            :class="$style.headerIcon"
          /> Source
        </span>
      </template>
      <template #header-optical_system>
        <span :class="$style.th">
          <TableJigsawSvg
            :class="$style.headerIcon"
          /> Optical system
        </span>
      </template>
      <template #header-light_functionality>
        <span :class="$style.th">
          <TableComplexitySvg
            :class="$style.headerIcon"
          /> Functionnality
        </span>
      </template>
      <template #header-id>
        <span :class="$style.th">
          Id
        </span>
      </template>
      <template #header-light_function>
        <span :class="$style.th">
          Light Function
        </span>
      </template>
      <template #item-light_source="{light_source}">
        {{ light_source?.name || "-" }}
      </template>
      <template #item-optical_system="{optical_system}">
        {{ optical_system?.name || "-" }}
      </template>
      <template #item-light_functionality="{light_functionality}">
        {{ light_functionality?.name || "-" }}
      </template>
      <template #item-light_function="{light_function}">
        {{ light_function?.name || "-" }}
      </template>
    </EasyDataTable>
    <BaseButton
      v-if="data.length === 0 && !isFormOpen"
      variant="success"
      @click="createMainFunctions()"
    >
      Create
    </BaseButton>
    <BaseLoader
      v-if="isFormOpen"
      :is-active="isProcessing"
    >
      <FormBox>
        <div
          v-for="(light_function, index) in lightFunctionsOptions"
          :key="light_function.id"
          :class="$style.card"
        >
          <div
            :class="$style.cardHeader"
          >
            {{ light_function.name }}
          </div>
          <div
            :class="$style.cardBody"
          >
            <BaseLabeledCheckbox
              v-for="light_functionality in getAllLightingFunctionnalities(light_function.name)"
              :key="light_functionality.id"
              v-model="initialFunctions[index].light_functionality"
              is-dark
              :value="light_functionality.id"
              :label-position="'right'"
              @change="changeFuntionality(index, light_function.id)"
            >
              {{ light_functionality.name }}
            </BaseLabeledCheckbox>
          </div>

          <template
            v-for="(light_functionality, functinalityIndex) in initialFunctions[index].light_functionality"
            :key="light_functionality"
          >
            <div
              :class="$style.card"
            >
              <div
                :class="$style.cardHeader"
              >
                {{ getFunctionalityName(light_functionality).name }}
              </div>
              <div>
                <p
                  :class="$style.title"
                >
                  Light Source
                </p>
                <div
                  :class="$style.cardBody"
                >
                  <template
                    v-for="light_source in getAllLightingSource(light_function.name, light_functionality)"
                  >
                    <BaseLabeledRadio
                      v-if="initialFunctions[index].config[functinalityIndex]"
                      :key="light_source.id"
                      v-model="initialFunctions[index].config[functinalityIndex].light_source"
                      :value="light_source.id"
                      :label-position="'right'"
                      is-dark
                    >
                      {{ light_source.name }}
                    </BaseLabeledRadio>
                  </template>
                </div>
                <p
                  :class="$style.title"
                >
                  Optical system
                </p>
                <div
                  :class="$style.cardBody"
                >
                  <template
                    v-for="optical_system in getAllLightingOpticalSystem(light_function.name, light_functionality)"
                  >
                    <BaseLabeledRadio
                      v-if="initialFunctions[index].config[functinalityIndex]"
                      :key="optical_system.id"
                      v-model="initialFunctions[index].config[functinalityIndex].optical_system"
                      :value="optical_system.id"
                      :label-position="'right'"
                      is-dark
                    >
                      {{ optical_system.name }}
                    </BaseLabeledRadio>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>

        <template #footer>
          <div :class="$style.formButtonsContainer">
            <BaseButton
              variant="success"
              @click="updateMainFunctions()"
            >
              Confirm
            </BaseButton>
            <BaseButton
              variant="secondary"
              type="closeForm"
              @click="closeForm"
            >
              Close
            </BaseButton>
          </div>
        </template>
      </FormBox>
    </BaseLoader>
  </BaseLoader>
</template>
<script>
import { difference, cloneDeep } from 'lodash';
import ldvs from '@/graphql/queries/ldvs';
import BaseLoader from '@/components/base/Loader';
import FormBox from '@/components/FormBox';
import BaseButton from '@/components/base/Button';
import BaseLabel from '@/components/base/Label';
import TableTypeSvg from '@/assets/images/icons/table-type.svg?inline';
import TableJigsawSvg from '@/assets/images/icons/table-jigsaw.svg?inline';
import TableComplexitySvg from '@/assets/images/icons/table-complexity.svg?inline';
import BaseLabeledCheckbox from '@/components/base/LabeledCheckbox';
import BaseLabeledRadio from '@/components/base/LabeledRadio';
import mlfs from '@/graphql/queries/mainLightFunctions';

export default ({
  components: {
    BaseLoader,
    FormBox,
    BaseButton,
    BaseLabel,
    TableTypeSvg,
    TableJigsawSvg,
    TableComplexitySvg,
    BaseLabeledCheckbox,
    BaseLabeledRadio,
  },
  props: {
    mainLightFunctionsData: {
      type: Array,
      default: null,
    },
    lightDeviceType: {
      type: String,
      default: null,
    },
    lightDeviceId: {
      type: Number,
      default: null,
    },
    lightingDeviceConfigurationOptions: {
      type: Array,
      default: null,
    },
  },
  emits: {
    mlfsUpdated: null,
    mlfRemoved: null,
  },
  data() {
    return {
      headers: [
        {
          text: 'Id', value: 'id', sortable: false,
        },
        {
          text: 'Light Function', value: 'light_function', sortable: false,
        },
        {
          text: 'Functionnality', value: 'light_functionality', sortable: false,
        },
        {
          text: 'Source', value: 'light_source', sortable: false,
        },
        {
          text: 'Optical system', value: 'optical_system', sortable: false,
        },
        {
          text: '', value: 'delete',
        },
      ],
      isFormOpen: false,
      isLoading: false,
      rowId: null,
      lightFunctionsOptions: [],
      initialFunctions: [],
      data: [],
      staticData: [],
      isProcessing: false,
    };
  },
  watch: {
    mainLightFunctionsData: {
      deep: true,
      handler() {
        this.initData();
      },
    },
    lightDeviceType() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    openForm() {
      this.isFormOpen = true;
    },
    async initData() {
      this.data = cloneDeep(this.mainLightFunctionsData);
      if (this.lightDeviceType) {
        this.lightFunctionsOptions = await ldvs.getLdvFunction(this.lightDeviceType);
        this.initialFunctions = [];

        this.lightFunctionsOptions.forEach((lightFunctionOption) => {
          const usedLightFunctions = this.data.filter(
            (mainLightFunction) => mainLightFunction.light_function.id === lightFunctionOption.id,
          );
          const usedLightFunctionalities = usedLightFunctions.map(
            (usedLightFunction) => usedLightFunction.light_functionality?.id,
          );
          const remainingLightFunctionalities = this.getAllLightingFunctionnalities(lightFunctionOption.name)
            .filter((lightFunctionality) => !usedLightFunctionalities.includes(lightFunctionality.id));
          this.initialFunctions.push({
            light_function: lightFunctionOption.id,
            light_functionality: usedLightFunctions.map(
              (usedLightFunction) => usedLightFunction.light_functionality.id,
            ),
            config: usedLightFunctions.map((usedLightFunction) => ({
              id: usedLightFunction.id,
              light_functionality: usedLightFunction.light_functionality?.id,
              light_source: usedLightFunction.light_source?.id,
              optical_system: usedLightFunction.optical_system?.id,
              active: true,
            })).concat(remainingLightFunctionalities.map((lightFunctionality) => ({
              light_functionality: lightFunctionality.id,
              light_source: this.getAllLightingSource(lightFunctionOption.name, lightFunctionality.id)[0].id,
              optical_system: this.getAllLightingOpticalSystem(lightFunctionOption.name, lightFunctionality.id)[0].id,
              active: false,
            }))),
          });
        });
      }
    },
    getAllLightingFunctionnalities(deviceFunction) {
      const result = this.lightingDeviceConfigurationOptions
        .filter((item) => item.light_device_type
          === this.lightDeviceType && item.light_function && item.light_function.name === deviceFunction)
        .map((item) => ({ name: item.light_functionality?.name, id: item.light_functionality?.id }));

      return result.filter((item, index) => index === result.findIndex(
        (other) => item.name === other.name,
      ));
    },
    getAllLightingSource(deviceFunction, deviceFunctionality) {
      const result = this.lightingDeviceConfigurationOptions
        .filter((item) => item.light_device_type
          === this.lightDeviceType && item.light_function && item.light_function.name === deviceFunction
          && item.light_functionality && item.light_functionality.id === deviceFunctionality)
        .map((item) => ({ name: item.light_source?.technology, id: item.light_source?.id }));

      return result.filter((item, index) => index === result.findIndex(
        (other) => item.name === other.name,
      ));
    },
    getAllLightingOpticalSystem(deviceFunction, deviceFunctionality) {
      const result = this.lightingDeviceConfigurationOptions
        .filter((item) => item.light_device_type
          === this.lightDeviceType && item.light_function && item.light_function.name === deviceFunction
          && item.light_functionality && item.light_functionality.id === deviceFunctionality)
        .map((item) => ({ name: item.optical_system?.name, id: item.optical_system?.id }));

      return result.filter((item, index) => index === result.findIndex(
        (other) => item.name === other.name,
      ));
    },
    closeForm() {
      this.isFormOpen = false;
    },
    updateRow() {
    },
    deleteRow(id) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const ok = confirm(`Main light fuctions ${id} will be deleted. Are you sure ?`);
      if (ok) {
        this.isLoading = true;
        mlfs.deleteMlfByPk(id)
          .then(() => {
            this.staticData = cloneDeep(this.data);
            this.staticData = this.staticData.filter((el) => id !== el.id);
            this.resetInitialData();
            this.$emit('mlfRemoved', id);
            this.isLoading = false;
          });
      }
    },
    getFunctionalityName(functionalityId) {
      const result = this.lightingDeviceConfigurationOptions
        .filter((item) => item.light_functionality && item.light_functionality.id === functionalityId)
        .map((item) => ({ name: item.light_functionality?.name, id: item.light_functionality?.id }));

      return result.filter((item, index) => index === result.findIndex(
        (other) => item.name === other.name,
      ))[0];
    },
    changeFuntionality(index, lightFunctionId) {
      const lightFunctionality = this.initialFunctions[index].light_functionality;
      const { config } = this.initialFunctions[index];
      const oldLightFunctionality = config
        .filter((item) => item.light_functionality !== null && item.active)
        .map((item) => item.light_functionality);
      if (lightFunctionality.length > oldLightFunctionality.length) {
        const newFunctionality = difference(lightFunctionality, oldLightFunctionality)[0];
        const additionalFunctionality = config
          .filter((item) => item.light_functionality !== null && !item.active)
          .map((item) => item.light_functionality);
        const isFunctionalityAlreadyExist = config
          .filter((item) => item.light_functionality === newFunctionality && !item.active)
          .length === 0;

        if (isFunctionalityAlreadyExist) {
          this.initialFunctions[index].config.push({
            id: null,
            light_functionality: newFunctionality,
            light_source: null,
            optical_system: null,
            active: true,
          });
        } else {
          this.initialFunctions[index].config = this.initialFunctions[index].config
            .map((item) => {
              if (item.light_functionality === newFunctionality && !item.active) {
                return {
                  ...item,
                  active: true,
                };
              }

              return item;
            });
        }
        const sortList = lightFunctionality.concat(additionalFunctionality);
        this.initialFunctions[index].config = this.initialFunctions[index].config
          .sort((a, b) => sortList.indexOf(a.light_functionality)
              - sortList.indexOf(b.light_functionality));
      } else {
        const removedFunctionality = difference(oldLightFunctionality, lightFunctionality)[0];
        if (removedFunctionality) {
          const sortList = cloneDeep(lightFunctionality);
          sortList.push(removedFunctionality);
          this.initialFunctions[index].config = this.initialFunctions[index].config
            .map((item) => {
              if (item.light_functionality === removedFunctionality) {
                return {
                  ...item,
                  active: false,
                };
              }
              return item;
            })
            .sort((a, b) => sortList.indexOf(a.light_functionality)
              - sortList.indexOf(b.light_functionality));
        }
      }
      this.initialFunctions[index].light_function = lightFunctionId;
    },
    updateMainFunctions() {
      this.isProcessing = true;
      let modifiedFunction = [];
      let lightFunction = null;
      let result = {};
      let code = null;
      let initalData = cloneDeep(this.data);
      let toRemoved = [];
      for (let i = 0; i < this.initialFunctions.length; i += 1) {
        lightFunction = this.initialFunctions[i].light_function;
        for (let j = 0; j < this.initialFunctions[i].config.length; j += 1) {
          if (this.initialFunctions[i].config[j].active) {
            result = {
              light_function: cloneDeep(lightFunction),
              ...cloneDeep(this.initialFunctions[i].config[j]),
            };
            delete result.active;
            code = Object.values(result).join('-');
            modifiedFunction.push({
              ...result,
              code,
            });
          }
        }
      }

      modifiedFunction = modifiedFunction.filter((item) => item.light_functionality !== null);

      initalData = initalData.map((item) => {
        const res = {
          light_function: item.light_function?.id,
          id: item.id,
          light_functionality: item.light_functionality?.id,
          light_source: item.light_source?.id,
          optical_system: item.optical_system?.id,
        };
        code = Object.values(res).join('-');

        return {
          ...res,
          code,
        };
      });

      modifiedFunction = modifiedFunction.map((item) => {
        const codeToCheck = item.code;
        let status = null;
        const isCodeExist = initalData.filter((el) => el.code === codeToCheck)
          .length !== 0;

        if (isCodeExist) {
          status = 'nothing';
        } else if (!item.id) {
          status = 'new';
        } else {
          status = 'update';
        }

        return {
          ...item,
          status,
        };
      });
      initalData.map((item) => {
        const codeToCheck = item.code;
        let status = null;
        const isCodeExist = modifiedFunction.filter((el) => el.code === codeToCheck)
          .length === 0;

        if (isCodeExist) {
          status = 'remove';
          toRemoved.push({
            ...item,
            status,
          });
        }

        return true;
      });
      modifiedFunction = modifiedFunction
        .filter((item) => item.status !== 'nothing')
        .map((item) => {
          toRemoved = toRemoved.filter((el) => el.id !== item.id);
          return item;
        })
        .concat(toRemoved);
      if (modifiedFunction.length === 0) {
        this.isProcessing = false;
        return false;
      }
      this.staticData = cloneDeep(this.data);
      return Promise.all(
        modifiedFunction.map(async (item) => {
          let newMlfs = {};
          switch (item.status) {
            case 'new': {
              newMlfs = {
                lighting_device_id: this.lightDeviceId,
                light_function_id: item.light_function,
                light_functionality_id: item.light_functionality,
                light_source_id: item.light_source,
                optical_system_id: item.optical_system,
              };
              const insertResult = await mlfs.insertMlf(newMlfs);
              this.staticData.push(insertResult);
              break;
            }
            case 'update': {
              newMlfs = {
                id: item.id,
                light_function_id: item.light_function,
                light_functionality_id: item.light_functionality,
                light_source_id: item.light_source,
                optical_system_id: item.optical_system,
              };
              const updateResult = await mlfs.updateMlfByPk(newMlfs);
              this.staticData = this.staticData.map((el) => {
                if (el.id === item.id) {
                  return updateResult;
                }
                return el;
              });
              break;
            }
            case 'remove': {
              await mlfs.deleteMlfByPk(item.id);
              this.staticData = this.staticData.filter((el) => item.id !== el.id);
              break;
            }
            default:
              console.log('Sorry, we are out of.');
          }
          this.isProcessing = false;
          return true;
        }),
      )
        .then(() => this.resetInitialData());
    },
    createMainFunctions() {
      this.isFormOpen = true;
    },
    resetInitialData() {
      this.initialFunctions = [];
      this.lightFunctionsOptions.forEach((lightFunctionOption) => {
        const usedLightFunctions = this.data.filter(
          (mainLightFunction) => mainLightFunction.light_function.id === lightFunctionOption.id,
        );
        const usedLightFunctionalities = usedLightFunctions.map(
          (usedLightFunction) => usedLightFunction.light_functionality?.id,
        );
        const remainingLightFunctionalities = this.getAllLightingFunctionnalities(lightFunctionOption.name)
          .filter((lightFunctionality) => !usedLightFunctionalities.includes(lightFunctionality.id));
        this.initialFunctions.push({
          light_function: lightFunctionOption.id,
          light_functionality: usedLightFunctions.map(
            (usedLightFunction) => usedLightFunction.light_functionality.id,
          ),
          config: usedLightFunctions.map((usedLightFunction) => ({
            id: usedLightFunction.id,
            light_functionality: usedLightFunction.light_functionality?.id,
            light_source: usedLightFunction.light_source?.id,
            optical_system: usedLightFunction.optical_system?.id,
            active: true,
          })).concat(remainingLightFunctionalities.map((lightFunctionality) => ({
            light_functionality: lightFunctionality.id,
            light_source: this.getAllLightingSource(lightFunctionOption.name, lightFunctionality.id)[0].id,
            optical_system: this.getAllLightingOpticalSystem(lightFunctionOption.name, lightFunctionality.id)[0].id,
            active: false,
          }))),
        });
      });

      this.$emit('mlfsUpdated', this.staticData);
    },
  },
});
</script>
<style lang="scss" module>

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.searchContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.dataTable {
  --easy-table-body-row-height: 32px;

  span {
    white-space: normal;
  }
}

.brandLogo {
  width: 25px;
  height: 25px;
}

.deleteIconContainer {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.field {
  width: 250px;
}

.automakerMultiselect {
  height: 100%;
}

.formButtonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.headerIcon {
  margin-right: 5px;
}

.th {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Urania', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
  color: #6a7073;
  text-align: left;
  letter-spacing: 0.24px;
  white-space: nowrap;
}

.card {
  margin-bottom: 10px;
  background: #fff;
  border: 1px solid #d9dee0;
  border-radius: 5px;

  .card {
    margin: 10px;
  }
}

.cardHeader {
  width: 100%;
  padding: 10px;
  font-weight: bold;
  background: #efefef;
  border-bottom: 1px solid #d9dee0;
}

.cardBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 20px;
}

.title {
  display: block;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.radioOption {
  padding: 8px;
  background: $white;
}
</style>
