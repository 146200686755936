import gql from 'graphql-tag';
import { apolloClient } from '@/vue-apollo';
import { remove } from 'lodash';

async function updateRef(updateReferenceId) {
  return (await apolloClient.query({
    query: gql`
      query ($updateReferenceId: String!) {
        import_history(
          where: { uuid: { _eq: $updateReferenceId}},
          distinct_on: uuid
        ) {
          update_ref
        }
      }
    `,
    variables: {
      updateReferenceId,
    },
  })).data.import_history[0].update_ref;
}

export default {
  namespaced: true,
  state() {
    let resolveInitializingPromise;
    const initializingPromise = new Promise((resolve) => {
      resolveInitializingPromise = resolve;
    });

    return {
      isInitialized: false,
      isInitializing: true,
      initializingPromise,
      resolveInitializingPromise,
      updateReferenceId: null,
      updateReferenceLabel: null,
      addedLightingConfigurations: [],
      updatedDevices: [],
    };
  },
  getters: {
    isUpdateReferenceExist(state) {
      return state.updateReferenceId;
    },
  },
  mutations: {
    setIsInitialized(state) {
      state.isInitializing = false;
      state.isInitialized = true;
      state.resolveInitializingPromise();
    },
    async setUpdateReferenceId(state, updateReferenceId) {
      state.updateReferenceId = updateReferenceId;
      state.updateReferenceLabel = await updateRef(updateReferenceId);
    },
    addLightingConfiguration(state, lightingConfigurationToAdd) {
      state.addedLightingConfigurations.push(lightingConfigurationToAdd);
    },
    updateLightingConfigurationMlfs(state, { lightingConfigurationId, mlfs }) {
      const indexToUpdate = state.addedLightingConfigurations.findIndex(
        (config) => config.id === lightingConfigurationId,
      );
      if (indexToUpdate !== -1) {
        state.addedLightingConfigurations[indexToUpdate].lighting_device = {
          ...state.addedLightingConfigurations[indexToUpdate].lighting_device,
          main_light_functions: mlfs,
        };
      }
    },
    addUpdatedLightingDevice(state, lightingDevice) {
      remove(state.updatedDevices, (deviceToRemove) => deviceToRemove.id === lightingDevice.id);
      state.updatedDevices.push(lightingDevice);
    },
  },
  actions: {
    async initialize({ dispatch, commit }) {
      await dispatch('user/restore', null, { root: true });
      commit('setIsInitialized');
    },
    referenceId({ commit }, data) {
      commit('setUpdateReferenceId', data.updateReferenceId);
    },
    saveLightingConfiguration({ commit }, lightingConfiguration) {
      commit('addLightingConfiguration', lightingConfiguration);
    },
    updateLightingConfigurationMlfs({ commit }, { lightingConfigurationId, mlfs }) {
      commit('updateLightingConfigurationMlfs', { lightingConfigurationId, mlfs });
    },
    addUpdatedLightingDevice({ commit }, lightingDevice) {
      commit('addUpdatedLightingDevice', lightingDevice);
    },
  },
};
