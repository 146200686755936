<template>
  <button
    :class="$style.action"
    type="button"
  >
    <slot />
  </button>
</template>

<script>
export default {
};
</script>

<style lang="scss" module>
.action {
  @include reset;
  display: block;
  width: 100%;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 1.375;
  color: rgba($title, 0.8);
  transition: color 0.2s ease-in-out;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      color: rgba($title, 1);
    }
  }
}
</style>
