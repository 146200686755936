<template>
  <TippyWrapper
    ref="tippyWrapper"
    :key="instanceKey"
    :append-to="getAppendTo"
    :trigger="trigger"
    :trigger-target="triggerTarget"
    :placement="placement"
    :max-width="maxWidth"
    :theme="themes"
    interactive
    tag="div"
    content-tag="div"
    @click-outside="onClickOutside"
    @show="onShow"
    @hide="onHide"
  >
    <template #default="{ tippy }">
      <slot
        name="trigger"
        :tippy="tippy"
        :isActive="isVisible"
      />
    </template>

    <template #content="tippy">
      <slot
        name="content"
        :tippy="tippy"
      />
    </template>
  </TippyWrapper>
</template>

<script>
import { Tippy as TippyWrapper } from 'vue-tippy';

const shouldSkipClickOutside = new Map();

export default {
  components: {
    TippyWrapper,
  },
  props: {
    trigger: {
      type: String,
      default: 'click',
    },
    triggerTarget: {
      type: null,
      default: undefined,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    maxWidth: {
      type: [String, Number],
      default: undefined,
    },
    isPaddingless: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    show: null,
    hide: null,
  },
  data() {
    return {
      instanceKey: 1,
      isVisible: false,
    };
  },
  computed: {
    themes() {
      const themes = ['dropdown'];
      if (this.isPaddingless) {
        themes.push('is-paddingless');
      }
      return themes.join(' ');
    },
  },
  watch: {
    triggerTarget() {
      // force recreate Tippy with correct trigger target
      this.instanceKey += 1;
    },
  },
  methods: {
    getAppendTo() {
      return this.$root.$el.parentElement;
    },
    onClickOutside(tippy, event) {
      const isClickedOnTippy = event.target.closest('.tippy-box') != null;

      if (isClickedOnTippy) {
        shouldSkipClickOutside.set(tippy.id, true);
      }
    },
    show() {
      this.$refs.tippyWrapper.show();
    },
    hide() {
      this.$refs.tippyWrapper.hide();
    },
    onShow() {
      this.isVisible = true;
      this.$emit('show');
    },
    onHide(tippy) {
      if (shouldSkipClickOutside.get(tippy.id)) {
        shouldSkipClickOutside.delete(tippy.id);
        return false;
      }

      this.isVisible = false;
      this.$emit('hide');
      return true;
    },
  },
};
</script>

<style lang="scss">
/* stylelint-disable selector-class-pattern */

[data-v-tippy] {
  display: inline-flex;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box[data-theme~='dropdown'] {
  position: relative;
  min-width: 40px;
  max-width: none !important;
  font-size: 13px;
  line-height: normal;
  color: #1b2a3b;
  background-color: #fff;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15),
    0 2px 5px rgba(0, 0, 0, 0.2);
  transition-property: transform, visibility, opacity;

  &[data-animation=fade][data-state=hidden] {
    opacity: 0;
  }

  &[data-inertia][data-state=visible] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }

  > .tippy-content {
    position: relative;
    z-index: 1;
  }
}

.tippy-box[data-theme~='dropdown']:not([data-theme~='is-paddingless']) {

  > .tippy-content {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
/* stylelint-enable */
</style>
