<template>
  <BaseLoader
    :is-active="isLoading"
    :class="$style.wrapper"
  >
    <div :class="$style.modelsContainer">
      <div :class="$style.header">
        <h1 :class="$style.modelsHeader">
          {{ brand.name }} models
        </h1>
      </div>
      <div :class="$style.modelsButtons">
        <button
          v-for="sourceModel in sourceModels"
          :key="sourceModel.modelid"
          :class="[
            $style.sourceModelButton,
            {
              [$style.selected]: selectedSourceModel && selectedSourceModel.modelid === sourceModel.modelid,
              [$style.noLmiCars]: sourceModel.numberOfLmiCars === 0,
            }
          ]"
          @click="selectSourceModel(sourceModel)"
        >
          {{ sourceModel.global_nameplate }}
        </button>
      </div>
      <BaseButton
        type="toggleGantts"
        variant="success"
        :class="$style.ganttButton"
        @click="toggleGantts"
      >
        {{ showGantts ? "Hide Gantts" : "Show Gantts" }}
      </BaseButton>
    </div>
    <Gantts
      v-if="showGantts"
      :brand-id="brand.id"
    />
    <ModelLightingClusters
      v-if="selectedSourceModel"
      :brand="brand"
      :model="selectedSourceModel"
      @finished-loading="setIsLoading(false)"
    />
  </BaseLoader>
</template>
<script>
import Gantts from '@/components/Gantts';
import ModelLightingClusters from '@/components/ModelLightingClusters';
import BaseLoader from '@/components/base/Loader';
import BaseButton from '@/components/base/Button';

export default ({
  components: {
    Gantts,
    ModelLightingClusters,
    BaseLoader,
    BaseButton,
  },
  props: {
    brand: {
      type: Object,
      default: null,
    },
    sourceModels: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedSourceModel: null,
      showGantts: false,
      isLoading: false,
    };
  },
  watch: {
    brand() {
      this.selectedSourceModel = null;
      this.showGantts = false;
      this.isLoading = false;
    },
  },
  methods: {
    selectSourceModel(sourceModel) {
      if (this.selectedSourceModel !== sourceModel) {
        this.setIsLoading(true);
      }
      this.selectedSourceModel = sourceModel;
    },
    toggleGantts() {
      this.showGantts = !this.showGantts;
    },
    setIsLoading(value) {
      this.isLoading = value;
    },
  },
});
</script>
<style lang="scss" module>

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modelsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  gap: 10px;
}

.modelsButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.sourceModelButton {
  display: inline-block;
  width: 200px;
  min-height: 52px;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #f4ebac;
  border: 0;
  border-radius: 5px;
}

.notIncluded {
  background-color: #b20009;
}

.selected {
  background-color: #7fe2e2;
}

.noLmiCars {
  color: #963959;
  background: #ffc4d9;
}

.modelsHeader {
  display: block;
  width: 100%;
  text-align: center;
}

.ganttButton {
  width: 25%;
  margin: 20px auto;
}
</style>
