<template>
  <teleport to="#headerPageContent">
    <HeaderBarItem>
      <HeaderIconButton
        @click="isLcModalActive = true"
      >
        <template #icon>
          <PlusCircleSvg />
        </template>

        <template #label>
          Add lighting configuration
        </template>
      </HeaderIconButton>
    </HeaderBarItem>
    <HeaderBarItem>
      <HeaderIconButton
        @click="isEquipmentInvisible = !isEquipmentInvisible"
      >
        <template
          v-if="!isEquipmentInvisible"
          #icon
        >
          <InvisibleCircleSvg />
        </template>
        <template
          v-else
          #icon
        >
          <VisibilityCircleSvg />
        </template>

        <template
          v-if="!isEquipmentInvisible"
          #label
        >
          Hide equipment
        </template>
        <template
          v-else
          #label
        >
          Show equipment
        </template>
      </HeaderIconButton>
    </HeaderBarItem>
    <HeaderBarItem>
      <HeaderIconButton
        @click="setShowTakeRatesModal(true)"
      >
        <template #icon>
          <BrushCircleSvg />
        </template>
        <template
          #label
        >
          Update take rates
        </template>
      </HeaderIconButton>
    </HeaderBarItem>
    <HeaderBarItem>
      <HeaderIconButton
        :class="$style.exit"
        @click="back"
      >
        <template #icon>
          <CrossSvg />
        </template>
        <template #label>
          Exit
        </template>
      </HeaderIconButton>
    </HeaderBarItem>
  </teleport>
  <div :class="$style.page">
    <BaseModal
      :model-value="showTakeRatesModal"
      @update:modelValue="setShowTakeRatesModal"
    >
      <TakeRatesUpdateTable
        :sorted-lighting-configurations="sortedLightingConfigurations"
        @updateTakeRates="updateTakeRates"
      />
    </BaseModal>
    <BaseLoader
      :is-active="isLoading"
      is-block
    >
      <template v-if="car">
        <BaseContainer>
          <ProductsCarHeader
            :car-data="car"
            :volumes-data="[]"
            :car-sop="carSop"
            :car-eop="carEop"
            :car-names="carNames"
          />

          <div :class="$style.illustration">
            <ProductsCarImages
              :class="$style.images"
              :front-image="car.front_image"
              :side-image="car.side_image"
              :back-image="car.back_image"
              :car-id="car.id"
              :brand="car.brand.name"
            />
            <div :class="$style.chartWrapper">
              <VolumesChart
                v-if="chartData"
                :class="$style.chart"
                :aspect-ratio="1"
                :datasets="chartData.datasets"
                :labels="chartData.labels"
              />
            </div>
          </div>
        </BaseContainer>
        <div :class="$style.deviceTypesContainer">
          <template v-for="deviceType in Object.keys(sortedLightingConfigurations)">
            <div
              v-if="sortedLightingConfigurations[deviceType].length"
              :key="deviceType"
              :class="$style.lightingConfigurationsSection"
            >
              <div v-if="deviceType === 'Empty'">
                <h3 :class="$style.lightingConfigurationsSectionHeading">
                  Empty lighting configurations
                </h3>
                <p :class="$style.lightingConfigurationsSectionSubheading">
                  There are
                  {{ sortedLightingConfigurations[deviceType].length }}
                  empty lighting configurations used on this vehicle.
                </p>
              </div>
              <div v-else>
                <h3 :class="$style.lightingConfigurationsSectionHeading">
                  {{ deviceType }} on this vehicle
                </h3>
                <p :class="$style.lightingConfigurationsSectionSubheading">
                  There are {{ sortedLightingConfigurations[deviceType].length }} {{ deviceType }} used on this vehicle.
                </p>
              </div>
              <div :class="$style.equipmentDeviceCardsContainer">
                <EquipmentDeviceCard
                  v-for="lightingConfiguration in sortedLightingConfigurations[deviceType]"
                  :key="lightingConfiguration.id"
                  :brand-name="lightingConfiguration.car.brand.name"
                  :brand-logo="lightingConfiguration.car.brand.logo"
                  :brand-id="car.brand.id"
                  :model-id="car.model_id"
                  :car-id="car.id"
                  :lighting-configuration="lightingConfiguration"
                  :is-equipment-invisible="isEquipmentInvisible"
                  :lighting-device-configuration-options="lightingDeviceConfigurationOptions"
                  :version="lightingConfiguration.version"
                  @lightingConfigurationUpdated="updateLightingConfiguration(lightingConfiguration.id, $event)"
                  @lightingConfigurationRemoved="removeLightingConfigurationFromList(lightingConfiguration.id)"
                />
              </div>
            </div>
          </template>
        </div>
      </template>
    </BaseLoader>
  </div>
  <BaseModal
    v-model="isLcModalActive"
  >
    <FormBox
      :class="$style.formBox"
      :heading="'Add lighting configuration'"
    >
      <div :class="$style.fieldsContainer">
        <BaseField
          v-for="(ldvType, index) in initConfig"
          :key="index"
          :class="$style.field"
        >
          <template #label>
            {{ ldvType.name }} number
          </template>
          <BaseInput
            v-model="initConfig[index].value"
            type="number"
            :min="0"
            @change="addOrRemoveConfigLine(index, initConfig[index].value)"
          />
        </BaseField>
      </div>

      <div
        v-for="(ldvType, typeIndex) in initConfig"
        :key="typeIndex"
        :class="$style.section"
      >
        <template
          v-if="initConfig[typeIndex].value > 0"
        >
          <h3 :class="$style.heading">
            {{ ldvType.name }} config
          </h3>

          <div
            v-for="(config, configIndex) in initConfig[typeIndex].model"
            :key="configIndex"
          >
            <p>#{{ configIndex + 1 }}</p>
            <div :class="$style.fieldsContainer">
              <template
                v-for="(field, fieldIndex) in initConfig[typeIndex].model[configIndex]"
                :key="fieldIndex"
              >
                <BaseField
                  :class="$style.field"
                >
                  <template #label>
                    {{ field.name }}
                  </template>
                  <BaseInput
                    v-if="field.type === 'number'"
                    v-model="field.value"
                    type="number"
                  />
                  <BaseMultiselect
                    v-if="field.type === 'multiselect'"
                    v-model="field.value"
                    :options="getOptions(field.name)"
                    :class="$style.automakerMultiselect"
                    is-searchable
                    is-block
                    @update:modelValue="setSelectedId(field, typeIndex, configIndex, $event)"
                  />
                </BaseField>
              </template>
            </div>
          </div>
        </template>
      </div>

      <template #footer>
        <div :class="$style.formButtonsContainer">
          <BaseButton
            variant="success"
            :is-disabled="numberOfLightingConfigsInForm <= 0"
            @click="createLdvConfig()"
          >
            Create
          </BaseButton>
          <BaseButton
            variant="secondary"
            @click="closeForm"
          >
            Cancel
          </BaseButton>
        </div>
      </template>
    </FormBox>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex';
import {
  cloneDeep, isEmpty, isArray, remove, sortBy,
} from 'lodash';
import gql from 'graphql-tag';
import BaseContainer from '@/components/base/Container';
import BaseLoader from '@/components/base/Loader';
import VolumesChart from '@/components/VolumesChart';
import ProductsCarHeader from '@/components/ProductsCarHeader';
import ProductsCarImages from '@/components/ProductsCarImages';
import HeaderBarItem from '@/components/HeaderBarItem';
import HeaderIconButton from '@/components/HeaderIconButton';
import EquipmentDeviceCard from '@/components/EquipmentDeviceCard';
import { groupByYear, convertGroupedByYearToChartData, styleYearSegments } from '@/charts';
import PlusCircleSvg from '@/assets/images/icons/plus-circle.svg?inline';
import VisibilityCircleSvg from '@/assets/images/icons/visibility-circle.svg?inline';
import InvisibleCircleSvg from '@/assets/images/icons/invisible-circle.svg?inline';
import BrushCircleSvg from '@/assets/images/icons/brush-circle.svg?inline';
import CrossSvg from '@/assets/images/icons/cross.svg?inline';
import BaseModal from '@/components/base/Modal';
import FormBox from '@/components/FormBox';
import BaseField from '@/components/base/Field';
import BaseButton from '@/components/base/Button';
import BaseInput from '@/components/base/Input';
import BaseMultiselect from '@/components/base/Multiselect';
import TakeRatesUpdateTable from '@/components/tables/TakeRatesUpdateTable';
import { mlfsInitConfig } from '@/config';
import utils from '@/graphql/queries/utils';
import brands from '@/graphql/queries/brands';
import ldvs from '@/graphql/queries/ldvs';
import cars from '@/graphql/queries/cars';

export default {
  components: {
    BaseContainer,
    BaseLoader,
    VolumesChart,
    ProductsCarHeader,
    ProductsCarImages,
    HeaderBarItem,
    HeaderIconButton,
    EquipmentDeviceCard,
    PlusCircleSvg,
    VisibilityCircleSvg,
    InvisibleCircleSvg,
    BaseModal,
    FormBox,
    BaseField,
    BaseButton,
    BaseInput,
    BaseMultiselect,
    TakeRatesUpdateTable,
    BrushCircleSvg,
    CrossSvg,
  },
  data() {
    return {
      car: null,
      sameModelCars: null,
      lightingConfigurations: [],
      volumes: null,
      carSop: null,
      carEop: null,
      isEquipmentInvisible: false,
      isLcModalActive: false,
      lightingDeviceConfigurationOptions: [],
      initConfig: [],
      countries: [],
      suppliers: [],
      isLoading: false,
      showTakeRatesModal: false,
    };
  },
  computed: {
    ...mapState('app', ['updateReferenceId']),
    ...mapState('app', ['addedLightingConfigurations']),
    carNames() {
      if (this.sameModelCars == null) return null;

      const res = this.sameModelCars.reduce((acc, car) => {
        if (car.commercial_name) {
          if (acc[car.commercial_name] == null) acc[car.commercial_name] = new Set();
          acc[car.commercial_name].add(car.country.region);
        }

        return acc;
      }, {});

      Object.keys(res).forEach((key) => {
        res[key] = Array.from(res[key]);
      });

      return res;
    },
    currentCarVolumes() {
      if (this.volumes == null) return null;

      return this.volumes.filter((item) => item.car.id === this.car.id);
    },
    chartData() {
      if (this.volumes == null) return null;
      const groupedByYears = groupByYear(this.volumes, 'car.id');
      const currentCarGroupedByYears = groupByYear(this.currentCarVolumes, 'car.id');

      const yearsSet = new Set();
      this.volumes.forEach((item) => yearsSet.add(item.year));

      return {
        labels: Array.from(yearsSet).sort(),
        datasets: [
          {
            label: `${this.car.brand.name}  ${this.car.model}`,
            borderColor: '#FFA829',
            backgroundColor: '#FFA829',
            pointBackgroundColor: '#fff',
            segment: {
              borderDash: styleYearSegments,
            },
            data: convertGroupedByYearToChartData(groupedByYears),
          },
          {
            label: 'This car',
            borderColor: '#B4E43B',
            backgroundColor: '#B4E43B',
            pointBackgroundColor: '#fff',
            segment: {
              borderDash: styleYearSegments,
            },
            data: convertGroupedByYearToChartData(currentCarGroupedByYears),
          },
        ],
      };
    },
    sortedLightingConfigurations() {
      const deviceTypes = mlfsInitConfig.map((item) => item.name);
      const initialValue = deviceTypes.reduce((acc, deviceType) => {
        acc[deviceType] = [];
        return acc;
      }, {
        Empty: [],
      });
      initialValue.Other = [];
      return cloneDeep(this.lightingConfigurations)
        .reduce((acc, lightingConfiguration) => {
          let group;
          if (!lightingConfiguration.lighting_device) group = 'Empty';
          else group = lightingConfiguration.lighting_device.type || 'Other';

          if (acc[group] == null) acc[group] = [];

          acc[group].push(lightingConfiguration);
          acc[group] = sortBy(acc[group], (lightingConfigurationToSort) => lightingConfigurationToSort.version);

          return acc;
        }, initialValue);
    },
    numberOfLightingConfigsInForm() {
      return this.initConfig.reduce((sum, initConfigType) => sum + initConfigType.value, 0);
    },
  },
  async created() {
    cars.getCarById(this.$route.params.carId).then((car) => {
      this.car = car;
      this.loadVolume(this.car);
      cars.getSameModelCars(this.car.brand.id, this.car.model).then((sameModelCars) => {
        this.sameModelCars = sameModelCars;
      });
    });
    ldvs.getLightingConfigurationsOfCar(this.$route.params.carId).then((result) => {
      this.lightingConfigurations = cloneDeep(result);
      this.addedLightingConfigurations.forEach((config) => {
        if (
          config.car.id === this.car.id
          && !this.lightingConfigurations.find((configToFind) => configToFind.id === config.id)
        ) this.lightingConfigurations.push(config);
      });
      this.lightingConfigurations.forEach((lightingConfiguration, index) => {
        if (!lightingConfiguration.take_rates[0]) {
          this.lightingConfigurations[index].take_rates = [
            {
              id: null,
              rate: 0,
              created_at: null,
              lighting_configuration_id: lightingConfiguration.id,
            },
          ];
        }
      });
    });

    this.carSop = this.$route.query.sop ? this.$route.query.sop : 'No date available';
    this.carEop = this.$route.query.eop ? this.$route.query.eop : 'No date available';
    ldvs.getAllLdvConfigOptions().then((result) => {
      this.lightingDeviceConfigurationOptions = result;
    });
    this.initConfig = cloneDeep(mlfsInitConfig);

    utils.getCarBodiesAndCountries()
      .then((queryResults) => {
        this.countries = queryResults.countries.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      });

    brands.getBrandsTiersOneNamesAndIds()
      .then((queryResults) => {
        this.suppliers = queryResults.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      });
  },
  methods: {
    updateTakeRates(takeRatesToUpdate) {
      takeRatesToUpdate.forEach((takeRate) => {
        const lcToUpdateIndex = this.lightingConfigurations.findIndex(
          (lightingConfiguration) => {
            if (isEmpty(lightingConfiguration.take_rates)) return false;
            return lightingConfiguration.id === takeRate.lighting_configuration_id;
          },
        );
        this.lightingConfigurations[lcToUpdateIndex].take_rates[0] = takeRate;
      });
      this.setShowTakeRatesModal(false);
    },
    closeForm() {
      this.isLcModalActive = false;
      this.initConfig = cloneDeep(mlfsInitConfig);
    },
    async loadVolume(car) {
      const { data } = await this.$apollo.query({
        query: gql`query {
            volumes: volumes_3e83f030e97749c4b572eafd7b7a90e4(where: {
              _and: { 
                units: { _is_null: false, _neq: 0 }, 
                brand_id: { _eq: ${car.brand.id} }, 
                model: { _eq: "${car.model}" },
                update_ref_id: { _eq: "${this.updateReferenceId}"},
              },
            }) {
              year
              units
              sop
              eop
              car {
                id
                model
              }
            }
          }
        `,
      });
      this.volumes = data.volumes;
    },
    addOrRemoveConfigLine(typeIndex, number) {
      if (number <= 0) {
        this.initConfig[typeIndex].value = 0;
        this.initConfig[typeIndex].model = [];
        return;
      }
      const initValues = cloneDeep(this.initConfig[typeIndex].init)
        .map((item) => {
          if (item.name === 'Order') {
            return {
              ...item,
              value: number,
            };
          }

          if (item.name === 'Version') {
            return {
              ...item,
              value: this.getOptions('Version')[Math.min(number - 1, 5)].value,
            };
          }

          if (item.name === 'Countries') {
            return {
              ...item,
              value: this.car.country.id,
            };
          }

          return item;
        });

      const numberOfExistingLine = this.initConfig[typeIndex].model.length;
      if (number > numberOfExistingLine) {
        this.initConfig[typeIndex].model.push(initValues);
      } else if (number < numberOfExistingLine) {
        this.initConfig[typeIndex].model.pop();
      }
    },
    setSelectedId(field, typeIndex, configIndex, id) {
      const device = this.initConfig[typeIndex];

      if (field.id === 'position' && ['Outer & Inner', 'Outer & Center'].includes(field.value)) {
        if (device.name === 'RL') {
          this.initConfig[typeIndex].value += 1;
          this.addOrRemoveConfigLine(typeIndex, this.initConfig[typeIndex].value);
          this.initConfig[typeIndex].model[configIndex] = this.initConfig[typeIndex].model[configIndex]
            .map((item) => {
              if (item.id === 'version') {
                return {
                  ...item,
                  value: 'Base',
                };
              }
              if (item.id === 'position') {
                return {
                  ...item,
                  value: 'Outer',
                };
              }
              return item;
            });
          const newPosition = this.initConfig[typeIndex].value - 1;
          this.initConfig[typeIndex].model[newPosition] = this.initConfig[typeIndex].model[newPosition]
            .map((item) => {
              if (item.id === 'version') {
                return {
                  ...item,
                  value: 'Base',
                };
              }
              if (item.id === 'position') {
                return {
                  ...item,
                  value: field.value.split(' & ')[1],
                };
              }

              if (item.id === 'order_in_row') {
                return {
                  ...item,
                  value: this.initConfig[typeIndex].model[configIndex].find(
                    (itemToFind) => itemToFind.id === 'order_in_row',
                  ).value,
                };
              }

              if (item.id === 'parts_per_car' && field.value.split(' & ')[1] === 'Center') {
                return {
                  ...item,
                  value: 1,
                };
              }
              return item;
            });
        }
      }

      if (isArray(id) && isEmpty(id)) {
        return {
          ...field,
          value: null,
        };
      }
      return {
        ...field,
        value: id,
      };
    },
    getOptions(type) {
      switch (type) {
        case 'Version':
          return [
            { label: 'Base', value: 'Base' },
            { label: 'Option1', value: 'Option1' },
            { label: 'Option2', value: 'Option2' },
            { label: 'Option3', value: 'Option3' },
            { label: 'Option4', value: 'Option4' },
            { label: '', value: null },
          ];
        case 'Countries':
          return this.countries;
        case 'Suppliers':
          return this.suppliers;
        case 'Position':
          return [
            { label: 'Outer', value: 'Outer' },
            { label: 'Inner', value: 'Inner' },
            { label: 'Center', value: 'Center' },
            { label: 'Outer & Inner', value: 'Outer & Inner' },
            { label: 'Outer & Center', value: 'Outer & Center' },
            { label: 'Middle', value: 'Middle' },
            { label: 'Lower', value: 'Lower' },
            { label: 'Upper', value: 'Upper' },
            { label: 'Side', value: 'Side' },
            { label: 'Front', value: 'Front' },
            { label: 'Back', value: 'Back' },
          ];
        default:
          return null;
      }
    },
    async createLdvConfig() {
      this.isLoading = true;
      this.isLcModalActive = false;
      const initConfig = cloneDeep(this.initConfig);
      const lightingConfigurationsToCreate = [];

      initConfig.forEach((ldv) => {
        ldv.model.forEach((fields) => {
          const fieldsInfos = {};

          fields.forEach((item) => {
            fieldsInfos[item.id] = item.value;
          });

          lightingConfigurationsToCreate.push({
            car_id: this.car.id,
            lighting_device_id: null,
            version: fieldsInfos.version,
            lighting_device: {
              supplier_id: fieldsInfos.supplier_id,
              part_number: `${this.car.brand.name
              }-${this.car.model
              }-${this.car.project_code
              }-P${this.car.phase
              }/${ldv.name
              }-${fieldsInfos.order_in_row
              }-${fieldsInfos.position}`,
              parts_per_car: fieldsInfos.parts_per_car,
              type: ldv.name,
              position: fieldsInfos.position,
              order_in_row: fieldsInfos.order_in_row,
              country_id: fieldsInfos.country_id,
            },
            global_take_rates: {
              lighting_configuration_id: null,
              rate: fieldsInfos.rate,
              update_ref: 'LMI_22_Q2',
            },
          });
        });
      });
      this.initConfig = cloneDeep(mlfsInitConfig);
      const numberOfConfigsToCreate = lightingConfigurationsToCreate.length;
      let numberOfConfigCreated = 0;
      lightingConfigurationsToCreate.forEach(async (lightingConfiguration) => {
        const newLightingDevice = await ldvs.insertLdv(lightingConfiguration.lighting_device);
        const lightingConfigurationToBeCreated = {
          car_id: lightingConfiguration.car_id,
          lighting_device_id: newLightingDevice.id,
          version: lightingConfiguration.version,
        };
        const newLightingConfiguration = await ldvs.insertLdvConfig(lightingConfigurationToBeCreated);
        const globalTakeRateToBeCreated = {
          lighting_configuration_id: newLightingConfiguration.id,
          rate: lightingConfiguration.global_take_rates.rate,
          update_ref: 'LMI_22_Q2',
        };
        const newGlobalTakeRates = await utils.insertGlobalTakeRate(globalTakeRateToBeCreated);
        newLightingConfiguration.take_rates = [newGlobalTakeRates];
        this.lightingConfigurations.push(newLightingConfiguration);
        this.$store.dispatch('app/saveLightingConfiguration', newLightingConfiguration);
        numberOfConfigCreated += 1;
        if (numberOfConfigsToCreate === numberOfConfigCreated) this.isLoading = false;
      });
    },
    updateLightingConfiguration(lightingConfigurationId, updatedLightingConfiguration) {
      const lcToUpdateIndex = this.lightingConfigurations.findIndex(
        (lc) => lc.id === lightingConfigurationId,
      );
      this.lightingConfigurations[lcToUpdateIndex] = {
        ...this.lightingConfigurations[lcToUpdateIndex],
        ...updatedLightingConfiguration,
      };
    },
    removeLightingConfigurationFromList(configId) {
      remove(this.lightingConfigurations, (lightingConfiguration) => lightingConfiguration.id === configId);
    },
    setShowTakeRatesModal(value) {
      this.showTakeRatesModal = value;
    },
    back() {
      this.$router.push({
        name: 'Equipment',
        path: '/equipment?test=1',
        query: {
          brand: this.car.brand.id,
          model: this.car.model_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.page {
  padding-top: map-get($page-vertical-paddings, 'small');
  padding-bottom: map-get($page-vertical-paddings, 'small');
}

.illustration {
  display: flex;
  padding: 0 15px;
  margin-top: 20px;
}

.images {
  flex-grow: 1;
}

.chartWrapper {
  padding: 15px 20px;
  margin-left: 8px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),
    0 3px 20px rgba(0, 0, 0, 0.1);
}

.chart {
  width: 350px;
}

.deviceTypesContainer {
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin: 35px;
  margin-bottom: 0;
}

.lightingConfigurationsSection {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:not(:first-child) {
    padding-top: 35px;
    border-top: 1px solid rgba($disabled, 0.4);
  }
}

.section {
  margin-top: 35px;

  &:not(:first-child) {

    .lightingConfigurationsSectionHeader {
      position: relative;
      padding-top: 35px;

      &::before {
        position: absolute;
        top: 0;
        right: 15px;
        left: 15px;
        display: block;
        width: 100%;
        height: 1px;
        content: '';
        background-color: rgba($disabled, 0.4);
      }
    }
  }
}

.lightingConfigurationsSectionHeading,
.heading {
  padding: 0;
  margin: 0;
  font-size: 21px;
  font-weight: 700;
  color: $title;
}

.lightingConfigurationsSectionSubheading {
  margin: 0;
  margin-top: 4px;
  font-size: 16px;
}

.equipmentDeviceCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.field {
  width: 250px;
}

.formButtonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.formBox {
  max-height: 600px;
  overflow-y: auto;
  border: 0;
}

.exit {
  color: #963959;
  background: #ffc4d9;
}
</style>
