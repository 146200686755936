<template>
  <div>
    <SectionHeader>
      <slot name="heading" />

      <template
        v-if="perPageOptions"
        #right
      >
        <BaseSelect
          v-model="perPageProxy"
          is-small
        >
          <option
            v-for="option in perPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }} per page
          </option>
        </BaseSelect>
      </template>
    </SectionHeader>

    <BaseTable
      is-block
      :data="data"
      :class="$style.table"
    >
      <slot />
    </BaseTable>

    <BasePagination
      v-if="totalItems > 0"
      :total-items="totalItems"
      :current-page="page"
      :per-page="perPage"
      :class="$style.pagination"
      @setPage="setPage"
    />
  </div>
</template>

<script>
import BaseSelect from '@/components/base/Select';
import BaseTable from '@/components/base/Table';
import BasePagination from '@/components/base/Pagination';
import SectionHeader from '@/components/SectionHeader';

export default {
  components: {
    BaseSelect,
    BaseTable,
    BasePagination,
    SectionHeader,
  },
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    totalItems: {
      type: Number,
      default: null,
    },
    page: {
      type: Number,
      default: null,
    },
    perPage: {
      type: Number,
      default: null,
    },
    perPageOptions: {
      type: Array,
      default: null,
    },
  },
  emits: {
    'update:page': null,
    'update:perPage': null,
  },
  computed: {
    perPageProxy: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.$emit('update:perPage', value);
      },
    },
  },
  methods: {
    setPage(page) {
      this.$emit('update:page', page);
    },
  },
};
</script>

<style lang="scss" module>
.table {
  margin-top: 6px;
}

.pagination {
  margin-top: 25px;
}
</style>
