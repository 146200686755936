<template>
  <div :class="$style.wrapper">
    <div :class="$style.clustersContainer">
      <EquipClusterCard
        v-for="carCluster in clustersToDisplay"
        :key="carCluster.lighting_cluster_reference_2"
        :car-cluster="carCluster"
        :model="model"
      />
    </div>
  </div>
</template>

<script>
import cars from '@/graphql/queries/cars';
import { getMaxEop, getMinSop } from '@/utils/getMinSopAndMaxEop';
import EquipClusterCard from '@/components/EquipClusterCard';
import {
  cloneDeep, remove, isEmpty,
} from 'lodash';

export default {
  components: {
    EquipClusterCard,
  },
  props: {
    brand: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
  },
  emits: {
    'finished-loading': null,
  },
  data() {
    return {
      clustersToDisplay: [],
      generation: null,
      projectCode: null,
      segment: null,
    };
  },
  watch: {
    model() {
      this.getCarsToDisplay();
    },
  },
  created() {
    this.getCarsToDisplay();
  },
  methods: {
    getCarsToDisplay() {
      this.clustersToDisplay = [];
      const sourceCarsPromise = cars.getSourceCarsByBrandAndModel(this.model.brandid, this.model.modelid);
      const lmiCarsPromise = cars.getLmiCarsByBrandAndModel(this.model.brandid, this.model.modelid);
      Promise.all([sourceCarsPromise, lmiCarsPromise]).then((results) => {
        const [sourceCars, lmiCars] = cloneDeep(results);
        const refs2List = new Set(lmiCars.map((car) => car.lighting_cluster_reference_2));
        refs2List.forEach((ref2) => {
          const clusterCars = lmiCars.filter((car) => car.lighting_cluster_reference_2 === ref2);
          const linkedSourceCars = sourceCars.filter((sourceCar) => sourceCar.lighting_cluster_reference_2 === ref2
              || (
                !sourceCar.lighting_cluster_reference_2
                && sourceCar.lighting_cluster_reference === clusterCars[0].lighting_cluster_reference
              ));
          const hasNoSourceCar = isEmpty(linkedSourceCars);
          const data = {
            lighting_cluster_reference_2: ref2,
            lighting_cluster_reference: (
              hasNoSourceCar ? clusterCars[0] : linkedSourceCars[0]
            ).lighting_cluster_reference,
            brand: clusterCars[0].brand,
            body: clusterCars[0].car_body,
            country: clusterCars[0].country,
            powerTrain: (hasNoSourceCar ? clusterCars : linkedSourceCars)[0].power_train,
            projectCode: clusterCars[0].project_code,
            generation: (hasNoSourceCar ? clusterCars : linkedSourceCars)[0].generation || clusterCars[0].generation,
            sop: hasNoSourceCar ? null : getMinSop(linkedSourceCars),
            eop: hasNoSourceCar ? null : getMaxEop(linkedSourceCars),
            cars: clusterCars,
            sourceCars: linkedSourceCars,
            segment: (hasNoSourceCar ? clusterCars : linkedSourceCars)[0].segment,
          };
          this.clustersToDisplay.push(data);
          linkedSourceCars.forEach((linkedSourceCar) => remove(
            sourceCars, (sourceCar) => sourceCar.id === linkedSourceCar.id,
          ));
        });
        this.clustersToDisplay.sort((a, b) => a.generation - b.generation);
        this.$emit('finished-loading');
      });
    },
  },
};
</script>
<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.clustersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}
</style>
