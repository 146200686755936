<template>
  <EasyDataTable
    :headers="headers"
    :items="data"
    :rows-per-page="mlfs.length"
    :table-min-height="0"
    :hide-footer="true"
    :class="$style.mlfsTable"
  />
</template>

<script>

export default {
  props: {
    mlfs: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Id', value: 'id', sortable: false,
        },
        {
          text: 'Light Function', value: 'light_function.name', sortable: false,
        },
        {
          text: 'Functionnality', value: 'light_functionality.name', sortable: false,
        },
        {
          text: 'Source', value: 'light_source.name', sortable: false,
        },
        {
          text: 'Optical system', value: 'optical_system.name', sortable: false,
        },
      ],
      data: [],
    };
  },
  watch: {
    mlfs() {
      this.data = this.mlfs;
    },
  },
  created() {
    this.data = this.mlfs;
  },
};
</script>

<style lang="scss" module>

.mlfsTable {
  width: 100%;
}
</style>
