<template>
  <BaseContainer :class="$style.mainContainer">
    <div :class="$style.buttonsContainer">
      <BaseButton
        :variant="tableType === 'oems' ? 'success': 'secondary'"
        type="switchTable"
        @click="switchTable('oems')"
      >
        OEMS
      </BaseButton>
      <BaseButton
        :variant="tableType === 'brands' ? 'success': 'secondary'"
        type="switchTable"
        @click="switchTable('brands')"
      >
        BRANDS
      </BaseButton>
      <BaseButton
        :variant="tableType === 'factories' ? 'success': 'secondary'"
        type="switchTable"
        @click="switchTable('factories')"
      >
        FACTORIES
      </BaseButton>
      <BaseButton
        :variant="tableType === 'cars' ? 'success': 'secondary'"
        type="switchTable"
        @click="switchTable('cars')"
      >
        CARS
      </BaseButton>
      <BaseButton
        :variant="tableType === 'ldvs' ? 'success': 'secondary'"
        type="switchTable"
        @click="switchTable('ldvs')"
      >
        PRODUCTS
      </BaseButton>
      <BaseButton
        :variant="tableType === 'mlfs' ? 'success': 'secondary'"
        type="switchTable"
        @click="switchTable('mlfs')"
      >
        MLFS
      </BaseButton>
      <BaseButton
        :variant="tableType === 'ldvcOptions' ? 'success': 'secondary'"
        type="switchTable"
        @click="switchTable('ldvcOptions')"
      >
        LDVC OPTIONS
      </BaseButton>
    </div>
    <OemTable v-if="tableType === 'oems'" />
    <BrandsTable v-else-if="tableType === 'brands'" />
    <FactoriesTable v-else-if="tableType === 'factories'" />
    <CarsTable v-else-if="tableType === 'cars'" />
    <LdvsTable v-else-if="tableType === 'ldvs'" />
    <MlfsTable v-else-if="tableType === 'mlfs'" />
    <LdvConfigOptionsTable v-else-if="tableType === 'ldvcOptions'" />
  </BaseContainer>
</template>
<script>
import BaseContainer from '@/components/base/Container';
import BaseButton from '@/components/base/Button';
import OemTable from '@/components/tables/OemTable';
import BrandsTable from '@/components/tables/BrandsTable';
import FactoriesTable from '@/components/tables/FactoriesTable';
import CarsTable from '@/components/tables/CarsTable';
import LdvsTable from '@/components/tables/LdvsTable';
import MlfsTable from '@/components/tables/MlfsTable';
import LdvConfigOptionsTable from '@/components/tables/LdvConfigOptionsTable';

export default ({
  components: {
    BaseContainer,
    BaseButton,
    OemTable,
    BrandsTable,
    FactoriesTable,
    CarsTable,
    LdvsTable,
    MlfsTable,
    LdvConfigOptionsTable,
  },
  data() {
    return {
      tableType: 'oems',
    };
  },
  methods: {
    switchTable(type) {
      this.tableType = type;
    },
  },
});
</script>
<style lang="scss" module>

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
}
</style>
